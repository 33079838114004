import React, { useContext } from 'react'
import { faSort, faSortDown, faSortUp, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TsxUtils from 'utils/tsx'
import { TableHeaderItemProps } from '../types'
import { SortDirection } from 'types/various'
import { TableContext } from 'contexts/TableContext'
import './TableHeaderItem.scss'

const TableHeaderItem: React.FC<TableHeaderItemProps> = ({
  children,
  className,
  sortBy,
  dataCy,
}) => {
  const { sort, onSort } = useContext(TableContext)

  const handleSort = (): void => {
    if (!sort || !onSort || !sortBy) return

    onSort({
      column: sortBy,
      direction: sort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
    })
  }

  const sortIndicator = (direction: SortDirection | null | undefined): IconDefinition => {
    if (sortBy !== sort?.column) return faSort

    switch (direction) {
      case SortDirection.ASC:
        return faSortUp
      case SortDirection.DESC:
        return faSortDown
      default:
        return faSort
    }
  }

  return (
    <th
      onClick={handleSort}
      data-cy={dataCy}
      className={
        'TableHeaderItem' +
        TsxUtils.extraStyle(className, className) +
        TsxUtils.extraStyle(sortBy, 'TableHeaderItem--clickable')
      }
    >
      {children}
      {sort && sortBy && (
        <FontAwesomeIcon
          className='TableHeaderItem__sort-icon'
          icon={sortIndicator(sort.direction)}
        />
      )}
    </th>
  )
}

export default TableHeaderItem
