import { gql } from '@apollo/client'

export const GET_INVENTORY_FOR_CHART = gql`
  query inventoryForChart(
    $agglomerations: [String!]
    $buildings: [String!]
    $cities: [String!]
    $fromDate: ISO8601DateTime
    $mediaFormats: [MediumFormatEnum!]
    $mediumAsIds: [String!]
    $toDate: ISO8601DateTime
    $granulation: InventoriesGranulationEnum!
    $slotDuration: Int!
  ) {
    inventoryForChart(
      agglomerations: $agglomerations
      buildings: $buildings
      cities: $cities
      fromDate: $fromDate
      mediaFormats: $mediaFormats
      mediumAsIds: $mediumAsIds
      toDate: $toDate
      granulation: $granulation
      slotDuration: $slotDuration
    ) {
      date
      freeSlots
      totalSlots
      plannedSlots
      reservedSlots
      p3PlannedSlots
      p4PlannedSlots
      p5PlannedSlots
      p6PlannedSlots
      p7PlannedSlots
      p8PlannedSlots
    }
  }
`
