import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'api/fragments'

const PAYLOAD_TYPE = 'UpdateCreationMediaPayload'

export const UPDATE_CREATION_MEDIA_USAGE = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation updateCreationMedia($creationId: ID!, $media: [MediumUsage!]!) {
    updateCreationMedia(input: { creationId: $creationId, media: $media }) {
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
