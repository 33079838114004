import { gql } from '@apollo/client'

export const CREATE_MEDIUM_CAMPAIGNS_REPORT = gql`
  mutation createMediumCampaignsReport(
    $asId: String!
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
  ) {
    createMediumCampaignsReport(input: { asId: $asId, startDate: $startDate, endDate: $endDate }) {
      report {
        name
        status
        startDate
        endDate
        generatedAt
        csvUrl
      }
      success
      errors
    }
  }
`
