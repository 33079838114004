import { Errors } from 'components/Form/InputError'
import { SelectAsyncValue } from 'components/Form/SelectAsync'
import { Agency } from 'types/agency'
import { Priority, User } from 'types/user'
import { BaseRoleSelectRawData } from '../service'

export type UserEditForm = Pick<User, 'id' | 'email' | 'status'> & {
  subscriptionAccount: boolean
  agencies: SelectAsyncValue<Agency>[]
  firstName: User['firstName']
  lastName: User['lastName']
  priorities: Priority[]
  roles: SelectAsyncValue<BaseRoleSelectRawData>[]
}

export interface FormErrors {
  firstName: Errors
  lastName: Errors
  priorities: Errors
  roles: Errors
  agencies: Errors
}

export const formErrorsDefault: FormErrors = {
  firstName: [],
  lastName: [],
  priorities: [],
  roles: [],
  agencies: [],
}
