import { Routes } from 'routes'
import { PAGES } from 'constant/authorization'
import { AllowedForArgs } from 'hooks/useAuthorize/types'
import { UseHomepageProps } from './types'

export const useHomepage = (allowedFor: (args: AllowedForArgs) => boolean): UseHomepageProps => {
  const result = Object.entries(PAGES).find(([, condition]) => {
    const rule = condition.LIST || condition.feature

    if (!rule) {
      return false
    }

    return allowedFor(rule)
  })
  const key = (result?.[0] || 'NOT_FOUND') as keyof typeof Routes

  return { homepageUrl: fetchPageUrl(key) }
}

const fetchPageUrl = (key: keyof typeof Routes): string => {
  const value = Routes[key]

  if (typeof value === 'string') {
    return value
  }

  if ('ALL' in value) {
    return value.ALL
  }

  return Routes.NOT_FOUND
}
