import { UserForm } from './types'
import { FormErrors } from '../../../types/various'
import { Dispatch, SetStateAction } from 'react'
import ErrorUtils from '../../../utils/error'
import UserService from '../service'

export default class UserInviteFormService {
  public static validate = (
    user: UserForm,
    errors: FormErrors | undefined,
    setErrors: Dispatch<SetStateAction<FormErrors | undefined>>
  ): void => {
    /**
     * If the user is a subscription account, we need to validate the first name and last name as well
     */
    if (user.subscriptionAccount) {
      void setErrors({
        ...errors,
        email: [...ErrorUtils.isRequired(user.email), ...ErrorUtils.isEmail(user.email)],
        priorities: [...ErrorUtils.isRequired(user.priorities)],
        roles: [...ErrorUtils.isRequired(user.roles)],
        agencies: [...UserService.agencyNameError(user)],
        firstName: [...ErrorUtils.isRequired(user.firstName)],
        lastName: [...ErrorUtils.isRequired(user.lastName)],
      })

      return
    }

    void setErrors({
      ...errors,
      email: [...ErrorUtils.isRequired(user.email), ...ErrorUtils.isEmail(user.email)],
      priorities: [...ErrorUtils.isRequired(user.priorities)],
      roles: [...ErrorUtils.isRequired(user.roles)],
      agencies: [...UserService.agencyNameError(user)],
      firstName: [],
      lastName: [],
    })
  }
}
