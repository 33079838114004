import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { produce } from 'immer'
import { GalleryOccupancySliceModel } from '../models/gallery-occupancy-slice.model'

const defaultState: GalleryOccupancySliceModel = {
  loading: true,
  selectedBuildingData: [],
  pageNumber: 1,
  totalItemsCount: 0,
  data: [],
  filters: {
    all: {
      agglomerations: [],
      cities: [],
    },
    selected: {
      fromDate: '',
      toDate: '',
      agglomerations: [],
      cities: [],
    },
  },
}

const initialState: GalleryOccupancySliceModel = produce(defaultState, draft => draft)

const galleryOccupancySlice = createSlice({
  name: 'galleryOccupancy',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setData(
      state,
      action: PayloadAction<{ data: GalleryOccupancySliceModel['data']; totalItemsCount: number }>
    ) {
      state.data = action.payload.data
      state.totalItemsCount = action.payload.totalItemsCount
    },
    setPageNumber(state, action: PayloadAction<number>) {
      state.pageNumber = action.payload
    },
    setSelectedFilterDate(
      state,
      action: PayloadAction<{ key: 'fromDate' | 'toDate'; data: string }>
    ) {
      state.filters.selected[action.payload.key] = action.payload.data
    },
    setSelectedFilterDropdown(
      state,
      action: PayloadAction<{
        key: 'agglomerations' | 'cities'
        data: string[]
      }>
    ) {
      state.filters.selected[action.payload.key] = action.payload.data
    },
    setAllFilters(state, action: PayloadAction<GalleryOccupancySliceModel['filters']['all']>) {
      state.filters.all = action.payload
    },
    setSelectedBuildingData(
      state,
      action: PayloadAction<GalleryOccupancySliceModel['selectedBuildingData']>
    ) {
      state.selectedBuildingData = action.payload
    },
    resetState() {
      return defaultState
    },
  },
})

export const galleryOccupancyActions = galleryOccupancySlice.actions

export default galleryOccupancySlice
