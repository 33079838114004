import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'api/fragments'

const PAYLOAD_TYPE = 'SendUpdatedCampaignToBrainPayload'

export const PUBLISH_CAMPAIGN = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation sendUpdatedCampaignToBrain($campaignId: ID!) {
    sendUpdatedCampaignToBrain(input: { campaignId: $campaignId }) {
      campaign {
        id
      }
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
