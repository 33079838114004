import { Errors } from '../InputError'
import { InputMask } from 'utils/inputMask'
import { ReactElement } from 'react'
import { CypressSelectable } from '../../../types'

export interface InputProps extends CypressSelectable {
  id?: string
  className?: string
  title?: string
  help?: string | ReactElement
  placeholder?: string
  label?: string
  name?: string
  type?: InputType
  value: InputValue
  onChange?: OnInputChangeFn
  onKeyDown?: InputOnKeyDownFn
  errors?: Errors
  maskType?: InputMask
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  autoComplete?: string
}

export type InputValue = number | string | string[]

export type OnInputChangeFn = (
  value: any,
  id?: string | undefined,
  extraField?: InputExtraFields
) => void

export type OnManyInputChangeFn = (newValues: Record<string, unknown>) => void

export interface InputOnKeyDownFn {
  key: string
  callback: () => void
}

export type Event = React.FormEvent<HTMLInputElement>

export type InputExtraFields = { id: string; value: string }[]

export interface InputState {
  focused: boolean
  pureFocused: boolean
}

export const inputStateDefault: InputState = {
  focused: false,
  pureFocused: false,
}

export enum InputType {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
}
