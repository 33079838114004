import { gql } from '@apollo/client'
import { USER_DETAILS_FIELDS } from '../fragments'

export const GET_USER_BY_ID = gql`
  ${USER_DETAILS_FIELDS}
  query userById($id: ID!) {
    node(id: $id) {
      ... on User {
        ...UserDetailsFields
      }
    }
  }
`
