import { gql } from '@apollo/client'
import { MUTATION_STATE, UPDATE_CREATION_EMISSIONS_NUMBER_FIELDS } from '../fragments'

const PAYLOAD = 'UpdateCreationImpressionsPayload'

export const UPDATE_CREATION_EMISSIONS_NUMBER = gql`
    ${UPDATE_CREATION_EMISSIONS_NUMBER_FIELDS}
    ${MUTATION_STATE(PAYLOAD)}
    mutation updateCreationImpressions($creationId: ID!, $impressions: Int) {
        updateCreationImpressions(input: { creationId: $creationId, impressions: $impressions }) {
            ...UpdateCreationImpressionsFields
            ...MutationState${PAYLOAD}Fields
        }
    }
`
