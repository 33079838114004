import { gql } from '@apollo/client'
import { CAMPAIGN_ACCEPTANCES_FIELDS } from '../fragments'

export const GET_CAMPAIGN_ACCEPTANCES = gql`
  ${CAMPAIGN_ACCEPTANCES_FIELDS}
  query campaignAcceptances($id: ID!) {
    campaignAcceptances(campaignId: $id) {
      ...CampaignAcceptancesFields
    }
  }
`
