import React, { ReactElement } from 'react'
import { View } from '@react-pdf/renderer'
import { Table, TableData, TableRow, TableTitle } from 'components/TablePdf'
import { t } from 'i18next'
import { PdfAudienceGender } from '../../../../../api/campaign/types'

type Props = {
  gender: PdfAudienceGender
}

export const AudienceGender = ({ gender }: Props): ReactElement => {
  return (
    <View>
      <Table>
        <TableTitle>{t('common.audienceGender')}</TableTitle>

        <TableRow>
          <TableData width={40}>{t('pdf.male')}</TableData>
          <TableData width={60}>{gender.male + '%'}</TableData>
        </TableRow>

        <TableRow>
          <TableData width={40}>{t('pdf.female')}</TableData>
          <TableData width={60}>{gender.female + '%'}</TableData>
        </TableRow>
      </Table>
    </View>
  )
}
