import React, { ReactElement } from 'react'
import { Image } from '@react-pdf/renderer'
import { SourceObject } from '@react-pdf/types'
import MapScreenshotService from '../../../../../components/Map/map-screenshot.service'
import MapService from '../../../../../components/Map/map.service'

export const PdfMap = (): ReactElement => {
  const getSource = async () => {
    return await MapScreenshotService.makeScreenshot(MapService.map as L.Map)
  }

  return <Image src={getSource as unknown as SourceObject} />
}
