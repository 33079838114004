import { Medium } from '../../../types/campaign'
import { InventoryAvailableFilters } from '../models/types'
import { MediumKey } from '../models/medium-key.model'

export const mediaToFilters = (media: Medium[]): InventoryAvailableFilters => {
  const mediumAsIds: string[] = []
  const uniqueMediumPropertiesNames: Record<MediumKey, string[]> = {
    agglomeration: [],
    building: [],
    city: [],
  }

  for (const medium of media) {
    for (const [mediumKey, mediumPropertyNames] of Object.entries(uniqueMediumPropertiesNames) as [
      MediumKey,
      string[]
    ][]) {
      const mediumProperty = medium[mediumKey]
      if (mediumProperty && !mediumPropertyNames.includes(mediumProperty.name)) {
        mediumPropertyNames.push(mediumProperty.name)
      }
    }
    mediumAsIds.push(medium.asId.toString())
  }

  return {
    agglomerations: uniqueMediumPropertiesNames.agglomeration,
    buildings: uniqueMediumPropertiesNames.building,
    cities: uniqueMediumPropertiesNames.city,
    mediumAsIds: mediumAsIds,
  }
}
