import React, { ReactElement } from 'react'
import { View } from '@react-pdf/renderer'
import { Table, TableData, TableRow, TableTitle } from 'components/TablePdf'
import { t } from 'i18next'
import { PdfAudienceAgeGender } from '../../../../../api/campaign/types'

type Props = {
  audienceAgeGender: PdfAudienceAgeGender[]
}

export const AudienceGenderAndAge = ({ audienceAgeGender }: Props): ReactElement => {
  const columns = {
    0: 20,
    1: 40,
    2: 40,
  }

  const audienceAgeGenderWithTranslations: {
    key: string
    female: number
    male: number
  }[] = audienceAgeGender.map(item => {
    return {
      key: item.ageGroup,
      female: item.female,
      male: item.male,
    }
  })

  return (
    <View>
      <Table>
        <TableTitle>{t('common.audienceGenderAndAge')}</TableTitle>

        <TableRow>
          <TableData width={columns[0]}>&nbsp;</TableData>
          <TableData width={columns[1]}>{t('pdf.male')}</TableData>
          <TableData width={columns[2]}>{t('pdf.female')}</TableData>
        </TableRow>
        {audienceAgeGenderWithTranslations.map(item => (
          <TableRow key={item.key}>
            <TableData width={columns[0]}>{item.key}</TableData>
            <TableData width={columns[1]}>{item.male}</TableData>
            <TableData width={columns[2]}>{item.female}</TableData>
          </TableRow>
        ))}
      </Table>
    </View>
  )
}
