import { Font, StyleSheet } from '@react-pdf/renderer'
import OpenSans400 from 'fonts/OpenSans400.ttf'
import OpenSans600 from 'fonts/OpenSans600.ttf'

export const registerFonts = (): void => {
  Font.register({
    family: 'Open Sans, sans-serif',
    fonts: [{ src: OpenSans400 }, { src: OpenSans600 }],
  })

  Font.registerHyphenationCallback(word => [word])
}

const MARGIN = 38 // [px] ~1cm
const LOGO_HEIGHT = 150
const HEADER_FOOTER_HEIGHT = 40

export const pdfStyles = StyleSheet.create({
  page: {
    paddingTop: MARGIN,
    paddingBottom: MARGIN + HEADER_FOOTER_HEIGHT,
    paddingHorizontal: MARGIN,
    fontFamily: 'Open Sans, sans-serif',
  },

  header: {
    display: 'flex',
    gap: 200,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 38,
    fontWeight: 600,
  },

  headerLeft: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
  },

  headerLogo: {
    width: 76,
    height: 40,
  },

  audienceHeaderLogo: {
    width: 76,
    height: 87,
  },

  headerImage: {
    height: LOGO_HEIGHT,
  },

  headerText: {
    width: '100%',
    fontSize: 14,
  },

  footer: {
    position: 'absolute',
    bottom: MARGIN,
    right: MARGIN,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: HEADER_FOOTER_HEIGHT,
    fontSize: 10,
  },

  completeData: {
    fontSize: 10,
  },
})
