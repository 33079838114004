import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import img404 from 'images/404.png'
import Button from 'components/Form/Button'
import { Routes } from 'routes'
import { AppContext } from 'contexts/AppContext'
import { useNavigate } from 'react-router-dom'
import './PageNotFound.scss'

const PageNotFound: React.FC = () => {
  const { t } = useTranslation()
  const { clear404 } = useContext(AppContext)
  const navigate = useNavigate()

  useEffect(() => {
    navigate(Routes.NOT_FOUND)

    return () => {
      clear404()
    }
  }, [])

  return (
    <div className='PageNotFound'>
      <div className='PageNotFound__left'>
        <div className='PageNotFound__left--header'>Uuups!</div>

        <div className='PageNotFound__left--text'>{t('pageNotFound.notExists')}</div>

        <Button toUrl={Routes.HOME}>{t('pageNotFound.goToCampaigns')}</Button>
      </div>

      <img
        className='PageNotFound__right'
        src={img404}
        alt='404'
      />
    </div>
  )
}

export default PageNotFound
