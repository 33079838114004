import React, { useCallback, useEffect } from 'react'
import Card from 'components/Layout/Card'
import { useTranslation } from 'react-i18next'
import FillingSpinner from 'components/FillingSpinner'
import './InventoryGalleryOccupancy.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import CardHeader from '../../../components/Layout/CardHeader'
import InventoryService from '../services/inventory.service'
import { galleryOccupancyActions } from '../store/inventory-gallery-occupancy-slice'
import InventoryOccupancyChart from '../components/InventoryOccupancyChart'
import Pagination from '../../../components/Pagination'
import { DATE_PLACEHOLDER, ITEMS_PER_PAGE } from '../../../constant'
import InventoryDetailsModal from '../components/InventoryDetailsModal'
import DatePickerSingle from '../../../components/Form/DatePickerSingle'
import debounce from 'lodash/debounce'
import SelectMultiCheckbox from '../../../components/Form/SelectMultiCheckbox'
import { SelectOption } from '../../../components/Form/Select'

const InventoryGalleryOccupancy: React.FC = () => {
  const debounceTime = 2000
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const debouncedFetchGalleryOccupancy = useCallback(
    debounce(InventoryService.fetchGalleryOccupancy, debounceTime),
    []
  )

  const { loading, filters, data, pageNumber, totalItemsCount, selectedBuildingData } = useSelector(
    (state: RootState) => state.inventory.galleryOccupancy
  )

  useEffect(() => {
    // if filters has changed, fetch data for the first page
    void debouncedFetchGalleryOccupancy(
      filters.selected.fromDate,
      filters.selected.toDate,
      filters.selected.agglomerations,
      filters.selected.cities,
      1
    )
  }, [filters.selected])

  useEffect(() => {
    const payload = {}

    void InventoryService.fetchInventoryFilters(payload)
  }, [])

  useEffect(() => {
    // Reset state on unmount/destroy
    return () => {
      dispatch(galleryOccupancyActions.resetState())
    }
  }, [])

  const setPageNumber = (page: number) => {
    void InventoryService.fetchGalleryOccupancy(
      filters.selected.fromDate,
      filters.selected.toDate,
      filters.selected.agglomerations,
      filters.selected.cities,
      page
    )
  }

  const selectInventoryBuilding = (building: string) => {
    void InventoryService.fetchSingleGalleryOccupancyDetails('', '', [], [], [building])
  }

  const closeInventoryBuildingModal = () => {
    dispatch(galleryOccupancyActions.setSelectedBuildingData([]))
  }

  const stringToSelectOption = (options: string[]): SelectOption[] => {
    return options.map(option => ({ value: option, label: option }))
  }

  return (
    <div className='InventoryGalleryOccupancy'>
      <Card>
        <CardHeader>{t('inventory.galleryOccupancy.header')}</CardHeader>
        <div>
          {loading ? (
            <div className={'InventoryGalleryOccupancy__spinner-container'}>
              <FillingSpinner className='InventoryGalleryOccupancy__loading' />
            </div>
          ) : (
            <div>
              <div className='InventoryGalleryOccupancy__filters'>
                <DatePickerSingle
                  id='dateFrom'
                  value={filters.selected.fromDate}
                  placeholder={DATE_PLACEHOLDER}
                  title={t('common.from')}
                  onChange={(fromDate): void => {
                    void dispatch(
                      galleryOccupancyActions.setSelectedFilterDate({
                        key: 'fromDate',
                        data: fromDate,
                      })
                    )
                  }}
                />
                <DatePickerSingle
                  id='dateTo'
                  value={filters.selected.toDate}
                  placeholder={DATE_PLACEHOLDER}
                  title={t('common.to')}
                  onChange={(toDate): void => {
                    void dispatch(
                      galleryOccupancyActions.setSelectedFilterDate({
                        key: 'toDate',
                        data: toDate,
                      })
                    )
                  }}
                />
                <SelectMultiCheckbox
                  id='agglomerations'
                  title={t('common.agglomeration')}
                  value={filters.selected.agglomerations}
                  options={stringToSelectOption(filters.all.agglomerations)}
                  onChange={selected => {
                    const payload = {
                      agglomerations: selected,
                      cities: filters.selected.cities,
                    }

                    void InventoryService.fetchInventoryFilters(payload)

                    void dispatch(
                      galleryOccupancyActions.setSelectedFilterDropdown({
                        key: 'agglomerations',
                        data: selected,
                      })
                    )
                  }}
                  selectAllOption
                />
                <SelectMultiCheckbox
                  id='cities'
                  title={t('common.city')}
                  value={filters.selected.cities}
                  options={stringToSelectOption(filters.all.cities)}
                  onChange={selected => {
                    const payload = {
                      agglomerations: filters.selected.agglomerations,
                      cities: selected,
                    }

                    void InventoryService.fetchInventoryFilters(payload)

                    void dispatch(
                      galleryOccupancyActions.setSelectedFilterDropdown({
                        key: 'cities',
                        data: selected,
                      })
                    )
                  }}
                  selectAllOption
                />
              </div>
              {data && data.length > 0 ? (
                <>
                  <div className='InventoryGalleryOccupancy__charts'>
                    {data.map((item, index) => {
                      return (
                        <InventoryOccupancyChart
                          key={index}
                          inventoryBuilding={item}
                          selectInventoryBuilding={selectInventoryBuilding}
                        />
                      )
                    })}
                  </div>
                  <div className='InventoryGalleryOccupancy__pagination'>
                    <Pagination
                      activePage={pageNumber}
                      itemsCountPerPage={ITEMS_PER_PAGE}
                      totalItemsCount={totalItemsCount}
                      onChange={setPageNumber}
                    />
                  </div>
                </>
              ) : (
                <div className='InventoryGalleryOccupancy__no-data'>
                  <p>{t('inventory.galleryOccupancy.noData')}</p>
                </div>
              )}
            </div>
          )}
        </div>
        <InventoryDetailsModal
          inventoryData={selectedBuildingData}
          closeClick={closeInventoryBuildingModal}
        />
      </Card>
    </div>
  )
}

export default InventoryGalleryOccupancy
