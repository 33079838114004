import React, { ReactNode } from 'react'
import InputTitle from '../InputTitle'
import { useTranslation } from 'react-i18next'
import './DatePickerRangeSeparate.scss'

type Props = {
  title?: string
  children: [ReactNode, ReactNode]
}

const DatePickerRangeSeparate: React.FC<Props> = ({ children, title }) => {
  const { t } = useTranslation()
  const titleFrom = upperCaseFirstLetter(
    [title, t('common.from').toLowerCase()].filter(Boolean).join(' ')
  )
  const titleTo = t('common.to').toLowerCase()

  return (
    <div className='DatePickerRangeSeparate'>
      <div className='DatePickerRangeSeparate__title-row'>
        <InputTitle title={titleFrom} />
        <InputTitle title={titleTo} />
      </div>
      <div className='DatePickerRangeSeparate__input-row'>
        <div className='DatePickerRangeSeparate__filter'>{children[0]}</div>
        <div className='DatePickerRangeSeparate__divider' />
        <div className='DatePickerRangeSeparate__filter'>{children[1]}</div>
      </div>
    </div>
  )
}

const upperCaseFirstLetter = (value: string) => `${value[0].toUpperCase()}${value.slice(1)}`

export default DatePickerRangeSeparate
