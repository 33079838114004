import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { ResetPasswordValues, resetPasswordValuesDefault } from './types'
import Input, { InputType } from 'components/Form/Input'
import Button from 'components/Form/Button'
import { useTranslation } from 'react-i18next'
import api from 'api'
import { Routes } from 'routes'
import { AppContext } from 'contexts/AppContext'
import { NotifierType } from 'components/Notifier'
import { User } from 'types/user'
import '../Sign.scss'

const ResetPassword: React.FC = () => {
  const { addNotification } = useContext(AppContext)
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [token, setToken] = useState<User['token']>()
  const [loading, setLoading] = useState<boolean>(false)
  const [values, setValues] = useState<ResetPasswordValues>(resetPasswordValuesDefault)

  useEffect(() => {
    const locationSearchValues = queryString.parse(location.search)

    if (locationSearchValues && locationSearchValues.token) {
      setToken(locationSearchValues.token as string)
    }
  }, [location])

  const onReset = (): void => {
    setLoading(true)
    const { password, passwordConfirmation } = values

    api.user
      .resetPasswordByToken({
        token,
        password,
        passwordConfirmation,
      })
      .then(() => {
        addNotification(NotifierType.SUCCESS, t('notification.passwordSaveSuccess'))
        navigate(Routes.SIGN.SIGN_IN)
      })
      .catch(() => void {})
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <h1 className='Sign__header--first'>{t('sign.resetPassword.passwordReset')}</h1>
      <h2 className='Sign__header--second'>{t('sign.resetPassword.enterNew')}</h2>

      <div className='Sign__form'>
        <Input
          id='password'
          title={t('common.password')}
          placeholder={t('common.enterPassword')}
          type={InputType.PASSWORD}
          value={values.password}
          onChange={(value): void => void setValues({ ...values, password: value as string })}
          onKeyDown={{ key: 'Enter', callback: onReset }}
        />

        <Input
          id='passwordConfirmation'
          title={t('common.repeatPassword')}
          placeholder={t('common.enterPasswordAgain')}
          type={InputType.PASSWORD}
          value={values.passwordConfirmation}
          onChange={(value): void =>
            void setValues({ ...values, passwordConfirmation: value as string })
          }
          onKeyDown={{ key: 'Enter', callback: onReset }}
        />

        <Button
          className='Sign__form--button'
          disabled={loading}
          onClick={onReset}
        >
          {t('sign.resetPassword.resetPassword')}
        </Button>
      </div>
    </>
  )
}

export default ResetPassword
