import api from 'api'
import { Campaign, GetCampaignsArgs } from './types'
import { Alert, CampaignProgressStatus, CampaignStatus } from 'types/campaign'
import EnumUtils from 'utils/enum'
import { NotifierType } from '../../../components/Notifier'
import { TFunction } from 'react-i18next'

export default class CampaignTableService {
  static getCampaigns = (args: GetCampaignsArgs, t: TFunction): void => {
    const {
      pageNumber,
      itemsPerPage,
      setCampaigns,
      setTotalCount,
      setLoading,
      setTrigger,
      tableFilter,
      filters,
    } = args

    setLoading(true)

    api.campaign
      .getCampaignsForTable({
        first: pageNumber * itemsPerPage,
        last: itemsPerPage,
        sortColumn: tableFilter.column,
        sortDirection: tableFilter.direction,
        filters,
      })
      .then(res => {
        const { campaigns } = res.data
        const mappedCampaigns: Campaign[] = campaigns.nodes.map(
          (campaign): Campaign => ({
            alerts: campaign.alerts.filter((a: Alert) => a.active),
            acceptanceAlerts: campaign.acceptanceAlerts
              .filter((alert: Alert) => alert.active)
              .map(alert => {
                return { ...alert, message: t('notification.rejectedCreationsInCampaign') }
              }),
            aggregatedAcceptanceAlerts: campaign.aggregatedAcceptanceAlerts
              .filter(alert => alert.active)
              .map(alert => {
                return { ...alert, message: t('notification.rejectedCreationsInCampaign') }
              }),
            id: campaign.id,
            name: campaign.name,
            startDate: campaign.startDate,
            endDate: campaign.endDate,
            acceptanceStatus: campaign.acceptanceStatus,
            status:
              CampaignStatus[
                EnumUtils.getKeyByValue(
                  CampaignStatus,
                  campaign.status
                ) as keyof typeof CampaignStatus
              ],
            agencyName: campaign.agency.name,
            progress: {
              percent: campaign.progress.percent,
              status:
                CampaignProgressStatus[
                  EnumUtils.getKeyByValueT(CampaignProgressStatus, campaign.progress.status)!
                ],
            },
            commercialAttribute: campaign.commercialAttribute,
          })
        )

        setCampaigns(mappedCampaigns)
        setTotalCount(campaigns.totalCount)
      })
      .catch(() => void {})
      .finally(() => {
        setLoading(false)
        setTrigger(false)
      })
  }

  static deleteCampaign = (
    campaignId: Campaign['id'],
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>,
    addNotification: (type: NotifierType, text: string | string[], time?: number) => void,
    t: TFunction
  ): Promise<void> =>
    api.campaign
      .deleteCampaign(campaignId)
      .then(res => {
        if (!res.data) {
          return
        }

        const { success } = res.data.destroyCampaign
        if (success) {
          addNotification(NotifierType.SUCCESS, t('form.campaign.removal.success'))
        }
      })
      .catch(() => void {})
      .finally(() => void setTrigger(true))
}
