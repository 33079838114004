import { defaultFeatures } from 'constant/features'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { Feature, UseFeatureProps } from './types'

const useFeature = (): UseFeatureProps => {
  const [features, setFeatures] = useState<Feature[]>([])
  const cookieName = 'features'

  useEffect(() => {
    const cookieValue: string | undefined = Cookies.get(cookieName)
    if (!cookieValue) {
      setFeatures(defaultFeatures)
      Cookies.set(cookieName, JSON.stringify(defaultFeatures))
    } else {
      setFeatures(JSON.parse(cookieValue))
    }
  }, [])

  const isFeatureActive = (name: Feature['name']): boolean =>
    features.find((f: Feature) => f.name === name)?.state ?? false

  const setFeatureState = (name: Feature['name'], state: Feature['state']) => {
    const tempFeatures: Feature[] = [...features]
    const index: number = tempFeatures.findIndex((f: Feature) => f.name === name)

    if (index < 0) {
      throw new Error(`Can't find feature with name: ${name}.`)
    }
    tempFeatures[index].state = state

    setFeatures(tempFeatures)
    Cookies.set(cookieName, JSON.stringify(tempFeatures))
  }

  const resetFeatures = (): void => {
    const defaults: Feature[] = features.map((f: Feature) => ({
      name: f.name,
      state: false,
    }))

    setFeatures(defaults)
    Cookies.set(cookieName, JSON.stringify(defaults))
  }

  return { features, isFeatureActive, setFeatureState, resetFeatures }
}

export default useFeature
