import { NavigateFunction } from 'react-router-dom'

export interface Use404Props {
  force404: () => void
  is404Present: boolean
  redirectTo404: (navigate: NavigateFunction) => void
  clear404: () => void
}

export const use404PropsDefault: Use404Props = {
  force404: () => void {},
  is404Present: false,
  redirectTo404: () => void {},
  clear404: () => void {},
}
