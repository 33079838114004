import { gql } from '@apollo/client'
import { MUTATION_STATE, USER_DETAILS_FIELDS } from '../fragments'

const PAYLOAD_TYPE = 'SignInPayload'

export const SIGN_IN = gql`
    ${MUTATION_STATE('SignInPayload')}
    ${USER_DETAILS_FIELDS}
    mutation signIn($email: String!, $password: String!) {
        signIn(input: { email: $email, password: $password }) {
            user {
                ...UserDetailsFields
            }
            ...MutationState${PAYLOAD_TYPE}Fields
            authenticationToken
            refreshToken
        }
    }
`
