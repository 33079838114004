import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'api/fragments'

const PAYLOAD_TYPE = 'PauseCampaignPayload'

export const PAUSE_CAMPAIGN = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation pauseCampaign($id: ID!) {
    pauseCampaign(input: { id: $id }) {
      campaign {
        id
      }
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
