import { onError } from '@apollo/client/link/error'
import { NotifierType } from 'components/Notifier'
import i18n from 'i18n'
import { NOTIFIER_CLOSE_TIME } from 'constant'
import rollbar from 'utils/rollbar'
import { ErrorCode } from './types'
import { AppContextProps } from 'contexts/AppContext/types'
import { UseUserProps } from 'hooks/useUser'
import { ApolloLink, fromPromise } from '@apollo/client'

const errorLink = ({
  addNotification,
  tryToGetNewToken,
  force404,
}: {
  addNotification: AppContextProps['addNotification']
  tryToGetNewToken: UseUserProps['tryToGetNewToken']
  force404: AppContextProps['force404']
}): ApolloLink =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        if (message)
          addNotification(NotifierType.ERROR, message, graphQLErrors.length * NOTIFIER_CLOSE_TIME)
      })

      const fromPromiseTryToGetNewToken = () =>
        fromPromise(tryToGetNewToken())
          .filter(value => Boolean(value))
          .flatMap(at => {
            if (at)
              operation.setContext(({ headers }: { headers: Headers }) => ({
                headers: {
                  ...headers,
                  authorization: `Bearer ${at}`,
                },
              }))

            return forward(operation)
          })

      // Error codes
      for (const err of graphQLErrors) {
        if (err.extensions && err.extensions.code)
          switch (err.extensions.code) {
            case ErrorCode.UNAUTHENTICATED: {
              return fromPromiseTryToGetNewToken()
            }
            case ErrorCode.NOT_FOUND: {
              console.error(`GraphQL error: ${ErrorCode.NOT_FOUND}`)
              force404()
              return
            }
            case ErrorCode.FORBIDDEN: {
              console.error(`GraphQL error: ${ErrorCode.FORBIDDEN}`)
              force404()
              return
            }
            case ErrorCode.EXPIRED_REFRESH_TOKEN: {
              console.error(`GraphQL error: ${ErrorCode.EXPIRED_REFRESH_TOKEN}`)
              return
            }
          }
      }
    }

    if (networkError) {
      addNotification(NotifierType.ERROR, [
        `${i18n.t('notification.networkError')}: ${networkError.message}`,
      ])
      rollbar.error('Apollo GraphQL network error', networkError)
    }
  })

export default errorLink
