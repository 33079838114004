import React from 'react'
import TsxUtils from 'utils/tsx'
import { RadioButtonProps } from './types'
import './RadioButton.scss'

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  groupId,
  className,
  value,
  onChange,
  children,
  disabled,
}) => (
  <div className={'RadioButton' + TsxUtils.extraStyle(className, className)}>
    <input
      className={
        'RadioButton__input' + TsxUtils.extraStyle(disabled, 'RadioButton__input--disabled')
      }
      id={id}
      type='radio'
      name={groupId}
      checked={value === id}
      disabled={disabled}
      onChange={() => !disabled && onChange(id)}
    />

    <label
      className={
        'RadioButton__label' + TsxUtils.extraStyle(disabled, 'RadioButton__label--disabled')
      }
      htmlFor={id}
    >
      {children}
    </label>
  </div>
)

export default RadioButton
