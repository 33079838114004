import React from 'react'
import { ModalActionFooterProps, ModalFooterContainerProps } from './types'
import FillingSpinner from 'components/FillingSpinner'
import Button, { ButtonTheme } from 'components/Form/Button'
import { useTranslation } from 'react-i18next'
import './ModalFooter.scss'

export const ModalFooterContainer: React.FC<ModalFooterContainerProps> = ({ footer }) => {
  return <div className={'ModalFooterContainer'}>{footer}</div>
}

export const ModalActionFooter: React.FC<ModalActionFooterProps> = ({
  isLoading,
  setIsLoading,
  setIsOpen,
  onAction,
  actionText,
  actionButtonDisabled,
}) => {
  const { t } = useTranslation()

  return isLoading ? (
    <FillingSpinner className='ModalActionFooter__spinner' />
  ) : (
    <div className='ModalActionFooter__buttons'>
      <Button
        dataCy={'modal-confirm-btn'}
        onClick={(): void => {
          setIsLoading && setIsLoading(true)

          onAction()
            .catch(() => void {})
            .finally(() => {
              setIsLoading && setIsLoading(false)
              setIsOpen(false)
            })
        }}
        disabled={actionButtonDisabled}
      >
        {actionText}
      </Button>
      <Button
        dataCy={'modal-cancel-btn'}
        theme={ButtonTheme.BLUE_OUTLINE}
        onClick={(): void => setIsOpen(false)}
      >
        {t('common.cancel')}
      </Button>
    </div>
  )
}
