import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReportSliceModel } from '../models/report-slice.model'
import { GeneratedReport } from '../../../api/report/types'

const initialState: ReportSliceModel = {
  campaign: {
    show: true,
    loading: false,
    reports: [],
  },
  creation: {
    show: false,
    loading: false,
    reports: [],
  },
  medium: {
    show: false,
    loading: false,
    reports: [],
  },
  mediumCampaigns: {
    show: false,
    loading: false,
    startDate: null,
    endDate: null,
    reports: [],
  },
  media: {
    show: false,
    loading: false,
    reports: [],
  },
  campaignsCombined: {
    show: false,
    loading: false,
    campaigns: [''],
    startDate: null,
    endDate: null,
  },
  pricing: {
    show: false,
    loading: false,
    audience: {
      headers: [],
      content: [],
    },
    impressions: {
      headers: [],
      content: [],
    },
  },
}

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    toggleVisibility(state, action: PayloadAction<keyof ReportSliceModel>) {
      const key = action.payload
      state[key].show = !state[key].show
    },
    setLoading(state, action: PayloadAction<{ key: keyof ReportSliceModel; loading: boolean }>) {
      const key = action.payload.key
      state[key].loading = action.payload.loading
    },
    setReportsData(
      state,
      action: PayloadAction<{
        key: keyof Omit<ReportSliceModel, 'campaignsCombined' | 'pricing'>
        data: GeneratedReport[]
      }>
    ) {
      const { key, data } = action.payload
      state[key].reports = data
    },
    setCombinedCampaignsData(
      state,
      action: PayloadAction<Partial<ReportSliceModel['campaignsCombined']>>
    ) {
      return {
        ...state,
        campaignsCombined: {
          ...state.campaignsCombined,
          ...action.payload,
        },
      }
    },
    setPricingData(
      state,
      action: PayloadAction<{
        audience: { headers: string[]; content: string[][] }
        impressions: { headers: string[]; content: string[][] }
      }>
    ) {
      state.pricing.audience = action.payload.audience
      state.pricing.impressions = action.payload.impressions
    },
    addCombinedCampaign(state) {
      state.campaignsCombined.campaigns = [...state.campaignsCombined.campaigns, '']
    },
    removeCombinedCampaign(state, action: PayloadAction<number>) {
      state.campaignsCombined.campaigns = state.campaignsCombined.campaigns.filter(
        (_, index) => index !== action.payload
      )
    },
    updateCombinedCampaign(state, action: PayloadAction<{ index: number; value: string }>) {
      const { index, value } = action.payload
      state.campaignsCombined.campaigns[index] = value
    },
  },
})

export const reportActions = reportSlice.actions

export default reportSlice
