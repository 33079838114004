import { Feature } from 'hooks/useFeature'

export enum Features {
  POI,
  CAMPAIGN_TARGET,
  CAMPAIGN_BUDGET,
  PAUSE_CAMPAIGN,
}

export const defaultFeatures: Feature[] = [
  { name: Features.POI, state: false },
  { name: Features.CAMPAIGN_TARGET, state: false },
  { name: Features.CAMPAIGN_BUDGET, state: false },
  { name: Features.PAUSE_CAMPAIGN, state: false },
]
