import React, { ReactNode, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Button, { ButtonTheme } from 'components/Form/Button'
import Card from 'components/Layout/Card'
import FormColumn from 'components/Form/FormColumn'
import FormRow from 'components/Form/FormRow'
import Input from 'components/Form/Input'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { ReactRouterParams } from 'types/various'
import PageHeader from '../../../components/Layout/PageHeader'
import '../Role.scss'
import PermissionsCard from './PermissionsCard/PermissionsCard'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { roleFormActions } from './store/role-form-slice'
import SwitchButton from '../../../components/Form/SwitchButton'
import service from '../service'
import { PermissionChangeFn } from '../models/role-form-slice.model'
import FillingSpinner from '../../../components/FillingSpinner'
import { AppContext } from '../../../contexts/AppContext'
import Select from '../../../components/Form/Select'
import { Routes } from '../../../routes'

const RoleForm: React.FC = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { addNotification } = useContext(AppContext)
  const { id: urlId } = useParams<ReactRouterParams>()
  const getNewRolePermissions = (): void => service.getNewRolePermissions()

  const { permissionElementRoles, loading, newPermissionElementRoles } = useSelector(
    (state: RootState) => state.roleForm
  )

  const permissionElements = urlId ? permissionElementRoles : newPermissionElementRoles

  useEffect(() => {
    if (!newPermissionElementRoles.length && !urlId) {
      getNewRolePermissions()
    }
    if (!urlId) {
      void dispatch(roleFormActions.resetRoleForm())
    }
  }, [])

  useEffect(() => {
    if (urlId) {
      service.getRole(urlId)
    }
  }, [urlId])

  const {
    name: roleName,
    id,
    baseRole: baseRole,
  } = useSelector((state: RootState) => state.roleForm)

  const onPermissionChange: PermissionChangeFn = (permissionGroup, permission, checked) => {
    void dispatch(
      roleFormActions.changePermission({
        editRole: !!urlId,
        permissionElementId: permissionGroup,
        permission,
        value: checked,
      })
    )
  }

  const handleSubmit = () => {
    service.handleSubmit(navigate, addNotification, t, id)
  }

  const Form = (): ReactNode => (
    <Card className='Roles__form'>
      {loading ? (
        <FillingSpinner className='Roles__form--loading' />
      ) : (
        <FormColumn>
          <FormRow>
            <FormColumn>
              <Input
                id='name'
                title={t('common.name')}
                value={roleName}
                placeholder={t('common.name')}
                onChange={val => {
                  dispatch(roleFormActions.changeRoleName(val))
                }}
              />
            </FormColumn>
          </FormRow>
          <FormRow className='Roles__form--row'>
            <Select
              clearable
              id='basicRole'
              onChange={val => {
                dispatch(roleFormActions.changeBasicRole(val))
              }}
              options={service.basicRoleOptions()}
              placeholder={t('roles.form.baseRole')}
              title={t('roles.form.baseRole')}
              value={baseRole}
            />
          </FormRow>
          <span className={'Roles__privileges--title'}>{t('roles.privileges.title')}</span>
          {permissionElements.map(permissionElement => {
            if (permissionElement.permissions.length === 1) {
              return (
                <Card
                  className={'Roles__privileges--card Roles__privileges--card--with-switch'}
                  key={permissionElement.name}
                >
                  <span>{permissionElement.name}</span>
                  <SwitchButton
                    id={'reportsRights'}
                    checked={permissionElement.permissions[0].value}
                    onChange={checked => {
                      dispatch(
                        roleFormActions.changePermission({
                          editRole: !!urlId,
                          permissionElementId: permissionElement.id,
                          permission: permissionElement.permissions[0].name,
                          value: checked,
                        })
                      )
                    }}
                  ></SwitchButton>
                </Card>
              )
            }

            return (
              <PermissionsCard
                permissionChange={onPermissionChange}
                key={permissionElement.id}
                id={permissionElement.id}
                title={permissionElement.name}
                permissions={permissionElement.permissions}
              ></PermissionsCard>
            )
          })}
        </FormColumn>
      )}
      <div className={'Roles__form--buttons'}>
        <Button
          icon={loading ? faSpinner : undefined}
          disabled={loading}
          onClick={() => handleSubmit()}
        >
          {t('common.save')}
        </Button>
        <Button
          theme={ButtonTheme.BLUE_OUTLINE}
          toUrl={Routes.ROLES.ALL}
        >
          {t('common.cancel')}
        </Button>
      </div>
    </Card>
  )

  return (
    <>
      <PageHeader>
        {urlId ? t(`roles.form.${'editRole'}`) : t(`roles.form.${'newRole'}`)}
      </PageHeader>
      {Form()}
    </>
  )
}

export default RoleForm
