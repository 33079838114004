import L, { ErrorEvent } from 'leaflet'
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter'
import MapService from './map.service'
import { DEBOUNCE_GET_DELAY_DEFAULT } from '../../constant'

export default class MapScreenshotService {
  static async makeScreenshot(map: L.Map): Promise<Blob | Error | ErrorEvent | undefined> {
    return new Promise(resolve => {
      return resolve(map.setView([MapService.center.lat, MapService.center.lng], MapService.zoom))
    })
      .then(
        () => new Promise(resolve => setTimeout(() => resolve(null), DEBOUNCE_GET_DELAY_DEFAULT))
      )
      .then(() => {
        const simpleMapScreenshoter = new SimpleMapScreenshoter().addTo(map)
        const format = 'image'
        const overridedPluginOptions = {
          mimeType: 'image/jpeg',
          domtoimageOptions: {
            quality: 0.5,
            filter: false,
          },
        }

        return simpleMapScreenshoter
          .takeScreen(format, overridedPluginOptions)
          .then(screenshot => screenshot)
          .catch(() => undefined)
          .finally(() => {
            simpleMapScreenshoter.remove()
          })
      })
  }
}
