import { gql } from '@apollo/client'
import { CAMPAIGN_IMPRESSIONS_FIELDS } from '../fragments'

export const GET_CAMPAIGN_IMPRESSIONS = gql`
  ${CAMPAIGN_IMPRESSIONS_FIELDS}
  query campaignImpressions(
    $agglomerations: [String!]!
    $campaignId: ID!
    $buildings: [String!]!
    $cities: [String!]!
    $mediaAsIds: [String!]!
    $pois: [String!]!
    $sex: [Int!]
    $ageGroups: [Int!]
    $creations: [ID!]
  ) {
    campaignImpressions(
      agglomerations: $agglomerations
      buildings: $buildings
      campaignId: $campaignId
      cities: $cities
      mediaAsIds: $mediaAsIds
      pois: $pois
      sex: $sex
      ageGroups: $ageGroups
      creations: $creations
    ) {
      nodes {
        ...CampaignImpressionsFields
      }
    }
  }
`

export const GET_CAMPAIGN_IMPRESSIONS_PUBLIC = gql`
  ${CAMPAIGN_IMPRESSIONS_FIELDS}
  query campaignReportPublic(
    $campaignUuid: String!
    $cities: [String!]!
    $agglomerations: [String!]!
    $buildings: [String!]!
    $pois: [String!]!
    $mediaAsIds: [String!]!
    $creations: [ID!]!
  ) {
    campaignReportPublic(campaignUuid: $campaignUuid) {
      campaignImpressions(
        campaignUuid: $campaignUuid
        cities: $cities
        agglomerations: $agglomerations
        buildings: $buildings
        mediaAsIds: $mediaAsIds
        pois: $pois
        creations: $creations
      ) {
        nodes {
          ...CampaignImpressionsFields
        }
      }
    }
  }
`
