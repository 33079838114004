import React from 'react'
import Table, {
  TableBody,
  TableData,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from 'components/Table'
import { DailySummaryNode } from '../../../api/emissionPlan/models/daily-summary-listing.model'
import { useTranslation } from 'react-i18next'
import './EmissionPlanTable.scss'
import Pagination from '../../../components/Pagination'
import { ITEMS_PER_PAGE } from '../../../constant'

const EmissionPlanTable: React.FC<{
  pageNumber: number
  totalItemsCount: number
  data: DailySummaryNode[]
  rowClick: (summary: DailySummaryNode) => void
  setPageNumber: (page: number) => void
  hasBuildings: boolean
}> = ({ pageNumber, totalItemsCount, data, rowClick, setPageNumber, hasBuildings }) => {
  const { t } = useTranslation()

  if (!data.length) {
    return (
      <div className='EmissionPlanTable__no-data'>
        <p>{t('emissionPlan.noData')}</p>
      </div>
    )
  }

  return (
    <div className='EmissionPlanTable__table'>
      <Table className='CommonTable'>
        <TableHeader sticky>
          <>
            <TableHeaderItem className='CommonTable__header-item'>
              {t<string>('emissionPlan.asId')}
            </TableHeaderItem>
            <TableHeaderItem className='CommonTable__header-item'>
              {t<string>('emissionPlan.agglomeration')}
            </TableHeaderItem>
            {hasBuildings && (
              <TableHeaderItem className='CommonTable__header-item'>
                {t<string>('emissionPlan.gallery')}
              </TableHeaderItem>
            )}
            <TableHeaderItem className='CommonTable__header-item'>
              {t<string>('emissionPlan.address')}
            </TableHeaderItem>
          </>
        </TableHeader>

        <TableBody>
          {data.map((summary, i: number) => (
            <TableRow
              key={i}
              className='CommonTable__data-row'
              onClick={() => rowClick(summary)}
            >
              <>
                <TableData
                  key={`${i}-asId`}
                  className='CommonTable__data-item EmissionPlanTable__medium-row'
                >
                  <span>{summary.medium.asId}</span>
                </TableData>
                <TableData
                  key={`${i}-agglomeration`}
                  className='CommonTable__data-item'
                >
                  <span>{summary.medium.agglomeration.name}</span>
                </TableData>
                {hasBuildings && (
                  <TableData
                    key={`${i}-building`}
                    className='CommonTable__data-item'
                  >
                    <span>{summary.medium.building?.name}</span>
                  </TableData>
                )}
                <TableData
                  key={`${i}-address`}
                  className='CommonTable__data-item'
                >
                  <span>{summary.medium.address}</span>
                </TableData>
              </>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        activePage={pageNumber}
        itemsCountPerPage={ITEMS_PER_PAGE}
        totalItemsCount={totalItemsCount}
        onChange={setPageNumber}
      />
    </div>
  )
}

export default EmissionPlanTable
