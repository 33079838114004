import { gql } from '@apollo/client'
import { MUTATION_STATE } from '../fragments'

const PAYLOAD = 'UpdateCreationDetailsPayload'

export const UPDATE_CREATION_DETAILS = gql`
  ${MUTATION_STATE(PAYLOAD)}
  mutation updateCreationDetails(
    $creationId: ID!
    $details: String!
  ) {
    updateCreationDetails(
      input: { 
        creationId: $creationId
        details: $details 
      }
    ) {
      ...MutationState${PAYLOAD}Fields
      brainState
    }
  }
`
