import { gql } from '@apollo/client'

export const FETCH_GENERATED_REPORTS = gql`
  query generatedReports($reportType: ReportTypeEnum!) {
    generatedReports(reportType: $reportType) {
      name
      status
      startDate
      endDate
      generatedAt
      csvUrl
    }
  }
`
