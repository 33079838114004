import { gql } from '@apollo/client'
import { MEDIUM_FIELDS } from '../fragments'

export const GET_ALL_MEDIA = gql`
  ${MEDIUM_FIELDS}
  query media($mediumFormat: MediumFormatEnum, $campaignId: ID) {
    media(mediumFormat: $mediumFormat, campaignId: $campaignId) {
      nodes {
        ...MediumFields
      }
    }
  }
`
