import { gql } from '@apollo/client'

export const GET_INVENTORY_ALERTS = gql`
  query inventoryAlerts($first: Int) {
    inventoryAlerts(first: $first) {
      nodes {
        week
        cities
      }
    }
  }
`
