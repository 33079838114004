import { gql } from '@apollo/client'
import { AGENCY_FIELDS } from '../fragments'

export const GET_AGENCIES = gql`
  ${AGENCY_FIELDS}
  query agencies(
    $first: Int
    $last: Int
    $sortColumn: AgenciesSortColumnEnum
    $sortDirection: SortDirectionEnum
    $term: String
  ) {
    agencies(
      first: $first
      last: $last
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      term: $term
    ) {
      totalCount
      nodes {
        ...AgencyFields
      }
    }
  }
`
