import { gql } from '@apollo/client'

const BODY = `
    nodes {
        id
        startDate
        endDate
    }
`

export const GET_CREATION_SCHEDULES = gql`
  query creationSchedules($creationId: ID!) {
    creationSchedules(creationId: $creationId) {
        ${BODY}
    }
  }
`

export const GET_CREATION_SCHEDULES_PUBLIC = gql`
  query creationSchedulesPublic($uuid: ID) {
    creationSchedulesPublic(uuid: $uuid) {
        ${BODY}
    }
  }
`
