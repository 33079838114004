import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RolesTableSliceModel, RoleTableItem } from '../../models/roles-table-slice.model'

const initialState: RolesTableSliceModel = {
  loading: false,
  loaded: false,
  roles: [],
  pageNumber: 1,
  totalCount: 0,
  removeRoleModal: {
    loading: false,
    role: null,
  },
}

const rolesTableSlice = createSlice({
  name: 'userTable',
  initialState,
  reducers: {
    setRoles(state, action: PayloadAction<{ id: string; name: string }[]>) {
      state.roles = action.payload
    },
    setPageNumber(state, action: PayloadAction<number>) {
      state.pageNumber = action.payload
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setLoaded(state, action: PayloadAction<boolean>) {
      state.loaded = action.payload
    },
    resetRoles(state) {
      state.loaded = false
      state.loading = false
      state.pageNumber = 1
      state.totalCount = 0
      state.roles = []
    },
    setModalLoading(state, action: PayloadAction<boolean>) {
      state.removeRoleModal.loading = action.payload
    },
    setModalRole(state, action: PayloadAction<RoleTableItem | null>) {
      state.removeRoleModal.role = action.payload
    },
  },
})

export const rolesTableActions = rolesTableSlice.actions

export default rolesTableSlice
