import api from 'api'
import { Dispatch, SetStateAction } from 'react'
import { Role, User } from 'types/user'
import VariableUtils from 'utils/variable'
import { UserEditForm } from './types'
import { UseNotifierProps } from 'hooks/useNotifier'
import { UseUserProps } from 'hooks/useUser'
import { NotifierType } from 'components/Notifier'
import { TFunction } from 'i18next'
import { BaseRoleSelectRawData } from '../service'
import { SelectAsyncValue } from 'components/Form/SelectAsync'
import { Agency } from 'types/agency'
import { NavigateFunction } from 'react-router-dom'
import { Routes } from 'routes'

export default class UserEditService {
  static getUser = (
    userId: User['id'],
    setUserForm: Dispatch<SetStateAction<UserEditForm | undefined>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setDefaultSubscription?: Dispatch<SetStateAction<boolean>>
  ): void => {
    setLoading(true)

    api.user
      .getUserById(userId)
      .then(res => {
        const {
          id,
          firstName,
          lastName,
          email,
          priorities,
          roles,
          agencies,
          status,
          subscriptionAccount,
        } = res.data.node

        void setUserForm({
          id,
          firstName,
          lastName,
          email,
          priorities,
          status,
          subscriptionAccount,
          roles: roles.map((role: Role) =>
            VariableUtils.toSelectAsyncValue<BaseRoleSelectRawData>({
              value: role.id,
              label: role.name,
              rawData: { baseRole: role.baseRole },
            })
          ),
          agencies: agencies.map((agency: Agency) =>
            VariableUtils.toSelectAsyncValue<Agency>({
              value: agency.name,
              rawData: agency,
            })
          ),
        })

        if (setDefaultSubscription) {
          void setDefaultSubscription(subscriptionAccount)
        }
      })
      .catch(() => void {})
      .finally(() => void setLoading(false))
  }

  static updateUser = (
    user: UserEditForm,
    setLoading: Dispatch<SetStateAction<boolean>>,
    addNotification: UseNotifierProps['addNotification'],
    navigate: NavigateFunction,
    updateUser: UseUserProps['updateUser'],
    t: TFunction
  ): void => {
    setLoading(true)

    api.user
      .updateUser({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        priorities: user.priorities,
        roles: user.roles.map((r: SelectAsyncValue<BaseRoleSelectRawData>) => r.value),
        agencies: user.agencies.map(agency => agency.rawData!.id),
      })
      .then(() => {
        addNotification(NotifierType.SUCCESS, t('notification.updateUserSuccess'))
        navigate(Routes.USERS.ALL)
      })
      .catch(() => void {})
      .finally(() => {
        setLoading(false)
        updateUser()
      })
  }

  static initiatePasswordReset = async (userId: string): Promise<string | undefined> => {
    try {
      const response = await api.user.initiatePasswordReset(userId)
      return response.data?.initiatePasswordReset.resetUrl
    } catch {
      return undefined
    }
  }

  static reInviteUser = async (userId: string): Promise<string | undefined> => {
    try {
      const response = await api.user.reInviteUser(userId)
      return response.data?.reinviteUser.invitationUrl
    } catch {
      return undefined
    }
  }

  static updateUserEmail = async (userId: string, email: string): Promise<string | undefined> => {
    try {
      const response = await api.user.updateUserEmail(userId, email)
      return response.data?.updateEmail.user.email
    } catch {
      return undefined
    }
  }
}
