import React, { ReactNode, useContext, useEffect, useState } from 'react'
import {
  Campaign,
  CampaignAcceptanceStatus,
  CampaignStatus,
  Creation,
  MediaFormat,
} from 'types/campaign'
import Preview from 'components/Preview'
import FlexCol from 'components/Layout/FlexCol'
import { useTranslation } from 'react-i18next'
import Button, { ButtonSize, ButtonTheme } from 'components/Form/Button'
import { Routes } from 'routes'
import { CampaignFormHashes } from 'pages/Campaign/CampaignForm'
import { CampaignDetailsCreationsProps, GroupedCreations, groupedCreationsDefault } from './types'
import EnumUtils from 'utils/enum'
import Link from 'components/Link'
import TsxUtils from 'utils/tsx'
import TooltipHelp from 'components/TooltipHelp'
import MediaFormatUtils from 'utils/mediaFormat'
import './CampaignDetailsCreations.scss'
import CreationHelper from 'utils/creationHelper'
import Modal from 'components/Modal'
import { CAMPAIGN_ACTION } from '../../../../constant/authorization'
import { BaseRole } from '../../../../types/user'
import { AppContext } from '../../../../contexts/AppContext'
import DateUtils from '../../../../utils/date'
import { DATE_FORMAT } from '../../../../constant'

const CampaignDetailsCreations: React.FC<CampaignDetailsCreationsProps> = ({
  campaignData,
  creations,
  publicViewProps,
  showAllMediaFormats = true,
}) => {
  const { t } = useTranslation()
  const { allowedFor, userData } = useContext(AppContext)

  const [groupedCreations, setGroupedCreations] = useState<GroupedCreations>(
    groupedCreationsDefault()
  )
  const [archiveModalIsOpenFor, setArchiveModalIsOpenFor] = useState<Creation['id'] | null>(null)
  const isPublicView = !!publicViewProps?.publicView

  useEffect(() => {
    const tempGC: GroupedCreations = groupedCreationsDefault()

    const sortedCreations = [...creations].sort((a: Creation, b: Creation) =>
      a.file.filename.localeCompare(b.file.filename)
    )

    sortedCreations.forEach((c: Creation) => {
      tempGC[c.mediaFormat].push(c)
    })

    if (!showAllMediaFormats) {
      for (const key in tempGC) {
        if (key !== campaignData.mediaFormat) {
          delete tempGC[key as keyof GroupedCreations]
        }
      }
    }

    setGroupedCreations(tempGC)
  }, [creations])

  const Header = (mediaFormat: MediaFormat): ReactNode => (
    <div className='CampaignDetailsCreations__header'>
      <img
        className='CampaignDetailsCreations__header--icon'
        src={MediaFormatUtils.getIcon(mediaFormat)}
        alt={mediaFormat}
      />
      <div className='CampaignDetailsCreations__header--format'>
        {t(`mediaType.${mediaFormat}`)}
      </div>
    </div>
  )

  const Container: React.FC<{ children: ReactNode }> = ({ children }) => (
    <div className='CampaignDetailsCreations__container'>{children}</div>
  )

  const Item = (creation: Creation, campaignTarget: Campaign['target']): ReactNode => (
    <>
      <div className='CampaignDetailsCreations__item--preview-container'>
        {creation.archivedAt && (
          <TooltipHelp containerClassName='CampaignDetailsCreations__item--preview-tooltip'>
            {t('common.archivedInfo')}
          </TooltipHelp>
        )}
        <Preview
          className={
            'CampaignDetailsCreations__item--preview' +
            TsxUtils.extraStyle(creation.archivedAt, 'CampaignDetailsCreations--grayed-out')
          }
          src={creation.file.url}
          thumbnailSrc={creation.thumbnailUrl}
          isVideo={creation.file.isVideo}
          alt={creation.file.filename}
        />
      </div>

      <div className={`CampaignDetailsCreations__item--info`}>
        <div
          className={
            'CampaignDetailsCreations__item--name' +
            TsxUtils.extraStyle(creation.archivedAt, 'CampaignDetailsCreations--grayed-out')
          }
        >
          {creation.file.filename}
        </div>

        <div className='CampaignDetailsCreations__item-details--container'>
          <p className='CampaignDetailsCreations__item-details--date'>
            {CreationHelper.getCreationDateText(creation)}
          </p>

          {creation.schedules && creation.schedules.length > 1 && (
            <div>
              <TooltipHelp containerClassName='CreationSchedules'>
                <div className='CreationSchedules__details'>
                  <div className='CreationSchedules__header'>
                    <p>{`${t('common.scheduleDates')}`}:</p>
                  </div>
                  <div>
                    {creation.schedules.map((schedule, index) => (
                      <p key={index}>
                        {`${DateUtils.parseAndFormat(
                          schedule.startDate,
                          DATE_FORMAT
                        )} - ${DateUtils.parseAndFormat(schedule.endDate, DATE_FORMAT)}`}
                      </p>
                    ))}
                  </div>
                </div>
              </TooltipHelp>
            </div>
          )}
        </div>

        <div className='CampaignDetailsCreations__creations-details'>
          <p>
            {t('common.creationDetails')}: {creation.details}
          </p>
        </div>

        <div className='CampaignDetailsCreations__creations-details'>
          <p>
            {t('campaignDetails.emissionTime')}: {creation.duration}s
          </p>
        </div>
        <div className='CampaignDetailsCreations__creations-details'>
          {campaignTarget === 'impressions' && creation.impressions && (
            <p>
              {t('form.campaign.creations.emissionRealization')}: {creation.impressions}
            </p>
          )}

          {campaignTarget === 'audience' && creation.audience && (
            <p>
              {t('form.campaign.creations.audienceRealization')}: {creation.audience}
            </p>
          )}
        </div>

        <div className='CampaignDetailsCreations__item-details--container'>
          <Link
            className='CampaignDetailsCreations__item-details'
            toUrl={
              isPublicView
                ? Routes.CAMPAIGNS.EMISSION_SCHEDULE_PUBLIC({
                    campaignUuid: publicViewProps!.campaignUuid!,
                    creationUuid: creation.uuid,
                  })
                : Routes.CAMPAIGNS.EMISSION_SCHEDULE_RO({
                    campaignId: campaignData.id, // eslint-disable-line react/prop-types
                    creationId: creation.id,
                  })
            }
          >
            {t('common.emissonSchedule')}
          </Link>
          {creation.archivedAt && (
            <Link
              className='CreationsFormPreview__creation-details--archived'
              onClick={() => void setArchiveModalIsOpenFor(creation.id)}
            >
              {t('common.archived')}
            </Link>
          )}
        </div>
      </div>

      {archiveModalIsOpenFor?.includes(creation.id) && (
        <Modal
          title={`${t('modal.archivedCreation')} ${creation.file.filename}`}
          isOpen={!!archiveModalIsOpenFor}
          onClose={() => void setArchiveModalIsOpenFor(null)}
          classNameContent={'Modal__content--creations-form-upload'}
          footer={
            <Button
              theme={ButtonTheme.BLUE_OUTLINE}
              onClick={() => void setArchiveModalIsOpenFor(null)}
            >
              {t('common.cancel')}
            </Button>
          }
        >
          <div className='ModalAction__text-input'>
            <span className='ModalAction__text-input-title'>{t('common.reason')}</span>
            <p>{creation.archiveReason}</p>
          </div>
        </Modal>
      )}
    </>
  )

  return (
    <>
      <div className='CampaignDetailsCreations'>
        {Object.entries(groupedCreations).map(
          ([mediaFormatValue, creations]: [string, Creation[]]) => (
            <FlexCol
              key={mediaFormatValue}
              className='CampaignDetailsCreations__col'
            >
              {Header(
                MediaFormat[
                  EnumUtils.getKeyByValue(MediaFormat, mediaFormatValue) as keyof typeof MediaFormat
                ]
              )}

              {creations.length > 0 ? (
                creations
                  .sort(
                    (a: Creation, b: Creation) =>
                      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                  )
                  .map((creation: Creation) => {
                    return (
                      <Container key={creation.id}>{Item(creation, campaignData.target)}</Container>
                    )
                  })
              ) : (
                <Container>{t('campaignDetails.info.noCreations')}</Container>
              )}
            </FlexCol>
          )
        )}
      </div>

      {!isPublicView && campaignData.status !== CampaignStatus.FINISHED && (
        <Button
          className='CampaignDetailsCreations__button'
          disabled={
            (!allowedFor({
              template: CAMPAIGN_ACTION.EDIT_IN_PENDING_ACCEPTANCE_NOT_BY_CLIENT,
              status: campaignData.status,
            }) &&
              campaignData?.acceptanceStatus === CampaignAcceptanceStatus.pending_acceptation) ||
            (allowedFor({
              template: CAMPAIGN_ACTION.EDIT_ACTIVE_PLANNED_RESERVED_CAMPAIGN_BY_CLIENT,
              status: campaignData.status,
            }) &&
              !userData.user?.roles.some(role => role.baseRole === BaseRole.ADMIN))
          }
          theme={ButtonTheme.NAVY_BLUE_OUTLINE}
          size={ButtonSize.SMALL}
          toUrl={Routes.CAMPAIGNS.EDIT(campaignData.id, CampaignFormHashes.CREATIONS)}
        >
          {t('common.edit')}
        </Button>
      )}
    </>
  )
}

export default React.memo(CampaignDetailsCreations)
