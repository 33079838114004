import { Dispatch, SetStateAction } from 'react'
import { Campaign, MediaFormat, Medium, MediumPlaceDetails, MediumUsage } from 'types/campaign'
import { CampaignAndCreationPubPrivIds } from 'types/various'
import { MediumTemplate } from 'api/media/types'

export type UseMediaSelection = {
  counter: MediaUsageCounter
  countTailMedia: (tailGroup: GroupedMedia) => MediaUsageCounter
  filters: Filters
  groupedMedia: GroupedMedia | undefined
  loading: boolean
  mediaUsage: MediumUsage[] | undefined
  onCheckMedium: (id: Medium['id'], newState?: boolean) => void
  onCheckTail: (id: MediumPlaceDetails['id'], state?: boolean, mediumIds?: Medium['id'][]) => void
  onClearFilters: () => void
  onFilter: (filters: Filters) => void
  onFoldTail: (id: MediumPlaceDetails['id']) => void
  refetchMediumTemplates: () => Promise<unknown>
  saveMedia: (
    mediaUsage: MediumUsage[],
    setShouldSaveMedia: Dispatch<SetStateAction<boolean>>,
    setIsMediaSelectionModalOpen?: Dispatch<SetStateAction<boolean>>
  ) => Promise<BrainState | undefined>
  selectAllState: boolean
  setSelectAllState: Dispatch<SetStateAction<boolean>>

  selectMedia: (mediumIds: Medium['id'][]) => void
  setFilters: Dispatch<SetStateAction<Filters>>
  setGroupedMedia: Dispatch<SetStateAction<GroupedMedia | undefined>>
  mediumTemplates: MediumTemplate[] | undefined
  templateLoader: boolean
}

export const filtersFormDefault: Filters = {
  userSelectedAgglomerations: [],
  allAgglomerations: [],
  userSelectedCities: [],
  allCities: [],
  userSelectedBuildings: [],
  allBuildings: [],
  userSelectedMedia: [],
  allMedia: [],
  userSelectedPois: [],
  allPois: [],
  text: '',
}

export type UseMediaSelectionProps = {
  ids: CampaignAndCreationPubPrivIds
  mediumFormat: MediaFormat
  readOnly: boolean
  autoSave?: boolean
  onLoading?: (isLoading: boolean) => void
}

export enum MEDIA_SOURCE {
  CAMPAIGN = 'CAMPAIGN',
  CREATION_PRIV = 'CREATION_PRIV',
  CREATION_PUB = 'CREATION_PUB',
}

export type GroupedMedia = Record<
  string,
  Record<string, Record<string, Record<string, FilterableMedium & GroupData>>>
>

export type MediaGroup = {
  [x: string]: Record<string, any>
}

export type MediaGroupTemp = {
  [x: string]: Medium & GroupData['group']
}

export type GroupData = {
  group: {
    group: MediumGroup
    used: boolean
    folded: boolean
    hidden: boolean
  } & MediumPlaceDetails
}

export enum MediumGroup {
  AGGLOMERATION = 'AGGLOMERATION',
  CITY = 'CITY',
  BUILDING = 'BUILDING',
}

export type MediaGroupArgs = {
  aID: string | null
  cID: string
  bID: string | null
  mID: string
  aGroup: GroupData
  cGroup: GroupData
  bGroup: GroupData
  acc: GroupedMedia
  curr: FilterableMedium
}

export type MediaUsageCounter = {
  used: number
  all: number
}

export type FilterableMedium = Medium & {
  hidden: boolean
}

export type FilterValue = MediumPlaceDetails['name']

export type Filters = {
  allAgglomerations: FilterValue[]
  allBuildings: FilterValue[]
  allCities: FilterValue[]
  allMedia: FilterValue[]
  allPois: FilterValue[]
  filteredAgglomerations?: FilterValue[]
  filteredBuildings?: FilterValue[]
  filteredCities?: FilterValue[]
  filteredMedia?: FilterValue[]
  userSelectedAgglomerations: FilterValue[]
  userSelectedBuildings: FilterValue[]
  userSelectedCities: FilterValue[]
  userSelectedMedia: FilterValue[]
  userSelectedPois: FilterValue[]
  text: string
}

export type BrainState = Campaign['brainState']
