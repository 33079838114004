import React, { ReactElement, ReactNode, useContext } from 'react'
import { BrowserRouter, Navigate, Route, RouteProps, Routes as ReactRoutes } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import FullScreenSpinner from 'components/FullScreenSpinner'
import Sign, { SignType } from 'pages/Sign'
import CampaignForm from 'pages/Campaign/CampaignForm'
import CampaignDetails from 'pages/Campaign/CampaignDetails'
import CampaignAcceptanceFields from 'pages/Campaign/CampaignAcceptanceFields'
import { Routes } from 'routes'
import { AppContext } from 'contexts/AppContext'
import ScrollToTop from 'components/ScrollToTop'
import { NotifierQueue } from 'components/Notifier'
import Layout from 'components/Layout'
import PageNotFound from 'pages/PageNotFound'
import Agencies from 'pages/Agencies'
import { FormType } from 'types/various'
import AgencyForm from 'pages/Agencies/AgencyForm'
import UserSettings from 'pages/User/UserSettings'
import EmissionSchedule from 'pages/Campaign/EmissionSchedule'
import Report from 'pages/Report'
import UserTable from 'pages/User/UserTable'
import UserEdit from 'pages/User/UserEdit'
import UserInvite from 'pages/User/UserInvite'
import Inventory from 'pages/Inventory'
import { PAGES } from 'constant/authorization'
import Features from 'pages/Features'
import CampaignTable from 'pages/Campaign/CampaignTable'
import RolesTable from './pages/Roles/RolesTable'
import RoleForm from './pages/Roles/RoleForm'
import { PermissionType, FeatureName, CampaignPermissionType } from './types/features'
import EmissionPlan from './pages/EmissionPlan'

const App: React.FC = () => {
  const { homepageUrl, userData, notifications, allowedFor } = useContext(AppContext)

  const UtilityRoutes = () => {
    const CampaignTablePrivateRoute: ReactElement = (
      <PrivateRoute
        allowed={allowedFor({
          feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Read },
        })}
      >
        <CampaignTable />
      </PrivateRoute>
    )
    const PageNotFoundPrivateRoute: ReactElement = (
      <PrivateRoute allowed={allowedFor({ template: PAGES.NOT_FOUND })}>
        <PageNotFound />
      </PrivateRoute>
    )

    return (
      <>
        <Route path={Routes.ROOT}>
          <Route
            path={''}
            element={
              <Navigate
                to={homepageUrl}
                replace={true}
              />
            }
          />
          <Route
            path={Routes.HOME}
            element={
              <Navigate
                to={homepageUrl}
                replace={true}
              />
            }
          />
          <Route
            path={Routes.CAMPAIGNS.ALL}
            element={CampaignTablePrivateRoute}
          />
        </Route>
        <Route
          path={Routes.ANY}
          element={PageNotFoundPrivateRoute}
        />
        <Route
          path={Routes.NOT_FOUND}
          element={PageNotFoundPrivateRoute}
        />
      </>
    )
  }

  const SignRoutes = () => {
    const elementOrHomeIfSignedIn = (el: ReactNode): RouteProps['element'] =>
      userData.signedIn ? <Navigate to={Routes.HOME} /> : el

    return (
      <>
        <Route
          path={Routes.SIGN.SIGN_IN}
          element={elementOrHomeIfSignedIn(<Sign type={SignType.SIGN_IN} />)}
        />
        <Route
          path={Routes.SIGN.REMIND_PASSWORD}
          element={elementOrHomeIfSignedIn(<Sign type={SignType.REMIND_PASSWORD} />)}
        />
        <Route
          path={Routes.SIGN.RESET_PASSWORD}
          element={elementOrHomeIfSignedIn(<Sign type={SignType.RESET_PASSWORD} />)}
        />
        <Route
          path={Routes.SIGN.ACCEPT_INVITATION}
          element={elementOrHomeIfSignedIn(<Sign type={SignType.ACCEPT_INVITATION} />)}
        />
      </>
    )
  }

  const AgencyRoutes = () => (
    <>
      <Route
        path={Routes.AGENCIES.ALL}
        element={
          <PrivateRoute
            allowed={allowedFor({
              template: PAGES.AGENCIES.LIST,
              feature: { name: FeatureName.Agencies, action: PermissionType.Read },
            })}
          >
            <Agencies />
          </PrivateRoute>
        }
      />
      <Route
        path={Routes.AGENCIES.NEW}
        element={
          <PrivateRoute
            allowed={allowedFor({
              template: PAGES.AGENCIES.ADD_EDIT,
              feature: { name: FeatureName.Agencies, action: PermissionType.Write },
            })}
          >
            <AgencyForm />
          </PrivateRoute>
        }
      />
      <Route
        path={Routes.AGENCIES.EDIT(':id')}
        element={
          <PrivateRoute
            allowed={allowedFor({
              template: PAGES.AGENCIES.ADD_EDIT,
              feature: { name: FeatureName.Agencies, action: PermissionType.Write },
            })}
          >
            <AgencyForm />
          </PrivateRoute>
        }
      />
    </>
  )

  const CampaignRoutes = () => {
    const EMISSION_SCHEDULE_PATH = Routes.CAMPAIGNS.EMISSION_SCHEDULE({
      campaignId: ':campaignId',
      creationId: ':creationId',
    })
    const EmissionSchedulePrivateRoute: ReactElement = (
      <PrivateRoute allowed={allowedFor({ template: PAGES.CAMPAIGNS.EMISSION_SCHEDULE })}>
        <EmissionSchedule />
      </PrivateRoute>
    )

    return (
      <>
        <Route
          path={Routes.CAMPAIGNS.ONE(':id')}
          element={
            <PrivateRoute allowed={allowedFor({ template: PAGES.CAMPAIGNS.DETAILS })}>
              <CampaignDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={Routes.CAMPAIGNS.ONE_PUBLIC(':uuid')}
          element={
            <Layout>
              <CampaignDetails publicView />
            </Layout>
          }
        />
        <Route
          path={Routes.EMISSION_PLAN(':uuid')}
          element={
            <Layout>
              <EmissionPlan />
            </Layout>
          }
        />
        <Route
          path={Routes.CAMPAIGNS.NEW}
          element={
            <PrivateRoute allowed={allowedFor({ template: PAGES.CAMPAIGNS.ADD_EDIT })}>
              <CampaignForm formType={FormType.NEW} />
            </PrivateRoute>
          }
        />
        <Route
          path={Routes.CAMPAIGNS.EDIT(':id')}
          element={
            <PrivateRoute allowed={allowedFor({ template: PAGES.CAMPAIGNS.ADD_EDIT })}>
              <CampaignForm formType={FormType.EDIT} />
            </PrivateRoute>
          }
        />
        <Route
          path={Routes.CAMPAIGNS.ACCEPTANCE_FIELDS(':id')}
          element={
            <PrivateRoute allowed={allowedFor({ template: PAGES.CAMPAIGNS.ACCEPTANCE_FIELDS })}>
              <CampaignAcceptanceFields />
            </PrivateRoute>
          }
        />
        <Route path={EMISSION_SCHEDULE_PATH}>
          <Route
            path={''}
            element={EmissionSchedulePrivateRoute}
          />
          <Route
            path={':readOnly'}
            element={EmissionSchedulePrivateRoute}
          />
        </Route>
        <Route
          path={Routes.CAMPAIGNS.EMISSION_SCHEDULE_PUBLIC({
            campaignUuid: ':campaignUuid',
            creationUuid: ':creationUuid',
          })}
          element={
            <Layout>
              <EmissionSchedule />
            </Layout>
          }
        />
      </>
    )
  }

  const UserRoutes = () => (
    <>
      <Route
        path={Routes.USERS.ALL}
        element={
          <PrivateRoute
            allowed={allowedFor({
              template: PAGES.USERS.LIST,
              feature: { name: FeatureName.Users, action: PermissionType.Read },
            })}
          >
            <UserTable />
          </PrivateRoute>
        }
      />
      <Route
        path={Routes.USERS.SETTINGS}
        element={
          <PrivateRoute allowed={allowedFor({ template: PAGES.USERS.SETTINGS })}>
            <UserSettings />
          </PrivateRoute>
        }
      />
      <Route
        path={Routes.USERS.EDIT(':id')}
        element={
          <PrivateRoute
            allowed={allowedFor({
              template: PAGES.USERS.EDIT,
              feature: { name: FeatureName.Users, action: PermissionType.Write },
            })}
          >
            <UserEdit />
          </PrivateRoute>
        }
      />
      <Route
        path={Routes.USERS.INVITE}
        element={
          <PrivateRoute
            allowed={allowedFor({
              template: PAGES.USERS.INVITE,
              feature: { name: FeatureName.Users, action: PermissionType.Write },
            })}
          >
            <UserInvite />
          </PrivateRoute>
        }
      />
    </>
  )

  const RoleRoutes = () => (
    <>
      <Route
        path={Routes.ROLES.ALL}
        element={
          <PrivateRoute
            allowed={allowedFor({
              template: PAGES.ROLES.LIST,
              feature: { name: FeatureName.Users, action: PermissionType.Read },
            })}
          >
            <RolesTable />
          </PrivateRoute>
        }
      />
      <Route
        path={Routes.ROLES.NEW}
        element={
          <PrivateRoute
            allowed={allowedFor({
              template: PAGES.ROLES.ADD_EDIT,
              feature: { name: FeatureName.Users, action: PermissionType.Write },
            })}
          >
            <RoleForm />
          </PrivateRoute>
        }
      />
      <Route
        path={Routes.ROLES.EDIT(':id')}
        element={
          <PrivateRoute
            allowed={allowedFor({
              template: PAGES.ROLES.ADD_EDIT,
              feature: { name: FeatureName.Users, action: PermissionType.Write },
            })}
          >
            <RoleForm />
          </PrivateRoute>
        }
      />
    </>
  )

  const OtherRoutes = () => (
    <>
      <Route
        path={Routes.REPORT}
        element={
          <PrivateRoute
            allowed={allowedFor({
              template: PAGES.REPORT,
              feature: { name: FeatureName.Reports, action: PermissionType.Active },
            })}
          >
            <Report />
          </PrivateRoute>
        }
      />
      <Route
        path={Routes.INVENTORY}
        element={
          <PrivateRoute
            allowed={allowedFor({
              template: PAGES.INVENTORY,
              feature: { name: FeatureName.Inventory, action: PermissionType.Active },
            })}
          >
            <Inventory />
          </PrivateRoute>
        }
      />
      <Route
        path={Routes.FEATURES}
        element={
          <PrivateRoute allowed={allowedFor({ template: PAGES.FEATURES })}>
            <Features />
          </PrivateRoute>
        }
      />
    </>
  )

  const RouterContainer = (
    <BrowserRouter>
      <ScrollToTop />
      <ReactRoutes>
        {UtilityRoutes()}
        {SignRoutes()}
        {AgencyRoutes()}
        {CampaignRoutes()}
        {UserRoutes()}
        {RoleRoutes()}
        {OtherRoutes()}
      </ReactRoutes>
    </BrowserRouter>
  )

  return (
    <>
      {userData.fetchComplete ? RouterContainer : <FullScreenSpinner />}
      {notifications.length > 0 ? <NotifierQueue /> : null}
    </>
  )
}

export default App
