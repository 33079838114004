import React from 'react'
import TsxUtils from 'utils/tsx'
import { TableDataProps } from '../types'
import './TableData.scss'

const TableData: React.FC<TableDataProps> = ({
  className,
  children,
  onMouseEnter,
  onMouseLeave,
}) => (
  <td
    className={'TableData' + TsxUtils.extraStyle(className, className)}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {children}
  </td>
)

export default TableData
