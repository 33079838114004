import { iterateOverTail } from './iterator'
import { GroupedMedia, MediaUsageCounter } from './types'

export const countTailMedia = (groupTail: GroupedMedia): MediaUsageCounter => {
  let all = 0
  let used = 0

  iterateOverTail({
    groupTail,
    nodeCallback: node => {
      if (!node.hidden) all += 1
      if (node.used) used += 1
    },
  })

  return { all, used }
}
