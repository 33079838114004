import { Agency } from 'types/agency'
import api from 'api'
import { ITEMS_PER_PAGE } from 'constant'
import { Dispatch, SetStateAction } from 'react'
import { TableSort } from 'hooks/useTableFilter'
import { NotifierType } from '../../../components/Notifier'
import { TFunction } from 'react-i18next'

export default class AgenciesListService {
  static getAgencies = (
    pageNumber: number,
    setAgencies: Dispatch<SetStateAction<Agency[]>>,
    setTotalCount: Dispatch<SetStateAction<number>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setTrigger: Dispatch<SetStateAction<boolean>>,
    tableSort: TableSort
  ): void => {
    setLoading(true)

    api.agency
      .getAgencies({
        first: pageNumber * ITEMS_PER_PAGE,
        last: ITEMS_PER_PAGE,
        sortColumn: tableSort.column,
        sortDirection: tableSort.direction,
      })
      .then(res => {
        const { agencies } = res.data

        setAgencies(agencies.nodes)
        setTotalCount(agencies.totalCount)
      })
      .catch(() => void {})
      .finally(() => {
        setLoading(false)
        setTrigger(false)
      })
  }

  static deleteAgency = (
    agencyId: Agency['id'],
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>,
    addNotification: (type: NotifierType, text: string | string[], time?: number) => void,
    t: TFunction
  ): Promise<void> =>
    api.agency
      .deleteAgency(agencyId)
      .then(res => {
        if (!res.data) {
          return
        }

        const { success } = res.data.destroyAgency
        if (success) {
          addNotification(NotifierType.SUCCESS, t('agencies.remove.success'))
        }
      })
      .catch(() => void {})
      .finally(() => {
        setTrigger(true)
      })
}
