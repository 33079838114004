import api from 'api'
import {
  Campaign as CampaignQgl,
  MediaFormat,
  ApprovalCategory,
  CreationStatus,
} from 'types/campaign'
import { Campaign, OnCheckboxUpdateFn } from './types'
import { TFunction } from 'i18next'
import { NotifierType } from 'components/Notifier'
import { UseNotifierProps } from 'hooks/useNotifier'
import EnumUtils from 'utils/enum'

export default class CampaignAcceptanceFieldsService {
  static getCampaign = (
    id: CampaignQgl['id'],
    setCampaign: React.Dispatch<React.SetStateAction<Campaign | undefined>>
  ): void => {
    api.campaign
      .getCampaignForAcceptanceFields(id)
      .then(res => {
        const { id, name, creations, approvals } = res.data.node

        setCampaign({
          id,
          name,
          creations: creations.map((creation: any) => ({
            ...creation,
            mediaFormat: MediaFormat[creation.mediaFormat as keyof typeof MediaFormat],
            state: CreationStatus[EnumUtils.getKeyByValueT(CreationStatus, creation.state)!],
          })),
          approvals: approvals.map((approval: any) => ({
            ...approval,
            category:
              ApprovalCategory[approval.category.toUpperCase() as keyof typeof ApprovalCategory],
          })),
        })
      })
      .catch(() => void {})
  }

  static onChangeApproval = (
    approvalId: string,
    approved: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    onCheckboxUpdate: OnCheckboxUpdateFn,
    addNotification: UseNotifierProps['addNotification'],
    t: TFunction
  ): void => {
    setLoading(true)

    api.campaign
      .updateApproval({ approvalId, approved })
      .then(res => {
        if (res.data?.updateApproval.success) {
          onCheckboxUpdate('approvals', approvalId, approved)
          CampaignAcceptanceFieldsService.acceptancesUpdateNotifier(addNotification, t)
        }
      })
      .catch(() => void {})
      .finally(() => void setLoading(false))
  }

  static onChangeCreation = (
    approvalId: string,
    approved: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    onCheckboxUpdate: OnCheckboxUpdateFn,
    addNotification: UseNotifierProps['addNotification'],
    t: TFunction
  ): void => {
    setLoading(true)

    api.creation
      .updateCreation(approvalId, approved)
      .then(res => {
        if (res.data?.updateCreationApproval.success) {
          onCheckboxUpdate('creations', approvalId, approved)
          CampaignAcceptanceFieldsService.acceptancesUpdateNotifier(addNotification, t)
        }
      })
      .catch(() => void {})
      .finally(() => void setLoading(false))
  }

  static acceptancesUpdateNotifier = (
    addNotification: UseNotifierProps['addNotification'],
    t: TFunction
  ): void => void addNotification(NotifierType.SUCCESS, t('notification.acceptancesUpdate'))
}
