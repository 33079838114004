// eslint-disable-next-line import/no-unresolved
import { IDeepEntry } from 'deepdash/es/IDeepEntry'
import deepdash from 'deepdash'
import lodash from 'lodash'
const _ = deepdash(lodash)
import { Medium, MediumPlaceDetails } from 'types/campaign'
import { countTailMedia } from './counter'
import { iterateOverTail } from './iterator'
import { GroupData, GroupedMedia, MediaGroup, MediaGroupTemp, UseMediaSelection } from './types'

export const switchGroupFold = (id: MediumPlaceDetails['id'], groupedMedia: GroupedMedia): void => {
  const { groupTail } = getAllToUpEntriesAndOriginGroup(id, groupedMedia)

  const { newState } = updateGroupFold(groupTail)
  switchFoldsFromCurrentToDown(groupTail, newState)
}

const updateGroupFold = (groupTail: MediaGroupTemp): { newState: boolean } => {
  const groupData: GroupData['group'] = groupTail.group
  const newState = !groupData.folded
  groupData.folded = newState

  return { newState }
}

const switchFoldsFromCurrentToDown = (groupTail: MediaGroup, newState: boolean): void =>
  void iterateOverTail({
    groupTail,
    nodeGroupCallback: ({ nodeGroup }) => {
      nodeGroup.folded = newState
    },
  })

export const switchGroupState = (
  id: MediumPlaceDetails['id'],
  groupedMedia: GroupedMedia,
  state?: boolean,
  mediumIds?: Medium['id'][]
): void => {
  const { originGroup, groupTail } = getAllToUpEntriesAndOriginGroup(id, groupedMedia)

  const { newState } = updateGroupState(groupTail)
  switchStatesFromCurrentToDown(groupTail, state === undefined ? newState : state, mediumIds)
  switchStatesFromOriginToDown(originGroup)
}

export const switchMediumState = (
  id: Medium['id'],
  groupedMedia: GroupedMedia,
  newState?: boolean
): void => {
  const { originGroup, mediumOrGroupData } = getAllToUpEntriesAndOriginGroup(id, groupedMedia)

  updateMediumState(mediumOrGroupData as Medium, newState)
  switchStatesFromOriginToDown(originGroup)
}

const updateGroupState = (groupTail: MediaGroupTemp): { newState: boolean } => {
  const groupData: GroupData['group'] = groupTail.group
  const newState = !groupData.used
  groupData.used = newState

  return { newState }
}

const switchStatesFromCurrentToDown = (
  groupTail: MediaGroup,
  newState: boolean,
  mediumIds?: Medium['id'][]
): void =>
  void iterateOverTail({
    groupTail,
    nodeGroupCallback: ({ nodeGroup }) => {
      nodeGroup.used = mediumIds ? !!mediumIds?.some(id => id === nodeGroup.id) : newState
    },
    nodeCallback: node => {
      node.used = mediumIds ? !!mediumIds?.some(id => id === node.asId.toString()) : newState
    },
  })

const switchStatesFromOriginToDown = (groupTail: GroupedMedia): void =>
  void iterateOverTail({
    groupTail,
    nodeGroupCallback: ({ nodeGroup, node }) => {
      const { used } = countTailMedia(node)
      nodeGroup.used = used > 0
    },
  })

const getAllToUpEntriesAndOriginGroup = (
  id: MediumPlaceDetails['id'],
  groupedMedia: GroupedMedia
): {
  originGroup: MediaGroup
  groupTail: MediaGroupTemp
  mediumOrGroupData: Medium | GroupData['group']
} => {
  const entry: IDeepEntry = _.findDeep(groupedMedia, (value, key) => key === 'id' && value === id)!

  const allToUp: IDeepEntry[] = entry.context!.parents!.slice(1)
  // dummy `_` property because `iterateOverTail` function omits first `group` key
  const originGroup: MediaGroup = { _: { ...allToUp[0].value } }
  const groupTail: MediaGroupTemp = allToUp.at(-2)!.value
  const mediumOrGroupData: Medium | GroupData['group'] = allToUp.at(-1)!.value

  return { originGroup, groupTail, mediumOrGroupData }
}

const updateMediumState = (medium: Medium, newState?: boolean): void => {
  if (newState !== undefined) {
    medium.used = newState
  } else {
    medium.used = !medium.used
  }
}

export const switchStatesByMediaToImport = (
  mediaToImport: string,
  groupedMedia: GroupedMedia,
  onCheckMedium: UseMediaSelection['onCheckMedium'],
  onCheckTail: UseMediaSelection['onCheckTail']
) => {
  const mediumIds = getValidMediaIds(mediaToImport)

  if (mediumIds) {
    Object.keys(groupedMedia).forEach(id => {
      onCheckTail(id, undefined, mediumIds)
    })
  }
}

export const getValidMediaIds = (mediaToImport: string): Medium['id'][] | null => {
  const REGEX_SEPARATORS = new RegExp(/ |,|;|'|"/, 'g')
  const REGEX_SEPARATED_NUMBERS = new RegExp(/^\d+(,\d+)*$/, 'g')
  const mediaToImportCleaned = mediaToImport
    .replace(REGEX_SEPARATORS, '-')
    .split('-')
    .filter(el => el)
    .join(',')
  if (REGEX_SEPARATED_NUMBERS.test(mediaToImportCleaned)) {
    return mediaToImportCleaned.split(',')
  }

  return null
}
