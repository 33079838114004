import React from 'react'
import ExternalPagination from 'react-js-pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons'
import './Pagination.scss'

interface PaginationProps {
  activePage: number
  itemsCountPerPage: number
  totalItemsCount: number
  pageRangeDisplayed?: number
  onChange: (pageNumber: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  pageRangeDisplayed = 5,
  onChange,
}) => (
  <ExternalPagination
    activePage={activePage}
    itemsCountPerPage={itemsCountPerPage}
    totalItemsCount={totalItemsCount}
    pageRangeDisplayed={pageRangeDisplayed}
    onChange={onChange}
    prevPageText={<FontAwesomeIcon icon={faAngleLeft} />}
    firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
    lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
    nextPageText={<FontAwesomeIcon icon={faAngleRight} />}
    innerClass='Pagination'
    itemClass='Pagination__item'
    activeClass='Pagination__item--active'
    disabledClass='Pagination__item--disabled'
    linkClass='Pagination__link'
    activeLinkClass='Pagination__link--active'
    linkClassFirst='Pagination__link--first'
    linkClassPrev='Pagination__link--prev'
    linkClassNext='Pagination__link--next'
    linkClassLast='Pagination__link--last'
  />
)

export default Pagination
