import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { CampaignDetailsAlertsProps } from './types'
import IconAlert from 'components/Icon/IconAlert'
import Alerts from 'components/Alerts'
import StripeCard from 'components/StripeCard'
import './CampaignDetailsAlerts.scss'
import { Alert, AlertType } from '../../../../types/campaign'

const CampaignDetailsAlerts: React.FC<CampaignDetailsAlertsProps> = ({ alerts }) => {
  const { t } = useTranslation()
  const infoAlerts: Alert[] = []
  const warnAlerts: Alert[] = []

  alerts.forEach(alert => {
    if (alert.type === AlertType.alert) {
      warnAlerts.push(alert)
    } else {
      infoAlerts.push(alert)
    }
  })

  const infoAlertsCards: ReactNode = infoAlerts.length ? (
    <StripeCard
      className={'CampaignDetailsAlerts--info'}
      title={t('common.info')}
      icon={<IconAlert alertType={AlertType.warning} />}
    >
      <Alerts alerts={infoAlerts} />
    </StripeCard>
  ) : (
    <></>
  )

  const warnAlertsCards: ReactNode = warnAlerts.length ? (
    <StripeCard
      className={'CampaignDetailsAlerts--warn'}
      title={t('common.alert')}
      icon={<IconAlert alertType={AlertType.alert} />}
    >
      <Alerts alerts={warnAlerts} />
    </StripeCard>
  ) : (
    <></>
  )

  return (
    <>
      {infoAlertsCards}
      {warnAlertsCards}
    </>
  )
}

export default CampaignDetailsAlerts
