import { gql } from '@apollo/client'
import { MUTATION_STATE } from '../fragments'

const PAYLOAD = 'DestroyCampaignCreationPayload'

export const DELETE_CREATION = gql`
  ${MUTATION_STATE(PAYLOAD)}
  mutation deleteCreation($creationId: ID!) {
    destroyCreation(input: { creationId: $creationId }) {
      ...MutationState${PAYLOAD}Fields
    }
  }
`
