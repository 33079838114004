import { gql } from '@apollo/client'
import { APPROVAL_FIELDS, CREATION_FIELDS } from '../fragments'

export const GET_CAMPAIGN_FOR_ACCEPTANCE_FIELDS = gql`
  ${CREATION_FIELDS}
  ${APPROVAL_FIELDS}
  query campaignForAcceptanceFields($id: ID!) {
    node(id: $id) {
      ... on Campaign {
        id
        name
        creations {
          ...CreationFields
        }
        approvals {
          ...ApprovalFields
        }
      }
    }
  }
`
