import { SelectAsyncValue } from 'components/Form/SelectAsync'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

export default class VariableUtils {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static isDeepEqual = (valueA: any, valueB: any): boolean => isEqual(valueA, valueB)

  static formatNumber = (value: number, options?: Intl.NumberFormatOptions): string => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'decimal',
      maximumFractionDigits: 2,
      ...options,
    }).format(value)
  }

  static formatNumberWithGuard = (
    value: number | null,
    options?: Intl.NumberFormatOptions
  ): string => {
    return value ? VariableUtils.formatNumber(value, options) : '0'
  }

  static formatNumberCompact = (value: number): string =>
    VariableUtils.formatNumber(value, { notation: 'compact' })

  // source: https://stackoverflow.com/a/18650828/7268884
  static formatBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  static numberToGqlFloat = (number: number): number => parseFloat(number.toString())

  static numberToGqlInt = (number: number): number => parseInt(number.toString())

  static parseNumbersFromStringToArray = (numbers: string): number[] =>
    numbers
      .replace(/\D+/g, ' ')
      .trim()
      .split(' ')
      .map(e => parseInt(e))

  static toSelectAsyncValue = <T>({
    value,
    label,
    rawData,
  }: {
    value: string
    label?: string
    rawData?: T
  }): SelectAsyncValue<T> => ({
    value,
    label: label ?? value,
    rawData,
  })

  static isEmptyObject = (obj: unknown): boolean => isEmpty(obj)

  static isNullOrUndefined = <T>(value: T): boolean => value === null || value === undefined

  static isEmptyArray = <T>(array: T[]): boolean => array.length === 0
}
