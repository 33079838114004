import React, { FC, ReactNode, useContext } from 'react'
import { AppContext } from 'contexts/AppContext'
import errorLink from 'components/ApolloProvider/errorLink'
import authLink from './authLink'
import defaultOptions from './defaultOptions'
import uploadLink from './uploadLink'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as ApolloProviderOriginal,
  ApolloLink,
  NormalizedCacheObject,
} from '@apollo/client'

export let client: ApolloClient<NormalizedCacheObject>

const ApolloProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { addNotification, tryToGetNewToken, force404 } = useContext(AppContext)

  client = new ApolloClient({
    link: ApolloLink.from([
      errorLink({ addNotification, tryToGetNewToken, force404 }),
      authLink,
      uploadLink,
    ]),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions,
  })

  return <ApolloProviderOriginal client={client}>{children}</ApolloProviderOriginal>
}

export default ApolloProvider
