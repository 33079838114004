import React, { ReactElement, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import FlexRow from 'components/Layout/FlexRow'
import FlexCol from 'components/Layout/FlexCol'
import Button, { ButtonSize, ButtonTheme } from 'components/Form/Button'
import { Routes } from 'routes'
import TsxUtils from 'utils/tsx'
import ArrayUtils from 'utils/array'
import { CampaignDetailsSummaryProps, RowBody } from './types'
import { AppContext } from 'contexts/AppContext'
import { CAMPAIGN_ACTION, PAGES } from 'constant/authorization'
import { Features } from 'constant/features'
import CampaignDetailsService from '../services/campaign-details.service'
import VariableUtils from 'utils/variable'
import FillingSpinner, { FillingSpinnerType } from 'components/FillingSpinner'
import i18n from 'i18n'
import { userFullName } from 'utils/user'
import './CampaignDetailsSummary.scss'
import { CampaignAcceptanceStatus } from '../../../../types/campaign'
import { BaseRole } from '../../../../types/user'
import { CampaignPermissionType, FeatureName } from 'types/features'
import { AgencyType } from '../../../../types/agency'

const CampaignDetailsSummary: React.FC<CampaignDetailsSummaryProps> = ({
  campaignData,
  everUsedMediaCount,
  mediaCount,
  mediaLoading,
  publicView,
}: CampaignDetailsSummaryProps) => {
  const { t } = useTranslation()
  const { allowedFor, isFeatureActive, userData } = useContext(AppContext)

  const row = (a: RowBody, b: RowBody, key: number): ReactElement => (
    <FlexRow
      key={key}
      className={
        'CampaignDetailsSummary__row' +
        TsxUtils.extraStyle(!a || !b, 'CampaignDetailsSummary__row--half')
      }
    >
      {a && rowInside(a)}
      {b && rowInside(b)}
    </FlexRow>
  )

  const rowInside = (el: RowBody) => (
    <FlexRow className='CampaignDetailsSummary__row--inside'>
      <>
        <FlexCol className='CampaignDetailsSummary__col  CampaignDetailsSummary__title'>
          {el.title}
        </FlexCol>
        <FlexCol
          className={
            'CampaignDetailsSummary__col' +
            TsxUtils.extraStyle(el.valueExtraStyle, el.valueExtraStyle)
          }
        >
          {el.value}
        </FlexCol>
      </>
    </FlexRow>
  )

  const briefName = (): RowBody => ({
    title: t('common.briefName'),
    value: campaignData.briefName,
  })

  const agencyName = (): RowBody => ({
    title: t('common.agency'),
    value: campaignData.agencyName,
  })

  const supervisors = (): RowBody => ({
    title: t('common.supervisors'),
    value:
      campaignData.supervisors.length > 0
        ? campaignData.supervisors.map(userFullName).sort().join(', ')
        : '-',
  })

  const emissionTime = (): RowBody => ({
    title: t('common.campaignTerm'),
    value: CampaignDetailsService.campaignTerm(campaignData.startDate, campaignData.endDate),
  })

  const mediaType = (): RowBody => ({
    title: t('common.mediaType'),
    value: t(`mediaType.${campaignData.mediaCriteriaSearch.mediumFormat}`),
  })

  const usedMedia = (): RowBody => ({
    title: t('common.usedMedia'),
    value: mediaLoading ? (
      <FillingSpinner type={FillingSpinnerType.STANDARD} />
    ) : (
      VariableUtils.formatNumber(mediaCount)
    ),
  })

  const everUsedMedia = (): RowBody => ({
    title: t('common.everUsedMedia'),
    value: VariableUtils.formatNumber(everUsedMediaCount),
  })

  const offer = (): RowBody => ({
    title: t('common.offerType'),
    value: campaignData.offer ? i18n.t(`offer.${campaignData.offer}`).toString() : '-',
  })

  const commercialAttribute = (): RowBody => ({
    title: t('attributesSelection.commercialAttribute'),
    value: campaignData.commercialAttribute
      ? t(`attributesSelection.commercialAttributesValues.${campaignData.commercialAttribute}`)
      : '-',
  })
  const campaignId = (): RowBody => ({
    title: t('common.campaignId'),
    value: `${campaignData.id}`,
  })

  const pois = (): RowBody => ({
    title: t('common.pois'),
    value:
      campaignData.mediaCriteriaSearch.pois.length > 0
        ? campaignData.mediaCriteriaSearch.pois.sort().map((poi: string) => (
            <div
              key={poi}
              className='CampaignDetailsSummary__label'
            >
              {poi}
            </div>
          ))
        : '-',
    valueExtraStyle: 'CampaignDetailsSummary__col--labels',
  })

  const mediaDuration = (): RowBody => ({
    title: t('common.mediaDuration'),
    value: `${campaignData.mediaDuration}`,
  })

  const bundleCommercialName = (): RowBody => ({
    title: t('campaign.bundleCommercialName'),
    value: campaignData.bundle?.commercialName || '-',
  })

  const bundleSuite = (): RowBody => ({
    title: t('campaign.bundleSuite'),
    value: campaignData.bundle?.suite || '-',
  })

  // todo: uncomment when emissionType will be ready on stage
  // const emissionSynchronized = (): RowBody => ({
  //   title: t('form.campaignData.details.synchronizedEmission'),
  //   value: '',
  // })

  const elements: (RowBody | null)[] = [
    briefName(),
    agencyName(),
    supervisors(),
    emissionTime(),
    mediaType(),
    usedMedia(),
    everUsedMedia(),
    offer(),
    campaignData.agencyType === AgencyType.internal ? commercialAttribute() : null,
    ...(allowedFor({ template: CAMPAIGN_ACTION.CAMPAIGN_ID }) ? [campaignId()] : []),
    ...(isFeatureActive(Features.POI) ? [pois()] : []),
    mediaDuration(),
    bundleCommercialName(),
    bundleSuite(),
    // todo: uncomment when emissionType will be ready on stage
    // ...(campaignData.emissionType === EmissionType.SYNCHRONIZED ? [emissionSynchronized()] : []),
  ]

  const Buttons = (): ReactNode => (
    <div className='CampaignDetailsSummary__buttons'>
      {allowedFor({
        template: CAMPAIGN_ACTION.EDIT,
        feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Write },
        status: campaignData.status, // eslint-disable-line react/prop-types
      }) && (
        <Button
          disabled={
            (!allowedFor({
              template: CAMPAIGN_ACTION.EDIT_IN_PENDING_ACCEPTANCE_NOT_BY_CLIENT,
              status: campaignData.status, // eslint-disable-line react/prop-types
            }) &&
              campaignData?.acceptanceStatus === // eslint-disable-line react/prop-types
                CampaignAcceptanceStatus.pending_acceptation) ||
            (allowedFor({
              template: CAMPAIGN_ACTION.EDIT_ACTIVE_PLANNED_RESERVED_CAMPAIGN_BY_CLIENT,
              status: campaignData.status, // eslint-disable-line react/prop-types
            }) &&
              !userData.user?.roles.some(role => role.baseRole === BaseRole.ADMIN))
          }
          theme={ButtonTheme.NAVY_BLUE_OUTLINE}
          size={ButtonSize.SMALL}
          toUrl={Routes.CAMPAIGNS.EDIT(campaignData.id)} // eslint-disable-line react/prop-types
        >
          {t('common.edit')}
        </Button>
      )}
      {allowedFor({ template: PAGES.CAMPAIGNS.ACCEPTANCE_FIELDS }) && (
        <Button
          theme={ButtonTheme.NAVY_BLUE_OUTLINE}
          size={ButtonSize.SMALL}
          toUrl={Routes.CAMPAIGNS.ACCEPTANCE_FIELDS(campaignData.id)} // eslint-disable-line react/prop-types
        >
          {t('campaignDetails.info.acceptanceFields')}
        </Button>
      )}
    </div>
  )

  return (
    <>
      {ArrayUtils.toChunks(
        elements.filter(el => !!el),
        2
      ).map(([a, b], key) => row(a as RowBody, b as RowBody, key))}
      {!publicView && Buttons()}
    </>
  )
}

export default CampaignDetailsSummary
