export enum AppMode {
  DEVELOPMENT = 'development',
  DEV = 'dev',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export default class EnvUtils {
  static toBoolean = (value: string | undefined): boolean => (value === 'true' ? true : false)
}
