import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from 'locale'

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  resources,
  lng: 'pl',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
