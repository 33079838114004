import React, { ReactElement, ReactNode, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Card from 'components/Layout/Card'
import PageHeader from 'components/Layout/PageHeader'
import { Routes } from 'routes'
import FormRow from 'components/Form/FormRow'
import FormColumn from 'components/Form/FormColumn'
import Input, { InputType, OnInputChangeFn } from 'components/Form/Input'
import service from './service'
import FillingSpinner from 'components/FillingSpinner'
import Button, { ButtonTheme } from 'components/Form/Button'
import { AppContext } from 'contexts/AppContext'
import { agencyDefault, AgencyForm as AgencyFormType } from './types'
import { ReactRouterParams } from 'types/various'
import Checkbox from '../../../components/Form/Checkbox'
import SelectAsync, { SelectAsyncValue } from '../../../components/Form/SelectAsync'
import basicFormService from '../../Campaign/CampaignForm/BasicsForm/basics-form.service'
import { Company } from '../../../api/companies/types'
import Select from '../../../components/Form/Select'
import { InputMask } from '../../../utils/inputMask'
import { Medium } from '../../../types/campaign'
import Table, {
  TableBody,
  TableData,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from '../../../components/Table'
import { AgencyMedium, AllowedTarget } from '../../../types/agency'

const AgencyForm: React.FC = () => {
  const { t } = useTranslation()
  const { id: urlId } = useParams<ReactRouterParams>()
  const navigate = useNavigate()
  const { addNotification } = useContext(AppContext)
  const [agency, setAgency] = useState<AgencyFormType>(agencyDefault)
  const [allowedTargets, setAllowedTargets] = useState<AllowedTarget[]>([])
  const [loadingAgency, setLoadingAgency] = useState<boolean>(false)
  const [mediums, setMediums] = useState<Medium[]>([])
  const [medium, setMedium] = useState<SelectAsyncValue<Medium> | null>(null)
  const [mediumAllocatedTime, setMediumAllocatedTime] = useState<string>('')

  useEffect(() => {
    if (urlId) {
      setAgency({ ...agency, id: urlId })
    }
  }, [urlId])

  const id = agency.id

  useEffect(() => {
    if (id) {
      navigate(Routes.AGENCIES.EDIT(id))
      service.fetchAgency(id, setAgency, setLoadingAgency, setMediums)
    }
  }, [id])

  useEffect(() => {
    void service.fetchAllowedTargets(setAllowedTargets)
  }, [])

  const onInputChange: OnInputChangeFn = (input, id) => {
    const payload = id === 'company' ? input?.rawData : input

    if (id === 'agencyType' && payload === 'internal') {
      /**
       * For internal agencies, there is no discount and no billing allowance
       */
      return setAgency({
        ...agency,
        [id ?? '']: payload,
        discount: 0,
        billingAllowance: false,
      })
    }

    void setAgency({
      ...agency,
      [id ?? '']: payload,
    })
  }

  const handleClick = () => {
    const args = { agency, navigate, addNotification, setLoadingAgency, t }

    id ? service.handleUpdate(args) : service.handleSubmit(args)
  }

  const addMedium = () => {
    if (!medium) {
      return
    }
    const newAgency = {
      ...agency,
      agencyMediums: [
        ...agency.agencyMediums,
        {
          mediumId: medium.value!,
          allocatedTime: mediumAllocatedTime ? parseInt(mediumAllocatedTime) : null,
        },
      ] as AgencyMedium[],
    }
    setAgency(newAgency)
    setMedium(null)
    setMediumAllocatedTime('')
  }

  const removeMedium = (mediumId: Medium['id']) => {
    setAgency({
      ...agency,
      agencyMediums: (agency.agencyMediums as AgencyMedium[]).filter(
        agencyMedium => agencyMedium.mediumId !== mediumId
      ),
    })
  }

  const MediaListTable: ReactNode = (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem>#</TableHeaderItem>
          <TableHeaderItem>{t('common.medium')}</TableHeaderItem>
          <TableHeaderItem>{t('agencies.form.allocatedTime')}</TableHeaderItem>
          <TableHeaderItem></TableHeaderItem>
        </TableHeader>

        <TableBody>
          {(agency.agencyMediums as AgencyMedium[]).map((agencyMedium: AgencyMedium, index) => (
            <TableRow key={agencyMedium.mediumId}>
              <TableData>{index + 1}</TableData>
              <TableData>
                {mediums.filter(medium => agencyMedium.mediumId === medium.id)[0]
                  ? service.getMediumLocalizationName(
                      mediums.filter(medium => agencyMedium.mediumId === medium.id)[0]
                    )
                  : ''}
              </TableData>
              <TableData>
                {agencyMedium.allocatedTime ? Math.round(agencyMedium.allocatedTime) + '%' : ''}
              </TableData>
              <TableData>
                <Button
                  theme={ButtonTheme.RED_OUTLINE}
                  onClick={() => removeMedium(agencyMedium.mediumId)}
                >
                  {t('common.remove')}
                </Button>
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )

  const form = (): ReactElement => (
    <>
      <FormRow className={'Agencies__container'}>
        <FormColumn>
          <FormRow>
            <SelectAsync
              dataCy='company'
              id='company'
              title={t('agencies.form.agencyName') + '/' + t('agencies.form.nip')}
              help={t('form.campaign.basics.advertiserHelp')}
              placeholder={t('agencies.form.agencyName') + '/' + t('agencies.form.nip')}
              value={
                {
                  value: agency.company?.id,
                  label: agency.company?.name,
                  rawData: agency.company,
                } as SelectAsyncValue<Company>
              }
              isClearable={true}
              label={t('common.search') + '...'}
              options={basicFormService.getCompanies}
              onChange={(company, id): void => onInputChange(company, id)}
              asyncFiltering={true}
            />
          </FormRow>
          <FormRow className='Agencies__form--row Agencies--readonly'>
            <FormColumn>
              <Input
                readOnly={true}
                title={t('agencies.form.nip')}
                value={agency.company?.nip || ''}
                placeholder={t('agencies.form.nip')}
              />
            </FormColumn>
          </FormRow>
          <FormRow className='Agencies__form--row Agencies--readonly'>
            <FormColumn>
              <Input
                readOnly={true}
                title={t('agencies.form.street')}
                value={agency.company?.street || ''}
                placeholder={t('agencies.form.street')}
              />
            </FormColumn>
          </FormRow>
          <FormRow className='Agencies__form--row Agencies--readonly'>
            <FormColumn>
              <Input
                readOnly={true}
                title={t('agencies.form.apartmentNumber')}
                value={agency.company?.apartmentNumber || ''}
                placeholder={t('agencies.form.apartmentNumber')}
              />
            </FormColumn>
          </FormRow>
          <FormRow className='Agencies__form--row Agencies--readonly'>
            <FormColumn>
              <Input
                readOnly={true}
                title={t('agencies.form.buildingNumber')}
                value={agency.company?.buildingNumber || ''}
                placeholder={t('agencies.form.buildingNumber')}
              />
            </FormColumn>
          </FormRow>
          <FormRow className='Agencies__form--row Agencies--readonly'>
            <FormColumn>
              <Input
                readOnly={true}
                title={t('agencies.form.postalCode')}
                value={agency.company?.postalCode || ''}
                placeholder={t('agencies.form.postalCode')}
              />
            </FormColumn>
          </FormRow>
          <FormRow className='Agencies__form--row Agencies--readonly'>
            <FormColumn>
              <Input
                readOnly={true}
                title={t('agencies.form.city')}
                value={agency.company?.city || ''}
                placeholder={t('agencies.form.city')}
              />
            </FormColumn>
          </FormRow>
          <FormRow className='Agencies__form--row Agencies--readonly'>
            <FormColumn>
              <Input
                readOnly={true}
                title={t('agencies.form.crmId')}
                value={agency.company?.crmId || ''}
                placeholder={t('agencies.form.crmId')}
              />
            </FormColumn>
          </FormRow>
          <FormRow className='Agencies__form--row'>
            <SelectAsync
              id='supervisors'
              title={t('common.supervisors')}
              placeholder={t('common.supervisors')}
              value={agency.supervisors}
              onChange={onInputChange}
              options={basicFormService.getUsersForSupervisors}
              multi
              closeOnSelect={false}
            />
          </FormRow>
          <FormRow className='Agencies__form--row'>
            <FormColumn>
              <Input
                id='name'
                title={t('agencies.form.agencyName')}
                value={agency.name}
                placeholder={t('agencies.form.agencyName')}
                onChange={onInputChange}
              />
            </FormColumn>
          </FormRow>
          <FormRow className='Agencies__form--row'>
            <FormColumn>
              <Input
                id='clientId'
                title={t('agencies.form.clientId')}
                value={agency.clientId}
                placeholder={t('agencies.form.clientId')}
                onChange={onInputChange}
              />
            </FormColumn>
          </FormRow>
        </FormColumn>
        <FormColumn>
          <FormRow>
            <FormColumn>
              <Input
                id='allocatedTime'
                title={t('agencies.form.maximumPotential')}
                value={agency.allocatedTime}
                maskType={InputMask.PERCENT}
                placeholder={t('agencies.form.maximumPotential')}
                onChange={onInputChange}
              />
            </FormColumn>
          </FormRow>
          <FormRow className='Agencies__form--row'>
            <Select
              dataCy='origin'
              clearable
              id='origin'
              onChange={(origin, id): void => onInputChange(origin, id)}
              options={service.originOptions()}
              placeholder={t('agencies.form.agencyOrigin')}
              title={t('agencies.form.agencyOrigin')}
              value={agency.origin}
            />
          </FormRow>
          <FormRow className='Agencies__form--row'>
            <Select
              dataCy='currencyType'
              clearable
              id='currencyType'
              onChange={(origin, id): void => onInputChange(origin, id)}
              options={service.currencyOptions()}
              placeholder={t('agencies.form.currency')}
              title={t('agencies.form.currency')}
              value={agency.currencyType}
            />
          </FormRow>
          <FormRow className='Agencies__form--row'>
            <FormColumn>
              <Checkbox
                id='agencyType'
                checked={agency.agencyType === 'internal'}
                onChange={(event): void => {
                  onInputChange(event.target.checked ? 'internal' : 'external', 'agencyType')
                }}
              >
                {t('agencies.form.internal')}
              </Checkbox>
            </FormColumn>
          </FormRow>
          {agency.agencyType === 'external' && (
            <>
              <FormRow className='Agencies__form--row'>
                <FormColumn>
                  <Input
                    id='discount'
                    title={t('agencies.form.discount')}
                    value={agency.discount.toString()}
                    placeholder={t('agencies.form.discount')}
                    type={InputType.NUMBER}
                    onChange={(event: string): void => {
                      onInputChange(parseFloat(event), 'discount')
                    }}
                  />
                </FormColumn>
              </FormRow>
              {/* TODO: billing - uncomment later */}
              {/* <FormRow className='Agencies__form--row'>
            <FormColumn>
              <Checkbox
                id='bilingAllowance'
                checked={agency.billingAllowance}
                onChange={(event): void => {
                  onInputChange(event.target.checked, 'billingAllowance')
                }}
              >
                {t('agencies.form.billingAllowance')}
              </Checkbox>
            </FormColumn>
          </FormRow> */}
            </>
          )}

          <FormRow className='Agencies__form--row'>
            <FormColumn>
              <Checkbox
                id='withAllocatedTime'
                checked={agency.withAllocatedTime}
                onChange={(event): void => {
                  onInputChange(event.target.checked, 'withAllocatedTime')
                }}
              >
                {t('agencies.form.mediumsOwner')}
              </Checkbox>
            </FormColumn>
          </FormRow>
          {agency.withAllocatedTime && (
            <>
              <FormRow className='Agencies__form--row'>
                <FormColumn>
                  <Input
                    id='mediumAllocatedTime'
                    title={t('agencies.form.mediumsAllocatedTime')}
                    value={agency.mediumAllocatedTime}
                    placeholder={'%'}
                    maskType={InputMask.PERCENT}
                    onChange={(event: string): void => {
                      onInputChange(parseFloat(event), 'mediumAllocatedTime')
                    }}
                  />
                </FormColumn>
              </FormRow>
              <>
                {agency.agencyMediums.length > 0 && (
                  <FormRow className={'Agencies__form--media-list'}>{MediaListTable}</FormRow>
                )}
                <FormRow>
                  <div className={'Agencies__form--mediums-container'}>
                    <Input
                      className={'Agencies__form--medium-usage-input'}
                      title={t('agencies.form.allocatedTime')}
                      value={mediumAllocatedTime}
                      maskType={InputMask.PERCENT}
                      placeholder={'%'}
                      onChange={setMediumAllocatedTime}
                    />

                    <FormRow>
                      <SelectAsync
                        key={agency.agencyMediums.join(',')}
                        id='supervisors'
                        title={t('common.medium')}
                        placeholder={t('common.medium')}
                        value={medium}
                        onChange={setMedium}
                        options={() => {
                          return service.getMediums(setMediums).then(mediums => {
                            return mediums.filter(
                              medium =>
                                !(agency.agencyMediums as AgencyMedium[]).some(
                                  assignedMedium => assignedMedium.mediumId === medium.value
                                )
                            )
                          })
                        }}
                        closeOnSelect={true}
                      />
                    </FormRow>
                    <Button
                      onClick={addMedium}
                      disabled={loadingAgency}
                    >
                      {t('common.add')}
                    </Button>
                  </div>
                </FormRow>
              </>
            </>
          )}
          <FormRow>
            <div className={'Agencies__form--targets-container'}>
              <div className='Agencies__form__header'>{t('common.target')}</div>
              <div>
                {allowedTargets.map((allowedTarget, index) => {
                  return (
                    <Checkbox
                      dataCy={`allowedTarget-${allowedTarget.name}`}
                      className='OrganizationAttributes__checkbox'
                      key={index}
                      checked={
                        agency.allowedTargets.filter(target => target.name === allowedTarget.name)
                          .length > 0
                      }
                      onChange={event => {
                        let allowedTargets = [...agency.allowedTargets]

                        if (!event.target.checked) {
                          allowedTargets = allowedTargets.filter(
                            target => target.name !== allowedTarget.name
                          )

                          return onInputChange(allowedTargets, 'allowedTargets')
                        }

                        allowedTargets = [...allowedTargets, allowedTarget]

                        onInputChange(allowedTargets, 'allowedTargets')
                      }}
                    >
                      {t(`targetFilters.${allowedTarget.name}`)}
                    </Checkbox>
                  )
                })}
              </div>
            </div>
          </FormRow>
        </FormColumn>
      </FormRow>
    </>
  )

  return (
    <>
      <PageHeader>{t(`agencies.form.${id ? 'editAgency' : 'newAgency'}`)}</PageHeader>

      <Card className='Agencies__form'>
        {loadingAgency ? <FillingSpinner className='Agencies__form--loading' /> : form()}

        <div className='Agencies__form--buttons'>
          <Button
            onClick={handleClick}
            disabled={loadingAgency}
          >
            {t('common.save')}
          </Button>

          <Button
            theme={ButtonTheme.BLUE_OUTLINE}
            toUrl={Routes.AGENCIES.ALL}
          >
            {t('common.cancel')}
          </Button>
        </div>
      </Card>
    </>
  )
}

export default AgencyForm
