import { gql } from '@apollo/client'
import { ROLE_FIELDS } from '../fragments'

export const GET_ROLE = gql`
  ${ROLE_FIELDS}
  query roleDetails($id: ID!) {
    node(id: $id) {
      ... on Role {
        ...RoleFields
      }
    }
  }
`
