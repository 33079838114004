import React, { ReactNode } from 'react'
import TsxUtils from 'utils/tsx'
import './FlexCol.scss'

export interface FlexColProps {
  className?: string
  children?: ReactNode | ReactNode[]
}

const FlexCol: React.FC<FlexColProps> = ({ className, children }) => (
  <div className={'FlexCol' + TsxUtils.extraStyle(className, className)}>{children}</div>
)

export default FlexCol
