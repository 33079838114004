import api from 'api'
import { store } from '../../store'
import { roleFormActions } from './RoleForm/store/role-form-slice'
import { rolesTableActions } from './RolesTable/store/roles-table-slice'
import { Routes } from '../../routes'
import { NotifierType } from '../../components/Notifier'
import { RoleFormModel } from './models/role-form-slice.model'
import { NavigateFunction } from 'react-router-dom'
import { UseNotifierProps } from '../../hooks/useNotifier'
import { TFunction } from 'i18next'
import { userTableActions } from '../User/UserTable/store/user-table-slice'
import { RoleTableItem } from './models/roles-table-slice.model'
import { BaseRole } from '../../types/user'
import { SelectOption } from '../../components/Form/Select'
import i18n from '../../i18n'

export default class RolesService {
  static getNewRolePermissions = (): void => {
    store.dispatch(roleFormActions.setLoading(true))
    api.roles
      .getNewRolePermissions()
      .then(res => {
        store.dispatch(
          roleFormActions.setNewPermissionElementRoles(
            res.data.newRolePermissions.nodes.map(node => {
              return {
                id: node.id,
                name: node.name,
                permissions: node.permissions.map(permission => {
                  return {
                    name: permission,
                    value: false,
                  }
                }),
              }
            })
          )
        )
      })
      .finally(() => {
        store.dispatch(roleFormActions.setLoading(false))
      })
  }

  static getRole = (roleId: string): void => {
    store.dispatch(roleFormActions.setLoading(true))
    api.roles
      .getRole(roleId)
      .then(res => {
        store.dispatch(roleFormActions.setRoleForm(res.data.node))
      })
      .finally(() => {
        store.dispatch(roleFormActions.setLoading(false))
      })
  }

  static getRoles = (pageNumber: number, quantityPerPage: number): void => {
    store.dispatch(rolesTableActions.setLoading(true))
    api.roles
      .getRoles({
        first: pageNumber * quantityPerPage,
        last: quantityPerPage,
      })
      .then(res => {
        const { rolesList } = res.data
        store.dispatch(rolesTableActions.setRoles(rolesList.nodes))
        store.dispatch(rolesTableActions.setTotalCount(rolesList.totalCount))
      })
      .finally(() => {
        store.dispatch(rolesTableActions.setLoading(false))
        store.dispatch(rolesTableActions.setLoaded(true))
      })
  }

  static handleSubmit = (
    navigate: NavigateFunction,
    addNotification: UseNotifierProps['addNotification'],
    t: TFunction,
    id?: string
  ): Promise<void> => {
    store.dispatch(rolesTableActions.setLoading(true))
    const roleFormModel: RoleFormModel = store.getState().roleForm
    return id
      ? api.roles
          .updateRole({
            id: roleFormModel.id,
            name: roleFormModel.name,
            baseRole: roleFormModel.baseRole,
            permissionElementRoles: roleFormModel.permissionElementRoles,
          })
          .then(() => {
            navigate(Routes.ROLES.ALL)
            addNotification(NotifierType.SUCCESS, t('notification.updateRoleSuccess'))
          })
          .catch(() => void {})
          .finally(() => {
            store.dispatch(rolesTableActions.resetRoles())
            store.dispatch(rolesTableActions.setLoading(false))
          })
      : api.roles
          .createRole({
            name: roleFormModel.name,
            baseRole: roleFormModel.baseRole,
            permissionElementRoles: roleFormModel.newPermissionElementRoles,
          })
          .then(() => {
            navigate(Routes.ROLES.ALL)
            addNotification(NotifierType.SUCCESS, t('notification.addRoleSuccess'))
          })
          .catch(() => void {})
          .finally(() => {
            store.dispatch(rolesTableActions.resetRoles())
            store.dispatch(rolesTableActions.setLoading(false))
          })
  }

  static removeRole = (role: RoleTableItem): Promise<unknown> =>
    api.roles
      .removeRole({ id: role.id })
      .catch(() => void {})
      .finally(() => store.dispatch(userTableActions.setLoaded(false)))

  static isReservedRole = (role: string) => {
    return Object.values(BaseRole).some(
      reservedRole => reservedRole.toLowerCase() === role.toLowerCase()
    )
  }

  static basicRoleOptions = () => {
    return Object.values(BaseRole).map(
      (basicRole: BaseRole): SelectOption => ({
        value: basicRole,
        label: i18n.t(`role.${basicRole}`),
      })
    )
  }
}
