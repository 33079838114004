import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'api/fragments'

const PAYLOAD_TYPE = 'SendCampaignToAcceptancePayload'

export const SEND_CAMPAIGN_TO_ACCEPTANCE = gql`
    ${MUTATION_STATE(PAYLOAD_TYPE)}
    mutation sendCampaignToAcceptance($id: ID!) {
        sendCampaignToAcceptance(input: { id: $id }) {
            campaign {
                id
                acceptanceStatus
            }
            ...MutationState${PAYLOAD_TYPE}Fields
        }
    }
`
