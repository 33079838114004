import { gql } from '@apollo/client'

export const FETCH_DAILY_SUMMARY_FILTERS = gql`
  query dailySummaryFilters($campaignUuid: String!) {
    dailySummaryFilters(campaignUuid: $campaignUuid) {
      agglomerations
      buildings
      cities
      mediaAsIds
      creations
    }
  }
`
