import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SignInValues, SignInValuesDefault, SignInValuesErr } from './types'
import Input, { InputType } from 'components/Form/Input'
import Button from 'components/Form/Button'
import { useTranslation } from 'react-i18next'
import api from 'api'
import Link from 'components/Link'
import { Routes } from 'routes'
import { AppContext } from 'contexts/AppContext'
import { NotifierType } from 'components/Notifier'
import { withoutAccess } from 'hooks/useUser/useUser'
import '../Sign.scss'
import './SignIn.scss'

const SignIn: React.FC = () => {
  const { authenticate, addNotification } = useContext(AppContext)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<SignInValuesErr | null>(null)
  const [values, setValues] = useState<SignInValues>(SignInValuesDefault)

  const onSignIn = (): void => {
    setLoading(true)
    setErrors(null)

    api.user
      .signIn({ email: values.email, password: values.password })
      .then(res => {
        setLoading(false)

        if (res.data) {
          const { authenticationToken, refreshToken, user } = res.data.signIn

          if (!withoutAccess(user.roles)) {
            authenticate({
              authenticationToken,
              refreshToken,
              user,
            })
            navigate(Routes.HOME)
            addNotification(NotifierType.SUCCESS, t('notification.logInSuccess'))
          } else {
            addNotification(NotifierType.ERROR, t('notification.accessDenided'))
          }
        }
      })
      .catch(() => {
        setLoading(false)
        setErrors({
          email: [t('sign.signIn.checkYourEmail')],
          password: [t('sign.signIn.checkYourPassword')],
        })
      })
  }

  return (
    <div>
      <h1 className='SignIn__header'>{t('sign.signIn.doohLogin')}</h1>

      <div className='Sign__form'>
        <form>
          <Input
            id='e-mail'
            title={t('common.email')}
            placeholder={t('common.enterEmail')}
            autoComplete={'email'}
            value={values.email}
            errors={errors?.email}
            onChange={(value): void => void setValues({ ...values, email: value as string })}
            onKeyDown={{ key: 'Enter', callback: onSignIn }}
          />
          <Input
            id='password'
            title={t('common.password')}
            placeholder={t('common.enterPassword')}
            autoComplete={'current-password'}
            type={InputType.PASSWORD}
            value={values.password}
            errors={errors?.password}
            onChange={(value): void => void setValues({ ...values, password: value as string })}
            onKeyDown={{ key: 'Enter', callback: onSignIn }}
          />
        </form>
        <Link
          className='SignIn__link'
          toUrl={Routes.SIGN.REMIND_PASSWORD}
        >
          {t('sign.signIn.forgotPassword')}
        </Link>
        <Button
          className='Sign__form--button'
          disabled={loading}
          onClick={onSignIn}
        >
          {t('sign.signIn.signIn')}
        </Button>
        <div className='SignIn__privacy_policy'>
          <a
            className='SignIn__link'
            href={'/privacy_policy.pdf'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >
            {t('sign.privacyPolicy')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default SignIn
