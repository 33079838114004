import { CampaignStatus } from 'types/campaign'
import { BaseRole } from 'types/user'

// Roles allowed to sign in to app
export const ALL_ROLES: BaseRole[] = [
  BaseRole.CLIENT,
  BaseRole.EMPLOYEE,
  BaseRole.ADMIN,
  BaseRole.SUPPORT,
]

export const ALL_CAMPAIGN_STATUSES: CampaignStatus[] = [
  CampaignStatus.SKETCH,
  CampaignStatus.PLANNED,
  CampaignStatus.ACTIVE,
  CampaignStatus.FINISHED,
  CampaignStatus.PAUSED,
  CampaignStatus.STOPPED,
  CampaignStatus.RESERVED,
  CampaignStatus.RESERVATION_CANCELLED,
]
