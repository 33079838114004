import Checkbox from 'components/Form/Checkbox'
import React, { ReactElement, ReactNode, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  InOut,
  mediaFormatCheckboxes,
  MediaFormatCheckboxes,
  PeriodGranulation,
  SlotDurations,
  SystemCapacityPerDay,
} from './types'
import { MediaFormat } from 'types/campaign'
import EnumUtils from 'utils/enum'
import MediaFormatUtils from 'utils/mediaFormat'
import Colors from 'styles/colors.module.scss'
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import { RechartsComponentData } from 'pages/Campaign/CampaignDetails/CampaignDetailsChart/types'
import DateUtils from 'utils/date'
import { DATE_FORMAT, DATE_FORMAT_SHORT_YEAR, DATE_PLACEHOLDER } from 'constant'
import VariableUtils from 'utils/variable'
import FillingSpinner from 'components/FillingSpinner'
import service from './inventory-chart.service'
import { FilterContext, FilterSelect } from 'components/Form/Filters'
import { InventoryChartFilters } from 'pages/Inventory/models/types'
import { FilterContextProps } from 'components/Form/Filters/FilterContext'
import DatePickerSingle from 'components/Form/DatePickerSingle'
import DatePickerRangeSeparate from 'components/Form/DatePickerRangeSeparate'
import { InventoryContext } from '../InventoryContext'
import './InventoryChart.scss'
import Select from '../../../components/Form/Select'
import { SlotsSecondsHelper } from './helpers/slots-seconds-helper'

const InventoryChart: React.FC = () => {
  const { t } = useTranslation()
  const [mediaFormats, setMediaFormats] = useState<MediaFormatCheckboxes>(mediaFormatCheckboxes)
  const [systemCapacity, setSystemCapacity] = useState<SystemCapacityPerDay[]>([])
  const [focusBar, setFocusBar] = useState(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const now: number = Date.now()
  const { filters, setFilters } =
    useContext<FilterContextProps<InventoryChartFilters>>(FilterContext)
  const { slotDuration } = filters

  useEffect(() => {
    service.getData(setSystemCapacity, setIsLoading, filters)
  }, [useContext(InventoryContext).filtersDebouncedCounter])

  const CommonCheckboxes = (inOut: InOut): ReactNode => (
    <div>
      <div className='InventoryChart__checkboxes--inOut'>{t(`common.${inOut}`)}</div>

      <div className='InventoryChart__checkboxes--container'>
        {Object.entries(mediaFormats[inOut]).map(([key, value]) => (
          <Checkbox
            key={key}
            className='InventoryChart__checkboxes--checkbox'
            id={key}
            checked={value}
            onChange={() => {
              const newMediaFormats = {
                ...mediaFormats,
                [inOut]: {
                  ...mediaFormats[inOut],
                  [key]: !value,
                },
              }
              setMediaFormats(newMediaFormats)
              setFilters({
                ...filters,
                mediaFormats: service.mediaFormatsCheckboxesToArray(
                  newMediaFormats
                ) as MediaFormat[],
              })
            }}
          >
            <img
              src={MediaFormatUtils.getIcon(
                MediaFormat[EnumUtils.getKeyByValueT(MediaFormat, key)!]
              )}
              alt={key}
            />
            {t(`mediaType.${key}`)}
          </Checkbox>
        ))}
      </div>
    </div>
  )

  const Checkboxes: ReactNode = (
    <>
      <div className='InventoryChart__checkboxes--header'>{t('common.mediaType')}</div>
      <div className='InventoryChart__checkboxes'>{CommonCheckboxes('indoor')}</div>

      <div className='InventoryChart__checkboxes'>{CommonCheckboxes('outdoor')}</div>
    </>
  )

  const FilterInputs: ReactNode = (
    <div className='InventoryChart__filters'>
      <div className='InventoryChart__filters-row'>
        <FilterSelect
          id='agglomerations'
          title={t('common.agglomeration')}
        />
        <FilterSelect
          id='cities'
          title={t('common.city')}
        />
        <FilterSelect
          id='buildings'
          title={t('common.building')}
        />
        <FilterSelect
          id='mediumAsIds'
          title={t('common.medium')}
        />
        <Select
          id='granulation'
          onChange={granulation => {
            setFilters({
              ...filters,
              granulation: granulation,
            })
          }}
          options={Object.values(PeriodGranulation).map(granulation => {
            return { value: granulation, label: t('filters.periodGranulation.' + granulation) }
          })}
          placeholder={t('common.gradation')}
          title={t('common.gradation')}
          value={filters.granulation}
        />
        <Select
          id='slotDuration'
          onChange={duration => {
            setFilters({
              ...filters,
              slotDuration: parseInt(duration),
            })
          }}
          options={SlotDurations.map(duration => {
            return { value: duration.toString(), label: duration + 's' }
          })}
          placeholder={t('common.unit')}
          title={t('common.unit')}
          value={filters.slotDuration.toString()}
        />
      </div>
      <div>
        <div className='CampaignTableFilters__range-input-row'>
          <DatePickerRangeSeparate>
            <DatePickerSingle
              id={'dateFrom'}
              placeholder={DATE_PLACEHOLDER}
              value={filters.fromDate}
              onChange={date => setFilters({ ...filters, fromDate: date })}
            />
            <DatePickerSingle
              isOutsideRange={date =>
                date.getTime() <= now || date.getTime() < new Date(filters.fromDate).getTime()
              }
              id={'dateTo'}
              placeholder={DATE_PLACEHOLDER}
              value={filters.toDate}
              onChange={date => setFilters({ ...filters, toDate: date })}
            />
          </DatePickerRangeSeparate>
        </div>
      </div>
    </div>
  )

  const ChartContainer = (sc: SystemCapacityPerDay[]): ReactNode => (
    <div className='InventoryChart__container'>
      <div className='InventoryChart__label'>
        {t(`inventory.${SlotsSecondsHelper.getName(slotDuration, true)}`)}
      </div>

      {Chart(sc)}

      <div className='InventoryChart__legend InventoryChart__legend--inventory'>
        <div className='InventoryChart__legend--item'>
          <div className='InventoryChart__legend--symbol InventoryChart__legend--symbol-prio-3' />
          <div>{t(`inventory.priorityThree${SlotsSecondsHelper.getName(slotDuration)}`)}</div>
        </div>

        <div className='InventoryChart__legend--item'>
          <div className='InventoryChart__legend--symbol InventoryChart__legend--symbol-prio-5' />
          <div>{t(`inventory.priorityFive${SlotsSecondsHelper.getName(slotDuration)}`)}</div>
        </div>

        <div className='InventoryChart__legend--item'>
          <div className='InventoryChart__legend--symbol InventoryChart__legend--symbol-prio-7' />
          <div>{t(`inventory.prioritySeven${SlotsSecondsHelper.getName(slotDuration)}`)}</div>
        </div>

        <div className='InventoryChart__legend--item'>
          <div className='InventoryChart__legend--symbol InventoryChart__legend--symbol-prio-4' />
          <div>{t(`inventory.priorityFour${SlotsSecondsHelper.getName(slotDuration)}`)}</div>
        </div>

        <div className='InventoryChart__legend--item'>
          <div className='InventoryChart__legend--symbol InventoryChart__legend--symbol-prio-6' />
          <div>{t(`inventory.prioritySix${SlotsSecondsHelper.getName(slotDuration)}`)}</div>
        </div>

        <div className='InventoryChart__legend--item'>
          <div className='InventoryChart__legend--symbol InventoryChart__legend--symbol-prio-8' />
          <div>{t(`inventory.priorityEight${SlotsSecondsHelper.getName(slotDuration)}`)}</div>
        </div>

        <div className='InventoryChart__legend--item'>
          <div className='InventoryChart__legend--symbol InventoryChart__legend--symbol-free' />
          <div>{t(`inventory.free${SlotsSecondsHelper.getName(slotDuration)}`)}</div>
        </div>

        <div className='InventoryChart__legend--item'>
          <div className='InventoryChart__legend--symbol InventoryChart__legend--symbol-reserved' />
          <div>{t(`inventory.reserved${SlotsSecondsHelper.getName(slotDuration)}`)}</div>
        </div>
      </div>
    </div>
  )

  const Chart = (capacity: SystemCapacityPerDay[]): ReactNode => (
    <ResponsiveContainer
      height={320}
      width='100%'
    >
      <ComposedChart
        data={capacity}
        maxBarSize={10}
        onMouseMove={(state: any): void => {
          if (state.isTooltipActive) {
            setFocusBar(state.activeTooltipIndex)
          } else {
            setFocusBar(null)
          }
        }}
      >
        <CartesianGrid
          stroke={Colors.gray2}
          strokeDasharray='2 2'
        />
        <XAxis
          dataKey='date'
          interval='preserveStartEnd'
          stroke={Colors.gray4}
          padding={{ right: 5 }}
          tick={XaxisTick}
          tickFormatter={value => DateUtils.parseAndFormat(value, DATE_FORMAT_SHORT_YEAR)!}
        />
        <YAxis
          dataKey='total'
          yAxisId='totalY'
          orientation='left'
          stroke={Colors.gray4}
          padding={{ top: 15 }}
          tickLine={false}
          tick={(props): ReactElement => YaxisTick(props, -37)}
        />
        <Tooltip
          content={CustomTooltip}
          offset={15}
          cursor={false}
        />
        <Bar
          dataKey='p3PlannedSlots'
          yAxisId='totalY'
          stackId='a'
        >
          {capacity.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index ? Colors.chartPrio3Hover : Colors.chartPrio3}
            />
          ))}
        </Bar>
        <Bar
          dataKey='p4PlannedSlots'
          yAxisId='totalY'
          stackId='a'
        >
          {capacity.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index ? Colors.chartPrio4Hover : Colors.chartPrio4}
            />
          ))}
        </Bar>
        <Bar
          dataKey='p5PlannedSlots'
          yAxisId='totalY'
          stackId='a'
        >
          {capacity.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index ? Colors.chartPrio5Hover : Colors.chartPrio5}
            />
          ))}
        </Bar>
        <Bar
          dataKey='p6PlannedSlots'
          yAxisId='totalY'
          stackId='a'
        >
          {capacity.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index ? Colors.chartPrio6Hover : Colors.chartPrio6}
            />
          ))}
        </Bar>
        <Bar
          dataKey='p7PlannedSlots'
          yAxisId='totalY'
          stackId='a'
        >
          {capacity.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index ? Colors.chartPrio7Hover : Colors.chartPrio7}
            />
          ))}
        </Bar>
        <Bar
          dataKey='p8PlannedSlots'
          yAxisId='totalY'
          stackId='a'
        >
          {capacity.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index ? Colors.chartPrio8Hover : Colors.chartPrio8}
            />
          ))}
        </Bar>
        <Bar
          yAxisId='totalY'
          dataKey='reservedSlots'
          stackId='a'
        >
          {capacity.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index ? Colors.navyBlue : Colors.purple}
            />
          ))}
        </Bar>
        <Bar
          dataKey='freeSlots'
          yAxisId='totalY'
          radius={[2, 2, 2, 2]}
          stackId='a'
          fill='#82ca9d'
        >
          {capacity.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index ? Colors.gray3 : Colors.gray2}
            />
          ))}
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  )

  const XaxisTick = ({ x, y, payload: { value } }: RechartsComponentData): ReactElement => (
    <g
      className='InventoryChart__axis-tick'
      transform={`translate(${x},${y})`}
    >
      <text
        x={0}
        y={0}
        dx={-22}
        dy={10}
        textAnchor='start'
      >
        {DateUtils.parseAndFormat(value, DATE_FORMAT_SHORT_YEAR)}
      </text>
    </g>
  )

  const YaxisTick = (
    { x, y, payload: { value } }: RechartsComponentData,
    customX: number
  ): ReactElement => (
    <g
      className='InventoryChart__axis-tick'
      transform={`translate(${x},${y})`}
    >
      <text
        x={customX}
        y={0}
        dy={3}
        textAnchor={'start'}
      >
        {value > 0 ? VariableUtils.formatNumberCompact(value) : ''}
      </text>
    </g>
  )

  const CustomTooltip = ({ active, payload, label }: TooltipProps<any, any>): ReactNode => {
    if (!active) {
      return null
    }

    const date = label as string
    const data: SystemCapacityPerDay = payload![0].payload

    return (
      <div className='InventoryChart__tooltip'>
        <div className='InventoryChart__tooltip--header'>
          {DateUtils.parseAndFormat(date, DATE_FORMAT)}
        </div>

        <div className='InventoryChart__tooltip--row'>
          <div className='InventoryChart__tooltip--col'>
            {t(`inventory.${SlotsSecondsHelper.getName(slotDuration, true)}Quantity`)}:
          </div>
          <div className='InventoryChart__tooltip--col'>
            {VariableUtils.formatNumber(data.total)}
          </div>
        </div>

        <div className='InventoryChart__tooltip--row'>
          <div className='InventoryChart__tooltip--col'>
            {t(`inventory.priorityThree${SlotsSecondsHelper.getName(slotDuration)}`)}:
          </div>
          <div className='InventoryChart__tooltip--col'>
            {VariableUtils.formatNumber(data.p3PlannedSlots)}
          </div>
        </div>

        <div className='InventoryChart__tooltip--row'>
          <div className='InventoryChart__tooltip--col'>
            {t(`inventory.priorityFour${SlotsSecondsHelper.getName(slotDuration)}`)}:
          </div>
          <div className='InventoryChart__tooltip--col'>
            {VariableUtils.formatNumber(data.p4PlannedSlots)}
          </div>
        </div>

        <div className='InventoryChart__tooltip--row'>
          <div className='InventoryChart__tooltip--col'>
            {t(`inventory.priorityFive${SlotsSecondsHelper.getName(slotDuration)}`)}:
          </div>
          <div className='InventoryChart__tooltip--col'>
            {VariableUtils.formatNumber(data.p5PlannedSlots)}
          </div>
        </div>

        <div className='InventoryChart__tooltip--row'>
          <div className='InventoryChart__tooltip--col'>
            {t(`inventory.prioritySix${SlotsSecondsHelper.getName(slotDuration)}`)}:
          </div>
          <div className='InventoryChart__tooltip--col'>
            {VariableUtils.formatNumber(data.p6PlannedSlots)}
          </div>
        </div>

        <div className='InventoryChart__tooltip--row'>
          <div className='InventoryChart__tooltip--col'>
            {t(`inventory.prioritySeven${SlotsSecondsHelper.getName(slotDuration)}`)}:
          </div>
          <div className='InventoryChart__tooltip--col'>
            {VariableUtils.formatNumber(data.p7PlannedSlots)}
          </div>
        </div>

        <div className='InventoryChart__tooltip--row'>
          <div className='InventoryChart__tooltip--col'>
            {t(`inventory.priorityEight${SlotsSecondsHelper.getName(slotDuration)}`)}:
          </div>
          <div className='InventoryChart__tooltip--col'>
            {VariableUtils.formatNumber(data.p8PlannedSlots)}
          </div>
        </div>

        <div className='InventoryChart__tooltip--row'>
          <div className='InventoryChart__tooltip--col'>
            {t(`inventory.occupied${SlotsSecondsHelper.getName(slotDuration)}`)}:
          </div>
          <div className='InventoryChart__tooltip--col'>
            {VariableUtils.formatNumber(data.plannedSlots + data.reservedSlots)}
          </div>
        </div>

        <div className='InventoryChart__tooltip--row'>
          <div className='InventoryChart__tooltip--col'>
            {t(`inventory.free${SlotsSecondsHelper.getName(slotDuration)}Quantity`)}:
          </div>
          <div className='InventoryChart__tooltip--col'>
            {VariableUtils.formatNumber(data.freeSlots)}
          </div>
        </div>

        <div className='InventoryChart__tooltip--row'>
          <div className='InventoryChart__tooltip--col'>
            {t(`inventory.reserved${SlotsSecondsHelper.getName(slotDuration)}Quantity`)}:
          </div>
          <div className='InventoryChart__tooltip--col'>
            {VariableUtils.formatNumber(data.reservedSlots)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='InventoryChart'>
      {Checkboxes}
      {FilterInputs}

      <div style={{ height: '450px' }}>
        {isLoading ? (
          <FillingSpinner className='InventoryChart__loading' />
        ) : systemCapacity.length > 0 ? (
          ChartContainer(systemCapacity)
        ) : (
          <div className='InventoryChart__empty'>{t('common.noDataFound')}</div>
        )}
      </div>
    </div>
  )
}

export default InventoryChart
