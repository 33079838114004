export interface FillingSpinnerProps {
  className?: string
  type?: FillingSpinnerType
  color?: FillingSpinnerColor
}

export enum FillingSpinnerType {
  FULL_CONTAINER,
  FULL_LAYOUT_CONTENT,
  STANDARD,
}

export enum FillingSpinnerColor {
  BLUE = 'blue',
  WHITE = 'white',
  GREEN = 'green',
}
