import React from 'react'
import { TableHeaderProps } from '../types'
import TableRow from '../TableRow'
import './TableHeader.scss'
import TsxUtils from 'utils/tsx'

const TableHeader: React.FC<TableHeaderProps> = ({ children, sticky }) => (
  <thead className={'TableHeader' + TsxUtils.extraStyle(sticky, 'TableHeader--sticky')}>
    <TableRow className='TableHeader__row'>{children}</TableRow>
  </thead>
)

export default TableHeader
