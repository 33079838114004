import { gql } from '@apollo/client'

export const CREATE_CAMPAIGN_REPORT = gql`
  mutation createCampaignReport(
    $campaignId: ID!
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
  ) {
    createCampaignReport(
      input: { campaignId: $campaignId, startDate: $startDate, endDate: $endDate }
    ) {
      report {
        name
        status
        startDate
        endDate
        generatedAt
        csvUrl
      }
      success
      errors
    }
  }
`
