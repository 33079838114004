import { Campaign, CampaignAcceptanceStatus, CampaignStatus } from 'types/campaign'
import i18n from 'i18n'
import {
  faBell,
  faCalendarDays,
  faCalendarXmark,
  faClock,
  faFlagCheckered,
  faPauseCircle,
  faPen,
  faStopCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { IconBoxTheme } from 'components/Icon/IconBox'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'

export default class CampaignUtils {
  static parseStatus = (
    campaignStatus: Campaign['status'],
    acceptanceStatus?: Campaign['acceptanceStatus']
  ): { icon: IconDefinition; iconTheme: IconBoxTheme; text: string } => {
    switch (true) {
      case campaignStatus === CampaignStatus.SKETCH &&
        acceptanceStatus === CampaignAcceptanceStatus.pending_acceptation:
        return {
          icon: faPaperPlane,
          iconTheme: IconBoxTheme.BLUE,
          text: i18n.t('campaignStatus.pendingAcceptation'),
        }
      case campaignStatus === CampaignStatus.SKETCH:
        return {
          icon: faPen,
          iconTheme: IconBoxTheme.PURPLE,
          text: i18n.t('campaignStatus.sketch'),
        }
      case campaignStatus === CampaignStatus.PLANNED:
        return {
          icon: faClock,
          iconTheme: IconBoxTheme.YELLOW,
          text: i18n.t('campaignStatus.planned'),
        }
      case campaignStatus === CampaignStatus.ACTIVE:
        return {
          icon: faBell,
          iconTheme: IconBoxTheme.GREEN,
          text: i18n.t('campaignStatus.active'),
        }
      case campaignStatus === CampaignStatus.PAUSED:
        return {
          icon: faPauseCircle,
          iconTheme: IconBoxTheme.ORANGE,
          text: i18n.t('campaignStatus.paused'),
        }
      case campaignStatus === CampaignStatus.STOPPED:
        return {
          icon: faStopCircle,
          iconTheme: IconBoxTheme.RED,
          text: i18n.t('campaignStatus.stopped'),
        }
      case campaignStatus === CampaignStatus.FINISHED:
        return {
          icon: faFlagCheckered,
          iconTheme: IconBoxTheme.GREEN_DARK,
          text: i18n.t('campaignStatus.finished'),
        }
      case campaignStatus === CampaignStatus.RESERVED:
        return {
          icon: faCalendarDays,
          iconTheme: IconBoxTheme.YELLOW,
          text: i18n.t('campaignStatus.reserved'),
        }
      case campaignStatus === CampaignStatus.RESERVATION_CANCELLED:
        return {
          icon: faCalendarXmark,
          iconTheme: IconBoxTheme.RED,
          text: i18n.t('campaignStatus.reservationCancelled'),
        }
      case campaignStatus === CampaignStatus.PLANNED_CANCELLED:
        return {
          icon: faStopCircle,
          iconTheme: IconBoxTheme.RED,
          text: i18n.t('campaignStatus.stopped'),
        }
      default:
        return {
          icon: faCalendarXmark,
          iconTheme: IconBoxTheme.RED,
          text: i18n.t('campaignStatus.reservationCancelled'),
        }
    }
  }
}
