import React, { useContext } from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from '../../Link'
import { MediumTemplate } from 'api/media/types'
import { AppContext } from '../../../contexts/AppContext'
import { BaseRole } from '../../../types/user'

const TemplateLabel: React.FC<{ template: MediumTemplate; onClick: () => void }> = ({
  template,
  onClick,
}) => {
  const { userData } = useContext(AppContext)

  return (
    <div className='MediaSelectionModal__template-label'>
      <span>{template.name}</span>
      {userData.user?.roles.some(role => role.name === BaseRole.ADMIN) && (
        <Link
          onClick={event => {
            event.stopPropagation()
            onClick()
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Link>
      )}
    </div>
  )
}

export default TemplateLabel
