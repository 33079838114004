import { CampaignData } from 'pages/Campaign/CampaignForm/CreationsForm/CreationsFormPreview'
import { Creation, MediaFormat } from 'types/campaign'
import { PublicViewProps } from '../types'

export interface CampaignDetailsCreationsProps {
  campaignData: CampaignData
  creations: Creation[]
  publicViewProps?: PublicViewProps
  showAllMediaFormats?: boolean
}

export type GroupedCreations = {
  [key in MediaFormat]: Creation[]
}

export const groupedCreationsDefault = (): GroupedCreations => ({
  dgCl: [],
  dgIn: [],
  dgInH: [],
  dgInS: [],
  dgCs: [],
})
