import { User } from 'types/user'
import { FormErrors } from 'types/various'

export type Form = Pick<User, 'firstName' | 'lastName' | 'password'> & {
  passwordConfirmation: User['password']
}

export const formDefault = {
  firstName: '',
  lastName: '',
  password: '',
  passwordConfirmation: '',
}

export const formErrorsDefault: FormErrors = {
  firstName: [],
  lastName: [],
  password: [],
  passwordConfirmation: [],
}
