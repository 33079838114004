import { Dispatch, SetStateAction } from 'react'
import { Form } from './types'
import ErrorUtils from 'utils/error'
import { FormErrors } from 'types/various'
import { TFunction } from 'i18next'
import { UseNotifierProps } from 'hooks/useNotifier'
import api from 'api'
import { User } from 'types/user'
import { NotifierType } from 'components/Notifier'
import { Routes } from 'routes'
import { NavigateFunction } from 'react-router-dom'

export default class AcceptInvitationService {
  static validate = (
    form: Form,
    errors: FormErrors | undefined,
    setErrors: Dispatch<SetStateAction<FormErrors | undefined>>
  ): void =>
    void setErrors({
      ...errors,
      firstName: [...ErrorUtils.isRequired(form.firstName)],
      lastName: [...ErrorUtils.isRequired(form.lastName)],
      password: [...ErrorUtils.isRequired(form.password)],
      passwordConfirmation: [
        ...ErrorUtils.isRequired(form.passwordConfirmation),
        ...ErrorUtils.areDifferent(form.password, form.passwordConfirmation),
      ],
    })

  static acceptInvitation = (
    form: Form,
    token: User['token'],
    navigate: NavigateFunction,
    setLoading: Dispatch<SetStateAction<boolean>>,
    addNotification: UseNotifierProps['addNotification'],
    t: TFunction
  ): void => {
    setLoading(true)
    const { firstName, lastName, password, passwordConfirmation } = form

    api.user
      .acceptInvitation({
        token,
        firstName,
        lastName,
        password,
        passwordConfirmation,
      })
      .then(() => {
        addNotification(NotifierType.SUCCESS, t('notification.acceptInvitationSuccess'))
        navigate(Routes.SIGN.SIGN_IN)
      })
      .catch(() => void {})
      .finally(() => void setLoading(false))
  }
}
