import React, { CSSProperties, ReactNode } from 'react'
import Card from '../Layout/Card'
import TsxUtils from 'utils/tsx'
import './StripeCardDouble.scss'

type Props = {
  icon?: ReactNode
  topTitle?: string
  bottomTitle?: string
  topContent: ReactNode
  bottomContent: ReactNode
  className?: string
  bodyMaxHeight?: CSSProperties['maxHeight']
}

const StripeCardDoubleDouble: React.FC<Props> = ({
  topTitle,
  bottomTitle,
  icon,
  className,
  topContent,
  bottomContent,
  bodyMaxHeight,
}) => (
  <div className={'StripeCardDouble' + TsxUtils.extraStyle(className, className)}>
    <Card className='StripeCardDouble__card'>
      <div className='StripeCardDouble__container'>
        <div className={'StripeCardDouble__top'}>
          <div className={'StripeCardDouble__icon'}>{icon}</div>
          <div className={'StripeCardDouble__content-container'}>
            <div className='StripeCardDouble__title'>{topTitle}</div>
            <div
              className='StripeCardDouble__content'
              style={{ maxHeight: bodyMaxHeight }}
            >
              {topContent}
            </div>
          </div>
        </div>
        <div className={'StripeCardDouble__bottom'}>
          <div className={'StripeCardDouble__content-container'}>
            <div className='StripeCardDouble__title'>{bottomTitle}</div>
            <div
              className='StripeCardDouble__content'
              style={{ maxHeight: bodyMaxHeight }}
            >
              {bottomContent}
            </div>
          </div>
        </div>
      </div>
    </Card>
  </div>
)

export default StripeCardDoubleDouble
