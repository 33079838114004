import { gql } from '@apollo/client'
import { MUTATION_STATE } from '../fragments'

const PAYLOAD = 'UpdateCreationContainsAlcoholPayload'

export const UPDATE_CREATION_CONTAINS_ALCOHOL = gql`
    ${MUTATION_STATE(PAYLOAD)}
    mutation updateCreationContainsAlcohol(
        $creationId: ID!,
        $containsAlcohol: Boolean!
    ) {
        updateCreationContainsAlcohol(
            input: {
                creationId: $creationId
                containsAlcohol: $containsAlcohol
            }
        ) {
            brainState
            ...MutationState${PAYLOAD}Fields
        }
    }
`
