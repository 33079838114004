import React, { ReactText } from 'react'
import './CardNumber.scss'

export interface CardNumberProps {
  number: ReactText
}

const CardNumber: React.FC<CardNumberProps> = ({ number }) => (
  <div className={`CardNumber`}>{number}</div>
)

export default CardNumber
