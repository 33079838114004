import React, { ReactElement } from 'react'
import { Creation, PdfCreation } from 'types/campaign'
import { Table, TableData, TableHeader, TableRow, TableTitle } from 'components/TablePdf'
import { t } from 'i18next'
import { Column } from '../models/types'
import DateUtils from 'utils/date'
import { DATE_HOUR_FORMAT } from 'constant'
import { Image } from '@react-pdf/renderer'

const columns: Column<Creation | PdfCreation>[] = [
  {
    width: 6,
    header: 'pdf.number',
    accessor: (creation, row) => {
      return creation && 'rowNumber' in creation
        ? creation.rowNumber.toString()
        : row?.toString() || ''
    },
  },
  {
    width: 12,
    header: 'common.thumbnail',
    accessor: ({ thumbnailUrl }) =>
      thumbnailUrl ? (
        <Image
          source={{
            uri: thumbnailUrl,
            method: 'GET',
            body: '',
            headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' },
          }}
        />
      ) : (
        ''
      ),
  },
  {
    width: 20,
    header: 'common.fileName',
    accessor: ({ file: { filename } }) => filename,
    forcedSplit: 16,
  },
  {
    width: 18,
    header: 'common.createdAt',
    accessor: creation => DateUtils.parseAndFormat(creation.createdAt, DATE_HOUR_FORMAT),
  },
  {
    width: 17,
    header: 'common.archived',
    accessor: ({ archivedAt }) =>
      archivedAt ? DateUtils.parseAndFormat(archivedAt, DATE_HOUR_FORMAT) : t('common.no'),
  },
  {
    width: 27,
    header: 'pdf.archiveReason',
    accessor: ({ archiveReason }: Creation) => archiveReason,
    forcedSplit: 23,
  },
]

type Props = {
  creations: (Creation | PdfCreation)[]
}

export const Creations = ({ creations }: Props): ReactElement => {
  return (
    <Table>
      <TableTitle>{t('common.creationList')}</TableTitle>
      <TableHeader>
        {columns.map(({ width, header }, key) => (
          <TableData
            key={key}
            width={width}
          >
            {t(header)}
          </TableData>
        ))}
      </TableHeader>
      {creations.map((creation: Creation | PdfCreation, index: number) => (
        <TableRow key={index}>
          {columns.map((column, columnCount) => (
            <TableData
              key={`${index}-${columnCount}`}
              width={column.width}
              forcedSplit={column.forcedSplit}
            >
              {column.accessor(creation, index + 1)}
            </TableData>
          ))}
        </TableRow>
      ))}
    </Table>
  )
}
