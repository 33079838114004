import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CampaignTableFiltersProps, FiltersForm } from './types'
import Button, { ButtonSize, ButtonTheme } from 'components/Form/Button'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import Card from 'components/Layout/Card'
import InputSearch from 'components/Form/InputSearch'
import DatePickerSingle from 'components/Form/DatePickerSingle'
import { DATE_PLACEHOLDER } from 'constant'
import FormRow from 'components/Form/FormRow'
import service from './service'
import SelectMultiCheckbox from 'components/Form/SelectMultiCheckbox'
import { SelectOption } from 'components/Form/Select'
import CampaignUtils from 'utils/campaign'
import { AlertType, CampaignStatus, MediaFormat } from 'types/campaign'
import FillingSpinner, { FillingSpinnerType } from 'components/FillingSpinner'
import TsxUtils from 'utils/tsx'
import EnumUtils from 'utils/enum'
import { useDispatch, useSelector } from 'react-redux'
import { setCollapsed, setFilters } from './slice'
import { AppDispatch, RootState } from 'store'
import DatePickerRangeSeparate from 'components/Form/DatePickerRangeSeparate'
import './CampaignTableFilters.scss'
import i18n from '../../../../i18n'

const CampaignTableFilters: React.FC<CampaignTableFiltersProps> = ({ onSubmit }) => {
  const [startDateFrom, setStartDateFrom] = React.useState('')
  const [endDateFrom, setEndDateFrom] = React.useState('')
  const [startDateTo, setStartDateTo] = React.useState('')
  const [endDateTo, setEndDateTo] = React.useState('')
  const { t } = useTranslation()
  const { filters, collapsed, loading } = useSelector((rs: RootState) => rs.campaignTableFilters)
  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    if (!collapsed && service.areFiltersFetched(filters)) {
      service.getFilters(dispatch)
    }
  }, [collapsed, filters])

  const updateFilters = (filtersSubset: Partial<FiltersForm>): void => {
    void dispatch(setFilters({ ...filters, ...filtersSubset }))
  }

  const SearchRow = (): ReactElement => (
    <div className='CampaignTableFilters__search-row'>
      <Button
        theme={ButtonTheme.NAVY_BLUE_OUTLINE}
        size={ButtonSize.SMALL}
        icon={collapsed ? faCaretDown : faCaretUp}
        onClick={() => void dispatch(setCollapsed(!collapsed))}
      >
        {t('common.filters')}
      </Button>

      <InputSearch
        id='text'
        value={filters.text}
        onChange={text => void dispatch(setFilters({ ...filters, text }))}
        onSubmit={text => {
          dispatch(setFilters({ ...filters, text }))
          onSubmit()
        }}
      />
    </div>
  )

  const Container = (loading: boolean): ReactElement => (
    <Card
      className={
        'CampaignTableFilters__container' +
        TsxUtils.extraStyle(loading, 'CampaignTableFilters__container--loading')
      }
    >
      {loading ? <FillingSpinner type={FillingSpinnerType.STANDARD} /> : Filters()}
    </Card>
  )

  const isNotAllowedDate = (
    day: Date,
    dateTo: string,
    dateFrom: string,
    isDateFromDatePicker: boolean
  ): boolean => {
    if (isDateFromDatePicker) {
      return day > new Date(dateTo)
    }
    return day < new Date(dateFrom)
  }

  const resetDatesState = () => {
    setStartDateFrom('')
    setStartDateTo('')
    setEndDateFrom('')
    setEndDateTo('')
  }

  const Filters = (): ReactElement => (
    <FormRow>
      <div className='CampaignTableFilters__filter'>
        <SelectMultiCheckbox
          id='agencies'
          title={t('common.agency')}
          value={filters.agencies}
          options={filters.agenciesAll.map((c: string): SelectOption => ({ value: c, label: c }))}
          onChange={(agencies): void => updateFilters({ agencies })}
          selectAllOption
        />
      </div>

      <div className='CampaignTableFilters__range-filter'>
        <DatePickerRangeSeparate title={t('filters.start')}>
          <DatePickerSingle
            id='startDateFrom'
            value={filters.startDateFrom}
            placeholder={DATE_PLACEHOLDER}
            isOutsideRange={date => isNotAllowedDate(date, startDateTo, startDateFrom, true)}
            onChange={(startDateFrom): void => {
              updateFilters({ startDateFrom })
              setStartDateFrom(startDateFrom)
            }}
          />
          <DatePickerSingle
            id='startDateTo'
            value={filters.startDateTo}
            placeholder={DATE_PLACEHOLDER}
            isOutsideRange={date => isNotAllowedDate(date, startDateTo, startDateFrom, false)}
            onChange={(startDateTo): void => {
              updateFilters({ startDateTo })
              setStartDateTo(startDateTo)
            }}
          />
        </DatePickerRangeSeparate>

        <DatePickerRangeSeparate title={t('filters.end')}>
          <DatePickerSingle
            id='endDateFrom'
            value={filters.endDateFrom}
            placeholder={DATE_PLACEHOLDER}
            isOutsideRange={date => isNotAllowedDate(date, endDateTo, endDateFrom, true)}
            onChange={(endDateFrom): void => {
              updateFilters({ endDateFrom })
              setEndDateFrom(endDateFrom)
            }}
          />
          <DatePickerSingle
            id='endDateTo'
            value={filters.endDateTo}
            placeholder={DATE_PLACEHOLDER}
            isOutsideRange={date => isNotAllowedDate(date, endDateTo, endDateFrom, false)}
            onChange={(endDateTo): void => {
              updateFilters({ endDateTo })
              setEndDateTo(endDateTo)
            }}
          />
        </DatePickerRangeSeparate>
      </div>

      <div className='CampaignTableFilters__filter'>
        <SelectMultiCheckbox
          id='statuses'
          title={t('common.status')}
          value={filters.statuses}
          options={[
            ...filters.statusesAll.map(
              (selectOption: string): SelectOption => ({
                value: selectOption,
                label: CampaignUtils.parseStatus(
                  CampaignStatus[EnumUtils.getKeyByValueT(CampaignStatus, selectOption)!]
                ).text,
              })
            ),
            {
              value: 'pending_acceptation',
              label: i18n.t('campaignStatus.pendingAcceptation'),
            },
          ]}
          onChange={(statuses): void => {
            updateFilters({ statuses })
          }}
          selectAllOption
        />
      </div>

      <div className='CampaignTableFilters__filter'>
        <SelectMultiCheckbox
          id='mediaFormats'
          title={t('common.mediaType')}
          value={filters.mediaFormats}
          options={filters.mediaFormatsAll.map(
            (so: string): SelectOption => ({
              value: so,
              label: t(`mediaType.${MediaFormat[so as keyof typeof MediaFormat]}`),
            })
          )}
          onChange={(mediaFormats): void => void dispatch(setFilters({ ...filters, mediaFormats }))}
          selectAllOption
        />
      </div>

      <div className='CampaignTableFilters__filter'>
        <SelectMultiCheckbox
          id='cities'
          title={t('common.locations')}
          value={filters.cities}
          options={filters.citiesAll.map((m: string): SelectOption => ({ value: m, label: m }))}
          onChange={(cities): void => void dispatch(setFilters({ ...filters, cities }))}
          selectAllOption
        />
      </div>

      <div className='CampaignTableFilters__filter'>
        <SelectMultiCheckbox
          id='alerts'
          title={t('common.alerts')}
          value={filters.alerts}
          options={[
            ...filters.alertsTypeAll.map(
              (selectOption: AlertType): SelectOption => ({
                value: selectOption,
                label: t(`common.${AlertType[selectOption]}`),
              })
            ),
          ]}
          onChange={(alerts): void => {
            updateFilters({
              alerts: alerts.map(alert => AlertType[alert as keyof typeof AlertType]),
            })
          }}
          selectAllOption
        />
      </div>

      <div className='CampaignTableFilters__filter'>
        <SelectMultiCheckbox
          id='targets'
          title={t('common.target')}
          value={filters.targets}
          options={filters.targetsAll.map(
            (target: string): SelectOption => ({
              value: target,
              label: t(`targetFilters.${target}`),
            })
          )}
          onChange={(targets): void => void dispatch(setFilters({ ...filters, targets }))}
          selectAllOption
        />
      </div>
      <div className='CampaignTableFilters__filter'>
        <SelectMultiCheckbox
          id='commercialAttributes'
          title={t('attributesSelection.commercialAttributes')}
          value={filters.commercialAttributes}
          options={filters.commercialAttributesAll.map(
            (commercialAttribute: string): SelectOption => ({
              value: commercialAttribute,
              label: t(`attributesSelection.commercialAttributesValues.${commercialAttribute}`),
            })
          )}
          onChange={(commercialAttributes): void =>
            void dispatch(setFilters({ ...filters, commercialAttributes }))
          }
          selectAllOption
        />
      </div>
      <Actions />
    </FormRow>
  )

  const Actions = (): ReactElement => (
    <div className='CampaignTableFilters__actions'>
      <Button
        size={ButtonSize.SMALL}
        theme={ButtonTheme.BLUE_OUTLINE}
        onClick={() => {
          resetDatesState()
          service.cleanFiltersForm(filters, dispatch)
          onSubmit()
        }}
      >
        {t('filters.clearFilters')}
      </Button>

      <Button
        size={ButtonSize.SMALL}
        onClick={() => void onSubmit()}
      >
        {t('filters.filter')}
      </Button>
    </div>
  )

  return (
    <div className='CampaignTableFilters'>
      {SearchRow()}
      {!collapsed && Container(loading)}
    </div>
  )
}

export default CampaignTableFilters
