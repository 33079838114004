import { latLng, MapOptions, MarkerClusterGroupOptions } from 'leaflet'

export const URL =
  `https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}` +
  `?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`

export const ATTRIBUTION = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'

export const MAP_OPTIONS: MapOptions = {
  preferCanvas: true,
  zoom: 6,
  center: latLng(52, 19), // Poland
  zoomControl: false,
  attributionControl: false,
}

export const CLUSTER_GROUP_OPTIONS: MarkerClusterGroupOptions = {
  showCoverageOnHover: false,
  removeOutsideVisibleBounds: true,
}
