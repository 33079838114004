import { gql } from '@apollo/client'
import { CREATION_FIELDS, MEDIUM_FIELDS } from '../fragments'

export const GET_CAMPAIGNS_COMBINED_SUMMARY = gql`
  ${CREATION_FIELDS}
  ${MEDIUM_FIELDS}
  query campaignsCombinedSummary(
    $campaignIds: [ID!]!
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
  ) {
    campaignsCombinedSummary(campaignIds: $campaignIds, startDate: $startDate, endDate: $endDate) {
      details {
        briefName
        budgetCurrency
        creations {
          ...CreationFields
        }
        currentEmissions
        endDate
        media {
          ...MediumFields
        }
        name
        offerType
        startDate
        status
        target
        totalAudience
        totalBudget
        totalEmissions
      }
      mediumFormat
    }
  }
`
