import { gql } from '@apollo/client'
import { MUTATION_STATE } from '../fragments'

const PAYLOAD = 'UpdateCreationDurationPayload'

export const UPDATE_CREATION_DURATION = gql`
  ${MUTATION_STATE(PAYLOAD)}
  mutation updateCreationDuration(
    $creationId: ID!
    $duration: Int!
  ) {
    updateCreationDuration(
      input: { 
        creationId: $creationId
        duration: $duration 
      }
    ) {
      ...MutationState${PAYLOAD}Fields
      brainState
    }
  }
`
