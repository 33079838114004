import { gql } from '@apollo/client'
import { USER_DETAILS_FIELDS } from '../fragments'

export const GET_USER = gql`
  ${USER_DETAILS_FIELDS}
  query currentUser {
    currentUser {
      ...UserDetailsFields
    }
  }
`
