import React, { CSSProperties, ReactNode } from 'react'
import Card from '../Layout/Card'
import TsxUtils from 'utils/tsx'
import './StripeCard.scss'

type Props = {
  title?: string
  icon?: ReactNode
  children: ReactNode
  className?: string
  bodyMaxHeight?: CSSProperties['maxHeight']
}

const StripeCard: React.FC<Props> = ({ title, icon, className, children, bodyMaxHeight }) => (
  <div className={'StripeCard' + TsxUtils.extraStyle(className, className)}>
    <Card className='StripeCard__card'>
      <div className='StripeCard__icon'>{icon}</div>
      <div className='StripeCard__container'>
        <div className='StripeCard__title'>{title}</div>
        <div
          className='StripeCard__body'
          style={{ maxHeight: bodyMaxHeight }}
        >
          {children}
        </div>
      </div>
    </Card>
  </div>
)

export default StripeCard
