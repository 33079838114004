import { gql } from '@apollo/client'

export const CREATE_CAMPAIGN_SUMMARY_PDF = gql`
  mutation createCampaignSummaryPdf($campaignUuid: String!) {
    createCampaignSummaryPdf(input: { campaignUuid: $campaignUuid }) {
      task {
        jobId
        status
      }
      success
      errors
    }
  }
`

export const FETCH_PDF_TASK_STATUS = gql`
  query task($jobId: String!) {
    task(jobId: $jobId) {
      jobId
      status
      file
      reason
    }
  }
`
