import { DateUtils, RangeModifier } from 'react-day-picker'
import { DisabledDays, RangeModifierNullable } from './types'

export default class DatePickerRangeService {
  static isDisabledDay = (day: Date, disabledDays: DisabledDays | undefined): boolean =>
    disabledDays
      ? DateUtils.isDayBefore(day, disabledDays.from) ||
        DateUtils.isDayBefore(day, new Date()) ||
        DateUtils.isDayAfter(day, disabledDays.to)
      : false

  static isSelectingFirstDay = (
    from: Date | null | undefined,
    to: Date | null | undefined,
    day: Date
  ): boolean | Date | null | undefined => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
    const isRangeSelected = from && to
    return !from || isBeforeFirstDay || isRangeSelected
  }

  static increaseSmallerRanges = (
    tempRangeNullable: RangeModifierNullable,
    ranges: RangeModifier[]
  ): { shouldIncrease: boolean; increasedRanges: RangeModifier[] } => {
    let shouldIncrease = false
    const tempRange = tempRangeNullable as RangeModifier

    const increasedRanges = ranges.map((r: RangeModifier) => {
      const { from, to } = r
      const isFromInTempRange = DateUtils.isDayInRange(from!, tempRange)
      const isToInTempRange = DateUtils.isDayInRange(to!, tempRange)

      if (isFromInTempRange && isToInTempRange) {
        shouldIncrease = true
        return tempRange as RangeModifier
      } else if (isFromInTempRange && !isToInTempRange) {
        shouldIncrease = true
        return { from: tempRange.from, to }
      } else {
        return r
      }
    })

    return {
      shouldIncrease,
      increasedRanges: [...new Set(increasedRanges)],
    }
  }
}
