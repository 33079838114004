import api from 'api'
import { OrganizationAttributes } from 'types/campaign'

export default class OrganizationAttributesService {
  static update = (
    campaignId: string,
    organizationAttributes: OrganizationAttributes
  ): Promise<unknown> =>
    api.campaign
      .updateCampaignOrganizationAttributes(campaignId, organizationAttributes)
      .catch(() => [])
}
