import { Medium } from 'types/campaign'
import { PublicViewProps } from '../types'
import { Filters } from 'components/Form/Filters'
import { CampaignData } from '../../models/campaign-data.model'

export interface CampaignDetailsChartProps {
  campaign: CampaignData
  displayedMedia: Medium[]
  publicViewProps?: PublicViewProps
}

export interface RechartsComponentData {
  x?: number
  y?: number
  cx?: number
  cy?: number
  payload?: any
}

export interface CampaignEmission {
  date: string
  budget: number
  totalAudience: number
  totalBudget: number
  emissions: number
  totalEmissions: number
  audience: number
  estimated: boolean | null
}

export const filterKeys = [
  'agglomerations',
  'cities',
  'buildings',
  'mediaAsIds',
  'pois',
  'creations',
] as const

export type FilterKey = (typeof filterKeys)[number]

export type CampaignLocalFilters = Filters<FilterKey>

export const SexDefaults = [
  0, // MAN
  1, // WOMAN
]

export const AgeGroupDefaults = [
  0, // 0-15
  1, // 15-30
  2, // 30-45
  3, // 45-60
  4, // 60+
]

export interface AudienceFilters {
  sex: number[]
  ageGroups: number[]
}
