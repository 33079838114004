import React, { ReactElement, useContext } from 'react'
import Modal from 'components/Modal'
import {
  MediumTemplate,
  RemoveMediaTemplateResult,
  RemoveMediaTemplateVariables,
} from 'api/media/types'
import Button, { ButtonTheme } from 'components/Form/Button'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import REMOVE_MEDIA_TEMPLATE from 'api/media/removeMediaTemplate'
import { AppContext } from '../../../contexts/AppContext'
import { NotifierType } from '../../Notifier'

type Props = {
  close: () => void
  template: MediumTemplate
}

const TemplateDeleteModal: React.FC<Props> = ({ close, template }) => {
  const { t } = useTranslation()
  const [removeMediaTemplate] = useMutation<
    RemoveMediaTemplateResult,
    RemoveMediaTemplateVariables
  >(REMOVE_MEDIA_TEMPLATE)
  const { addNotification } = useContext(AppContext)

  const Footer: ReactElement = (
    <div className='Modal__footer'>
      <Button
        onClick={() => {
          removeMediaTemplate({ variables: { input: { id: template.id } } })
            .then(res => {
              if (res.data?.removeMediaTemplate.success) {
                addNotification(NotifierType.SUCCESS, t('mediaSelection.template.remove.success'))
              }
              close()
            })
            .catch(() => void {})
        }}
      >
        {t('common.remove')}
      </Button>
      <Button
        theme={ButtonTheme.BLUE_OUTLINE}
        onClick={close}
      >
        {t('common.cancel')}
      </Button>
    </div>
  )

  const Body: ReactElement = (
    <Trans
      i18nKey='mediaSelection.template.removeMessage'
      values={{ template: template.name }}
      components={{ template: <b /> }}
    />
  )

  return (
    <Modal
      onClose={close}
      footer={Footer}
      isOpen
    >
      {Body}
    </Modal>
  )
}

export default TemplateDeleteModal
