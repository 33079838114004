import api from 'api'
import { NotifierType } from 'components/Notifier'
import { UseNotifierProps } from 'hooks/useNotifier'
import { UseUserProps } from 'hooks/useUser'
import { TFunction } from 'i18next'
import { User } from 'types/user'
import { PasswordData, ProfileData } from './types'

export default class UserSettingsService {
  static handleUpdateProfile = (
    profileData: ProfileData,
    setProfileDataLoading: React.Dispatch<React.SetStateAction<boolean>>,
    updateUser: UseUserProps['updateUser'],
    addNotification: UseNotifierProps['addNotification'],
    t: TFunction
  ): Promise<void> => {
    setProfileDataLoading(true)
    const { id, firstName, lastName } = profileData

    return api.user
      .updateProfile({
        id,
        firstName,
        lastName,
      })
      .then(() => {
        addNotification(NotifierType.SUCCESS, t('notification.updateProfileSuccess'))
      })
      .catch(() => void {})
      .finally(() => {
        setProfileDataLoading(false)
        updateUser()
      })
  }

  static handleUpdatePassword = (
    id: User['id'],
    passwordData: PasswordData,
    setPasswordDataLoading: React.Dispatch<React.SetStateAction<boolean>>,
    addNotification: UseNotifierProps['addNotification'],
    t: TFunction
  ): Promise<void> => {
    setPasswordDataLoading(true)

    return api.user
      .updatePassword({
        id,
        ...passwordData,
      })
      .then(() => {
        addNotification(NotifierType.SUCCESS, t('notification.passwordSaveSuccess'))
      })
      .catch(() => void {})
      .finally(() => void setPasswordDataLoading(false))
  }
}
