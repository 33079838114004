import React, { ReactNode } from 'react'
import Table, {
  TableBody,
  TableData,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from 'components/Table'
import { TFunction } from 'i18next'
import { GeneratedReport } from '../../../api/report/types'
import Button from '../../../components/Form/Button'
import DateUtils from '../../../utils/date'
import { DATE_FORMAT } from '../../../constant'

export default class CommonReport {
  static ReportTable = (reports: GeneratedReport[], t: TFunction): ReactNode => {
    if (!reports.length) {
      return (
        <div className='Report__no-data'>
          <>{t('report.noData')}</>
        </div>
      )
    }

    return (
      <div className='Report__table'>
        <Table>
          <TableHeader sticky>
            <TableHeaderItem>{t<string>('report.firstColumn')}</TableHeaderItem>
            <TableHeaderItem>{t<string>('report.secondColumn')}</TableHeaderItem>
            <TableHeaderItem>{t<string>('report.thirdColumn')}</TableHeaderItem>
            <TableHeaderItem>{t<string>('report.fourthColumn')}</TableHeaderItem>
            <TableHeaderItem>{t<string>('report.fifthColumn')}</TableHeaderItem>
          </TableHeader>

          <TableBody>
            {reports.map(({ name, startDate, endDate, status, generatedAt, csvUrl }, i: number) => (
              <TableRow key={i}>
                <TableData key={`${i}-name`}>
                  <span className='Report__title'>{name}</span>
                </TableData>
                <TableData key={`${i}-date`}>
                  {CommonReport.GetDateRange(startDate, endDate)}
                </TableData>
                <TableData key={`${i}-status`}>
                  {t<string>('report.' + status + 'Status')}
                </TableData>
                <TableData key={`${i}-generated`}>
                  {generatedAt ? DateUtils.parseAndFormat(generatedAt, DATE_FORMAT) : '-'}
                </TableData>
                <TableData key={`${i}-action`}>
                  <Button
                    onClick={() => {
                      if (!csvUrl) return
                      window.open(csvUrl, '_blank')
                    }}
                    disabled={!csvUrl}
                  >
                    {t('common.download')}
                  </Button>
                </TableData>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }

  static GetDateRange = (startDate: string | null, endDate: string | null): string => {
    // 1. no date range
    if (!startDate && !endDate) return '-'

    // 2. only start date
    if (startDate && !endDate) return DateUtils.parseAndFormat(startDate, DATE_FORMAT)

    // 3. only end date
    if (!startDate && endDate) return DateUtils.parseAndFormat(endDate, DATE_FORMAT)

    // 4. both dates
    return (
      DateUtils.parseAndFormat(startDate!, DATE_FORMAT) +
      ' - ' +
      DateUtils.parseAndFormat(endDate!, DATE_FORMAT)
    )
  }
}
