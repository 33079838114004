import React, { ReactNode, useState } from 'react'
import './AcceptancesCard.scss'
import {
  AcceptanceStatus,
  Campaign,
  Creation,
  CreationAcceptance,
  CreationAcceptanceDetails,
} from '../../types/campaign'
import Table from '../Table/Table'
import { TableBody, TableData, TableHeader, TableHeaderItem, TableRow } from '../Table'
import FlexRow from '../Layout/FlexRow'
import service from '../../pages/Campaign/CampaignForm/CreationsForm/CreationsFormPreview/service'
import TooltipHelp from '../TooltipHelp'
import { useTranslation } from 'react-i18next'
import CardHeader from '../Layout/CardHeader'
import Card from '../Layout/Card'
import FillingSpinner from '../FillingSpinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import TsxUtils from '../../utils/tsx'
import Button, { ButtonSize } from '../Form/Button'

interface AcceptancesCardProps {
  loadingAcceptances: boolean
  acceptances: CreationAcceptance[]
  campaignId?: Campaign['id']
  border?: boolean
  creations?: Creation[]
  isCollapsible?: boolean
}

const AcceptancesCard: React.FC<AcceptancesCardProps> = ({
  acceptances,
  loadingAcceptances,
  campaignId,
  border = true,
  creations,
  isCollapsible,
}) => {
  const { t } = useTranslation()
  const [loading, setLoadingDownloadAcceptancesFile] = useState<boolean>(false)

  const acceptanceStatus: { [key in AcceptanceStatus]: string } = {
    ['not_sent']: t('form.campaign.creations.accepting'),
    ['pending_acceptation']: t('form.campaign.creations.accepting'),
    ['accepted']: t('form.campaign.creations.accepted'),
    ['rejected']: t('form.campaign.creations.rejected'),
  }

  const [isAcceptancesListCollapsed, setIsAcceptancesListCollapsed] = useState<boolean>(
    !!isCollapsible
  )

  const [isAcceptancesListDetailsCollapsed, setIsAcceptancesListDetailsCollapsed] = useState<
    string[]
  >([])

  const TableBodyForCreationsPreview = (): ReactNode =>
    acceptances.map((acceptance: CreationAcceptance) => (
      <TableRow key={acceptance.building}>
        <TableData>{acceptance.acceptances[0].agglomeration}</TableData>
        <TableData>{acceptance.acceptances[0].city}</TableData>
        <TableData>{acceptance.building}</TableData>
        <TableData>
          <FlexRow>
            {service.getBuildingAcceptanceStatus(acceptance.acceptances, t)}
            {acceptance.acceptances[0].rejectionDetails && (
              <TooltipHelp>{acceptance.acceptances[0].rejectionDetails}</TooltipHelp>
            )}
          </FlexRow>
        </TableData>
      </TableRow>
    ))

  const TableBodyForCampaignDetails = (): ReactNode =>
    creations?.map((creation: Creation) => {
      const acceptancesWithBuilding: (CreationAcceptanceDetails & { building: string })[] = []

      acceptances.forEach(creationAcceptance => {
        creationAcceptance.acceptances.forEach(acceptanceDetails => {
          if (acceptanceDetails.creationId === creation.id) {
            acceptancesWithBuilding.push({
              ...acceptanceDetails,
              building: creationAcceptance.building,
            })
          }
        })
      })
      return (
        <>
          <TableRow key={creation.id}>
            <TableData>{creation.file.filename}</TableData>
            <TableData>
              {
                acceptancesWithBuilding.filter(
                  acceptance =>
                    acceptance.status === 'pending_acceptation' || acceptance.status === 'not_sent'
                ).length
              }
            </TableData>
            <TableData>
              {
                acceptancesWithBuilding.filter(acceptance => acceptance.status === 'accepted')
                  .length
              }
            </TableData>
            <TableData>
              {
                acceptancesWithBuilding.filter(acceptance => acceptance.status === 'rejected')
                  .length
              }
            </TableData>
            <TableData>{acceptancesWithBuilding.length}</TableData>
            <TableData>
              <div
                className={'CardHeader__collapse-btn--no-shadow'}
                onClick={() => {
                  setIsAcceptancesListDetailsCollapsed(
                    isAcceptancesListDetailsCollapsed.some(id => creation.id === id)
                      ? isAcceptancesListDetailsCollapsed.filter(el => el !== creation.id)
                      : [...isAcceptancesListDetailsCollapsed, creation.id]
                  )
                }}
              >
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className={
                    'CardHeader__collapse-icon' +
                    TsxUtils.extraStyle(
                      isAcceptancesListDetailsCollapsed?.some(id => id === creation.id),
                      'CardHeader__collapse-icon--collapsed'
                    )
                  }
                />
              </div>
            </TableData>
          </TableRow>
          {isAcceptancesListDetailsCollapsed?.some(id => id === creation.id) && (
            <>
              <TableRow>
                <td
                  colSpan={5}
                  className={'acceptance-details'}
                >
                  <Table className={'AcceptancesDetailsTable'}>
                    <TableHeader>
                      <TableHeaderItem>#</TableHeaderItem>
                      <TableHeaderItem>
                        {t('form.campaign.creations.agglomeration')}
                      </TableHeaderItem>
                      <TableHeaderItem>{t('common.city')}</TableHeaderItem>
                      <TableHeaderItem>{t('common.building')}</TableHeaderItem>
                      <TableHeaderItem>{t('common.status')} </TableHeaderItem>
                    </TableHeader>

                    <TableBody>
                      <>{TableBodyForAcceptancesDetails(acceptancesWithBuilding)}</>
                    </TableBody>
                  </Table>
                </td>
              </TableRow>
            </>
          )}
        </>
      )
    })

  const TableBodyForAcceptancesDetails = (
    acceptancesWithBuilding: (CreationAcceptanceDetails & { building: string })[]
  ): ReactNode =>
    acceptancesWithBuilding?.map(
      (acceptanceWithBuilding: CreationAcceptanceDetails & { building: string }, index) => {
        return (
          <>
            <TableRow key={acceptanceWithBuilding.id}>
              <TableData>{index + 1}</TableData>
              <TableData>{acceptanceWithBuilding.agglomeration}</TableData>
              <TableData>{acceptanceWithBuilding.city}</TableData>
              <TableData>{acceptanceWithBuilding.building}</TableData>
              <TableData>
                <div className={'TooltipHelp--contained-outside'}>
                  <span>{acceptanceStatus[acceptanceWithBuilding.status]}</span>
                  {acceptanceWithBuilding.rejectionDetails && (
                    <TooltipHelp>{acceptanceWithBuilding.rejectionDetails}</TooltipHelp>
                  )}
                </div>
              </TableData>
            </TableRow>
          </>
        )
      }
    )

  const downloadAcceptancesFile = async () => {
    if (!campaignId) {
      return
    }

    setLoadingDownloadAcceptancesFile(true)
    await service.fetchCampaignAcceptancesReportFile(campaignId)
    setLoadingDownloadAcceptancesFile(false)
  }
  return (
    <Card className={border ? 'AcceptancesCard' : 'AcceptancesCard AcceptancesCard--no-border'}>
      <CardHeader
        collapsed={isCollapsible ? isAcceptancesListCollapsed : undefined}
        onCollapse={(collapsed: boolean) => void setIsAcceptancesListCollapsed(collapsed)}
      >
        {t('form.campaign.creations.acceptancesList')}
      </CardHeader>

      {!isAcceptancesListCollapsed && (
        <>
          {loadingAcceptances ? (
            <FillingSpinner className='AcceptancesTable__spinner' />
          ) : (
            <Table className={'Table--relative'}>
              <TableHeader>
                {creations ? (
                  <>
                    <TableHeaderItem>{t('common.creationName')}</TableHeaderItem>
                    <TableHeaderItem>{t('campaignStatus.pendingAcceptation')}</TableHeaderItem>
                    <TableHeaderItem>{t('form.campaign.creations.accepted')}</TableHeaderItem>
                    <TableHeaderItem>{t('form.campaign.creations.rejected')}</TableHeaderItem>
                    <TableHeaderItem>{t('offer.buildings')}</TableHeaderItem>
                  </>
                ) : (
                  <>
                    <TableHeaderItem>{t('form.campaign.creations.agglomeration')}</TableHeaderItem>
                    <TableHeaderItem>{t('common.city')}</TableHeaderItem>
                    <TableHeaderItem>{t('common.building')}</TableHeaderItem>
                    <TableHeaderItem>{t('common.status')} </TableHeaderItem>
                  </>
                )}
              </TableHeader>

              <TableBody>
                <>{creations ? TableBodyForCampaignDetails() : TableBodyForCreationsPreview()}</>
              </TableBody>
            </Table>
          )}
          {campaignId && (
            <div className={'AcceptancesCard__report'}>
              <Button
                size={ButtonSize.SMALL}
                onClick={downloadAcceptancesFile}
                disabled={loading}
              >
                {loading ? (
                  <FillingSpinner className={'AcceptancesCard__report-btn-spinner'} />
                ) : (
                  t('campaignDetails.acceptancesReport')
                )}
              </Button>
            </div>
          )}
        </>
      )}
    </Card>
  )
}

export default AcceptancesCard
