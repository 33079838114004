import React, { ReactChild } from 'react'
import './Preview.scss'

interface PreviewProps {
  className: string
  src: string
  thumbnailSrc?: string
  isVideo?: boolean
  alt: string
}

const Preview: React.FC<PreviewProps> = ({ className, src, thumbnailSrc, isVideo, alt }) => {
  const imgPreview = (): ReactChild => (
    <img
      src={thumbnailSrc}
      alt={alt}
    />
  )

  const videoPreview = (): ReactChild => (
    <video
      className={alt}
      src={thumbnailSrc}
      poster={thumbnailSrc}
      loop
      muted
      autoPlay
    />
  )

  return (
    <div className={className}>
      <a
        className={'Preview__thumbnail'}
        rel='noopener noreferrer'
        href={src}
        target='_blank'
      >
        {isVideo ? videoPreview() : imgPreview()}
      </a>
    </div>
  )
}

export default Preview
