import { Dispatch, SetStateAction } from 'react'
import { Campaign } from 'types/campaign'

export interface CampaignDetailsActionModalsProps {
  campaign: Pick<Campaign, 'id' | 'name'>
  onShouldReload: Dispatch<SetStateAction<boolean>>
  reducerState: ModalReducerState
  dispatch: DispatchModalAction
}

export interface ModalReducerState {
  createCopyOpen: boolean
  createCopyLoading: boolean
  editOpen: boolean

  removeOpen: boolean
  removeLoading: boolean

  publishOpen: boolean
  publishLoading: boolean

  approveOpen: boolean
  approveLoading: boolean

  rejectOpen: boolean
  rejectLoading: boolean

  pauseOpen: boolean
  pauseLoading: boolean

  stopOpen: boolean
  stopLoading: boolean

  confirmReservationOpen: boolean
  confirmReservationLoading: boolean

  cancelReservationOpen: boolean
  cancelReservationLoading: boolean
}

export const modalsReducerInitialState: ModalReducerState = {
  createCopyOpen: false,
  createCopyLoading: false,

  editOpen: false,

  removeOpen: false,
  removeLoading: false,

  publishOpen: false,
  publishLoading: false,

  approveOpen: false,
  approveLoading: false,

  rejectOpen: false,
  rejectLoading: false,

  pauseOpen: false,
  pauseLoading: false,

  stopOpen: false,
  stopLoading: false,

  confirmReservationOpen: false,
  confirmReservationLoading: false,

  cancelReservationOpen: false,
  cancelReservationLoading: false,
}

export type DispatchModalAction = (
  type: ModalActionKind,
  payload: boolean
) => Dispatch<ModalAction> | void

export enum ModalActionKind {
  CREATE_COPY_OPEN,
  CREATE_COPY_LOADING,
  EDIT_OPEN,

  REMOVE_OPEN,
  REMOVE_LOADING,

  PUBLISH_OPEN,
  PUBLISH_LOADING,

  APPROVE_OPEN,
  APPROVE_LOADING,
  REJECT_OPEN,
  REJECT_LOADING,
  PAUSE_OPEN,
  PAUSE_LOADING,

  STOP_OPEN,
  STOP_LOADING,

  CONFIRM_RESERVATION_OPEN,
  CONFIRM_RESERVATION_LOADING,

  CANCEL_RESERVATION_OPEN,
  CANCEL_RESERVATION_LOADING,
}

export interface ModalAction {
  type: ModalActionKind
  payload: boolean
}
