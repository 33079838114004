import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'api/fragments'

const PAYLOAD_TYPE = 'CreateSketchCampaignPayload'

export const CREATE_SKETCH_CAMPAIGN = gql`
    ${MUTATION_STATE(PAYLOAD_TYPE)}
    mutation createSketchCampaign(
        $agencyId: ID
        $name: String!
        $briefName: String!
        $startDate: ISO8601DateTime!
        $endDate: ISO8601DateTime!
    ) {
        createSketchCampaign(
            input: {
                agencyId: $agencyId
                name: $name
                briefName: $briefName
                startDate: $startDate
                endDate: $endDate
                
            }
        ) {
            campaign {
                id
                priority
            }
            ...MutationState${PAYLOAD_TYPE}Fields
        }
    }
`
