import { AuthorizationTemplate } from 'hooks/useAuthorize'
import { BaseRole } from 'types/user'
import { ALL_ROLES } from './various'
import { CampaignPermissionType, FeatureName, PermissionType } from 'types/features'

export const PAGES: AuthorizationTemplate = {
  CAMPAIGNS: {
    LIST: {
      feature: {
        name: FeatureName.Campaigns,
        action: CampaignPermissionType.Read,
      },
    },
    ADD_EDIT: {
      feature: {
        name: FeatureName.Campaigns,
        action: CampaignPermissionType.Write,
      },
    },
    DETAILS: {
      feature: {
        name: FeatureName.Campaigns,
        action: CampaignPermissionType.Read,
      },
    },
    HISTORY: { roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN] },
    ACCEPTANCE_FIELDS: { roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN] },
    EMISSION_SCHEDULE: {
      feature: {
        name: FeatureName.Campaigns,
        action: CampaignPermissionType.Read,
      },
    },
  },
  AGENCIES: {
    LIST: {
      feature: {
        name: FeatureName.Agencies,
        action: PermissionType.Read,
      },
    },
    ADD_EDIT: {
      feature: {
        name: FeatureName.Agencies,
        action: PermissionType.Write,
      },
    },
  },
  ROLES: {
    LIST: { roles: [BaseRole.ADMIN] },
    ADD_EDIT: { roles: [BaseRole.ADMIN] },
  },
  USERS: {
    LIST: {
      feature: {
        name: FeatureName.Users,
        action: PermissionType.Read,
      },
    },
    INVITE: {
      feature: {
        name: FeatureName.Users,
        action: PermissionType.Write,
      },
    },
    EDIT: {
      feature: {
        name: FeatureName.Users,
        action: PermissionType.Write,
      },
    },
    SETTINGS: { roles: ALL_ROLES },
  },
  REPORT: {
    feature: {
      name: FeatureName.Reports,
      action: PermissionType.Active,
    },
  },
  INVENTORY: {
    feature: {
      name: FeatureName.Inventory,
      action: PermissionType.Active,
    },
  },
  FEATURES: { roles: [BaseRole.ADMIN] },
  NOT_FOUND: { roles: ALL_ROLES },
}
