import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'api/fragments'

const PAYLOAD_TYPE = 'UpdateCampaignMediaPayload'

export const UPDATE_CAMPAIGN_MEDIA_USAGE = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation updateMedia($campaignId: ID!, $media: [MediumUsage!]!) {
    updateCampaignMedia(input: { campaignId: $campaignId, media: $media }) {
      ...MutationState${PAYLOAD_TYPE}Fields
      campaign {
        brainState
      }
    }
  }
`
