import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { produce } from 'immer'
import { TopTenGalleriesSliceModel } from '../models/top-ten-galleries-slice.model'

const defaultState: TopTenGalleriesSliceModel = {
  loading: true,
  data: [],
  filters: {
    fromDate: '',
    toDate: '',
  },
}

const initialState: TopTenGalleriesSliceModel = produce(defaultState, draft => draft)

const topTenGalleriesSlice = createSlice({
  name: 'topTenGalleries',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setData(state, action: PayloadAction<{ data: TopTenGalleriesSliceModel['data'] }>) {
      state.data = action.payload.data
    },
    setFilters(state, action: PayloadAction<TopTenGalleriesSliceModel['filters']>) {
      state.filters = action.payload
    },
    resetState() {
      return defaultState
    },
  },
})

export const topTenGalleriesActions = topTenGalleriesSlice.actions

export default topTenGalleriesSlice
