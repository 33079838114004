import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EmissionPlanSliceModel } from '../models/emission-plan-slice.model'
import { produce } from 'immer'

const defaultState: EmissionPlanSliceModel = {
  loading: true,
  loaded: false,
  pageNumber: 1,
  totalItemsCount: 0,
  dailySummary: undefined,
  data: [],
  campaign: {
    loading: false,
    loaded: false,
    data: undefined,
  },
  filters: {
    loaded: false,
    loading: false,
    all: {
      agglomerations: [],
      cities: [],
      buildings: [],
      mediaAsIds: [],
      creations: [],
    },
    selected: {
      agglomerations: [],
      cities: [],
      buildings: [],
      mediaAsIds: [],
      creations: [],
    },
  },
}

const initialState: EmissionPlanSliceModel = produce(defaultState, draft => draft)

const emissionPlanSlice = createSlice({
  name: 'emissionPlan',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setLoaded(state, action: PayloadAction<boolean>) {
      state.loaded = action.payload
    },
    setFiltersLoading(state, action: PayloadAction<boolean>) {
      state.filters.loading = action.payload
    },
    setFiltersLoaded(state, action: PayloadAction<boolean>) {
      state.filters.loaded = action.payload
    },
    setAvailableFilters(state, action: PayloadAction<EmissionPlanSliceModel['filters']['all']>) {
      state.filters.all = action.payload
    },
    setSelectedFilters(
      state,
      action: PayloadAction<{
        key: keyof EmissionPlanSliceModel['filters']['selected']
        data: string[]
      }>
    ) {
      state.filters.selected[action.payload.key] = action.payload.data
    },
    setCampaignData(state, action: PayloadAction<EmissionPlanSliceModel['campaign']['data']>) {
      state.campaign.data = action.payload
    },
    setCampaignLoading(state, action: PayloadAction<boolean>) {
      state.campaign.loading = action.payload
    },
    setCampaignLoaded(state, action: PayloadAction<boolean>) {
      state.campaign.loaded = action.payload
    },
    setData(
      state,
      action: PayloadAction<{ data: EmissionPlanSliceModel['data']; totalItemsCount: number }>
    ) {
      state.data = action.payload.data
      state.totalItemsCount = action.payload.totalItemsCount
    },
    setDailySummary(state, action: PayloadAction<EmissionPlanSliceModel['dailySummary']>) {
      state.dailySummary = action.payload
    },
    setPageNumber(state, action: PayloadAction<number>) {
      state.pageNumber = action.payload
    },
    resetState() {
      return defaultState
    },
  },
})

export const emissionPlanActions = emissionPlanSlice.actions

export default emissionPlanSlice
