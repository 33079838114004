import React, { createContext, useCallback, useEffect, useState } from 'react'
import {
  CampaignFormContextProps,
  CampaignFormContextPropsDefault,
  CampaignFormContextProviderProps,
  CreationsValues,
  FormCreation,
} from './types'
import debounce from 'lodash/debounce'
import service from './service'
import usePrevious from 'hooks/usePrevious'
import VariableUtils from 'utils/variable'
import { CREATION_DETAILS_CHANGE_DEBOUNCE_TIME } from '../../constant'

export const CampaignFormContext = createContext<CampaignFormContextProps>(
  CampaignFormContextPropsDefault
)

const DEBOUNCE_UPDATE_CREATION_DELAY = 500

const CampaignFormContextProvider: React.FC<CampaignFormContextProviderProps> = ({ children }) => {
  const [creationsValues, setCreationsValues] = useState<CreationsValues>([])
  const creationsValuesPrevious = usePrevious<CreationsValues>(creationsValues) || []
  const [triggerCreations, setTriggerCreations] = useState<boolean>(false)
  const [triggerFetchCampaign, setTriggerFetchCampaign] = useState<boolean>(false)

  useEffect(() => {
    if (triggerCreations) setTriggerFetchCampaign(triggerCreations)
  }, [triggerCreations])

  const debouncedUpdateCreationEmissionsNumber = useCallback(
    debounce(service.updateCreationEmissionsNumber, DEBOUNCE_UPDATE_CREATION_DELAY),
    []
  )

  const debouncedUpdateCreationAudienceNumber = useCallback(
    debounce(service.updateCreationAudienceNumber, DEBOUNCE_UPDATE_CREATION_DELAY),
    []
  )

  const debouncedUpdateCreationDetails = useCallback(
    debounce(service.updateCreationDetails, DEBOUNCE_UPDATE_CREATION_DELAY),
    []
  )

  const debouncedUpdateCreationDuration = useCallback(
    debounce(service.updateCreationDuration, CREATION_DETAILS_CHANGE_DEBOUNCE_TIME),
    []
  )

  const debouncedUpdateCreationContainsAlcohol = useCallback(
    debounce(service.updateCreationContainsAlcohol, CREATION_DETAILS_CHANGE_DEBOUNCE_TIME),
    []
  )

  // Update creation AS_ID if changed
  useEffect(() => {
    if (
      VariableUtils.isNullOrUndefined(creationsValues) ||
      VariableUtils.isNullOrUndefined(creationsValuesPrevious) ||
      VariableUtils.isEmptyArray(creationsValues) ||
      VariableUtils.isEmptyArray(creationsValuesPrevious) ||
      creationsValues.length !== creationsValuesPrevious.length
    )
      return

    creationsValues.forEach((creation: FormCreation) => {
      const creationPrev = creationsValuesPrevious.find((c: FormCreation) => c.id === creation.id)

      if (creation.details !== creationPrev?.details) {
        debouncedUpdateCreationDetails(creation)
      }

      if (creation.duration !== creationPrev?.duration) {
        debouncedUpdateCreationDuration(creation)
      }

      if (creation.impressions !== creationPrev?.impressions) {
        debouncedUpdateCreationEmissionsNumber(creation)
      }

      if (creation.audience !== creationPrev?.audience) {
        debouncedUpdateCreationAudienceNumber(creation)
      }

      if (creation.containsAlcohol !== creationPrev?.containsAlcohol) {
        debouncedUpdateCreationContainsAlcohol(creation)
      }
    })
  }, [creationsValues, creationsValuesPrevious])

  return (
    <CampaignFormContext.Provider
      value={{
        creationsValues,
        setCreationsValues,
        triggerCreations,
        setTriggerCreations,
        triggerFetchCampaign,
        setTriggerFetchCampaign,
      }}
    >
      {children}
    </CampaignFormContext.Provider>
  )
}

export default CampaignFormContextProvider
