import { GetCompaniesAQR } from './types'
import { client } from '../../components/ApolloProvider'
import { loader } from 'graphql.macro'

const companiesApi = {
  getCompanies: (term: string): Promise<GetCompaniesAQR> =>
    client.query({
      query: loader('./companies.gql'),
      variables: { term },
    }),
}

export default companiesApi
