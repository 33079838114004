import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import service from './service'
import { CreationsFormScheduleProps } from './types'
import DatePickerRange, { DateRange } from 'components/Form/DatePickerRange'
import { CreationSchedule } from 'types/campaign'
import FillingSpinner from 'components/FillingSpinner'
import './CreationsFormSchedule.scss'
import debounce from 'lodash/debounce'

const DEBOUNCE_UPDATE_CREATION_DELAY = 200

const CreationsFormSchedule: React.FC<CreationsFormScheduleProps> = ({
  campaignStartDate,
  campaignEndDate,
  creationId,
  creationUuid,
  readOnly,
}) => {
  const { t } = useTranslation()
  const [creationSchedules, setCreationSchedules] = useState<CreationSchedule[]>()
  const [creationSchedulesUpdateCounter, setCreationSchedulesUpdateCounter] = useState<number>(0)
  const [loadingSchedules, setLoadingSchedules] = useState<boolean>(false)

  const mappedCreationSchedules = useMemo(
    () => (creationSchedules ? service.creationSchedulesToDateRanges(creationSchedules) : []),
    [creationSchedules]
  )

  const debouncedChangeCreationSchedules = useCallback(
    debounce(service.handleChangeCreationSchedules, DEBOUNCE_UPDATE_CREATION_DELAY),
    []
  )

  useEffect(
    () => () => {
      setCreationSchedulesUpdateCounter(creationSchedulesUpdateCounter + 1)
    },
    [creationSchedules, creationSchedulesUpdateCounter]
  )

  useEffect(() => {
    if (creationId || creationUuid) {
      service.fetchCreationSchedules({ creationId, creationUuid, setCreationSchedules })
    }
  }, [creationId, creationUuid])

  return creationSchedules === undefined ? (
    <FillingSpinner />
  ) : (
    <div className='CreationsFormSchedule__calendar'>
      <DatePickerRange
        disabledDays={{
          from: new Date(Date.parse(campaignStartDate)),
          to: new Date(Date.parse(campaignEndDate)),
        }}
        values={mappedCreationSchedules}
        onChange={(dateRanges: DateRange[]) => {
          if (creationId && !readOnly) {
            debouncedChangeCreationSchedules(
              dateRanges,
              creationId,
              creationSchedules,
              setCreationSchedules,
              creationSchedulesUpdateCounter,
              setLoadingSchedules
            )
          }
        }}
        readOnly={readOnly || loadingSchedules}
        loading={loadingSchedules}
      />

      <div className='CreationsFormSchedule__list'>
        <div className='CreationsFormSchedule__list--header'>
          {t('form.campaign.creations.schedules.selectedDates')}:
        </div>

        {creationSchedules.map((cs: CreationSchedule, i: number) => (
          <div
            key={i}
            className='CreationsFormSchedule__list--item'
          >
            {service.parseSchedule(cs)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CreationsFormSchedule
