import React, { ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Select, {
  ActionMeta,
  components,
  OptionProps,
  ValueContainerProps,
  ValueType,
} from 'react-select'
import { MenuPlacement, SelectMultiCheckboxProps } from './types'
import InputTitle from 'components/Form/InputTitle'
import { InputValue } from 'components/Form/Input/'
import TsxUtils from 'utils/tsx'
import Checkbox from 'components/Form/Checkbox'
import { OPTION_ALL, SelectOption } from 'components/Form/Select'
import i18n from 'i18n'
import './SelectMultiCheckbox.scss'

// It is outside because https://react-select.com/components#defining-components
const ValueContainer = (props: ValueContainerProps<SelectOption, true>): ReactElement => {
  const [values, input] = props.children as any

  const optionsWithoutAllOption = [...props.options].filter(
    (so: SelectOption) => so.value !== OPTION_ALL.value
  )

  let text: string
  if (!values || !values.length || values.length === optionsWithoutAllOption.length) {
    text = i18n.t('form.all')
  } else {
    text = `${i18n.t('form.selected')}: ${values.length}`
  }

  return (
    <components.ValueContainer {...props}>
      {text}
      {input}
    </components.ValueContainer>
  )
}

const SelectMultiCheckbox: React.FC<SelectMultiCheckboxProps> = ({
  id,
  className,
  title,
  value,
  options,
  onChange,
  closeOnSelect = false,
  selectAllOption,
  menuPlacement = MenuPlacement.LEFT,
  disabled,
  onMenuClose,
  onMenuOpen,
  dataCy,
  help,
}) => {
  const { t } = useTranslation()

  // https://stackoverflow.com/a/61250357/7268884
  const valueRef = useRef(value)
  valueRef.current = value

  const optionsMeta = (): SelectOption[] =>
    selectAllOption && options.length > 0
      ? [
          {
            value: OPTION_ALL.value,
            label: OPTION_ALL.label,
          },
          ...options,
        ]
      : options

  const areAllOptionsSelected = () => valueRef.current.length === options.length

  const handleChange = (
    selected: ValueType<SelectOption, true>,
    actionMeta: ActionMeta<SelectOption>
  ): void => {
    const {
      action,
      option: { value },
    }: any = actionMeta

    if (action === 'select-option' && value === OPTION_ALL.value) {
      // handle click 'Select all'
      onChange(options.map(o => o.value))
      // handle click 'Deselect all' while OPTION_ALL not selected
    } else if (action === 'deselect-option' && value === OPTION_ALL.value) {
      onChange([])
    } else if (actionMeta.action === 'deselect-option' && areAllOptionsSelected()) {
      // handle click other option while all are selected
      onChange(
        options.filter((so: SelectOption) => so.value !== value).map((so: SelectOption) => so.value)
      )
    } else if (Array.isArray(selected)) {
      // handle click other options while OPTION_ALL is not selected
      onChange(selected.map(o => o.value))
    }
  }

  const Option = (props: OptionProps<SelectOption, true>): ReactElement => {
    const { value, isSelected }: any = props

    return (
      <components.Option {...props}>
        {value === OPTION_ALL.value ? (
          isSelected ? (
            t('form.deselectAll')
          ) : (
            t('form.selectAll')
          )
        ) : (
          <Checkbox checked={isSelected}>{props.label}</Checkbox>
        )}
      </components.Option>
    )
  }

  const handleIsOptionSelected = (option: SelectOption) =>
    valueRef.current.some(value => value === option.value) || areAllOptionsSelected()

  const parsedValue = (value: InputValue): SelectOption[] | undefined => {
    if (!Array.isArray(value)) {
      return undefined
    }

    return value.map((value: string) => {
      const matchingOption = options.find(option => option.value === value)

      return {
        value,
        label: matchingOption ? matchingOption.label : '-',
      }
    })
  }

  return (
    <div data-cy={dataCy}>
      {title && (
        <InputTitle
          title={title}
          help={help}
        />
      )}

      <Select
        className={
          'SelectMultiCheckbox' +
          TsxUtils.extraStyle(className, className) +
          TsxUtils.extraStyle(menuPlacement, `SelectMultiCheckbox--menu-placement-${menuPlacement}`)
        }
        classNamePrefix='SelectMultiCheckbox'
        closeMenuOnSelect={closeOnSelect}
        components={{
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
          MultiValueContainer: () => null,
          Option,
          ValueContainer,
        }}
        hideSelectedOptions={false}
        inputId={id}
        isDisabled={disabled}
        isMulti={true}
        isSearchable={true}
        noOptionsMessage={(): string => t('form.noOptions')}
        options={optionsMeta()}
        onChange={handleChange}
        isOptionSelected={handleIsOptionSelected}
        value={parsedValue(value)}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
      />
    </div>
  )
}

export default SelectMultiCheckbox
