import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { components, default as ReactSelect, IndicatorProps, ValueType } from 'react-select'
import { SelectOption, SelectOptionValue, SelectProps } from './types'
import InputError from '../InputError'
import InputTitle from '../InputTitle'
import TsxUtils from 'utils/tsx'
import './Select.scss'

export type NodeSelectOption<T = string> = { value: T; label: ReactNode }

const Select: React.FC<SelectProps> = ({
  className,
  clearable,
  disabled,
  errors,
  id,
  onChange,
  options,
  placeholder,
  title,
  value,
  dataCy,
}) => {
  const { t } = useTranslation()
  const [hasErrors, setHasErrors] = useState<boolean>(false)

  useEffect(() => {
    setHasErrors((errors && errors.length > 0) || false)
  }, [errors])

  const DropdownIndicator = (props: IndicatorProps<any, any>) =>
    disabled ? null : <components.DropdownIndicator {...props} />

  const ClearIndicator = (props: IndicatorProps<any, any>) => (
    <components.ClearIndicator {...props} />
  )

  const resolveValue = (value: SelectOptionValue): NodeSelectOption<SelectOptionValue> | null => {
    if (typeof value === 'string') {
      const selectOptions = options as SelectOption[]
      const label = selectOptions.find((option: SelectOption) => option.value === value)?.label

      if (!label) {
        return null
      }

      return {
        value,
        label,
      }
    } else if (Array.isArray(value) && value.length > 0) {
      return { value, label: value.join(', ') }
    } else {
      return null
    }
  }

  return (
    <div
      className={TsxUtils.joinClasses('Select__container', className)}
      id={id}
      data-cy={dataCy}
    >
      {title && <InputTitle title={title} />}

      <ReactSelect
        className={'Select' + TsxUtils.extraStyle(hasErrors, 'Select__error')}
        classNamePrefix='Select'
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
          ClearIndicator: value ? ClearIndicator : null,
        }}
        inputId={id}
        isDisabled={disabled}
        isMulti={false}
        isSearchable={false}
        isClearable={clearable}
        noOptionsMessage={(): string => t('form.noOptions')}
        value={resolveValue(value)}
        options={options}
        onChange={(selected: ValueType<NodeSelectOption<SelectOptionValue>, any>) =>
          void onChange((selected as { value: string })?.value ?? null, id)
        }
        placeholder={placeholder ?? t('common.selectDots')}
      />

      {hasErrors && <InputError errors={[...(errors ?? [])]} />}
    </div>
  )
}

export default Select
