import React, { useContext } from 'react'
import SelectMultiCheckbox from 'components/Form/SelectMultiCheckbox'
import { SelectOption } from 'components/Form/Select'
import FilterContext from './FilterContext'
import { FilterKey } from '../../../pages/Campaign/CampaignDetails/CampaignDetailsChart/types'
import { Medium } from '../../../types/campaign'

type Props = {
  id: string
  title: string
}

const FilterSelect: React.FC<Props> = ({ id, title }) => {
  const {
    availableFilters,
    filters,
    setFilters,
    disabled,
    displayedMedia,
    setAvailableFilters,
    creations,
  } = useContext(FilterContext)
  const onChange = (selected: string[]): void => {
    const filterKey = id as FilterKey
    if (filterKey !== 'creations' && displayedMedia && setAvailableFilters) {
      const removeDuplicates = (
        el: string | number | undefined,
        index: number,
        array: (string | number | undefined)[]
      ) => el && array.indexOf(el.toString()) === index

      switch (filterKey) {
        case 'agglomerations': {
          const matchingAgglomeration = (displayedMedia: Medium) =>
            selected.some(agglomeration => displayedMedia.agglomeration?.name === agglomeration)

          const reducedFilters: Record<FilterKey, string[]> = {
            ...availableFilters,
            cities: selected.length
              ? displayedMedia
                  .filter(matchingAgglomeration)
                  .map(el => el.city.name)
                  .filter(removeDuplicates)
              : displayedMedia.map(media => media.city.name).filter(removeDuplicates),
            buildings: selected.length
              ? displayedMedia
                  .filter(matchingAgglomeration)
                  .map(el => el.building?.name)
                  .filter(removeDuplicates)
              : displayedMedia.map(media => media.building?.name).filter(removeDuplicates),
            mediaAsIds: selected.length
              ? displayedMedia
                  .filter(matchingAgglomeration)
                  .map(el => el.asId)
                  .filter(removeDuplicates)
              : displayedMedia.map(media => media.asId).filter(removeDuplicates),
          }
          setAvailableFilters(reducedFilters)
          setFilters(
            selected.length
              ? { ...reducedFilters, [id]: selected }
              : { ...filters, [id]: selected, cities: [], buildings: [], mediaAsIds: [] }
          )
          break
        }
        case 'cities': {
          const matchingCity = (displayedMedia: Medium) =>
            selected.some(city => displayedMedia.city?.name === city)

          const reducedFilters: Record<FilterKey, string[]> = {
            ...availableFilters,
            buildings: selected.length
              ? displayedMedia
                  .filter(matchingCity)
                  .map(el => el.building?.name)
                  .filter(removeDuplicates)
              : displayedMedia.map(media => media.building?.name).filter(removeDuplicates),
            mediaAsIds: selected.length
              ? displayedMedia
                  .filter(matchingCity)
                  .map(el => el.asId)
                  .filter(removeDuplicates)
              : displayedMedia.map(media => media.asId).filter(removeDuplicates),
          }
          setAvailableFilters(reducedFilters)
          setFilters(
            selected.length
              ? { ...reducedFilters, [id]: selected }
              : { ...filters, [id]: selected, buildings: [], mediaAsIds: [] }
          )
          break
        }

        case 'buildings': {
          const matchingBuilding = (displayedMedia: Medium) =>
            selected.some(building => displayedMedia.building?.name === building)

          const reducedFilters: Record<FilterKey, string[]> = {
            ...availableFilters,
            mediaAsIds: selected.length
              ? displayedMedia
                  .filter(matchingBuilding)
                  .map(el => el.asId)
                  .filter(removeDuplicates)
              : displayedMedia.map(media => media.asId).filter(removeDuplicates),
          }
          setAvailableFilters(reducedFilters)
          setFilters(
            selected.length
              ? { ...reducedFilters, [id]: selected }
              : { ...filters, [id]: selected, mediaAsIds: [] }
          )
          break
        }

        case 'mediaAsIds': {
          const reducedFilters: Record<FilterKey, string[]> = {
            ...availableFilters,
            mediaAsIds: displayedMedia.map(el => el.asId).filter(removeDuplicates),
          }
          setFilters(
            selected.length
              ? { ...reducedFilters, [id]: selected }
              : { ...filters, [id]: selected, mediaAsIds: [] }
          )
          break
        }
      }
    } else {
      setFilters({ ...filters, [id]: selected })
    }
  }
  const options: SelectOption[] =
    id === 'creations'
      ? availableFilters[id].map((creationId: string) => {
          const creation = creations?.filter(creation => creation.id === creationId)[0]
          return {
            value: creationId,
            label: creation?.file.filename,
          }
        })
      : availableFilters[id].map(valueToSelectOption)

  return (
    <SelectMultiCheckbox
      id={id}
      className='FilterSelect'
      closeOnSelect={false}
      disabled={disabled}
      onChange={onChange}
      options={options}
      selectAllOption
      title={title}
      value={filters[id]}
    />
  )
}

const valueToSelectOption = (value: string): SelectOption => ({ value, label: value })

export default FilterSelect
