import Button, { ButtonTheme } from 'components/Form/Button'
import FormColumn from 'components/Form/FormColumn'
import FormRow from 'components/Form/FormRow'
import Input, { InputType } from 'components/Form/Input'
import Card, { CardStatus } from 'components/Layout/Card'
import PageHeader from 'components/Layout/PageHeader'
import { AppContext } from 'contexts/AppContext'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Routes } from 'routes'
import service from './service'
import { PasswordData, passwordDataDefault, ProfileData, profileDataDefault } from './types'
import './UserSettings.scss'

const UserSettings: React.FC = () => {
  const { t } = useTranslation()
  const { userData, addNotification, updateUser } = useContext(AppContext)
  const [profileData, setProfileData] = useState<ProfileData>(profileDataDefault)
  const [passwordData, setPasswordData] = useState<PasswordData>(passwordDataDefault)
  const [profileDataLoading, setProfileDataLoading] = useState<boolean>(false)
  const [passwordDataLoading, setPasswordDataLoading] = useState<boolean>(false)

  useEffect(() => {
    if (userData.user) {
      const { id, firstName, lastName, email } = userData.user
      setProfileData({ id, firstName, lastName, email })
    }
  }, [userData.user])

  return (
    <>
      <PageHeader>{t('user.settings.header')}</PageHeader>

      <Card
        title={t('user.settings.profile')}
        status={profileDataLoading ? CardStatus.LOADING : CardStatus.NONE}
      >
        <div className='UserSettings__form UserSettings--profile'>
          <FormRow className='UserSettings__form--row'>
            <FormColumn>
              <Input
                id='firstName'
                title={t('common.firstName')}
                placeholder={t('common.enterFirstName')}
                value={profileData.firstName}
                onChange={(value: string) => {
                  setProfileData({ ...profileData, firstName: value })
                }}
                disabled={profileDataLoading}
              />
            </FormColumn>
          </FormRow>

          <FormRow className='UserSettings__form--row'>
            <FormColumn>
              <Input
                id='lastName'
                title={t('common.lastName')}
                placeholder={t('common.enterLastName')}
                value={profileData.lastName}
                onChange={(value: string) => {
                  setProfileData({ ...profileData, lastName: value })
                }}
                disabled={profileDataLoading}
              />
            </FormColumn>
          </FormRow>

          <FormRow className='UserSettings__form--row'>
            <FormColumn>
              <Input
                id='e-mail'
                title={t('common.email')}
                value={profileData.email}
                onChange={() => void {}}
                disabled
              />
            </FormColumn>
          </FormRow>

          <div className='UserSettings__form--buttons'>
            <Button
              onClick={() =>
                void service.handleUpdateProfile(
                  profileData,
                  setProfileDataLoading,
                  updateUser,
                  addNotification,
                  t
                )
              }
              disabled={profileDataLoading}
            >
              {t('common.save')}
            </Button>

            <Button
              theme={ButtonTheme.BLUE_OUTLINE}
              toUrl={Routes.HOME}
            >
              {t('common.cancel')}
            </Button>
          </div>
        </div>
      </Card>

      <Card
        title={t('user.settings.changePassword')}
        status={passwordDataLoading ? CardStatus.LOADING : CardStatus.NONE}
      >
        <div className='UserSettings__form UserSettings--password'>
          <FormRow className='UserSettings__form--row'>
            <FormColumn>
              <Input
                id='currentPassword'
                title={t('user.settings.previousPassword')}
                placeholder={t('user.settings.enterPreviousPassword')}
                type={InputType.PASSWORD}
                value={passwordData.currentPassword}
                onChange={(value: string) => {
                  setPasswordData({ ...passwordData, currentPassword: value })
                }}
                disabled={passwordDataLoading}
              />
            </FormColumn>
          </FormRow>

          <FormRow className='UserSettings__form--row'>
            <FormColumn>
              <Input
                id='password'
                title={t('user.settings.newPassword')}
                placeholder={t('user.settings.enterNewPassword')}
                type={InputType.PASSWORD}
                value={passwordData.password}
                onChange={(value: string) => {
                  setPasswordData({ ...passwordData, password: value })
                }}
                disabled={passwordDataLoading}
              />
            </FormColumn>
          </FormRow>

          <FormRow className='UserSettings__form--row'>
            <FormColumn>
              <Input
                id='passwordConfirmation'
                title={t('user.settings.repeatNewPassword')}
                placeholder={t('user.settings.reEnterNewPassword')}
                type={InputType.PASSWORD}
                value={passwordData.passwordConfirmation}
                onChange={(value: string) => {
                  setPasswordData({ ...passwordData, passwordConfirmation: value })
                }}
                disabled={passwordDataLoading}
              />
            </FormColumn>
          </FormRow>

          <div className='UserSettings__form--buttons'>
            <Button
              onClick={() =>
                void service.handleUpdatePassword(
                  profileData.id,
                  passwordData,
                  setPasswordDataLoading,
                  addNotification,
                  t
                )
              }
              disabled={passwordDataLoading}
            >
              {t('common.save')}
            </Button>

            <Button
              theme={ButtonTheme.BLUE_OUTLINE}
              toUrl={Routes.HOME}
            >
              {t('common.cancel')}
            </Button>
          </div>
        </div>
      </Card>
    </>
  )
}

export default UserSettings
