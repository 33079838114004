import { t } from 'i18next'
import DateUtils from './date'
import { DATE_FORMAT, DATE_HOUR_FORMAT } from '../constant'
import { FormCreation } from '../contexts/CampaignFormContext'

export default class CreationHelper {
  public static getCreationDateText = ({
    archivedAt,
    createdAt,
    schedules,
  }: Pick<FormCreation, 'archivedAt' | 'createdAt' | 'schedules'>): string => {
    if (archivedAt) {
      return `${t('common.archivedAt')}: ${DateUtils.parseAndFormat(archivedAt, DATE_HOUR_FORMAT)}`
    }

    if (schedules && schedules.length > 0) {
      return `${t('common.scheduleDates')}: ${DateUtils.parseAndFormat(
        schedules[0].startDate,
        DATE_FORMAT
      )} - ${DateUtils.parseAndFormat(schedules[0].endDate, DATE_FORMAT)}`
    }

    return `${t('common.createdAt')}: ${DateUtils.parseAndFormat(createdAt, DATE_HOUR_FORMAT)}`
  }
}
