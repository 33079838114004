import i18n from 'i18n'
import { Errors } from '../InputError'
import { OnInputChangeFn } from '../Input'
import { NodeSelectOption } from './Select'

export interface SelectProps {
  className?: string
  clearable?: boolean
  disabled?: boolean
  errors?: Errors
  id: string
  onChange: OnInputChangeFn
  options: NodeSelectOption<SelectOptionValue>[]
  placeholder?: string
  title?: string
  value: SelectOptionValue
  dataCy?: string
}

export type SelectOption<T = string> = {
  value: T
  label: string
  isDisabled?: boolean
}

export type SelectOptionValue = string | number[] | null

export const OPTION_ALL: SelectOption = {
  value: 'all',
  label: i18n.t('form.selectAll'),
}
