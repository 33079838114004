import React, { CSSProperties } from 'react'
import ProgressBarIndicator from './ProgressBarIndicator'
import './ProgressBar.scss'

interface ProgressBarProps {
  percent: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percent }) => {
  const progressComputedStyle: CSSProperties = {
    width: `${percent}%`,
  }

  const indicatorComputedStyle: CSSProperties = {
    left: `calc(${percent}% - 11px)`,
  }

  return (
    <div className='ProgressBar__container'>
      <div className='ProgressBar'>
        <div
          className='ProgressBar__progress'
          style={progressComputedStyle}
        />
        <ProgressBarIndicator computedStyle={indicatorComputedStyle} />
      </div>
    </div>
  )
}

export default ProgressBar
