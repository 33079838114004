import { User } from '../../../../types/user'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserTableSliceModel } from '../models/user-table-slice.model'

const initialState: UserTableSliceModel = {
  loading: false,
  loaded: false,
  users: [],
  pageNumber: 1,
  totalCount: 0,
  deactivateModal: {
    loading: false,
    user: null,
  },
  filters: {
    show: false,
    loading: false,
    searchNameInput: '',
    allAgencies: [],
    allRoles: [],
    allStatuses: [],
    selectedAgencies: [],
    selectedRoles: [],
    selectedStatuses: [],
  },
}

const userTableSlice = createSlice({
  name: 'userTable',
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<User[]>) {
      state.users = action.payload
    },
    setPageNumber(state, action: PayloadAction<number>) {
      state.pageNumber = action.payload
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setLoaded(state, action: PayloadAction<boolean>) {
      state.loaded = action.payload
    },
    setModalLoading(state, action: PayloadAction<boolean>) {
      state.deactivateModal.loading = action.payload
    },
    setModalUser(state, action: PayloadAction<User | null>) {
      state.deactivateModal.user = action.payload
    },
    setSearchNameInput(state, action: PayloadAction<string>) {
      state.filters.searchNameInput = action.payload
    },
    setFiltersShow(state, action: PayloadAction<boolean>) {
      state.filters.show = action.payload
    },
    setFiltersLoading(state, action: PayloadAction<boolean>) {
      state.filters.loading = action.payload
    },
    setAllData(
      state,
      action: PayloadAction<{ agencies: string[]; roles: string[]; statuses: string[] }>
    ) {
      state.filters.allAgencies = action.payload.agencies
      state.filters.allRoles = action.payload.roles
      state.filters.allStatuses = action.payload.statuses
    },
    setSelectedFilters(
      state,
      action: PayloadAction<{ agencies?: string[]; roles?: string[]; statuses?: string[] }>
    ) {
      if (action.payload.agencies) {
        state.filters.selectedAgencies = action.payload.agencies
      }
      if (action.payload.roles) {
        state.filters.selectedRoles = action.payload.roles
      }
      if (action.payload.statuses) {
        state.filters.selectedStatuses = action.payload.statuses
      }
    },
    resetFilters(state) {
      state.filters.searchNameInput = ''
      state.filters.selectedAgencies = []
      state.filters.selectedRoles = []
      state.filters.selectedStatuses = []
    },
  },
})

export const userTableActions = userTableSlice.actions

export default userTableSlice
