import { User } from 'types/user'

export interface UseUserProps {
  userData: UserData
  authenticate: ({ authenticationToken, refreshToken, user }: AuthenticateInput) => void
  tryToGetNewToken: () => Promise<string | boolean>
  resetUser: () => void
  updateUser: () => void
}

export interface AuthenticateInput {
  authenticationToken: string
  refreshToken: string
  user: User
}

export interface CookiesData {
  authenticationToken?: string
  refreshToken?: string
  userId?: User['id']
}

export interface UserData {
  user: User | null
  signedIn: boolean
  fetchComplete: boolean
  withoutAccess: boolean
  withAllocatedTime: boolean
}

export const userDataDefault: UserData = {
  user: null,
  signedIn: false,
  fetchComplete: false,
  withoutAccess: true,
  withAllocatedTime: false,
}

export const useUserPropsDefault: UseUserProps = {
  userData: userDataDefault,
  authenticate: () => void {},
  tryToGetNewToken: () => Promise.resolve(false),
  resetUser: () => void {},
  updateUser: () => void {},
}
