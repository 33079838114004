import React, { ReactNode, useState } from 'react'
import TsxUtils from 'utils/tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import './TooltipHelp.scss'

interface TooltipHelpProps {
  className?: string
  containerClassName?: string
  children: ReactNode
}

const TooltipHelp: React.FC<TooltipHelpProps> = ({ className, containerClassName, children }) => {
  const [hover, setHover] = useState<boolean>(false)

  return (
    <div
      className={'TooltipHelp' + TsxUtils.extraStyle(containerClassName, containerClassName)}
      onMouseEnter={() => void setHover(true)}
      onMouseLeave={() => void setHover(false)}
    >
      <FontAwesomeIcon
        className='TooltipHelp__icon'
        icon={faQuestion}
      />
      {hover && (
        <div className={'TooltipHelp__text' + TsxUtils.extraStyle(className, className)}>
          {children}
        </div>
      )}
    </div>
  )
}

export default TooltipHelp
