export default class EnumUtils {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static getKeyByValue = (EnumType: any, enumValue: any): string | undefined =>
    Object.keys(EnumType).find((key: keyof typeof EnumType) => EnumType[key] === enumValue)

  static getKeyByValueT = <T extends { [index: string]: string }>(
    myEnum: T,
    enumValue: string
  ): keyof T | undefined => {
    const keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue)
    return keys.length > 0 ? keys[0] : undefined
  }
}
