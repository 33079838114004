import { gql } from '@apollo/client'
import { USER_FIELDS } from '../fragments'

export const GET_USERS = gql`
  ${USER_FIELDS}
  query users(
    $sortColumn: UsersSortColumnEnum
    $sortDirection: SortDirectionEnum
    $first: Int
    $last: Int
    $filters: UserFilters
    $term: String
  ) {
    users(
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      first: $first
      last: $last
      filters: $filters
      term: $term
    ) {
      totalCount
      nodes {
        ...UserFields
      }
    }
  }
`
