import { gql } from '@apollo/client'
import { MUTATION_STATE } from '../fragments'

const PAYLOAD_TYPE = 'DestroyRolePayload'

export const REMOVE_ROLE = gql`
    ${MUTATION_STATE(PAYLOAD_TYPE)}
    mutation destroyRole(
        $id: ID!
    ) {
        destroyRole(
            input: {
                id: $id
            }
        ) {
            ...MutationState${PAYLOAD_TYPE}Fields
        }
    }
`
