import React from 'react'
import { SummaryProps } from './types'
import FillingSpinner, { FillingSpinnerType } from 'components/FillingSpinner'
import TsxUtils from 'utils/tsx'
import VariableUtils from 'utils/variable'
import './Summary.scss'

const Summary: React.FC<SummaryProps> = ({
  title,
  quantity,
  isLoading,
  withCircle,
  children,
  dataCy,
}) => (
  <>
    <div className='Summary__header'>
      <div className='Summary__header--title'>{title}</div>
    </div>

    <div className='Summary'>
      <div
        data-cy={dataCy}
        className={
          'Summary__quantity' + TsxUtils.extraStyle(withCircle, 'Summary__quantity--with-circle')
        }
      >
        {isLoading ? (
          <FillingSpinner type={FillingSpinnerType.STANDARD} />
        ) : (
          <>
            {quantity !== undefined && formatNumber(quantity)}
            {children}
          </>
        )}
      </div>
    </div>
  </>
)

const formatNumber = (value: number | null) => (value ? VariableUtils.formatNumber(value) : 0)

export default Summary
