import api from 'api'
import { NotifierType } from 'components/Notifier'
import { AppContext } from 'contexts/AppContext'
import { CampaignReservation } from 'pages/Campaign/CampaignForm/Reservation'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'routes'
import { Campaign } from 'types/campaign'
import { UseCampaignActionProps } from './types'
import { useSelector } from 'react-redux'
import { RootState, store } from '../../store'
import { campaignActions } from '../../pages/Campaign/store/campaign-slice'

const useCampaignAction = (): UseCampaignActionProps => {
  const { t } = useTranslation()
  const { addNotification } = useContext(AppContext)
  const navigate = useNavigate()
  const { detailsValues } = useSelector((state: RootState) => state.campaign.form)
  const createCampaignCopy = (campaignId: Campaign['id']): Promise<void> =>
    api.campaign
      .copyCampaign({ id: campaignId })
      .then(res => {
        if (!res.data) {
          return
        }

        const { success, campaign } = res.data.copyCampaign

        if (!success) {
          return
        }

        addNotification(NotifierType.SUCCESS, t('form.campaign.copy.success'))
        navigate(Routes.CAMPAIGNS.EDIT(campaign.id))
      })
      .catch(() => void {})
  const acceptCampaign = (campaignId: Campaign['id']): Promise<void> =>
    api.campaign
      .acceptCampaign({ id: campaignId })
      .then(res => {
        if (!res.data) {
          return
        }
        const { success } = res.data.acceptCampaign

        if (success) {
          addNotification(NotifierType.SUCCESS, t('form.campaign.approval.success'))
          navigate(Routes.CAMPAIGNS.ALL)
        }
      })
      .catch(() => void {})

  const rejectCampaign = (campaignId: Campaign['id']): Promise<void> =>
    api.campaign
      .rejectCampaign({ id: campaignId })
      .then(res => {
        if (!res.data) {
          return
        }
        const { success } = res.data.rejectCampaign

        if (success) {
          addNotification(NotifierType.SUCCESS, t('form.campaign.reject.success'))
          navigate(Routes.CAMPAIGNS.ALL)
        }
      })
      .catch(() => void {})

  const publishCampaign = (campaignId: Campaign['id']): Promise<void> =>
    api.campaign
      .publishCampaign({ campaignId })
      .then(res => {
        if (res.data) {
          const { success } = res.data.sendUpdatedCampaignToBrain

          if (success) {
            addNotification(NotifierType.SUCCESS, t('notification.publishChangesSuccess'))
            navigate(Routes.CAMPAIGNS.ALL)
          }
        }
      })
      .catch(() => void {})

  const reserveCampaign = (campaignReservation: CampaignReservation): Promise<void> =>
    api.campaign
      .reserveCampaign(campaignReservation)
      .then(res => {
        if (res.data) {
          const { success } = res.data.reserveCampaign

          if (success) {
            addNotification(NotifierType.SUCCESS, t('notification.reserveCampaignSuccess'))
            navigate(Routes.CAMPAIGNS.ALL)
          }
        }
      })
      .catch(() => void {})

  const updateCampaignReservation = (campaignReservation: CampaignReservation): Promise<void> =>
    api.campaign
      .updateCampaignReservation(campaignReservation)
      .then(res => {
        if (res.data) {
          const { success } = res.data.updateCampaignReservation

          if (success) {
            addNotification(
              NotifierType.SUCCESS,
              t('notification.updateCampaignReservationSuccess')
            )
            navigate(Routes.CAMPAIGNS.ALL)
          }
        }
      })
      .catch(() => void {})

  const confirmCampaignReservation = (id: Campaign['id']): Promise<void> =>
    api.campaign
      .confirmCampaignReservation({ id })
      .then(res => {
        if (res.data) {
          const { success } = res.data.confirmCampaignReservation

          if (success) {
            addNotification(
              NotifierType.SUCCESS,
              t('notification.confirmCampaignReservationSuccess')
            )
          }
        }
      })
      .catch(() => void {})

  const cancelCampaignReservation = (id: Campaign['id']): Promise<void> =>
    api.campaign
      .cancelCampaignReservation({ id })
      .then(res => {
        if (res.data) {
          const { success } = res.data.cancelCampaignReservation

          if (success) {
            addNotification(
              NotifierType.SUCCESS,
              t('notification.cancelCampaignReservationSuccess')
            )
          }
        }
      })
      .catch(() => void {})

  const sendCampaignToAcceptance = (id: Campaign['id']): Promise<void> =>
    api.campaign
      .sendCampaignToAcceptance({ id })
      .then(res => {
        if (!res.data) {
          return
        }
        const { success } = res.data.sendCampaignToAcceptance
        const { acceptanceStatus } = res.data.sendCampaignToAcceptance

        if (success) {
          store.dispatch(
            campaignActions.updateDetailsValues({ ...detailsValues, acceptanceStatus })
          )
          addNotification(NotifierType.SUCCESS, t('notification.sendCampaignToAcceptanceSuccess'))
          navigate(Routes.CAMPAIGNS.ONE(id))
        }
      })
      .catch(() => void {})

  return {
    createCampaignCopy,
    acceptCampaign,
    publishCampaign,
    reserveCampaign,
    updateCampaignReservation,
    confirmCampaignReservation,
    cancelCampaignReservation,
    sendCampaignToAcceptance,
    rejectCampaign,
  }
}

export default useCampaignAction
