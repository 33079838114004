import { gql } from '@apollo/client'
import { CREATION_FIELDS } from '../fragments'

const CAMPAIGN_FOR_EMISSION_SCHEDULE_FIELDS = gql`
  ${CREATION_FIELDS}
  fragment CampaignForEmissionScheduleFields on Campaign {
    startDate
    endDate
    status
    impressions
    creations {
      ...CreationFields
    }
    mediumFormat
    target
    audience
  }
`

export const GET_CAMPAIGN_FOR_EMISSION_SCHEDULE = gql`
  ${CAMPAIGN_FOR_EMISSION_SCHEDULE_FIELDS}
  query campaignForEmissionSchedule($id: ID!) {
    node(id: $id) {
      ... on Campaign {
        ...CampaignForEmissionScheduleFields
      }
    }
  }
`

export const GET_CAMPAIGN_FOR_EMISSION_SCHEDULE_PUBLIC = gql`
  ${CAMPAIGN_FOR_EMISSION_SCHEDULE_FIELDS}
  query campaignForEmissionSchedulePublic($campaignUuid: String!) {
    campaignReportPublic(campaignUuid: $campaignUuid) {
      campaign {
        ...CampaignForEmissionScheduleFields
      }
    }
  }
`
