import React, { useContext } from 'react'
import TopBarUser from './TopBarUser'
import TopBarLanguage from './TopBarLanguage'
import logo from 'images/logo-ams.jpg'
import Link from 'components/Link'
import { Routes } from 'routes'
import { AppContext } from 'contexts/AppContext'
import './TopBar.scss'

const TopBar: React.FC = () => {
  const {
    userData: { user },
  } = useContext(AppContext)

  return (
    <div className='TopBar'>
      <Link
        className='TopBar__logo'
        toUrl={user ? Routes.HOME : Routes.SIGN.SIGN_IN}
      >
        <img
          className='TopBar__logo--img'
          src={logo}
          alt='logo'
        />
      </Link>

      <>
        <TopBarLanguage />
        {user && <TopBarUser />}
      </>
    </div>
  )
}
export default TopBar
