import React from 'react'
import { IconBoxProps, IconBoxTheme } from './types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TsxUtils from 'utils/tsx'
import './IconBox.scss'

const IconBox: React.FC<IconBoxProps> = ({ className, icon, theme = IconBoxTheme.GREEN }) => (
  <div className={'IconBox' + ` IconBox--${theme}` + TsxUtils.extraStyle(className, className)}>
    <FontAwesomeIcon icon={icon} />
  </div>
)

export default IconBox
