import { StyleSheet } from '@react-pdf/renderer'
import Colors from 'styles/colors.module.scss'

const BORDER_COLOR = Colors.gray2
const TITLE_COLOR = Colors.gray2
const HEADER_COLOR = Colors.gray1
const CELL_PADDING = 5

export const defaultStyles = StyleSheet.create({
  table: {
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderColor: BORDER_COLOR,
    marginBottom: 25,
  },

  tableTitle: {
    backgroundColor: TITLE_COLOR,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: BORDER_COLOR,
    padding: CELL_PADDING,
    fontWeight: 'bold',
    fontSize: 12,
  },

  tableRow: {
    flexDirection: 'row',
  },

  tableHeader: {
    backgroundColor: HEADER_COLOR,
    fontWeight: 'bold',
  },

  tableData: {
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: BORDER_COLOR,
  },

  tableCell: {
    padding: CELL_PADDING,
    fontSize: 10,
  },
})
