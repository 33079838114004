import { gql } from '@apollo/client'

export const INITIATE_PASSWORD_RESET = gql`
  mutation initiatePasswordReset($id: ID!) {
    initiatePasswordReset(input: { id: $id }) {
      resetUrl
      success
      errors
    }
  }
`
