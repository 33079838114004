import React, { useCallback, useContext, useEffect, useState } from 'react'
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import FillingSpinner from 'components/FillingSpinner'
import { ModalUploadFile, ModalUploadProps } from './types'
import TsxUtils from 'utils/tsx'
import { AppContext } from 'contexts/AppContext'
import { NotifierType } from 'components/Notifier'
import { CREATIONS_MAX_FILES, CREATIONS_MAX_FILE_SIZE } from 'constant'
import './ModalUpload.scss'

const ModalUpload: React.FC<ModalUploadProps> = ({ className, onDrop, accept }) => {
  const { t } = useTranslation()
  const { addNotification } = useContext(AppContext)
  const [loadingLocal, setLoadingLocal] = useState<boolean>(false)
  const [files, setFiles] = useState<ModalUploadFile[]>()

  const setLoading = (isLoading: boolean): void => {
    setLoadingLocal(isLoading)
  }

  const onDropLocal: DropzoneOptions['onDrop'] = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0)
        addNotification(NotifierType.ERROR, t('notification.tooManyCreations'))

      if (acceptedFiles.length > 0)
        setFiles(
          acceptedFiles.map(
            (f: File): ModalUploadFile =>
              Object.assign(f, {
                preview: URL.createObjectURL(f),
              })
          )
        )
    },
    []
  )

  useEffect(() => {
    if (files) {
      onDrop(files, setLoading)

      return () => {
        files.forEach((f: ModalUploadFile) => {
          URL.revokeObjectURL(f.preview)
        })
      }
    }
  }, [files])

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: CREATIONS_MAX_FILES,
    maxSize: CREATIONS_MAX_FILE_SIZE,
    onDrop: onDropLocal,
    accept,
  })

  const DropContainer = (
    <div
      {...getRootProps({ className: 'ModalUpload' + TsxUtils.extraStyle(className, className) })}
    >
      <div className='ModalUpload__body'>
        <div className='ModalUpload__body--cloud' />

        <div className='ModalUpload__body--drop'>{t('form.campaign.creations.dropHere')}</div>

        <div className='ModalUpload__body--frame'>
          <span className='ModalUpload__frame--or'>{t('common.or') + ' '}</span>
          <span className='ModalUpload__frame--browse'>{t('common.browse') + ' '}</span>
          <span className='ModalUpload__frame--select'>
            {t('form.campaign.creations.toSelectFile')}
          </span>
        </div>
      </div>

      <input {...getInputProps()} />
    </div>
  )

  const LoadingContainer = (
    <div className='ModalUpload__loading-container'>
      <FillingSpinner />
    </div>
  )

  return loadingLocal ? LoadingContainer : DropContainer
}

export default ModalUpload
