import React, { ReactChild, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/Layout/PageHeader'
import Card from 'components/Layout/Card'
import FillingSpinner, { FillingSpinnerType } from 'components/FillingSpinner'
import service from './service'
import { Campaign, OnCheckboxUpdateFn } from './types'
import Button from 'components/Form/Button'
import { Approval, ApprovalCategory, Creation, MediaFormat } from 'types/campaign'
import Checkbox from 'components/Form/Checkbox'
import EnumUtils from 'utils/enum'
import { Routes } from 'routes'
import { AppContext } from 'contexts/AppContext'
import { CardStatus } from 'components/Layout/Card/types'
import { ReactRouterParams } from 'types/various'
import './CampaignAcceptanceFields.scss'

const CampaignAcceptanceFields: React.FC = () => {
  const { t } = useTranslation()
  const { addNotification } = useContext(AppContext)
  const { id: urlId } = useParams<ReactRouterParams>()
  const [campaign, setCampaign] = useState<Campaign>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (urlId) service.getCampaign(urlId, setCampaign)
  }, [urlId])

  const onCheckboxUpdate: OnCheckboxUpdateFn = (checkboxParent, id, condition) => {
    const parent = campaign![checkboxParent].map((obj: any) => ({
      ...obj,
      approved: obj.id === id ? condition : obj.approved,
    }))

    setCampaign({ ...campaign!, [checkboxParent]: [...parent] })
  }

  const creationsCheckboxes = (creations: Creation[]): JSX.Element[] =>
    creations
      .sort((a, b) => a.mediaFormat.localeCompare(b.mediaFormat))
      .map(c => (
        <div
          className='CampaignAcceptanceFields__checkbox'
          key={c.id}
        >
          <div className='CampaignAcceptanceFields__checkbox--title'>
            {EnumUtils.getKeyByValue(MediaFormat, c.mediaFormat)}:
          </div>

          <Checkbox
            id={c.id}
            checked={c.approved}
            onChange={(e): void =>
              service.onChangeCreation(
                e.target.id,
                !c.approved,
                setLoading,
                onCheckboxUpdate,
                addNotification,
                t
              )
            }
          >
            {c.approved
              ? t('campaignDetails.info.accepted')
              : t('campaignDetails.info.notAccepted')}
          </Checkbox>
        </div>
      ))

  const approvalTitle = (approvalCategory: ApprovalCategory): string => {
    switch (approvalCategory) {
      case ApprovalCategory.AGREEMENT_SIGNED:
        return t('common.agreement')
      case ApprovalCategory.CAMPAIGN_ACCEPTED:
        return t('common.campaign')
    }
  }

  const approvalsCheckboxes = (approvals: Approval[]): JSX.Element[] =>
    approvals.map(a => (
      <div
        className='CampaignAcceptanceFields__checkbox'
        key={a.id}
      >
        <div className='CampaignAcceptanceFields__checkbox--title'>{approvalTitle(a.category)}</div>

        <Checkbox
          id={a.id}
          checked={a.approved}
          onChange={(e): void =>
            service.onChangeApproval(
              e.target.id,
              !a.approved,
              setLoading,
              onCheckboxUpdate,
              addNotification,
              t
            )
          }
        >
          {a.approved ? t('campaignDetails.info.accepted') : t('campaignDetails.info.notAccepted')}
        </Checkbox>
      </div>
    ))

  const container = (campaign: Campaign): ReactChild => (
    <>
      <PageHeader>{t('campaignDetails.info.acceptanceFields')}</PageHeader>

      <Card
        title={`${t('common.campaign')}: ${campaign.name}`}
        status={loading ? CardStatus.LOADING : CardStatus.NONE}
      >
        <>
          <div className='CampaignAcceptanceFields__checkboxes'>
            {campaign.creations.length > 0
              ? creationsCheckboxes(campaign.creations)
              : t('campaignDetails.info.noCreations')}
          </div>

          <div className='CampaignAcceptanceFields__checkboxes'>
            {campaign.approvals.length > 0
              ? approvalsCheckboxes(campaign.approvals)
              : t('campaignDetails.info.noApprovals')}
          </div>
        </>
      </Card>

      <Button
        className='CampaignAcceptanceFields__button'
        toUrl={Routes.CAMPAIGNS.ONE(campaign.id)}
      >
        {t('common.back')}
      </Button>
    </>
  )

  return (
    <>
      {campaign ? (
        container(campaign)
      ) : (
        <FillingSpinner type={FillingSpinnerType.FULL_LAYOUT_CONTENT} />
      )}
    </>
  )
}

export default CampaignAcceptanceFields
