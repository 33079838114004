import React, { useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import CREATE_MEDIA_TEMPLATE from 'api/media/createMediaTemplate'
import Button, { ButtonTheme } from 'components/Form/Button'
import Modal from 'components/Modal'
import { useTranslation } from 'react-i18next'
import Input from 'components/Form/Input'
import { CreateMediaTemplateVariables } from 'api/media/types'
import { AppContext } from '../../../../../contexts/AppContext'
import { NotifierType } from '../../../../../components/Notifier'

type Props = {
  media: string[]
  close: () => void
  goBack: () => void
}

const CreateTemplateModal: React.FC<Props> = ({ media, goBack, close }) => {
  const [templateName, setTemplateName] = useState<string>('')
  const { t } = useTranslation()
  const { addNotification } = useContext(AppContext)

  const [saveTemplate] = useMutation<any, CreateMediaTemplateVariables>(CREATE_MEDIA_TEMPLATE)

  const save = () => {
    saveTemplate({ variables: { input: { name: templateName, mediaIds: media } } })
      .then(res => {
        if (res.data.createMediaTemplate.success) {
          addNotification(NotifierType.SUCCESS, t('mediaSelection.template.create.success'))
        }
        close()
      })
      .catch(() => void {})
  }

  const Footer = (
    <div className='MediaSelectionModal__template-footer'>
      <Button onClick={save}>{t('common.save')}</Button>

      <Button
        theme={ButtonTheme.BLUE_OUTLINE}
        onClick={goBack}
      >
        {t('common.back')}
      </Button>
    </div>
  )

  return (
    <Modal
      isOpen={true}
      onClose={close}
      footer={Footer}
    >
      <div className='TemplateSection'>
        <Input
          id={'templateName'}
          title={t('mediaSelection.template.name')}
          value={templateName}
          onChange={setTemplateName}
        />
      </div>
    </Modal>
  )
}

export default CreateTemplateModal
