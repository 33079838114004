import { gql } from '@apollo/client'
import { ALL_ROLES_FIELDS } from '../fragments'

export const GET_ALL_ROLES = gql`
  ${ALL_ROLES_FIELDS}
  query rolesForFilters {
    rolesForFilters {
      ...AllRolesFields
    }
  }
`
