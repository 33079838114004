import { Agency } from '../../types/agency'

export class AllocatedTimeHelper {
  /**
   * This is a temporary solution to get the withAllocatedTime value from the user's agency.
   * Most probably this will be changed in the future.
   * Agreed on 11.06.2024
   */
  public static getAllocatedTime = (agencies: Agency[]): boolean => {
    if (!agencies || !agencies.length) {
      return false
    }

    // we take the status from the first agency, and we assume that the user has only one agency
    return agencies[0].withAllocatedTime
  }
}
