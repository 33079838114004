import Card from 'components/Layout/Card'
import React, { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Summary from '../Summary'
import { AlertsCardProps } from './types'
import TsxUtils from 'utils/tsx'
import IconAlert from 'components/Icon/IconAlert'
import Button, { ButtonSize, ButtonTheme } from 'components/Form/Button'
import Modal from 'components/Modal'
import './AlertsCard.scss'
import { Alert, AlertType } from '../../../../types/campaign'

const AlertsCard: React.FC<AlertsCardProps> = ({ alerts, className }) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  if (!alerts.some(alert => alert)) {
    alerts = []
  }

  const hasAlertType = alerts.some(alert => alert && alert.type === AlertType.alert)
  const infoAlerts: Alert[] = []
  const warnAlerts: Alert[] = []

  alerts.forEach(alert => {
    if (alert && alert.type === AlertType.alert) {
      warnAlerts.push(alert)
    } else {
      infoAlerts.push(alert)
    }
  })

  const infoMessages: ReactNode = infoAlerts.length ? (
    <>
      <div className={'Alerts__header'}>
        <IconAlert alertType={AlertType.warning} />
        <h1>{t('common.info')}</h1>
      </div>
      {
        <>
          {infoAlerts.map((alert: Alert, index: number) => (
            <div
              key={alert.message}
              className='Alerts__alert'
            >
              <span className='Alerts__alert--index'>{index + 1 + '. '}</span>
              {alert.message}
            </div>
          ))}
        </>
      }
    </>
  ) : (
    <></>
  )

  const warnMessages: ReactNode = warnAlerts.length ? (
    <>
      <div className={'Alerts__header'}>
        <IconAlert alertType={AlertType.alert} />
        <h1>{t('common.alert')}</h1>
      </div>
      {
        <>
          {warnAlerts.map((alert: Alert, index: number) => (
            <div
              key={alert.message}
              className='Alerts__alert'
            >
              <span className='Alerts__alert--index'>{index + 1 + '. '}</span>
              {alert.message}
            </div>
          ))}
        </>
      }
    </>
  ) : (
    <></>
  )
  return (
    <>
      <Card
        className={
          `AlertsCard${hasAlertType ? '--warn' : '--info'}` +
          TsxUtils.extraStyle(className, className)
        }
      >
        <Summary
          title={<IconAlert alertType={hasAlertType ? AlertType.alert : AlertType.warning} />}
        >
          <div className='AlertsCard__body'>
            <span>
              {hasAlertType ? t('common.alertsNumber') : t('common.informationsNumber')}:&nbsp;
            </span>
            <span className='AlertsCard__body--number'>{alerts.length}</span>
          </div>

          <Button
            size={ButtonSize.SMALL}
            theme={hasAlertType ? ButtonTheme.RED : ButtonTheme.YELLOW}
            onClick={() => void setIsModalOpen(true)}
          >
            {t('common.show')}
          </Button>
        </Summary>
      </Card>

      {isModalOpen && (
        <Modal
          classNameChildren='AlertsCard__modal--content'
          isOpen={isModalOpen}
          onClose={(isOpen: boolean) => void setIsModalOpen(isOpen)}
          separateTitleBar={false}
          footer={<Button onClick={() => void setIsModalOpen(false)}>{t('common.ok')}</Button>}
          scrollable
        >
          <div className='AlertsCard__modal'>
            <>
              {infoMessages}
              {warnMessages}
            </>
          </div>
        </Modal>
      )}
    </>
  )
}

export default AlertsCard
