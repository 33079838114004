import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'api/fragments'

const PAYLOAD_TYPE = 'ReserveCampaignPayload'

export const RESERVE_CAMPAIGN = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation reserveCampaign($id: ID!, $endDate: ISO8601DateTime!) {
    reserveCampaign(input: { id: $id, endDate: $endDate }) {
      campaign {
        id
      }
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
