import Button, { ButtonTheme } from 'components/Form/Button'
import Modal from 'components/Modal'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './AttributesModal.scss'
import Select from '../../../../../components/Form/Select'
import {
  CommercialAttribute,
  defaultCommercialAttribute,
  OrganizationAttributes,
} from '../../../../../types/campaign'
import Input, { OnManyInputChangeFn } from '../../../../../components/Form/Input'
import FillingSpinner, { FillingSpinnerType } from '../../../../../components/FillingSpinner'
import Checkbox from '../../../../../components/Form/Checkbox'

type Props = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  commercialAttribute: CommercialAttribute | null
  organizationAttribute: OrganizationAttributes | null
  onManyInputChange: OnManyInputChangeFn
  updateOrganizationAttributes: (organizationAttributes: OrganizationAttributes) => void
}

const AttributesModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  commercialAttribute,
  organizationAttribute,
  onManyInputChange,
  updateOrganizationAttributes,
}) => {
  const { t } = useTranslation()
  const [commercial, setCommercial] = useState<CommercialAttribute | null>(
    commercialAttribute ?? defaultCommercialAttribute
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [organizationCheckboxes, setOrganizationCheckboxes] = useState({
    social: organizationAttribute?.social ?? false,
    commercial: organizationAttribute?.commercial ?? false,
    outsidePh: organizationAttribute?.outsidePh ?? false,
    productOffer: organizationAttribute?.productOffer ?? false,
    complaint: !!organizationAttribute?.complaint,
    freeComplaint: organizationAttribute?.freeComplaint ?? false,
  })
  const [complaintCampaignId, setComplaintCampaignId] = useState<string>(
    organizationAttribute?.complaint ?? ''
  )

  useEffect(() => {
    if (!organizationCheckboxes.complaint) {
      setComplaintCampaignId('')
      setOrganizationCheckboxes({
        ...organizationCheckboxes,
        freeComplaint: false,
      })
    }
  }, [organizationCheckboxes.complaint])

  const disableSaveButton = () => {
    return organizationCheckboxes.complaint && complaintCampaignId === ''
  }

  const formattedAttributes = (
    commercial: CommercialAttribute | null,
    organizationCheckboxes: {
      commercial: boolean
      freeComplaint: boolean
      outsidePh: boolean
      productOffer: boolean
      social: boolean
    },
    campaignId: string
  ): Record<string, unknown> => {
    return {
      commercialAttribute: commercial,
      organizationAttribute: {
        commercial: organizationCheckboxes.commercial,
        complaint: campaignId,
        freeComplaint: organizationCheckboxes.freeComplaint,
        outsidePh: organizationCheckboxes.outsidePh,
        social: organizationCheckboxes.social,
        productOffer: organizationCheckboxes.productOffer,
      },
    }
  }

  const Footer = () => {
    return (
      <div className='Modal__footer AttributesModal__footer'>
        <Button
          dataCy={'attributes-modal-save-btn'}
          onClick={() => {
            onManyInputChange(
              formattedAttributes(commercial, organizationCheckboxes, complaintCampaignId)
            )
            updateOrganizationAttributes({
              commercial: organizationCheckboxes.commercial,
              complaint: complaintCampaignId,
              freeComplaint: organizationCheckboxes.freeComplaint,
              outsidePh: organizationCheckboxes.outsidePh,
              productOffer: organizationCheckboxes.productOffer,
              social: organizationCheckboxes.social,
            })
            setLoading(true)
            setIsOpen(false)
          }}
          disabled={loading || disableSaveButton()}
        >
          {t('common.save')}
        </Button>
        <Button
          theme={ButtonTheme.BLUE_OUTLINE}
          onClick={() => void setIsOpen(false)}
          disabled={loading}
        >
          {t('common.back')}
        </Button>
      </div>
    )
  }

  return (
    <Modal
      classNameChildren={loading ? 'AttributesModal AttributesModal--loading' : 'AttributesModal'}
      title={t('attributesSelection.title')}
      isOpen={isOpen}
      onClose={setIsOpen}
      footer={Footer()}
      scrollable
    >
      {loading ? (
        <FillingSpinner type={FillingSpinnerType.STANDARD} />
      ) : (
        <div className={'AttributesContainer'}>
          <div className={'OrganizationAttributes'}>
            <div className='InputTitle'>{t('attributesSelection.organizationAttributes')}</div>
            {Object.keys(organizationCheckboxes)
              .filter(key => key !== 'freeComplaint')
              .map((key, index) => {
                return (
                  <Checkbox
                    className='OrganizationAttributes__checkbox'
                    key={index}
                    checked={organizationCheckboxes[key as keyof typeof organizationCheckboxes]}
                    onChange={event => {
                      setOrganizationCheckboxes({
                        ...organizationCheckboxes,
                        [key]: event.target.checked,
                      })
                    }}
                  >
                    {t(`attributesSelection.${key}`)}
                  </Checkbox>
                )
              })}
            {organizationCheckboxes.complaint && (
              <>
                <Input
                  id='name'
                  className={'OrganizationAttributes__campaignInput'}
                  value={complaintCampaignId}
                  placeholder={t('attributesSelection.complaintDetail')}
                  onChange={text => setComplaintCampaignId(text)}
                />

                <Checkbox
                  className='OrganizationAttributes__checkbox OrganizationAttributes__checkbox--nested'
                  checked={organizationCheckboxes.freeComplaint}
                  onChange={event => {
                    setOrganizationCheckboxes({
                      ...organizationCheckboxes,
                      freeComplaint: event.target.checked,
                    })
                  }}
                >
                  {t(`attributesSelection.freeComplaint`)}
                </Checkbox>
              </>
            )}
          </div>

          <Select
            id='commercialAttribute'
            onChange={value => setCommercial(value)}
            options={[
              { label: 'Direct Guarantee', value: 'automatic_guarantee' },
              { label: 'Best Effort', value: 'best_effort' },
            ]}
            placeholder={t('form.campaign.details.offerPlaceholder')}
            title={t('attributesSelection.commercialAttributes')}
            value={commercial}
          />
        </div>
      )}
    </Modal>
  )
}

export default AttributesModal
