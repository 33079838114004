import React, { ReactElement } from 'react'
import { Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { pdfStyles } from '../../utils/pdf-styles'

export const FooterPdf = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <View
      style={pdfStyles.footer}
      fixed
    >
      <Text
        render={({ pageNumber, totalPages }) => `${t('pdf.page')} ${pageNumber}/${totalPages}`}
      />
    </View>
  )
}
