import { useEffect, useState } from 'react'
import { AuthenticateInput, CookiesData, UserData, userDataDefault, UseUserProps } from './types'
import i18n from 'i18n'
import api from 'api'
import Cookies, { CookieAttributes } from 'js-cookie'
import { COOKIE_EXPIRATION_DAYS } from 'constant'
import EnvUtils from 'utils/env'
import { BaseRole, User } from 'types/user'
import ArrayUtils from 'utils/array'
import { Agency } from '../../types/agency'
import { AllocatedTimeHelper } from './allocated-time-helper.class'

const AUTH_COOKIE_OPTIONS: CookieAttributes = {
  expires: COOKIE_EXPIRATION_DAYS,
  sameSite: 'strict',
  secure: EnvUtils.toBoolean(process.env.REACT_APP_AUTH_COOKIE_SECURE_FLAG),
}

export const withoutAccess = (userRoles: User['roles']): boolean =>
  ArrayUtils.containsOneOrMoreItems(userRoles, [BaseRole.ACCOUNT, BaseRole.PLANNER]) &&
  !ArrayUtils.containsOneOrMoreItems(userRoles, [
    BaseRole.ADMIN,
    BaseRole.CLIENT,
    BaseRole.EMPLOYEE,
    BaseRole.SUPPORT,
  ])

const useUser = (): UseUserProps => {
  const [userData, setUserData] = useState<UserData>(userDataDefault)

  const authenticate = ({ authenticationToken, refreshToken, user }: AuthenticateInput): void => {
    saveDataToCookies({ authenticationToken, refreshToken, userId: user.id })

    const withAllocatedTime = AllocatedTimeHelper.getAllocatedTime(user.agencies as Agency[])

    setUserData({
      ...userDataDefault,
      user,
      signedIn: true,
      fetchComplete: true,
      withoutAccess: withoutAccess(user.roles),
      withAllocatedTime,
    })
  }

  const restoreUser = (): void => {
    const { authenticationToken: at, refreshToken: rt, userId: id } = getDataFromCookies()
    if (!at || !rt || !id) {
      resetUser()
      return
    }

    if (id) {
      api.user
        .getUserById(id)
        .then(res => {
          const user: User = res.data.node

          const withAllocatedTime = AllocatedTimeHelper.getAllocatedTime(user.agencies as Agency[])

          setUserData({
            ...userData,
            user,
            signedIn: true,
            fetchComplete: true,
            withoutAccess: withoutAccess(user.roles),
            withAllocatedTime,
          })
        })
        .catch(() => void setUserData({ ...userDataDefault, fetchComplete: true }))
    }
  }

  const tryToGetNewToken = (): Promise<string | false> => {
    const { authenticationToken: at, refreshToken: rt, userId: id } = getDataFromCookies()
    if (!rt || !at || !id) {
      resetUser()
      return Promise.resolve(false)
    }

    return api.user
      .refreshToken({
        authenticationToken: at,
        refreshToken: rt,
      })
      .then(res => {
        const { refreshToken } = res.data!

        if (refreshToken.success) {
          const { authenticationToken, user } = refreshToken
          authenticate({ authenticationToken, refreshToken: rt, user })
          return authenticationToken
        } else {
          resetUser()
          return false
        }
      })
      .catch(() => {
        resetUser()
        return false
      })
  }

  const updateUser = (): Promise<void> =>
    api.user
      .getUser()
      .then(res => {
        const user: User = res.data.currentUser
        setUserData({ ...userData, user, withoutAccess: withoutAccess(user.roles) })
      })
      .catch(() => void {})

  const resetUser = (): void => {
    removeDataFromCookies()
    setUserData({ ...userDataDefault, fetchComplete: true })
  }

  const saveDataToCookies = ({ authenticationToken, refreshToken, userId }: CookiesData): void => {
    if (authenticationToken) Cookies.set('at', authenticationToken, AUTH_COOKIE_OPTIONS)
    if (refreshToken) Cookies.set('rt', refreshToken, AUTH_COOKIE_OPTIONS)
    if (userId) Cookies.set('id', userId, AUTH_COOKIE_OPTIONS)
  }

  const getDataFromCookies = (): CookiesData => ({
    authenticationToken: Cookies.get('at'),
    refreshToken: Cookies.get('rt'),
    userId: Cookies.get('id'),
  })

  const removeDataFromCookies = (): void => {
    Cookies.remove('at')
    Cookies.remove('rt')
    Cookies.remove('id')
    Cookies.remove('features')
  }

  useEffect(() => {
    if (!userData.user) {
      restoreUser()
    } else {
      i18n.changeLanguage(userData.user.preferredLocale)
    }
  }, [userData.user])

  return {
    userData,
    authenticate,
    tryToGetNewToken,
    resetUser,
    updateUser,
  }
}

export default useUser
