import { gql } from '@apollo/client'
import { CAMPAIGN_CHART_FILTERS_FIELDS } from '../fragments'

export const GET_CAMPAIGN_CHART_FILTERS = gql`
  ${CAMPAIGN_CHART_FILTERS_FIELDS}
  query campaignChartFilters($id: ID!) {
    campaignChartFilters(campaignId: $id) {
      ...CampaignChartFiltersFields
    }
  }
`

export const GET_CAMPAIGN_CHART_FILTERS_PUBLIC = gql`
  ${CAMPAIGN_CHART_FILTERS_FIELDS}
  query campaignReportPublic($campaignUuid: String!) {
    campaignReportPublic(campaignUuid: $campaignUuid) {
      campaignChartFilters(campaignUuid: $campaignUuid) {
        ...CampaignChartFiltersFields
      }
    }
  }
`
