import React, { ReactElement } from 'react'
import { Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { pdfStyles } from '../../../../../utils/pdf-styles'

export const AudienceNotCompleteData = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <View style={pdfStyles.completeData}>
      <Text render={() => `${t('pdf.notCompleteData')}`} />
    </View>
  )
}
