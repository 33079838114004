import { gql } from '@apollo/client'

export const UPDATE_EMAIL = gql`
  mutation updateUserEmail($id: ID!, $email: String!) {
    updateEmail(input: { id: $id, email: $email }) {
      user {
        email
      }
      success
      errors
    }
  }
`
