import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal'
import updateCampaignNote from 'api/campaign/updateCampaignNote'
import NoteModalFooter from './NoteModalFooter'
import { UpdateCampaignNoteAQR, UpdateCampaignNoteInput } from 'api/campaign/types'

type Props = {
  campaignId: string
  close: () => void
  initValue: string
  contractNote: string
  isOpen: boolean
  onEdit: (note: string) => void
}

const NoteModal: React.FC<Props> = ({
  campaignId,
  close,
  initValue,
  contractNote,
  isOpen,
  onEdit,
}) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState(!initValue)
  const [note, setNote] = useState(initValue)

  const [mutate, { loading }] = useMutation<UpdateCampaignNoteAQR, UpdateCampaignNoteInput>(
    updateCampaignNote,
    {
      variables: { input: { campaignId, note } },
    }
  )
  const save = async (): Promise<void> => {
    if (loading) return

    mutate()
      .then(() => {
        onEdit(note)
        close()
      })
      .catch(() => void {})
  }

  const NoteInput = (
    <form
      onSubmit={(event): void => {
        event.preventDefault()
        save()
      }}
    >
      <textarea
        autoFocus
        className='Input__field NoteModal__input'
        onChange={e => setNote(e.target.value)}
        value={note}
      />
      {contractNote && (
        <div className='NoteModal__display pre-line'>
          <p className={'NoteModal__display--contractNote'}>{contractNote}</p>
        </div>
      )}
    </form>
  )

  const NoteDisplay = (
    <div className='NoteModal__display pre-line'>
      <p>{note}</p>
      {contractNote && <p className={'NoteModal__display--contractNote'}>{contractNote}</p>}
    </div>
  )

  return (
    <Modal
      title={t('common.note')}
      isOpen={isOpen}
      onClose={close}
      classNameOverlay='NoteModal'
      classNameChildren={'NoteModal__children'}
      footer={
        <NoteModalFooter
          isEditing={isEditing}
          loading={loading}
          onCancel={close}
          onEdit={() => setIsEditing(true)}
          onSave={save}
        />
      }
    >
      {isEditing ? NoteInput : NoteDisplay}
    </Modal>
  )
}

export default NoteModal
