import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button, { ButtonSize } from 'components/Form/Button'
import NoteModal from './NoteModal'
import './NoteSummary.scss'

type Props = {
  campaignId: string
  note: string
  contractNote: string
  onEdit: (note: string) => void
}

const NoteSummary: React.FC<Props> = ({ campaignId, note, contractNote, onEdit }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const openModal = (): void => setIsOpen(true)
  const closeModal = (): void => setIsOpen(false)
  return (
    <div className='NoteCard'>
      <div className='Summary__header'>
        <div className='Summary__header--title'>{t('common.note')}</div>
      </div>

      <div className='Summary'>
        <Button
          onClick={openModal}
          size={ButtonSize.SMALL}
        >
          {note || contractNote ? t('common.open') : t('common.add')}
        </Button>
      </div>

      {isOpen && (
        <NoteModal
          campaignId={campaignId}
          isOpen={isOpen}
          close={closeModal}
          initValue={note}
          contractNote={contractNote}
          onEdit={onEdit}
        />
      )}
    </div>
  )
}

export default NoteSummary
