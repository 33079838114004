import { ButtonHTMLAttributes, ReactChild } from 'react'
import { TFunctionResult } from 'i18next'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { CypressSelectable } from '../../../types'

export interface ButtonProps extends CypressSelectable {
  children?: ReactChild | TFunctionResult
  className?: string
  disabled?: boolean
  icon?: IconDefinition
  iconSvg?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  size?: ButtonSize
  theme?: ButtonTheme
  toUrl?: string
  toUrlExternal?: string
  tooltip?: string
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
}

export enum ButtonTheme {
  BLUE = 'blue',
  YELLOW = 'yellow',
  BLUE_OUTLINE = 'blue-outline',
  NAVY_BLUE = 'navy-blue',
  NAVY_BLUE_OUTLINE = 'navy-blue-outline',
  RED = 'red',
  RED_OUTLINE = 'red-outline',
}

export enum ButtonSize {
  SMALL = 'small',
  STANDARD = 'standard',
}
