import { Notification, NotifierType } from 'components/Notifier/types'

export interface UseNotifierProps {
  notifications: Notification[]
  addNotification: (type: NotifierType, text: string | string[], time?: number) => void
  removeNotification: (id: string) => void
}

export const useNotifierPropsDefault: UseNotifierProps = {
  notifications: [],
  addNotification: () => void {},
  removeNotification: () => void {},
}
