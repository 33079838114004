import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'api/fragments'

const PAYLOAD_TYPE = 'StopCampaignPayload'

export const STOP_CAMPAIGN = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation stopCampaign($id: ID!) {
    stopCampaign(input: { id: $id }) {
      campaign {
        id
      }
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
