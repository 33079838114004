import { gql } from '@apollo/client'
import { CAMPAIGN_FILTERS_VALUES_FIELDS } from '../fragments'

export const GET_CAMPAIGN_FILTERS = gql`
  ${CAMPAIGN_FILTERS_VALUES_FIELDS}
  query campaignFilters {
    campaignFilters {
      ...CampaignFiltersValuesFields
    }
  }
`
