import api from 'api'
import { AgencyForm, SubmitArgs } from './types'
import { Routes } from 'routes'
import { NotifierType } from 'components/Notifier'
import { SelectOption } from '../../../components/Form/Select'
import i18n from '../../../i18n'
import { AgencyOrigin, Currency } from '../../../api/agency/types'
import { Company } from '../../../api/companies/types'
import VariableUtils from 'utils/variable'
import { User } from 'types/user'
import { userFullName } from 'utils/user'
import { Medium } from '../../../types/campaign'
import { AgencyMedium, AgencyMediumQR, AllowedTarget } from '../../../types/agency'
import { SelectAsyncValue } from 'components/Form/SelectAsync'

export default class AgencyFormService {
  static fetchAgency = (
    agencyId: AgencyForm['id'],
    setAgency: React.Dispatch<React.SetStateAction<AgencyForm>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setMediums: React.Dispatch<React.SetStateAction<Medium[]>>
  ): void => {
    setLoading(true)
    api.agency
      .getAgency(agencyId)
      .then(res => {
        const {
          withAllocatedTime,
          clientId,
          allocatedTime,
          mediumAllocatedTime,
          supervisors,
          ...rest
        } = res.data.node

        if (!withAllocatedTime) {
          setAgency({
            ...rest,
            withAllocatedTime,
            allocatedTime: allocatedTime * 100,
            mediumAllocatedTime: mediumAllocatedTime * 100,
            clientId: clientId || '',
            supervisors: supervisors.map(supervisor =>
              VariableUtils.toSelectAsyncValue<User>({
                label: userFullName(supervisor),
                value: supervisor.id,
                rawData: supervisor,
              })
            ),
          })
        }
        return res.data.node
      })
      .then(agency => {
        if (agency.withAllocatedTime) {
          api.media.getAll().then(mediaResponse => {
            setMediums(mediaResponse.data.media.nodes)
            setAgency({
              ...agency,
              agencyMediums: (agency.agencyMediums as AgencyMediumQR[]).map(agencyQR => {
                return {
                  mediumId: agencyQR.media.id,
                  allocatedTime: agencyQR.allocatedTime ? agencyQR.allocatedTime * 100 : null,
                }
              }),
              allocatedTime: agency.allocatedTime * 100,
              mediumAllocatedTime: agency.mediumAllocatedTime * 100,
              clientId: agency.clientId || '',
              supervisors: agency.supervisors.map(supervisor =>
                VariableUtils.toSelectAsyncValue<User>({
                  label: userFullName(supervisor),
                  value: supervisor.id,
                  rawData: supervisor,
                })
              ),
            })
            setLoading(false)
          })
        } else {
          setLoading(false)
        }
      })
      .catch(() => void {})
  }

  static handleUpdate = (args: SubmitArgs): Promise<void> => {
    const {
      agency: {
        id,
        company,
        clientId,
        agencyType,
        origin,
        currencyType,
        supervisors,
        name,
        discount,
        billingAllowance,
        allocatedTime,
        mediumAllocatedTime,
        agencyMediums,
        withAllocatedTime,
        allowedTargets,
      },
      navigate,
      addNotification,
      setLoadingAgency,
      t,
    } = args
    setLoadingAgency(true)

    return api.agency
      .updateAgency({
        id,
        company: company as Company,
        clientId,
        agencyType,
        origin,
        currencyType: currencyType,
        name,
        supervisors: supervisors.map(supervisor => supervisor.rawData!.id),
        discount,
        billingAllowance,
        allocatedTime: allocatedTime * 0.01,
        mediumAllocatedTime: mediumAllocatedTime * 0.01,
        agencyMediums: (agencyMediums as AgencyMedium[]).map(agencyMedium => {
          return {
            ...agencyMedium,
            allocatedTime: agencyMedium.allocatedTime ? agencyMedium.allocatedTime * 0.01 : null,
          }
        }),
        withAllocatedTime,
        allowedTargets: allowedTargets.map(target => target.id),
      })
      .then(() => {
        navigate(Routes.AGENCIES.ALL)
        addNotification(NotifierType.SUCCESS, t('notification.updateAgencySuccess'))
      })
      .finally(() => void setLoadingAgency(false))
      .catch(() => void {})
  }

  static handleSubmit = (args: SubmitArgs): Promise<void> => {
    const {
      agency: {
        company,
        clientId,
        agencyType,
        origin,
        currencyType,
        supervisors,
        name,
        allocatedTime,
        mediumAllocatedTime,
        agencyMediums,
        withAllocatedTime,
        allowedTargets,
      },
      navigate,
      addNotification,
      setLoadingAgency,
      t,
    } = args
    setLoadingAgency(true)

    return api.agency
      .createAgency({
        company: company || null,
        clientId,
        agencyType,
        origin,
        currencyType: currencyType,
        name,
        allocatedTime: allocatedTime * 0.01,
        supervisors: supervisors.map(supervisor => supervisor.rawData!.id),
        mediumAllocatedTime: mediumAllocatedTime * 0.01,
        agencyMediums: (agencyMediums as AgencyMedium[]).map(agencyMedium => {
          return {
            ...agencyMedium,
            allocatedTime: agencyMedium.allocatedTime ? agencyMedium.allocatedTime * 0.01 : null,
          }
        }),
        withAllocatedTime,
        allowedTargets: allowedTargets.map(target => target.id),
      })
      .then(() => {
        navigate(Routes.AGENCIES.ALL)
        addNotification(NotifierType.SUCCESS, t('notification.addAgencySuccess'))
      })
      .catch(() => void {})
      .finally(() => void setLoadingAgency(false))
  }

  static getMediums = (
    setMediums: React.Dispatch<React.SetStateAction<Medium[]>>
  ): Promise<SelectAsyncValue<Medium>[]> => {
    return api.media
      .getAll()
      .then(mediaResponse => {
        setMediums(mediaResponse.data.media.nodes)
        return AgencyFormService.mediumsToSelectAsyncValues(mediaResponse.data.media.nodes)
      })
      .catch(() => [])
  }

  static fetchAllowedTargets = (
    setAllowedTargets: React.Dispatch<React.SetStateAction<AllowedTarget[]>>
  ): Promise<void> => {
    return api.agency
      .getAllowedTargets()
      .then(response => {
        if (!response || !response.data || !response.data.allowedTargets) {
          return
        }

        setAllowedTargets(response.data.allowedTargets)
      })
      .catch(() => void {})
  }

  static originOptions = () => {
    return Object.values(AgencyOrigin).map(
      (agencyOrigin: AgencyOrigin): SelectOption => ({
        value: agencyOrigin,
        label: i18n.t(`agencies.form.${agencyOrigin}`),
      })
    )
  }

  static mediumsToSelectAsyncValues = (mediums: Medium[]): SelectAsyncValue<Medium>[] =>
    mediums.map(
      (medium: Medium): SelectAsyncValue<Medium> => ({
        value: medium.id,
        label: AgencyFormService.getMediumLocalizationName(medium),
        rawData: medium,
      })
    )

  static getMediumLocalizationName = (medium: Medium): string =>
    [medium.asId, medium.city.name, medium.address]
      .filter(mediumDescription => !!mediumDescription)
      .join(', ')

  static mediumOptions = (addedAgencyMediums: AgencyMedium[], mediums: Medium[]) => {
    return mediums
      .map(
        (medium: Medium): SelectOption => ({
          value: medium.id,
          label: [medium.asId, medium.city.name, medium.address]
            .filter(mediumDescription => !!mediumDescription)
            .join(', '),
        })
      )
      .filter((medium: SelectOption) => {
        return !addedAgencyMediums.some(agencyMedium => agencyMedium.mediumId === medium.value)
      })
  }

  static currencyOptions = () => {
    return Object.values(Currency).map(
      (currency: Currency): SelectOption => ({
        value: currency,
        label: currency,
      })
    )
  }
}
