import React, { ReactElement } from 'react'
import { CampaignDetailsPdfData } from '../models/types'
import { View } from '@react-pdf/renderer'
import { Table, TableData, TableRow, TableTitle } from 'components/TablePdf'
import { t } from 'i18next'
import VariableUtils from 'utils/variable'
import i18n from 'i18n'
import { MediaFormat } from '../../../../../types/campaign'

type Props = {
  campaignData: Pick<
    CampaignDetailsPdfData,
    'briefName' | 'media' | 'offer' | 'commercialAttribute'
  >
  mediumFormat: MediaFormat
}

export const Details = ({ campaignData, mediumFormat }: Props): ReactElement => {
  const { briefName, media, offer } = campaignData

  return (
    <View>
      <Table>
        <TableTitle>{t('common.campaignDetails')}</TableTitle>

        <TableRow>
          <TableData width={40}>{t('common.briefName')}</TableData>
          <TableData width={60}>{briefName}</TableData>
        </TableRow>

        <TableRow>
          <TableData width={40}>{t('common.mediaType')}</TableData>
          <TableData width={60}>{t(`mediaType.${mediumFormat}`)}</TableData>
        </TableRow>

        <TableRow>
          <TableData width={40}>{t('common.usedMedia')}</TableData>
          <TableData width={60}>{VariableUtils.formatNumber(media.length)}</TableData>
        </TableRow>

        <TableRow>
          <TableData width={40}>{t('common.offerType')}</TableData>
          <TableData width={60}>{offer ? i18n.t(`offer.${offer}`) : '-'}</TableData>
        </TableRow>

        <TableRow>
          <TableData width={40}>{t('attributesSelection.commercialAttribute')}</TableData>
          <TableData width={60}>
            {campaignData.commercialAttribute
              ? i18n.t(
                  `attributesSelection.commercialAttributesValues.${campaignData.commercialAttribute}`
                )
              : '-'}
          </TableData>
        </TableRow>
      </Table>
    </View>
  )
}
