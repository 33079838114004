import React, { ReactNode } from 'react'
import { DetailsFormMediaFormatProps } from './types'
import { useTranslation } from 'react-i18next'
import InputTitle from 'components/Form/InputTitle'
import RadioButton from 'components/Form/RadioButton'
import { MediaFormat } from 'types/campaign'
import './DetailsFormMediaFormat.scss'

const DetailsFormMediaFormat: React.FC<DetailsFormMediaFormatProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation()
  const groupId = 'mediaFormatRBG'

  const changeValue = (id: string): void => void onChange(id, 'mediumFormat')

  const indoorInputs = (): ReactNode => (
    <div>
      {t('common.indoor')}

      <RadioButton
        className='DetailsFormMediaFormat__radio-button'
        id={MediaFormat.DG_IN}
        groupId={groupId}
        value={value}
        onChange={changeValue}
        disabled={disabled}
      >
        {t(`mediaType.${MediaFormat.DG_IN}`)}
      </RadioButton>
    </div>
  )

  const outdoorInputs = (): ReactNode => (
    <div>
      {t('common.outdoor')}

      <RadioButton
        className='DetailsFormMediaFormat__radio-button'
        id={MediaFormat.DG_CL}
        groupId={groupId}
        value={value}
        onChange={changeValue}
        disabled={disabled}
      >
        {t(`mediaType.${MediaFormat.DG_CL}`)}
      </RadioButton>

      <RadioButton
        className='DetailsFormMediaFormat__radio-button'
        id={MediaFormat.DG_CS}
        groupId={groupId}
        value={value}
        onChange={changeValue}
        disabled={disabled}
      >
        {t(`mediaType.${MediaFormat.DG_CS}`)}
      </RadioButton>
    </div>
  )

  return (
    <div className='DetailsFormMediaFormat'>
      {<InputTitle title={t('common.mediaType')} />}

      <div className='DetailsFormMediaFormat__row'>
        {indoorInputs()}
        {outdoorInputs()}
      </div>
    </div>
  )
}

export default DetailsFormMediaFormat
