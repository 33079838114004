import { FormType } from 'types/various'

export interface CampaignFormContainerProps {
  formType: FormType
}

export type CampaignFormProps = CampaignFormContainerProps

export enum CampaignFormHashes {
  CREATIONS = 'creations',
}
