import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import React, { useEffect } from 'react'
import EmissionPlanService from '../services/emission-plan.service'
import SelectMultiCheckbox from '../../../components/Form/SelectMultiCheckbox'
import { t } from 'i18next'
import { SelectOption } from '../../../components/Form/Select'
import { emissionPlanActions } from '../store/emission-plan-slice'
import './EmissionPlanFilters.scss'

export const EmissionPlanFilters: React.FC<{ uuid?: string }> = ({ uuid }) => {
  const dispatch = useDispatch()
  const { filters } = useSelector((state: RootState) => state.emissionPlan)

  useEffect(() => {
    if (filters.loaded) {
      return
    }

    if (!uuid) {
      return
    }

    void EmissionPlanService.fetchDailySummaryFilters(uuid)
  }, [filters.loaded])

  const stringToSelectOption = (options: string[]): SelectOption[] => {
    return options.map(option => ({ value: option, label: option }))
  }

  return (
    <div className={'EmissionPlanFilters'}>
      {!!filters.all.agglomerations.length && (
        <SelectMultiCheckbox
          id='agglomerations'
          title={t('common.agglomeration')}
          value={filters.selected.agglomerations}
          options={stringToSelectOption(filters.all.agglomerations)}
          onChange={selected => {
            void dispatch(
              emissionPlanActions.setSelectedFilters({ key: 'agglomerations', data: selected })
            )
          }}
          selectAllOption
        />
      )}

      {!!filters.all.cities.length && (
        <SelectMultiCheckbox
          id='cities'
          title={t('common.city')}
          value={filters.selected.cities}
          options={stringToSelectOption(filters.all.cities)}
          onChange={selected => {
            void dispatch(emissionPlanActions.setSelectedFilters({ key: 'cities', data: selected }))
          }}
          selectAllOption
        />
      )}

      {!!filters.all.buildings.length && (
        <SelectMultiCheckbox
          id='buildings'
          title={t('common.building')}
          value={filters.selected.buildings}
          options={stringToSelectOption(filters.all.buildings)}
          onChange={selected => {
            void dispatch(
              emissionPlanActions.setSelectedFilters({ key: 'buildings', data: selected })
            )
          }}
          selectAllOption
        />
      )}

      {!!filters.all.mediaAsIds.length && (
        <SelectMultiCheckbox
          id='mediaAsIds'
          title={t('common.medium')}
          value={filters.selected.mediaAsIds}
          options={stringToSelectOption(filters.all.mediaAsIds)}
          onChange={selected => {
            void dispatch(
              emissionPlanActions.setSelectedFilters({ key: 'mediaAsIds', data: selected })
            )
          }}
          selectAllOption
        />
      )}

      {!!filters.all.creations && (
        <SelectMultiCheckbox
          id='creations'
          title={t('common.creation')}
          value={filters.selected.creations}
          options={filters.all.creations.map(creation => ({
            value: creation.value,
            label: creation.name,
          }))}
          onChange={selected => {
            void dispatch(
              emissionPlanActions.setSelectedFilters({ key: 'creations', data: selected })
            )
          }}
          selectAllOption
        />
      )}
    </div>
  )
}
