import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GetInventoryFiltersQueryData, GetInventoryFiltersVariables } from 'api/inventory/types'
import { mediaFormatCheckboxes } from './InventoryChart/types'
import service from './InventoryChart/inventory-chart.service'
import { MediaFormat } from 'types/campaign'
import { useDebounce } from 'utils/tsx'
import { filtersDefault } from 'components/Form/Filters'
import { InventoryAvailableFilters, InventoryChartFilters } from './models/types'
import { GET_INVENTORY_FILTERS } from '../../api/inventory/getInventoryFilters'
import { mediaToFilters } from './helpers/media-filters.helper'

type Result = {
  availableFilters: InventoryAvailableFilters
  filters: InventoryChartFilters
  setFilters: (filters: InventoryChartFilters) => void
  filtersDebouncedCounter: number
}

const useFilters = (): Result => {
  const [filtersDebouncedCounter, setFiltersDebouncedCounter] = useState<number>(0)

  const [filters, setFilters] = useState<InventoryChartFilters>({
    ...filtersDefault('agglomerations', 'buildings', 'cities', 'mediumAsIds'),
    mediaFormats: service.mediaFormatsCheckboxesToArray(mediaFormatCheckboxes) as MediaFormat[],
    granulation: 'daily',
    slotDuration: 0,
    fromDate: '',
    toDate: '',
  })

  const [availableFilters, setAvailableFilters] = useState<InventoryAvailableFilters>({
    ...filtersDefault('agglomerations', 'buildings', 'cities', 'mediumAsIds'),
  })

  const { data: availableMedia, refetch: refetchFilters } = useQuery<
    GetInventoryFiltersQueryData,
    GetInventoryFiltersVariables
  >(GET_INVENTORY_FILTERS)

  useEffect((): void => {
    if (availableMedia) setAvailableFilters(mediaToFilters(availableMedia.mediaFilter))
  }, [availableMedia])

  const [onFiltersChangeDebounced] = useDebounce((): void => {
    setFiltersDebouncedCounter(prev => prev + 1)
  }, 1000)

  useEffect(() => void refetchFilters(filters), [filtersDebouncedCounter])

  useEffect(onFiltersChangeDebounced, [filters])

  return { availableFilters, filters, setFilters, filtersDebouncedCounter }
}

export default useFilters
