import api from 'api'
import { CampaignData, PrivateViewIds, PublicViewIds } from './types'
import { Routes } from 'routes'
import { NavigateFunction } from 'react-router-dom'
import { MediaFormat } from 'types/campaign'
import { GetCampaignForEmissionScheduleData } from 'api/campaign/types'

export default class EmissionScheduleService {
  static getCampaignData = (
    privateViewIds: PrivateViewIds | undefined,
    publicViewIds: PublicViewIds | undefined,
    navigate: NavigateFunction,
    setCampaignData: React.Dispatch<React.SetStateAction<CampaignData | undefined>>
  ): void => {
    if (publicViewIds?.urlCampaignUuid) {
      api.campaign
        .getCampaignForEmissionSchedulePublic(publicViewIds.urlCampaignUuid)
        .then(res => res.data.campaignReportPublic.campaign)
        .then(data => void EmissionScheduleService.setCampaignDataHelper(data, setCampaignData))
        .catch(() => void navigate(Routes.SIGN.SIGN_IN))
    } else if (privateViewIds?.urlCampaignId) {
      api.campaign
        .getCampaignForEmissionSchedule(privateViewIds.urlCampaignId)
        .then(res => res.data.node)
        .then(data => void EmissionScheduleService.setCampaignDataHelper(data, setCampaignData))
        .catch(() => void {})
    }
  }

  static setCampaignDataHelper = (
    data: GetCampaignForEmissionScheduleData,
    setCampaignData: React.Dispatch<React.SetStateAction<CampaignData | undefined>>
  ): void =>
    void setCampaignData({
      ...data,
      mediumFormat: MediaFormat[data.mediumFormat as string as keyof typeof MediaFormat],
    })
}
