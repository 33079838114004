import api from 'api'
import { ModalUploadFile } from 'components/Modal/ModalUpload'
import { NotifierType } from 'components/Notifier'
import { UseNotifierProps } from 'hooks/useNotifier'
import { UseUserProps } from 'hooks/useUser'
import { TFunction } from 'i18next'
import { User } from 'types/user'

export default class TopBarUserService {
  static uploadAvatar = (
    userId: User['id'],
    file: ModalUploadFile, // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
    setLoading: (isLoading: boolean) => void,
    hideModal: () => void,
    updateUser: UseUserProps['updateUser']
  ): void => {
    setLoading(true)

    api.user
      .createAvatar({ userId, avatar: file })
      .catch(() => void {})
      .finally(() => {
        setLoading(false)
        hideModal()
        updateUser()
      })
  }

  static signOut = (
    setSignOutLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setSignOutSuccess: React.Dispatch<React.SetStateAction<boolean>>,
    addNotification: UseNotifierProps['addNotification'],
    t: TFunction
  ): void => {
    setSignOutLoading(true)

    api.user
      .signOut()
      .then(() => {
        addNotification(NotifierType.SUCCESS, t('notification.logOutSuccess'))

        setSignOutLoading(false)
        setSignOutSuccess(true)
      })
      .catch(() => void {})
  }
}
