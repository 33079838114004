import { snakeCase } from 'case-anything'
import api from 'api'
import { ReportType } from '../../../api/report/types'
import { store } from '../../../store'
import { reportActions } from '../store/report-slice'
import { Campaign } from '../../../types/campaign'
import { FetchResult } from '@apollo/client'
import { CamelToSnakeCase, MutationStateResponse } from '../../../types/various'
import ErrorUtils from '../../../utils/error'

export default class CommonReportsService {
  static createCampaignReport = ({
    campaignId,
    startDate,
    endDate,
  }: {
    campaignId: Campaign['id']
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<FetchResult<MutationStateResponse> | void> => {
    return api.report.createCampaignReport({ campaignId, startDate, endDate }).catch(() => void {})
  }

  static createCreationReport = ({
    campaignId,
    startDate,
    endDate,
  }: {
    campaignId: Campaign['id']
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<FetchResult<MutationStateResponse> | void> => {
    return api.report.createCreationReport({ campaignId, startDate, endDate }).catch(() => void {})
  }

  static createMediaReport = ({
    asIds,
    startDate,
    endDate,
  }: {
    asIds: string[]
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<FetchResult<MutationStateResponse> | void> => {
    return api.report.createMediaReport({ asIds, startDate, endDate }).catch(() => void {})
  }

  static createMediumReport = ({
    asId,
    startDate,
    endDate,
  }: {
    asId: string
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<FetchResult<MutationStateResponse> | void> => {
    return api.report.createMediumReport({ asId, startDate, endDate }).catch(() => void {})
  }

  static createMediumCampaignsReport = ({
    asId,
    startDate,
    endDate,
  }: {
    asId: string
    startDate: string | null
    endDate: string | null
  }): Promise<FetchResult<MutationStateResponse> | void> => {
    return api.report.createMediumCampaignsReport({ asId, startDate, endDate }).catch(() => void {})
  }

  static fetchReports = (reportType: ReportType): Promise<unknown> => {
    const normalizedValue = snakeCase(reportType) as CamelToSnakeCase<ReportType>

    return Promise.resolve(
      store.dispatch(reportActions.setLoading({ key: reportType, loading: true }))
    )
      .then(() => api.report.fetchGeneratedReports(normalizedValue))
      .then(({ data }) => data.generatedReports)
      .then(reports => {
        return store.dispatch(reportActions.setReportsData({ key: reportType, data: reports }))
      })
      .catch(() => [])
      .finally(
        () => void store.dispatch(reportActions.setLoading({ key: reportType, loading: false }))
      )
  }

  static fetchPricingReport = (): Promise<unknown> => {
    return Promise.resolve(
      store.dispatch(reportActions.setLoading({ key: 'pricing', loading: true }))
    )
      .then(() => api.report.fetchPricingReport())
      .then(({ data }) => {
        if (!data) {
          return {
            audience: { headers: [], content: [] },
            impressions: { headers: [], content: [] },
          }
        }

        return data?.currentPricingReport
      })
      .then(result => {
        return store.dispatch(
          reportActions.setPricingData({
            audience: result.audience,
            impressions: result.impressions,
          })
        )
      })
      .catch(() => [])
      .finally(
        () => void store.dispatch(reportActions.setLoading({ key: 'pricing', loading: false }))
      )
  }

  static validateMainInput = (inputValue: string, isNumeric = false): string[] => {
    if (isNumeric) {
      return [...ErrorUtils.isRequired(inputValue), ...ErrorUtils.isNumeric(inputValue)]
    }

    return [...ErrorUtils.isRequired(inputValue), ...ErrorUtils.isBase64(inputValue)]
  }

  /**
   * Validate input value for report with ids like: '1,2,3,4'
   * @param inputValue
   */
  static validateInputWithIds = (inputValue: string): string[] => {
    return [
      ...ErrorUtils.isRequired(inputValue),
      ...ErrorUtils.areNumbersSeparatedCorrectly(inputValue),
    ]
  }
}
