import { gql } from '@apollo/client'

export const FETCH_DAILY_SUMMARY = gql`
  query dailySummary($campaignUuid: String!, $schedule: ID!) {
    dailySummary(campaignUuid: $campaignUuid, schedule: $schedule) {
      totalImpressions
      day
      medium {
        asId
      }
      intervals {
        from
        to
        totalImpressions
      }
      impressionsPerCreation {
        creation {
          duration
          thumbnailUrl
        }
        impressions {
          hour
          totalImpressions
        }
        totalImpressions
      }
    }
  }
`
