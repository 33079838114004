import React, { useMemo } from 'react'
import { Document, Page } from '@react-pdf/renderer'
import { pdfStyles, registerFonts } from '../../../utils/pdf-styles'
import { HeaderPdf } from 'components/HeaderPdf'
import { FooterPdf } from 'components/FooterPdf'
import {
  CampaignsCombinedSummaryDetails,
  CampaignsCombinedSummaryModel,
} from '../../../api/report/types'
import { Media } from '../../Campaign/CampaignDetails/pdfs/parts/Media'
import { Details } from 'pages/Campaign/CampaignDetails/pdfs/parts/Details'
import { CampaignDetailsPdfData } from '../../Campaign/CampaignDetails/pdfs/models/types'
import { CampaignStatus, MediaFormat } from '../../../types/campaign'
import { Creations } from 'pages/Campaign/CampaignDetails/pdfs/parts/Creations'
import { Summary } from '../../Campaign/CampaignDetails/pdfs/parts/Summary'

type Props = {
  summaries: CampaignsCombinedSummaryModel[]
}

const CombinedCampaignReportPdf: React.FC<Props> = ({ summaries }) => {
  useMemo(() => {
    registerFonts()
  }, [])

  const getCampaignDataDetails = (
    details: CampaignsCombinedSummaryDetails
  ): Pick<CampaignDetailsPdfData, 'briefName' | 'media' | 'offer' | 'commercialAttribute'> => {
    return {
      briefName: details.name,
      media: details.media,
      offer: details.offerType || null,
      commercialAttribute: details.commercialAttribute,
    }
  }

  const getCampaignDataSummary = (
    details: CampaignsCombinedSummaryDetails
  ): Pick<
    CampaignDetailsPdfData,
    | 'budgetCurrency'
    | 'currentEmissions'
    | 'endDate'
    | 'startDate'
    | 'status'
    | 'target'
    | 'totalBudget'
    | 'totalEmissions'
    | 'acceptanceStatus'
    | 'totalAudience'
    | 'currentAudience'
  > => {
    return {
      budgetCurrency: details.budgetCurrency,
      currentEmissions: details.currentEmissions,
      endDate: details.endDate,
      startDate: details.startDate,
      status: details.status as CampaignStatus,
      target: details.target,
      totalBudget: details.totalBudget,
      totalAudience: details.totalAudience,
      totalEmissions: details.totalEmissions,
      acceptanceStatus: details.acceptanceStatus,
      currentAudience: details.currentAudience,
    }
  }

  return (
    <Document>
      {summaries.map((summary, index) => (
        <Page
          style={pdfStyles.page}
          key={index}
        >
          <HeaderPdf
            name={summary.details.name}
            mediumFormat={MediaFormat[summary.mediumFormat]}
          />
          <Summary campaignData={getCampaignDataSummary(summary.details)} />
          <Details
            campaignData={getCampaignDataDetails(summary.details)}
            mediumFormat={MediaFormat[summary.mediumFormat]}
          />
          <Creations creations={summary.details.creations} />
          <Media
            media={summary.details.media}
            mediumFormat={MediaFormat[summary.mediumFormat]}
          />
          <FooterPdf />
        </Page>
      ))}
    </Document>
  )
}

export default CombinedCampaignReportPdf
