import React, { useCallback, useEffect } from 'react'
import Card from 'components/Layout/Card'
import { useTranslation } from 'react-i18next'
import FillingSpinner from 'components/FillingSpinner'
import './InventoryTopTenGalleries.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import CardHeader from '../../../components/Layout/CardHeader'
import InventoryService from '../services/inventory.service'
import { topTenGalleriesActions } from '../store/inventory-top-ten-galleries-slice'
import InventoryTopTenChart from '../components/InventoryTopTenChart'
import DatePickerSingle from '../../../components/Form/DatePickerSingle'
import { DATE_PLACEHOLDER } from '../../../constant'
import debounce from 'lodash/debounce'

const InventoryTopTenGalleries: React.FC = () => {
  const debounceTime = 2000
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const debouncedFetchTopTen = useCallback(
    debounce(InventoryService.fetchTopTenGalleryOccupancy, debounceTime),
    []
  )

  const { loading, data, filters } = useSelector(
    (state: RootState) => state.inventory.topTenGalleries
  )

  useEffect(() => {
    void debouncedFetchTopTen(filters.fromDate, filters.toDate)
  }, [filters])

  useEffect(() => {
    // Reset state on unmount/destroy
    return () => {
      dispatch(topTenGalleriesActions.resetState())
    }
  }, [])

  return (
    <div className='InventoryTopTenGalleries'>
      <Card>
        <CardHeader>{t('inventory.topTenGalleries.header')}</CardHeader>
        <div>
          {loading ? (
            <div className={'InventoryTopTenGalleries__spinner-container'}>
              <FillingSpinner className='InventoryTopTenGalleries__loading' />
            </div>
          ) : (
            <>
              <div className='InventoryTopTenGalleries__filters'>
                <DatePickerSingle
                  id='dateFrom'
                  value={filters.fromDate}
                  placeholder={DATE_PLACEHOLDER}
                  title={t('common.from')}
                  onChange={(fromDate): void => {
                    void dispatch(topTenGalleriesActions.setFilters({ ...filters, fromDate }))
                  }}
                />
                <DatePickerSingle
                  id='dateTo'
                  value={filters.toDate}
                  placeholder={DATE_PLACEHOLDER}
                  title={t('common.to')}
                  onChange={(toDate): void => {
                    void dispatch(topTenGalleriesActions.setFilters({ ...filters, toDate }))
                  }}
                />
              </div>
              {data && data.length > 0 ? (
                <InventoryTopTenChart data={data} />
              ) : (
                <p>{t('common.noDataFound')}</p>
              )}
            </>
          )}
        </div>
      </Card>
    </div>
  )
}

export default InventoryTopTenGalleries
