import { Dispatch, SetStateAction } from 'react'
import VariableUtils from 'utils/variable'
import { FormErrors, formErrorsDefault, UserEditForm } from './types'
import ErrorUtils from 'utils/error'
import UserService from '../service'

export default class UserEditFormService {
  public static validate = (
    user: UserEditForm,
    errors: FormErrors | undefined,
    setErrors: Dispatch<SetStateAction<FormErrors | undefined>>
  ): void =>
    void setErrors({
      ...errors,
      firstName: [...ErrorUtils.isRequired(user.firstName)],
      lastName: [...ErrorUtils.isRequired(user.lastName)],
      priorities: [...ErrorUtils.isRequired(user.priorities)],
      roles: [...ErrorUtils.isRequired(user.roles)],
      agencies: [...UserService.agencyNameError(user)],
    })

  public static hasErrors = (errors: FormErrors | undefined): boolean => {
    return !VariableUtils.isDeepEqual(errors, formErrorsDefault)
  }
}
