import FillingSpinner from 'components/FillingSpinner'
import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DatePickerSingle from 'components/Form/DatePickerSingle'
import { DATE_PLACEHOLDER } from 'constant'
import { FormData, formDataDefault, FormDataErrors, formDataErrorsDefault } from './types'
import Input from 'components/Form/Input'
import Button from 'components/Form/Button'
import '../../Report.scss'
import { useSelector } from 'react-redux'
import { RootState, store } from '../../../../store'
import CommonReport from '../../components/CommonReport'
import { reportActions } from '../../store/report-slice'
import CommonReportsService from '../../services/common-reports.service'
import { FETCH_REPORT_DELAY } from '../../utils/report.constants'

const CampaignReport: React.FC = () => {
  const reportType = 'campaign'
  const { t } = useTranslation()
  const [formData, setFormData] = useState<FormData>(formDataDefault)
  const [errors, setErrors] = useState<FormDataErrors>({ ...formDataErrorsDefault })
  const { campaign } = useSelector((state: RootState) => state.report)

  // We need it to fetch reports on page load
  useEffect(() => {
    void CommonReportsService.fetchReports(reportType)
  }, [])

  const generateReport = async () => {
    const inputErrors = CommonReportsService.validateMainInput(formData.campaignId)

    setErrors({
      ...errors,
      campaignId: [...inputErrors],
    })

    if (inputErrors.length) {
      return
    }

    Promise.resolve(store.dispatch(reportActions.setLoading({ key: reportType, loading: true })))
      .then(() =>
        CommonReportsService.createCampaignReport({
          campaignId: formData.campaignId,
          startDate: formData.campaignStartDate,
          endDate: formData.campaignEndDate,
        })
      )
      .then(() => new Promise(resolve => setTimeout(resolve, FETCH_REPORT_DELAY)))
      .then(() => CommonReportsService.fetchReports(reportType))
      .finally(
        () => void store.dispatch(reportActions.setLoading({ key: reportType, loading: false }))
      )
  }

  const Form = (): ReactNode => (
    <div className='Report__form'>
      <Input
        id='campaignId'
        title={t('report.campaignId')}
        placeholder='Q2FtcGFpZ24tNTE='
        className={'Report__input'}
        value={formData.campaignId}
        onChange={(value: string) => void setFormData({ ...formData, campaignId: value })}
        errors={errors?.campaignId}
        disabled={campaign.loading}
      />

      <DatePickerSingle
        id='campaignStartDate'
        value={formData.campaignStartDate ?? ''}
        title={t('common.from')}
        placeholder={DATE_PLACEHOLDER}
        onChange={(value: string) => {
          setFormData({ ...formData, campaignStartDate: !value ? null : value })
        }}
        isOutsideRange={() => false}
        disabled={campaign.loading}
      />

      <DatePickerSingle
        id='campaignEndDate'
        value={formData.campaignEndDate ?? ''}
        title={t('common.to')}
        placeholder={DATE_PLACEHOLDER}
        onChange={(value: string) => {
          setFormData({ ...formData, campaignEndDate: !value ? null : value })
        }}
        isOutsideRange={() => false}
        disabled={campaign.loading}
      />

      <div className='Report__generate-button-container'>
        <Button
          onClick={generateReport}
          disabled={campaign.loading}
        >
          {t('report.generate')}
        </Button>
      </div>
    </div>
  )

  return (
    <>
      {Form()}
      {campaign.loading ? (
        <FillingSpinner className='Report__loading' />
      ) : (
        CommonReport.ReportTable(campaign.reports, t)
      )}
    </>
  )
}

export default CampaignReport
