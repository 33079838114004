import api from 'api'
import { InviteOrSaveUserInput, UserForm } from './types'
import { NotifierType } from 'components/Notifier'
import { BaseRoleSelectRawData } from '../service'
import { Routes } from 'routes'
import { SelectAsyncValue } from 'components/Form/SelectAsync'
import { CreateOrInviteUserFR, InviteUserInput } from 'api/user'

export default class UserInviteService {
  public static inviteUser = ({
    user,
    setLoading,
    addNotification,
    navigate,
    t,
  }: InviteOrSaveUserInput): void => {
    setLoading(true)

    void UserInviteService.postMutate({
      mutation: api.user.inviteUser(UserInviteService.prepareForMutate(user)),
      addNotification,
      navigate,
      setLoading,
      t,
    })
  }

  public static prepareForMutate = (userForm: UserForm): InviteUserInput => ({
    email: userForm.email,
    priorities: userForm.priorities,
    roles: UserInviteService.getRoleValues(userForm),
    agencies: userForm.agencies.map(agency => agency.rawData!.id),
    firstName: userForm.firstName,
    lastName: userForm.lastName,
  })

  public static postMutate = ({
    mutation,
    addNotification,
    navigate,
    setLoading,
    t,
  }: { mutation: Promise<CreateOrInviteUserFR> } & Omit<
    InviteOrSaveUserInput,
    'user'
  >): Promise<void> => {
    return mutation
      .then(() => {
        addNotification(NotifierType.SUCCESS, t('notification.inviteUserSuccess'))
        navigate(Routes.USERS.ALL)
      })
      .catch(() => void {})
      .finally(() => void setLoading(false))
  }

  /**
   * Returns only a value like: "Um9sZS00"
   *
   * @param userForm
   */
  public static getRoleValues = (userForm: UserForm): string[] => {
    return userForm.roles.map((role: SelectAsyncValue<BaseRoleSelectRawData>) => role.value)
  }
}
