import React, { ReactElement, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AgeGroupDefaults,
  AudienceFilters,
  CampaignDetailsChartProps,
  CampaignEmission,
  CampaignLocalFilters,
  FilterKey,
  filterKeys,
  RechartsComponentData,
  SexDefaults,
} from './types'
import { FilterContext, filtersDefault, FilterSelect } from 'components/Form/Filters'
import service from './service'
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import VariableUtils from 'utils/variable'
import DateUtils from 'utils/date'
import { DATE_FORMAT } from 'constant'
import FillingSpinner from 'components/FillingSpinner'
import FlexCol from 'components/Layout/FlexCol'
import FlexRow from 'components/Layout/FlexRow'
import Colors from 'styles/colors.module.scss'
import { AppContext } from 'contexts/AppContext'
import { Features } from 'constant/features'
import debounce from 'lodash/debounce'
import './CampaignDetailsChart.scss'
import { CampaignTarget, MediaFormat } from '../../../../types/campaign'
import { usePrevious } from '../../../../hooks/usePrevious/usePrevious'
import ChartSupportService from './chart-support.service'
import { SelectOption } from '../../../../components/Form/Select'
import SelectMultiCheckbox from '../../../../components/Form/SelectMultiCheckbox'

const DEBOUNCE_GET_DELAY = 2000 // [ms]

const CampaignDetailsChart: React.FC<CampaignDetailsChartProps> = ({
  campaign,
  publicViewProps,
  displayedMedia,
}) => {
  const { creations } = campaign
  const { t } = useTranslation()
  const { isFeatureActive } = useContext(AppContext)
  const [filters, setFilters] = useState<CampaignLocalFilters>({
    ...filtersDefault(...filterKeys),
  })
  const previousFiltersRef = usePrevious<CampaignLocalFilters>(filters)
  const [audienceFilters, setAudienceFilters] = useState<AudienceFilters>({
    sex: [...SexDefaults],
    ageGroups: [...AgeGroupDefaults],
  })
  const previousAudienceFiltersRef = usePrevious<AudienceFilters>(audienceFilters)
  const [availableFilters, setAvailableFilters] = useState<CampaignLocalFilters>({
    ...filtersDefault(...filterKeys),
  })
  const [availableAudienceFilters] = useState<AudienceFilters>({
    sex: [...SexDefaults],
    ageGroups: [...AgeGroupDefaults],
  })

  const [emissions, setEmissions] = useState<CampaignEmission[]>([])
  const [focusBar, setFocusBar] = useState<number | null>(null)
  const [emissionsLoading, setEmissionsLoading] = useState<boolean>(true)
  const debouncedGetData = useCallback(debounce(service.getData, DEBOUNCE_GET_DELAY), [])
  const isInDoor =
    campaign.mediaCriteriaSearch.mediumFormat === MediaFormat.DG_IN ||
    campaign.mediaCriteriaSearch.mediumFormat === MediaFormat.DG_IN_H ||
    campaign.mediaCriteriaSearch.mediumFormat === MediaFormat.DG_IN_S
  const isAudienceCampaign = campaign.target === CampaignTarget.AUDIENCE

  useEffect(() => {
    const filters: Record<FilterKey, string[]> = {
      agglomerations: displayedMedia
        .map(media => media.agglomeration?.name || '')
        .filter((el, index, array) => !!el && array.indexOf(el) === index),
      buildings: displayedMedia
        .map(media => media.building?.name || '')
        .filter((el, index, array) => !!el && array.indexOf(el) === index),
      cities: displayedMedia
        .map(media => media.city.name)
        .filter((el, index, array) => array.indexOf(el) === index),
      mediaAsIds: displayedMedia.map(media => media.asId.toString()),
      pois: displayedMedia
        .map(media => media.poiCategories)
        .filter((el, index, array) => !!el && array.indexOf(el) === index)
        .flat(),
      creations: campaign.creations.map(creation => creation.id),
    }

    setAvailableFilters(filters)
    setFilters(filters)
  }, [displayedMedia])

  useEffect(() => {
    /**
     * If filters are the same as previous ones, do not fetch data
     */
    if (
      VariableUtils.isDeepEqual(filters, previousFiltersRef) &&
      VariableUtils.isDeepEqual(audienceFilters, previousAudienceFiltersRef)
    ) {
      return
    }

    debouncedGetData(
      campaign.id,
      publicViewProps,
      filters,
      audienceFilters,
      setEmissions,
      setEmissionsLoading
    )
  }, [filters, audienceFilters])

  const Filters = (): ReactNode => (
    <FlexRow className='CampaignDetailsChart__filters'>
      <FilterContext.Provider
        value={{
          availableFilters,
          filters,
          setFilters,
          disabled: false,
          creations: creations,
          displayedMedia,
          setAvailableFilters,
        }}
      >
        <div className={'CampaignDetailsChart__filters-row'}>
          <FilterSelect
            id='agglomerations'
            title={t('common.agglomeration')}
          />
          <FilterSelect
            id='cities'
            title={t('common.city')}
          />
          <FilterSelect
            id='creations'
            title={t('campaign.creation')}
          />
        </div>

        <div className={'CampaignDetailsChart__filters-row'}>
          {isInDoor && (
            <FilterSelect
              id='buildings'
              title={t('common.building')}
            />
          )}

          <FilterSelect
            id='mediaAsIds'
            title={t('common.medium')}
          />
        </div>

        {isFeatureActive(Features.POI) && (
          <FilterSelect
            id='pois'
            title={t('common.pois')}
          />
        )}
      </FilterContext.Provider>

      {isInDoor && isAudienceCampaign && (
        <SelectMultiCheckbox
          id='sex'
          title={t('common.sex')}
          value={audienceFilters.sex.map(String)}
          options={availableAudienceFilters.sex.map(
            (value: number): SelectOption => ({
              value: value.toString(),
              label: t(`sexFilters.${value}`),
            })
          )}
          onChange={(selected): void => {
            const parsedValues = selected.map(value => parseInt(value))
            void setAudienceFilters({ ...audienceFilters, sex: [...parsedValues] })
          }}
          selectAllOption
        />
      )}

      {isInDoor && isAudienceCampaign && (
        <SelectMultiCheckbox
          id='ageGroups'
          title={t('common.ageGroup')}
          value={audienceFilters.ageGroups.map(String)}
          options={availableAudienceFilters.ageGroups.map(
            (value: number): SelectOption => ({
              value: value.toString(),
              label: t(`ageGroupFilters.${value}`),
            })
          )}
          onChange={(selected): void => {
            const parsedValues = selected.map(value => parseInt(value))
            void setAudienceFilters({ ...audienceFilters, ageGroups: [...parsedValues] })
          }}
          selectAllOption
        />
      )}
    </FlexRow>
  )

  const ChartContainer = (emissions: CampaignEmission[], target: CampaignTarget): ReactElement => {
    const label =
      target === CampaignTarget.IMPRESSIONS ? 'common.numberOfEmissions' : 'common.numberOfAudience'
    const legend =
      target === CampaignTarget.IMPRESSIONS ? 'common.numberOfEmissions' : 'common.numberOfAudience'

    const estimatedAudienceLegend = 'common.numberOfAudienceEstimated'

    return (
      <>
        <div className='CampaignDetailsChart__label'>{t(label)}</div>

        {Chart(emissions, target)}

        <div className='CampaignDetailsChart__legend'>
          <div className='CampaignDetailsChart__legend--item'>
            <div className='CampaignDetailsChart__legend--symbol CampaignDetailsChart__legend--symbol-emitted-views' />
            <div>{t(legend)}</div>
          </div>
          {isAudienceCampaign && (
            <div className='CampaignDetailsChart__legend--item'>
              <div className='CampaignDetailsChart__legend--symbol CampaignDetailsChart__legend--symbol-estimated-audience' />
              <div>{t(estimatedAudienceLegend)}</div>
            </div>
          )}
        </div>
      </>
    )
  }

  const Chart = (emissions: CampaignEmission[], target: CampaignTarget): ReactElement => {
    const dataKey = target === CampaignTarget.IMPRESSIONS ? 'emissions' : 'audience'
    const yAxisId = target === CampaignTarget.IMPRESSIONS ? 'emissionsY' : 'audienceY'

    return (
      <div className='CampaignDetailsChart__chart'>
        <ResponsiveContainer height={320}>
          <ComposedChart
            throttleDelay={100}
            data={emissions}
            maxBarSize={30}
            onMouseMove={({ isTooltipActive, activeTooltipIndex }): void => {
              const payload =
                isTooltipActive && activeTooltipIndex !== undefined ? activeTooltipIndex : null

              if (payload === focusBar) {
                return
              }

              setFocusBar(payload)
            }}
          >
            <CartesianGrid
              stroke={Colors.gray2}
              strokeDasharray='2 2'
            />
            <XAxis
              dataKey='date'
              interval='preserveStartEnd'
              stroke={Colors.gray4}
              padding={{ right: 5 }}
              tick={XaxisTick}
            />
            <YAxis
              dataKey={dataKey}
              yAxisId={yAxisId}
              orientation='left'
              stroke={Colors.gray4}
              padding={{ top: 15 }}
              tickLine={false}
              tick={(props): ReactElement => YaxisTick(props, -37)}
            />
            <Tooltip
              cursor={false}
              content={CustomTooltip}
              offset={10}
            />
            <Bar
              dataKey={dataKey}
              yAxisId={yAxisId}
              radius={[2, 2, 0, 0]}
            >
              {emissions.map((campaignEmission, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={ChartSupportService.getCellColor(target, campaignEmission, index, focusBar)}
                />
              ))}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }

  const XaxisTick = ({ x, y, payload: { value } }: RechartsComponentData): ReactElement => (
    <g
      className='CampaignDetailsChart__axis-tick'
      transform={`translate(${x},${y})`}
    >
      <text
        x={0}
        y={0}
        dx={-28}
        dy={17}
        textAnchor='start'
      >
        {DateUtils.parseAndFormat(value, DATE_FORMAT)}
      </text>
    </g>
  )

  const YaxisTick = (
    { x, y, payload: { value } }: RechartsComponentData,
    customX: number
  ): ReactElement => (
    <g
      className='CampaignDetailsChart__axis-tick'
      transform={`translate(${x},${y})`}
    >
      <text
        x={customX}
        y={0}
        dy={3}
        textAnchor={'start'}
      >
        {value > 0 ? VariableUtils.formatNumberCompact(value) : ''}
      </text>
    </g>
  )

  const CustomTooltip = ({ active, payload, label }: TooltipProps<any, any>): ReactNode => {
    if (!active) {
      return null
    }

    const date = label as string
    const data: CampaignEmission = payload![0].payload
    const audienceMode = campaign.target === CampaignTarget.AUDIENCE

    const topTranslation = ChartSupportService.getTooltipTopTranslationKey(
      audienceMode,
      data.estimated
    )
    const topValue = audienceMode ? data.audience : data.emissions

    const bottomTranslation = ChartSupportService.getTooltipBottomTranslationKey(
      audienceMode,
      data.estimated
    )
    const bottomValue = audienceMode ? data.totalAudience : data.totalEmissions

    const emissionsTranslation = 'common.numberOfEmissions'
    const emissionsValue = data.emissions
    const totalEmissionsTranslation = 'campaignDetails.chart.sumOfEmissions'
    const totalEmissionsValue = data.totalEmissions

    return (
      <div className='CampaignDetailsChart__tooltip'>
        <div className='CampaignDetailsChart__tooltip--header'>
          {DateUtils.parseAndFormat(date, DATE_FORMAT)}
        </div>
        <FlexRow className='CampaignDetailsChart__tooltip--row'>
          <FlexCol className='CampaignDetailsChart__tooltip--col'>{t(topTranslation)}:</FlexCol>
          <FlexCol className='CampaignDetailsChart__tooltip--col'>
            {VariableUtils.formatNumber(topValue)}
          </FlexCol>
        </FlexRow>
        <FlexRow className='CampaignDetailsChart__tooltip--row'>
          <FlexCol className='CampaignDetailsChart__tooltip--col'>{t(bottomTranslation)}:</FlexCol>
          <FlexCol className='CampaignDetailsChart__tooltip--col'>
            {VariableUtils.formatNumber(bottomValue)}
          </FlexCol>
        </FlexRow>
        {audienceMode && (
          <>
            <FlexRow className='CampaignDetailsChart__tooltip--row'>
              <FlexCol className='CampaignDetailsChart__tooltip--col'>
                {t(emissionsTranslation)}:
              </FlexCol>
              <FlexCol className='CampaignDetailsChart__tooltip--col'>
                {VariableUtils.formatNumber(emissionsValue)}
              </FlexCol>
            </FlexRow>
            <FlexRow className='CampaignDetailsChart__tooltip--row'>
              <FlexCol className='CampaignDetailsChart__tooltip--col'>
                {t(totalEmissionsTranslation)}:
              </FlexCol>
              <FlexCol className='CampaignDetailsChart__tooltip--col'>
                {VariableUtils.formatNumber(totalEmissionsValue)}
              </FlexCol>
            </FlexRow>
          </>
        )}
      </div>
    )
  }

  return (
    <div className='CampaignDetailsChart'>
      {Filters()}

      {emissionsLoading ? (
        <FillingSpinner className='CampaignDetailsChart--loading' />
      ) : emissions.length > 0 ? (
        ChartContainer(emissions, campaign.target)
      ) : (
        <div className='CampaignDetailsChart__no-data'>{t('campaignDetails.chart.noData')}</div>
      )}
    </div>
  )
}

export default CampaignDetailsChart
