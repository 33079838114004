import React, { useContext } from 'react'
import { default as DayPickerRaw, DayPickerProps as DayPickerPropsRaw } from 'react-day-picker'
import { DayPickerProps } from './types'
import DayPickerNavbar from '../DayPickerNavbar'
import { AppContext } from 'contexts/AppContext'

// TODO: Remove moment.js dependency
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/en-gb'
import 'moment/locale/pl'

import 'react-day-picker/lib/style.css'
import '../DatePickerRange/DatePickerRange.scss'

const DayPicker: React.FC<DayPickerProps> = ({ value, onChange, disabledDays }) => {
  const { userData } = useContext(AppContext)

  const handleDayClick: DayPickerPropsRaw['onDayClick'] = (day, modifiers) => {
    if (modifiers.disabled) return
    onChange(day)
  }

  const modifiers = {
    'first-day-from-range': () => true, // For proper styling `selected` from DatePickerRange
  }

  return (
    <DayPickerRaw
      className='DatePickerRange'
      localeUtils={MomentLocaleUtils}
      locale={userData.user?.preferredLocale as string}
      navbarElement={DayPickerNavbar}
      firstDayOfWeek={1}
      onDayClick={handleDayClick}
      selectedDays={[value]}
      month={value}
      modifiers={modifiers}
      disabledDays={disabledDays}
    />
  )
}

export default DayPicker
