import { iterateOverTail } from './iterator'
import { GroupedMedia, FilterableMedium, MediaGroup } from './types'

export const collectMedia = (groupedMedia: GroupedMedia): FilterableMedium[] => {
  const media: FilterableMedium[] = []

  Object.values(groupedMedia).map(
    (mediaGroup: MediaGroup) =>
      void iterateOverTail({
        groupTail: mediaGroup,
        nodeCallback: node => {
          media.push(node)
        },
      })
  )

  return media
}
