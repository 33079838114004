import React, { ReactNode, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from 'contexts/CampaignFormContext'
import Button, { ButtonTheme } from 'components/Form/Button'
import { Routes } from 'routes'
import useCampaignAction from 'hooks/useCampaignAction'
import { FormType } from 'types/various'
import ModalAction from 'components/Modal/ModalAction'
import { AppContext } from 'contexts/AppContext'
import { CAMPAIGN_ACTION } from 'constant/authorization'
import VariableUtils from 'utils/variable'
import { ApprovalProps } from './types'
import Modal from 'components/Modal'
import Reservation, { ReservationService } from '../Reservation'
import './CampaignActions.scss'
import { useSelector } from 'react-redux'
import { DetailsFormSliceModel } from '../DetailsForm/store/details-form-slice'
import { useNavigate } from 'react-router-dom'
import { NotifierType } from '../../../../components/Notifier'
import { CampaignAcceptanceStatus } from '../../../../types/campaign'
import { RootState } from '../../../../store'
import { CampaignPermissionType, FeatureName } from 'types/features'
import { ActionButtonsHelper } from '../../../../utils/action-buttons-helper'

const CampaignActions: React.FC<ApprovalProps> = ({
  campaignData: { id, startDate, reservationTill, status },
  formType,
}) => {
  const { t } = useTranslation()
  const { allowedFor, userData } = useContext(AppContext)
  const { basicsValues, detailsValues } = useSelector((state: RootState) => state.campaign.form)
  const { campaignId, brainState, name } = basicsValues
  const { acceptanceStatus } = detailsValues
  const { creationsValues } = useContext(CampaignFormContext)
  const { acceptCampaign, publishCampaign, sendCampaignToAcceptance, rejectCampaign } =
    useCampaignAction()
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false)
  const [approveCampaignLoading, setApproveCampaignLoading] = useState<boolean>(false)
  const [rejectCampaignLoading, setRejectCampaignLoading] = useState<boolean>(false)
  const [isPublishModalOpen, setIsPublishModalOpen] = useState<boolean>(false)
  const [publishCampaignLoading, setPublishCampaignLoading] = useState<boolean>(false)
  const [isReserveModalOpen, setIsReserveModalOpen] = useState<FormType | null>(null)
  const disableMediaSelection = useSelector(
    state => (state as { detailsForm: DetailsFormSliceModel }).detailsForm.disableMediaSelection
  )

  const canEdit = allowedFor({
    template: CAMPAIGN_ACTION.EDIT,
    feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Write },
    status,
  })

  const canApprove = ActionButtonsHelper.getCanApprove(
    allowedFor,
    status,
    userData.withAllocatedTime
  )

  const canPublish = ActionButtonsHelper.getCanPublish(
    allowedFor,
    status,
    userData.withAllocatedTime
  )

  const canSendToAcceptance = ActionButtonsHelper.getCanSendToAcceptance(
    allowedFor,
    status,
    userData.withAllocatedTime,
    false
  )

  const canReserve = allowedFor({ template: CAMPAIGN_ACTION.RESERVE, status })
  const canEditReservation = allowedFor({
    template: CAMPAIGN_ACTION.EDIT_RESERVATION,
    status,
  })
  const reservationDisabled = ReservationService.shouldDisableReservation({ startDate })
  const closeReservationModal = () => void setIsReserveModalOpen(null)

  const showContainer = canEdit || canApprove || canPublish
  const navigate = useNavigate()
  const { addNotification } = useContext(AppContext)

  const saveCampaign = () => {
    navigate(Routes.CAMPAIGNS.ONE(campaignId))
    addNotification(NotifierType.SUCCESS, t('form.campaign.save.success'))
  }

  const setSendToAcceptance = useSelector(
    state => (state as RootState).creationAcceptances.setDisableSendToAcceptance
  )
  const Container = (): ReactNode => (
    <div className='CampaignActions'>
      {canEdit &&
        (formType === FormType.NEW ? (
          <Button
            theme={ButtonTheme.BLUE_OUTLINE}
            toUrl={Routes.CAMPAIGNS.ONE(campaignId)}
            disabled={disableMediaSelection || setSendToAcceptance}
          >
            {t('form.campaign.approval.saveAsDraft')}
          </Button>
        ) : (
          <Button
            theme={ButtonTheme.BLUE_OUTLINE}
            onClick={() => void saveCampaign()}
            disabled={disableMediaSelection || setSendToAcceptance}
            dataCy={'save-campaign-btn'}
          >
            {t('common.save')}
          </Button>
        ))}

      {canApprove && (
        <Button
          dataCy={'activate-campaign-btn'}
          disabled={disableMediaSelection || setSendToAcceptance}
          onClick={() => void setIsApproveModalOpen(true)}
        >
          {t('common.activateCampaign')}
        </Button>
      )}

      {canApprove && acceptanceStatus === CampaignAcceptanceStatus.pending_acceptation && (
        <Button
          theme={ButtonTheme.RED_OUTLINE}
          dataCy={'reject-campaign-btn'}
          onClick={() => void setIsRejectModalOpen(true)}
        >
          {t('common.rejectCampaign')}
        </Button>
      )}

      {canPublish && (
        <Button
          onClick={() => void setIsPublishModalOpen(true)}
          disabled={
            VariableUtils.isEmptyObject(brainState) || disableMediaSelection || setSendToAcceptance
          }
        >
          {t('common.publishChanges')}
        </Button>
      )}

      {canSendToAcceptance && (
        <Button
          onClick={() => void sendCampaignToAcceptance(campaignId)}
          dataCy={'send-campaign-to-acceptance-btn'}
          disabled={
            disableMediaSelection ||
            acceptanceStatus === CampaignAcceptanceStatus.pending_acceptation ||
            creationsValues.length === 0
          }
        >
          {t('common.sendToAcceptance')}
        </Button>
      )}

      {canReserve && (
        <Button
          onClick={() => void setIsReserveModalOpen(FormType.NEW)}
          theme={ButtonTheme.BLUE_OUTLINE}
          disabled={reservationDisabled || disableMediaSelection || setSendToAcceptance}
          tooltip={reservationDisabled ? t('reservation.disabledInfo') : undefined}
        >
          {t('reservation.reserve')}
        </Button>
      )}

      {canEditReservation && (
        <Button
          onClick={() => void setIsReserveModalOpen(FormType.EDIT)}
          theme={ButtonTheme.BLUE_OUTLINE}
          disabled={reservationDisabled || disableMediaSelection}
        >
          {t('reservation.edit')}
        </Button>
      )}

      {isApproveModalOpen && (
        <ModalAction
          title={t('modal.approveCampaign')}
          itemText={t('modal.doYouWantToApproveCampaign')}
          itemName={name}
          actionText={t('modal.activate')}
          isOpen={isApproveModalOpen}
          setIsOpen={setIsApproveModalOpen}
          isLoading={approveCampaignLoading}
          setIsLoading={setApproveCampaignLoading}
          onAction={() => acceptCampaign(campaignId)}
        />
      )}

      {isRejectModalOpen && (
        <ModalAction
          title={t('modal.rejectCampaign')}
          itemText={t('modal.doYouWantToRejectCampaign')}
          itemName={name}
          actionText={t('modal.reject')}
          isOpen={isRejectModalOpen}
          setIsOpen={setIsRejectModalOpen}
          isLoading={rejectCampaignLoading}
          setIsLoading={setRejectCampaignLoading}
          onAction={() => rejectCampaign(campaignId)}
        />
      )}

      {isPublishModalOpen && (
        <ModalAction
          title={t('modal.publishingChanges')}
          itemText={t('modal.doYouWantToPublishCampaign')}
          itemName={name}
          actionText={t('modal.publish')}
          isOpen={isPublishModalOpen}
          setIsOpen={setIsPublishModalOpen}
          isLoading={publishCampaignLoading}
          setIsLoading={setPublishCampaignLoading}
          onAction={() => publishCampaign(campaignId)}
        />
      )}

      {isReserveModalOpen !== null && (
        <Modal
          title={t('reservation.title')}
          isOpen={isReserveModalOpen !== null}
          onClose={closeReservationModal}
        >
          <Reservation
            formType={isReserveModalOpen}
            campaignData={{
              id,
              reservationTill,
              startDate,
            }}
            setIsOpenModal={state => {
              if (state === false) closeReservationModal()
            }}
          />
        </Modal>
      )}
    </div>
  )

  return <>{showContainer && Container()}</>
}

export default CampaignActions
