import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'api/fragments'

const PAYLOAD_TYPE = 'ConfirmCampaignReservationPayload'

export const CONFIRM_CAMPAIGN_RESERVATION = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation confirmCampaignReservation($id: ID!) {
    confirmCampaignReservation(input: { id: $id }) {
      campaign {
        id
      }
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
