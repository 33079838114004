import React, { ReactElement } from 'react'
import { Handle, Range } from 'rc-slider'
import InputTitle from '../InputTitle'
import { OnInputChangeFn } from '../Input'
import Colors from 'styles/colors.module.scss'
import TsxUtils from 'utils/tsx'
import 'rc-slider/assets/index.css'
import './RangeSlider.scss'

interface RangeSliderProps {
  id: string
  title?: string
  className?: string
  help?: string
  minLabel?: string
  maxLabel?: string
  value: [number, number]
  onChange: OnInputChangeFn
  min: number
  max: number
}

const Slider: React.FC<RangeSliderProps> = ({
  id,
  title,
  className,
  help,
  value,
  onChange,
  min,
  max,
  minLabel,
  maxLabel,
}) => {
  const handleEl = (props: any): ReactElement => {
    const { dragging, ...restProps } = props // eslint-disable-line react/prop-types
    return (
      <Handle
        key={restProps.index}
        dragging={dragging.toString()}
        {...restProps}
      >
        <div className='Slider__handle'>{restProps.value}</div>
      </Handle>
    )
  }

  return (
    <div
      className={'RangeSlider' + TsxUtils.extraStyle(className, className)}
      id={id}
    >
      {title && (
        <InputTitle
          className='Slider__title'
          title={title}
          help={help}
        />
      )}
      <Range
        className={'Range'}
        min={min}
        max={max}
        value={[value[0], value[value.length - 1]]}
        onChange={onChange}
        allowCross={true}
        handle={handleEl}
        railStyle={{
          height: 8,
          borderRadius: 10,
          backgroundColor: Colors.gray2,
        }}
        trackStyle={[
          {
            height: 8,
            borderRadius: 10,
            backgroundColor: Colors.blue,
          },
        ]}
        handleStyle={[
          {
            top: '5px',
            height: '12px',
            width: '12px',
            borderColor: Colors.blue,
            accentColor: Colors.blue,
          },
          {
            top: '5px',
            height: '12px',
            width: '12px',
            borderColor: Colors.blue,
            accentColor: Colors.blue,
          },
        ]}
      ></Range>
      {minLabel && maxLabel && (
        <div className='RangeSlider__labels'>
          <div>{minLabel}</div>
          <div>{maxLabel}</div>
        </div>
      )}
    </div>
  )
}
export default Slider
