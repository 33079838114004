import { Campaign, CampaignStatus } from '../../../types/campaign'
import React from 'react'
import CampaignUtils from '../../../utils/campaign'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DateUtils from '../../../utils/date'
import { DATE_HOUR_FORMAT } from '../../../constant'

const CampaignStatusComponent: React.FC<{
  status: Campaign['status']
  acceptanceStatus: Campaign['acceptanceStatus']
  reservationTill?: Campaign['reservationTill']
}> = ({ status, acceptanceStatus, reservationTill }) => {
  const statusParsed = CampaignUtils.parseStatus(status, acceptanceStatus)

  return (
    <div className={`CampaignDetails__status CampaignDetails__status--${statusParsed.iconTheme}`}>
      <FontAwesomeIcon
        className='CampaignDetails__status--icon'
        icon={statusParsed.icon}
      />
      {statusParsed.text}
      {status === CampaignStatus['RESERVED'] && reservationTill && (
        <div className='CampaignDetails__status--reservation'>
          {DateUtils.parseAndFormat(reservationTill, DATE_HOUR_FORMAT)}
        </div>
      )}
    </div>
  )
}

export default CampaignStatusComponent
