import { defaultFeatures, Features } from 'constant/features'

export interface UseFeatureProps {
  features: Feature[]
  isFeatureActive: (name: Feature['name']) => boolean
  setFeatureState: (name: Feature['name'], state: Feature['state']) => void
  resetFeatures: () => void
}

export interface Feature {
  name: Features
  state: boolean
}

export const useFeaturePropsDefault: UseFeatureProps = {
  features: defaultFeatures,
  isFeatureActive: () => false,
  setFeatureState: () => void {},
  resetFeatures: () => void {},
}
