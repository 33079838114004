import React from 'react'
import FillingSpinner from '../FillingSpinner'
import { TableProps, TableContainerProps } from './types'
import TableContextProvider from 'contexts/TableContext'
import TsxUtils from 'utils/tsx'
import './Table.scss'

const TableContainer: React.FC<TableContainerProps> = ({
  className,
  loading,
  sort,
  onSort,
  children,
}) => (
  <TableContextProvider
    sort={sort}
    onSort={onSort}
  >
    <Table
      className={className}
      loading={loading}
    >
      {children}
    </Table>
  </TableContextProvider>
)

const Table: React.FC<TableProps> = ({ className, loading, children }) => (
  <div className='Table__container'>
    {loading ? (
      <FillingSpinner />
    ) : (
      <table className={'Table' + TsxUtils.extraStyle(className, className)}>{children}</table>
    )}
  </div>
)

export default TableContainer
