import Button, { ButtonTheme } from 'components/Form/Button'
import Modal from 'components/Modal'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import './ReInviteModal.scss'
import FormRow from '../../../components/Form/FormRow'
import FormColumn from '../../../components/Form/FormColumn'
import Input from '../../../components/Form/Input'

type Props = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  invitationUrl?: string
}

const ReInviteModal: React.FC<Props> = ({ isOpen, setIsOpen, invitationUrl }) => {
  const { t } = useTranslation()

  const Footer = () => {
    return (
      <div className='Modal__footer ReinviteModal__footer'>
        <Button
          disabled={!invitationUrl}
          onClick={() => {
            invitationUrl && navigator.clipboard.writeText(invitationUrl)
          }}
        >
          {t('common.copyButton')}
        </Button>
        <Button
          theme={ButtonTheme.BLUE_OUTLINE}
          onClick={() => void setIsOpen(false)}
        >
          {t('common.back')}
        </Button>
      </div>
    )
  }

  return (
    <Modal
      classNameChildren={'ReInviteModal'}
      title={t('user.reInviteModal.header')}
      isOpen={isOpen}
      onClose={setIsOpen}
      footer={Footer()}
      scrollable
    >
      <div className={'ReInviteContainer'}>
        <FormRow>
          <FormColumn>
            <Input
              id='email'
              title={t('user.reInviteModal.description')}
              placeholder={t('user.reInviteModal.placeholder')}
              value={invitationUrl ?? 'n/o'}
              onChange={() => void {}}
              disabled
            />
          </FormColumn>
        </FormRow>
      </div>
    </Modal>
  )
}

export default ReInviteModal
