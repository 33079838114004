import React, { ReactElement } from 'react'
import { View } from '@react-pdf/renderer'
import { Table, TableData, TableRow, TableTitle } from 'components/TablePdf'
import { t } from 'i18next'
import { PdfAudienceAge } from '../../../../../api/campaign/types'

type Props = {
  audienceAge: PdfAudienceAge[]
}

export const AudienceAge = ({ audienceAge }: Props): ReactElement => {
  const audienceAgeWithTranslations: { text: string; value: string }[] = audienceAge.map(item => {
    return {
      text: item.ageGroup,
      value: `${item.percent}%`,
    }
  })

  return (
    <View>
      <Table>
        <TableTitle>{t('common.audienceAge')}</TableTitle>

        {audienceAgeWithTranslations.map(row => (
          <TableRow key={row.text}>
            <TableData width={40}>{row.text}</TableData>
            <TableData width={60}>{row.value}</TableData>
          </TableRow>
        ))}
      </Table>
    </View>
  )
}
