import React, { ReactNode } from 'react'
import TsxUtils from 'utils/tsx'
import './FlexRow.scss'

export interface FlexRowProps {
  className?: string
  children: ReactNode | ReactNode[]
}

const FlexRow: React.FC<FlexRowProps> = ({ className, children }) => (
  <div className={'FlexRow' + TsxUtils.extraStyle(className, className)}>{children}</div>
)

export default FlexRow
