import React, { ReactChild } from 'react'
import FillingSpinner, { FillingSpinnerType } from 'components/FillingSpinner'
import CardNumber from '../CardNumber'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamationCircle, faAngleUp, faPlus } from '@fortawesome/free-solid-svg-icons'
import { CardHeaderProps } from './types'
import { CardStatus } from '../Card'
import { useTranslation } from 'react-i18next'
import { FillingSpinnerColor } from 'components/FillingSpinner/types'
import TsxUtils from 'utils/tsx'
import Button from 'components/Form/Button'
import './CardHeader.scss'

const CardHeader: React.FC<CardHeaderProps> = ({
  className,
  number = '',
  status = CardStatus.NONE,
  children,
  collapsed,
  onCollapse,
  link,
  linkTooltip,
  dataCy,
}) => {
  const { t } = useTranslation()

  const isCollapsible = collapsed !== undefined

  const getCardStatus = (status: CardStatus): ReactChild => {
    switch (status) {
      case CardStatus.NONE:
        return ''

      case CardStatus.LOADING:
        return (
          <div className='CardHeader__status'>
            <FillingSpinner type={FillingSpinnerType.STANDARD} />
          </div>
        )

      case CardStatus.SAVING:
        return (
          <div className='CardHeader__status'>
            <div className='CardHeader__status--text CardHeader__status--green'>
              {t('status.saving')}
            </div>
            <FillingSpinner
              color={FillingSpinnerColor.GREEN}
              type={FillingSpinnerType.STANDARD}
            />
          </div>
        )

      case CardStatus.SAVED:
        return (
          <div className='CardHeader__status CardHeader__status--fade-in'>
            <div className='CardHeader__status--text CardHeader__status--green'>
              {t('status.saved')}
            </div>
            <FontAwesomeIcon
              icon={faCheck}
              className='CardHeader__status--green'
            />
          </div>
        )

      case CardStatus.ERROR:
        return (
          <div className='CardHeader__status'>
            <div className='CardHeader__status--text CardHeader__status--red'>
              {t('status.error')}
            </div>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className='CardHeader__status--red'
            />
          </div>
        )
    }
  }

  return (
    <div
      data-cy={dataCy}
      className={
        'CardHeader' +
        TsxUtils.extraStyle(className, className) +
        TsxUtils.extraStyle(isCollapsible, 'CardHeader--clickable') +
        TsxUtils.extraStyle(collapsed, 'CardHeader--collapsed')
      }
      onClick={() => {
        if (isCollapsible && onCollapse) onCollapse(!collapsed)
      }}
    >
      {number && <CardNumber number={number} />}

      <h3 className='CardHeader__heading'>{children}</h3>

      {link && (
        <Button
          className='CardHeader__link'
          icon={faPlus}
          tooltip={linkTooltip}
          toUrl={link}
        ></Button>
      )}

      {getCardStatus(status)}

      {isCollapsible && onCollapse && (
        <div className='CardHeader__collapse-btn--container'>
          <div
            className={'CardHeader__collapse-btn'}
            onClick={event => {
              event.stopPropagation()
              void onCollapse(!collapsed)
            }}
          >
            <FontAwesomeIcon
              icon={faAngleUp}
              className={
                'CardHeader__collapse-icon' +
                TsxUtils.extraStyle(collapsed, 'CardHeader__collapse-icon--collapsed')
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default CardHeader
