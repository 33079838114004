import { client } from 'components/ApolloProvider'
import { loader } from 'graphql.macro'
import { Campaign, Creation, CreationSchedule, MediumUsage } from 'types/campaign'
import { CREATE_CREATIONS } from './createCreations'
import { DELETE_CREATION } from './deleteCreation'
import { ARCHIVE_CREATION } from './archiveCreation'
import { GET_CREATION_SCHEDULES, GET_CREATION_SCHEDULES_PUBLIC } from './getCreationSchedules'
import * as CAT from './types' // Creation API types
import { CampaignAcceptanceReportFileResponse } from './types'
import { ModalUploadFile } from 'components/Modal/ModalUpload'
import { GET_CREATION_MEDIA_USAGE, GET_CREATION_MEDIA_USAGE_PUBLIC } from './getMediaUsage'
import { UPDATE_CREATION_MEDIA_USAGE } from './updateMediaUsage'
import { UPDATE_CREATION_DETAILS } from './updateDetails'
import { GET_CAMPAIGN_ACCEPTANCES } from './getCampaignAcceptances'
import { SEND_TO_ACCEPTANCE } from './sendToAcceptance'
import { UPDATE_CREATION_DURATION } from './updateDuration'
import { FetchResult } from '@apollo/client'
import { MutationStateResponse } from '../../types/various'
import { UPDATE_CREATION_EMISSIONS_NUMBER } from './updateCreationEmissionsNumber'
import { UPDATE_CREATION_CONTAINS_ALCOHOL } from './updateCreationContainsAlcohol'
import { UPDATE_CREATION_AUDIENCE_NUMBER } from './updateCreationAudienceNumber'
import { GET_CAMPAIGN_ACCEPTANCE_REPORT_FILE } from './getCampiagnAcceptancesReportFile'

const creationsApi = {
  createCreations: (variables: {
    campaignId: Campaign['id']
    mediaFormat: string | undefined
    files: ModalUploadFile[]
    duration: number
    containsAlcohol: boolean
  }): Promise<CAT.CreateCreationsFR> =>
    client.mutate({
      mutation: CREATE_CREATIONS,
      variables,
      errorPolicy: 'all',
    }),

  updateCreation: (
    creationId: Creation['id'],
    approved: Creation['approved']
  ): Promise<FetchResult<{ updateCreationApproval: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./updateCreationApproval.gql'),
      variables: { creationId, approved },
    }),

  deleteCreation: (creationId: Creation['id']): Promise<CAT.DeleteCreationFR> =>
    client.mutate({
      mutation: DELETE_CREATION,
      variables: { creationId },
    }),

  archiveCreation: (
    creationId: Creation['id'],
    archiveReason: Creation['archiveReason']
  ): Promise<CAT.ArchiveCreationFR> =>
    client.mutate({
      mutation: ARCHIVE_CREATION,
      variables: { creationId, archiveReason },
    }),

  getCreationSchedules: (creationId: Creation['id']): Promise<CAT.GetCreationSchedulesAQR> =>
    client.query({
      query: GET_CREATION_SCHEDULES,
      variables: { creationId },
    }),

  getCreationSchedulesPublic: (
    uuid: Creation['uuid']
  ): Promise<CAT.GetCreationSchedulesPublicAQR> =>
    client.query({
      query: GET_CREATION_SCHEDULES_PUBLIC,
      variables: { uuid },
    }),

  updateCreationSchedule: (
    creationId: Creation['id'],
    schedules: CreationSchedule[]
  ): Promise<FetchResult<{ updateCreationSchedule: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./updateCreationSchedule.gql'),
      variables: { creationId, schedules },
    }),

  updateCreationDetails: (variables: {
    creationId: Creation['id']
    details: Creation['details']
  }): Promise<CAT.UpdateCreationDetailsFR> =>
    client.mutate({
      mutation: UPDATE_CREATION_DETAILS,
      variables,
    }),

  updateCreationDuration: (variables: {
    creationId: Creation['id']
    duration: Creation['duration']
  }): Promise<CAT.UpdateCreationDurationFR> =>
    client.mutate({
      mutation: UPDATE_CREATION_DURATION,
      variables,
    }),

  updateCreationContainsAlcohol: (variables: {
    creationId: Creation['id']
    containsAlcohol: Creation['containsAlcohol']
  }): Promise<CAT.UpdateCreationContainsAlcoholFR> =>
    client.mutate({
      mutation: UPDATE_CREATION_CONTAINS_ALCOHOL,
      variables,
    }),

  getMediaUsage: (variables: {
    creationId: Creation['id']
  }): Promise<CAT.GetCreationMediaUsageAQR> =>
    client.query({
      query: GET_CREATION_MEDIA_USAGE,
      variables,
    }),

  getMediaUsagePublic: (variables: {
    creationUuid: Creation['uuid']
  }): Promise<CAT.GetCreationMediaUsagePublicAQR> =>
    client.query({
      query: GET_CREATION_MEDIA_USAGE_PUBLIC,
      variables,
    }),

  updateMediaUsage: (variables: {
    creationId: Creation['id']
    media: MediumUsage[]
  }): Promise<CAT.UpdateCreationMediaUsageFR> =>
    client.mutate({
      mutation: UPDATE_CREATION_MEDIA_USAGE,
      variables,
    }),

  getCampaignAcceptances: (id: Campaign['id']): Promise<CAT.GetCampaignAcceptancesAQR> =>
    client.query({
      query: GET_CAMPAIGN_ACCEPTANCES,
      variables: { id },
    }),

  sendToAcceptance: (id: Campaign['id']): Promise<CAT.SendToAcceptanceFR> =>
    client.mutate({
      mutation: SEND_TO_ACCEPTANCE,
      variables: { id },
    }),

  updateCreationEmissionsNumber: (
    creationId: Creation['id'],
    impressions: number
  ): Promise<CAT.UpdateCreationEmissionsNumberFR> =>
    client.mutate({
      mutation: UPDATE_CREATION_EMISSIONS_NUMBER,
      variables: { creationId, impressions },
    }),

  updateCreationAudienceNumber: (
    creationId: Creation['id'],
    audience: number
  ): Promise<CAT.UpdateCreationAudienceNumberFR> =>
    client.mutate({
      mutation: UPDATE_CREATION_AUDIENCE_NUMBER,
      variables: { creationId, audience },
    }),

  fetchCampaignAcceptanceReportFile: (
    campaignId: Campaign['id']
  ): Promise<CampaignAcceptanceReportFileResponse> => {
    return client.query({
      query: GET_CAMPAIGN_ACCEPTANCE_REPORT_FILE,
      variables: { campaignId: campaignId },
    })
  },
}

export default creationsApi
