import React, { ReactChild, useEffect } from 'react'
import { NotifierProps, NotifierType } from './types'
import { faCheckCircle, faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { NOTIFIER_CLOSE_TIME } from 'constant'
import './Notifier.scss'

const Notifier: React.FC<NotifierProps> = ({ id, type, text, time, onRemoveNotification }) => {
  useEffect(() => {
    setTimeout(() => onRemoveNotification(id), time ? time : NOTIFIER_CLOSE_TIME)
  }, [id, onRemoveNotification, time])

  const getIcon = (type: NotifierType): IconProp => {
    switch (type) {
      case NotifierType.SUCCESS:
        return faCheckCircle
      case NotifierType.WARNING:
        return faInfoCircle
      case NotifierType.ERROR:
        return faExclamationCircle
    }
  }

  return (
    <div
      className={`Notifier Notifier--${type}`}
      onClick={(): void => void onRemoveNotification(id)}
    >
      <div className={`Notifier__icon Notifier__icon--${type}`}>
        <FontAwesomeIcon icon={getIcon(type)} />
      </div>

      <div className='Notifier__text'>
        {Array.isArray(text)
          ? text.map(
              (t: string, i: number): ReactChild => (
                <div
                  className='Notifier__text--row'
                  key={i}
                >
                  {t}
                </div>
              )
            )
          : text}
      </div>
    </div>
  )
}

export default Notifier
