import api from '../../../../../api'
import { TFunction } from 'react-i18next'

export default class CreationEmissionsNumberService {
  static updateCreationEmissionsNumber = (
    creationId: string,
    emissions: number,
    setEmissionsNumber: React.Dispatch<React.SetStateAction<string | null>>,
    setLoadingEmissionsNumber: React.Dispatch<React.SetStateAction<boolean>>,
    setErrors: React.Dispatch<React.SetStateAction<string[]>>,
    t: TFunction
  ) => {
    api.creation
      .updateCreationEmissionsNumber(creationId, emissions)
      .then(res =>
        setEmissionsNumber(
          res.data?.updateCreationImpressions.creation.impressions
            ? res.data?.updateCreationImpressions.creation.impressions.toString()
            : null
        )
      )
      .finally(() => {
        setLoadingEmissionsNumber(false)
      })
      .catch(errors => {
        if (errors) {
          setErrors([t('form.campaign.creations.invalidCreationEmissions')])
        }
      })
  }
}
