import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Input, { InputType } from 'components/Form/Input'
import Button from 'components/Form/Button'
import { useTranslation } from 'react-i18next'
import { formErrorsDefault, Form, formDefault } from './types'
import { Routes } from 'routes'
import queryString from 'query-string'
import { User } from 'types/user'
import service from './service'
import ErrorUtils from 'utils/error'
import { FormErrors } from 'types/various'
import { AppContext } from 'contexts/AppContext'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import './AcceptInvitation.scss'

const AcceptInvitation: React.FC = () => {
  const { addNotification } = useContext(AppContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [token, setToken] = useState<User['token']>()
  const [values, setValues] = useState<Form>(formDefault)
  const [errors, setErrors] = useState<FormErrors>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const urlToken = queryString.parse(location.search).token

    if (urlToken) {
      setToken(urlToken as string)
    } else {
      navigate(Routes.NOT_FOUND)
    }
  }, [location])

  useEffect(() => {
    if (!ErrorUtils.haveErrors(errors, formErrorsDefault)) {
      service.acceptInvitation(values, token, navigate, setLoading, addNotification, t)
    }
  }, [errors])

  const onSubmit = (): void => void service.validate(values, errors, setErrors)

  return (
    <>
      <h1 className='AcceptInvitation__header'>{t('sign.acceptInvitation.createNew')}</h1>

      <div className='AcceptInvitation__form'>
        <Input
          id='firstName'
          title={t('common.firstName')}
          placeholder={t('common.enterFirstName')}
          value={values.firstName}
          onChange={(firstName): void => void setValues({ ...values, firstName })}
          disabled={loading}
          errors={errors?.firstName}
        />
        <Input
          id='lastName'
          title={t('common.lastName')}
          placeholder={t('common.enterLastName')}
          value={values.lastName}
          onChange={lastName => void setValues({ ...values, lastName })}
          disabled={loading}
          errors={errors?.lastName}
        />
        <Input
          id='password'
          title={t('common.password')}
          placeholder={t('common.enterPassword')}
          type={InputType.PASSWORD}
          value={values.password}
          onChange={password => void setValues({ ...values, password })}
          disabled={loading}
          errors={errors?.password}
        />
        <Input
          id='passwordConfirmation'
          title={t('common.repeatPassword')}
          placeholder={t('common.enterPasswordAgain')}
          type={InputType.PASSWORD}
          value={values.passwordConfirmation}
          onChange={(passwordConfirmation): void =>
            void setValues({ ...values, passwordConfirmation })
          }
          disabled={loading}
          errors={errors?.passwordConfirmation}
        />

        <Button
          icon={loading ? faSpinner : undefined}
          className='AcceptInvitation__form--button'
          disabled={loading}
          onClick={onSubmit}
        >
          {t('sign.acceptInvitation.createAccount')}
        </Button>
      </div>
    </>
  )
}

export default AcceptInvitation
