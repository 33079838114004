import { CampaignEmission } from './types'
import Colors from '../../../../styles/colors.module.scss'
import { CampaignTarget } from '../../../../types/campaign'

export default class ChartSupportService {
  static getCellColor = (
    target: CampaignTarget,
    campaignEmission: CampaignEmission,
    index: number,
    focusBar: number | null
  ): string => {
    if (target === CampaignTarget.IMPRESSIONS && focusBar === index) {
      return Colors.blue
    }

    if (target === CampaignTarget.IMPRESSIONS) {
      return Colors.chartBar
    }

    if (campaignEmission.estimated && focusBar === index) {
      return Colors.gray5
    }

    if (campaignEmission.estimated) {
      return Colors.gray4
    }

    if (focusBar === index) {
      return Colors.blue
    }

    return Colors.chartBar
  }

  static getTooltipTopTranslationKey = (
    audienceMode: boolean,
    estimated: boolean | null
  ): string => {
    if (audienceMode && estimated) {
      return 'common.numberOfAudienceEstimated'
    }

    if (audienceMode) {
      return 'common.numberOfAudience'
    }

    return 'common.numberOfEmissions'
  }

  static getTooltipBottomTranslationKey = (
    audienceMode: boolean,
    estimated: boolean | null
  ): string => {
    if (audienceMode && estimated) {
      return 'campaignDetails.chart.sumOfAudienceEstimated'
    }

    if (audienceMode) {
      return 'campaignDetails.chart.sumOfAudience'
    }

    return 'campaignDetails.chart.sumOfEmissions'
  }
}
