import { GetCampaignForEmissionScheduleData } from 'api/campaign/types'
import { EmissionScheduleRouteParams } from 'routes'

export interface Collapsed {
  schedule: boolean
  locations: boolean
  emissionNumber: boolean
  map: boolean
}

export const collapsedDefault: Collapsed = {
  schedule: false,
  locations: true,
  emissionNumber: true,
  map: false,
}

export type CampaignData = GetCampaignForEmissionScheduleData

export interface PrivateViewIds {
  urlCampaignId: EmissionScheduleRouteParams['campaignId']
  urlCreationId: EmissionScheduleRouteParams['creationId']
}

export interface PublicViewIds {
  urlCampaignUuid: EmissionScheduleRouteParams['campaignUuid']
  urlCreationUuid: EmissionScheduleRouteParams['creationUuid']
}
