import React, { useMemo } from 'react'
import ReactDOMServer from 'react-dom/server'
import L, { Icon, Point, Marker } from 'leaflet'
import Map from 'components/Map'
import MediaMapPopup from 'components/MediaMap/MediaMapPopup'
import { Medium, MediumStatus } from 'types/campaign'
import { MediaMapProps } from './types'
import mapMarkerBlue from 'images/map-marker-blue.svg'
import mapMarkerGray from 'images/map-marker-gray.svg'

const createMarkers = (media: Medium[]): Marker[] => {
  return media.map((medium: Medium) => {
    const marker: Marker = new Marker([medium.lat, medium.lon], {
      icon: new Icon({
        iconUrl: medium.status === MediumStatus.ACTIVE ? mapMarkerBlue : mapMarkerGray,
        iconSize: new Point(18.75, 24.61),
      }),
    })

    const popup = L.popup()
    const content = L.DomUtil.create('div')
    popup.setContent(() => {
      if (!content.innerHTML) {
        content.innerHTML = ReactDOMServer.renderToString(<MediaMapPopup medium={medium} />)
      }

      return content
    })
    content.addEventListener('click', event => {
      if (!event || !event.target) {
        return
      }

      const selectedImage = event.target as HTMLElement
      const mainImage = content.querySelector('.image-gallery-image')

      if (!mainImage) {
        return
      }

      if (selectedImage.className === 'image-gallery-thumbnail-image') {
        const src = selectedImage.getAttribute('src')
        const preview = selectedImage.getAttribute('data-preview')
        mainImage.setAttribute('src', src || '')
        mainImage.setAttribute('data-preview', preview || '')
      }

      if (mainImage.isSameNode(selectedImage)) {
        const preview = mainImage.getAttribute('data-preview')
        preview && window.open(preview, '_blank')
      }
    })

    marker.bindPopup(popup)
    return marker
  })
}

const MediaMap: React.FC<MediaMapProps> = ({
  activeAreaClassName,
  className,
  media,
  withClustering,
  zoomControlPosition,
}) => {
  const markers = useMemo(() => createMarkers(media), [media])

  return (
    <Map
      activeAreaClassName={activeAreaClassName}
      className={className}
      markers={markers}
      withClustering={withClustering}
      zoomControlPosition={zoomControlPosition}
    />
  )
}

export default MediaMap
