import React, { ReactElement } from 'react'
import { isArray } from 'lodash'
import { useTranslation } from 'react-i18next'
import formatAlterValue from './formatValue'
import TsxUtils from 'utils/tsx'
import VariableUtils from 'utils/variable'
import { FormattedAlter, FormattedAlterField } from '../types'
import { HistoryLogAlter, HistoryLogAlterValue } from 'types/campaign'

export default (alters: HistoryLogAlter[]): FormattedAlter[] => {
  const { t } = useTranslation()

  const formatField = (value: HistoryLogAlterValue): FormattedAlterField => {
    return valueIsEmpty(value)
      ? {
          value: t('common.empty') as string,
          component: ValueComponent({ empty: true }),
        }
      : {
          value: formatAlterValue(value),
          component: ValueComponent(),
        }
  }

  return (
    alters?.map(alter => {
      const after = formatField(alter.after)
      const before = formatField(alter.before)

      return {
        values: {
          after: after.value,
          attribute: alter.attribute,
          before: before.value,
        },
        components: {
          after: after.component,
          attribute: ValueComponent(),
          before: before.component,
        },
      }
    }) || []
  )
}

const ValueComponent = (options?: { empty: boolean }): ReactElement => (
  <span
    className={TsxUtils.joinClasses(
      'HistoryLogItem__alter-value',
      options?.empty && 'HistoryLogItem__alter-value--empty'
    )}
  />
)

const valueIsEmpty = (value: unknown): boolean =>
  value === '' ||
  VariableUtils.isNullOrUndefined(value) ||
  (isArray(value) && VariableUtils.isEmptyArray(value))
