import React, { useState } from 'react'
import isEmail from 'validator/es/lib/isEmail'
import Input from 'components/Form/Input'
import Button from 'components/Form/Button'
import { useTranslation } from 'react-i18next'
import api from 'api'
import { Errors } from 'components/Form/InputError'
import { Routes } from 'routes'
import '../Sign.scss'

const RemindPassword: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [sendSuccess, setSendSuccess] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [emailErrors, setEmailErrors] = useState<Errors>([])

  const onRemind = (): void => {
    setEmailErrors([])
    if (!isEmail(email)) {
      setEmailErrors([t('sign.signIn.checkYourEmail')])
      return
    }

    setLoading(true)
    api.user
      .sendResetPasswordInstructions(email)
      .catch(() => void {})
      .finally(() => {
        setLoading(false)
        setSendSuccess(true)
      })
  }

  const remindPasswordContainer = (
    <>
      <h1 className='Sign__header--first'>{t('sign.remindPassword.doNotRemember')}</h1>
      <h2 className='Sign__header--second'>{t('sign.remindPassword.enterEmail')}</h2>

      <div className='Sign__form'>
        <Input
          id='e-mail'
          title={t('common.email')}
          placeholder={t('common.enterEmail')}
          value={email}
          errors={emailErrors}
          onChange={(value): void => void setEmail(value as string)}
          onKeyDown={{ key: 'Enter', callback: onRemind }}
        />

        <Button
          className='Sign__form--button'
          disabled={loading}
          onClick={onRemind}
        >
          {t('common.send')}
        </Button>
      </div>
    </>
  )

  const successContainer = (
    <>
      <h1 className='Sign__header--first'>{t('common.checkInbox')}</h1>
      <h2 className='Sign__header--second'>{t('sign.remindPassword.checkInboxDetails')}</h2>

      <Button
        className='Sign__form--button'
        toUrl={Routes.SIGN.SIGN_IN}
      >
        {t('sign.remindPassword.backToSignIn')}
      </Button>
    </>
  )

  return sendSuccess ? successContainer : remindPasswordContainer
}

export default RemindPassword
