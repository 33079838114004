import { gql } from '@apollo/client'
import { NEW_ROLE_FIELDS } from '../fragments'

export const GET_NEW_ROLE_PERMISSIONS = gql`
  ${NEW_ROLE_FIELDS}
  query newRolePermissions {
    newRolePermissions {
      ...NewRolePermissions
    }
  }
`
