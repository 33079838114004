import React, { ReactElement } from 'react'
import Card from 'components/Layout/Card'
import CardHeader from 'components/Layout/CardHeader'
import { useQuery } from '@apollo/client'
import campaignHistory from 'api/campaign/campaignHistory'
import { HistoryLog } from 'types/campaign'
import { CampaignHistoryQueryData } from 'api/campaign/types'
import { useTranslation } from 'react-i18next'
import HistoryLogItem from './HistoryLogItem'
import FillingSpinner from 'components/FillingSpinner'
import './CampaignDetailsHistory.scss'
import { CampaignData } from '../../models/campaign-data.model'

type Props = {
  campaign: CampaignData
}

const CampaignDetailsHistory: React.FC<Props> = ({ campaign }) => {
  const { t } = useTranslation()
  const { data, loading } = useQuery<CampaignHistoryQueryData, { campaignId: string }>(
    campaignHistory,
    {
      variables: { campaignId: campaign.id },
    }
  )

  const Logs = ({ logs }: { logs: HistoryLog[] }): ReactElement => (
    <>
      {logs.map((log, key) => (
        <HistoryLogItem
          campaign={campaign}
          historyLog={log}
          key={key}
        />
      ))}
    </>
  )

  const Body = (): ReactElement => {
    if (data?.campaignHistory.nodes.length) return <Logs logs={data.campaignHistory.nodes} />

    if (loading) return <FillingSpinner />

    return <div className='CampaignDetailsHistory__no-data'>{t('campaignHistory.noData')}</div>
  }

  return (
    <Card className='CampaignDetailsHistory'>
      <CardHeader collapsed={false}>{t('campaignHistory.title')}</CardHeader>
      <div className='CampaignDetailsHistory__container'>{Body()}</div>
    </Card>
  )
}

export default CampaignDetailsHistory
