import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  PermissionElementRole,
  PermissionChange,
  RoleFormModel,
  RoleModel,
} from '../../models/role-form-slice.model'
import { BaseRole } from '../../../../types/user'

const initialState: RoleFormModel = {
  id: '',
  name: '',
  baseRole: BaseRole.ADMIN,
  permissionElementRoles: [],
  newPermissionElementRoles: [],
  loading: false,
}

const roleFormSlice = createSlice({
  name: 'roleForm',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setNewPermissionElementRoles(state, action: PayloadAction<PermissionElementRole[]>) {
      state.newPermissionElementRoles = action.payload
    },
    changeRoleName(state, action: PayloadAction<string>) {
      state.name = action.payload
    },
    changeBasicRole(state, action: PayloadAction<BaseRole>) {
      state.baseRole = action.payload
    },
    changePermission(state, action: PayloadAction<PermissionChange>) {
      const permissionElementsPropName = action.payload.editRole
        ? 'permissionElementRoles'
        : 'newPermissionElementRoles'

      state[permissionElementsPropName] = state[permissionElementsPropName].map(
        permissionElementRole => {
          return {
            ...permissionElementRole,
            permissions:
              action.payload.permissionElementId === permissionElementRole.id
                ? permissionElementRole.permissions.map(permission => {
                    if (permission.name === action.payload.permission) {
                      return {
                        ...permission,
                        value: action.payload.value,
                      }
                    }
                    return {
                      ...permission,
                      value: permission.value,
                    }
                  })
                : permissionElementRole.permissions,
          }
        }
      )
    },
    setRoleForm(state, action: PayloadAction<RoleModel>) {
      state.id = action.payload.id
      state.name = action.payload.name
      state.baseRole = action.payload.baseRole
      state.permissionElementRoles = action.payload.permissionElementRoles
    },
    resetRoleForm(state) {
      state.id = initialState.id
      state.name = initialState.name
      state.baseRole = initialState.baseRole
      state.newPermissionElementRoles = state.newPermissionElementRoles.map(
        permissionElementRole => {
          return {
            ...permissionElementRole,
            permissions: permissionElementRole.permissions.map(permission => {
              return {
                ...permission,
                value: false,
              }
            }),
          }
        }
      )
    },
  },
})

export const roleFormActions = roleFormSlice.actions

export default roleFormSlice
