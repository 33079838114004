import React from 'react'
import { ImageCarouselThumbItem as ImageCarouselThumbItemType } from './types'

const defaultProps: ImageCarouselThumbItemType = {
  preview: '',
  original: '',
  loading: 'eager',
  thumbnail: '',
  thumbnailHeight: undefined,
  thumbnailWidth: undefined,
  thumbnailAlt: '',
  thumbnailLoading: 'eager',
}

const ImageCarouselThumbItem = React.memo((props: ImageCarouselThumbItemType) => {
  const {
    thumbnail,
    thumbnailHeight,
    thumbnailWidth,
    thumbnailAlt,
    thumbnailTitle,
    thumbnailLoading,
    thumbnailLabel,
    preview,
  } = { ...defaultProps, ...props }

  return (
    <React.Fragment>
      <span className='image-gallery-thumbnail-inner'>
        <img
          className='image-gallery-thumbnail-image'
          src={thumbnail}
          data-preview={preview}
          height={thumbnailHeight}
          width={thumbnailWidth}
          alt={thumbnailAlt}
          title={thumbnailTitle}
          loading={thumbnailLoading}
        />
        {thumbnailLabel && <div className='image-gallery-thumbnail-label'>{thumbnailLabel}</div>}
      </span>
    </React.Fragment>
  )
})
ImageCarouselThumbItem.displayName = 'ImageCarouselThumbItem'

export default ImageCarouselThumbItem
