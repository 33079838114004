import React, { ReactNode } from 'react'
import CardHeader from '../CardHeader'
import TsxUtils from 'utils/tsx'
import FillingSpinner from 'components/FillingSpinner'
import { CardProps, CardStatus } from './types'
import './Card.scss'

const Card: React.FC<CardProps> = ({
  className = '',
  title = '',
  id,
  headerNumber,
  status = CardStatus.NONE,
  isCollapsed,
  onCollapse,
  children,
  disabled,
  noMarginBody,
  dataCy,
}) => {
  const isCollapsible = isCollapsed !== undefined

  const Header = (): ReactNode => (
    <CardHeader
      number={headerNumber}
      status={status}
      collapsed={isCollapsed ?? undefined}
      onCollapse={(isCollapsed: boolean) => {
        if (isCollapsible && onCollapse) onCollapse(isCollapsed)
      }}
    >
      {title}
    </CardHeader>
  )

  const Body = (): ReactNode =>
    noMarginBody ? (
      <div className={TsxUtils.extraStyle(noMarginBody, 'Card--no-margin-body')}>{children}</div>
    ) : (
      children
    )

  return (
    <div
      className={
        'Card' +
        TsxUtils.extraStyle(className, className) +
        TsxUtils.extraStyle(disabled, 'Card--disabled') +
        TsxUtils.extraStyle(status === CardStatus.SAVED, 'Card--saved')
      }
      id={id}
      data-cy={dataCy}
    >
      {title ? (
        <>
          {Header()}
          {Body()}
        </>
      ) : status === CardStatus.LOADING ? (
        <FillingSpinner />
      ) : (
        Body()
      )}
    </div>
  )
}

export default Card
