import { Campaign, CampaignChartFilters } from 'types/campaign'
import api from 'api'
import { AudienceFilters, CampaignEmission, CampaignLocalFilters } from './types'
import { PublicViewProps } from '../types'
import { Dispatch, SetStateAction } from 'react'

export default class CampaignDetailsChartService {
  static getData = (
    campaignId: Campaign['id'],
    publicViewProps: PublicViewProps | undefined,
    filters: CampaignLocalFilters,
    audienceFilters: AudienceFilters,
    setEmissions: Dispatch<SetStateAction<CampaignEmission[]>>,
    setEmissionsLoading: Dispatch<SetStateAction<boolean>>
  ): void => {
    const { pois } = filters
    const areAllPoisSelected = filters.pois.length === filters.pois.length
    const variables = {
      ...filters,
      pois: areAllPoisSelected ? [] : pois,
      sex: audienceFilters.sex,
      ageGroups: audienceFilters.ageGroups,
    }

    setEmissionsLoading(true)
    CampaignDetailsChartService.fetchCampaignEmissions(campaignId, publicViewProps, variables)
      .then(campaignEmissions => {
        // TODO: remove map after API clean-up (https://alterdataio.atlassian.net/browse/AMS-1115)
        setEmissions(
          campaignEmissions.map(
            ({
              audience,
              date,
              budget,
              totalBudget,
              totalAudience,
              impressions,
              totalImpressions,
              estimated,
            }: any): CampaignEmission => ({
              audience,
              date,
              budget,
              totalBudget,
              totalAudience,
              emissions: impressions,
              totalEmissions: totalImpressions,
              estimated,
            })
          )
        )
      })
      .catch(() => void {})
      .finally(() => void setEmissionsLoading(false))
  }

  static fetchCampaignEmissions = (
    campaignId: Campaign['id'],
    publicViewProps: PublicViewProps | undefined,
    variables: CampaignChartFilters
  ): Promise<CampaignEmission[]> => {
    const { publicView, campaignUuid } = publicViewProps || {}

    if (publicView && campaignUuid) {
      const query = api.campaign.getCampaignImpressionsPublic({
        campaignUuid,
        ...variables,
      })

      return query.then(res => res.data.campaignReportPublic.campaignImpressions.nodes)
    }

    const query = api.campaign.getCampaignImpressions({
      campaignId,
      ...variables,
    })

    return query.then(res => res.data.campaignImpressions.nodes)
  }
}
