import React from 'react'
import { useTranslation } from 'react-i18next'
import Summary from '../Summary'
import { Campaign } from '../../../../types/campaign'

const EstimatedValue: React.FC<{ estimatedValue: Campaign['estimatedValue'] }> = ({
  estimatedValue,
}) => {
  const { t } = useTranslation()

  if (!estimatedValue) return <></>

  const { value, currency } = estimatedValue

  return (
    <Summary title={t(`common.estimatedValue`)}>
      <div className='estimated-value'>
        <span className='estimated-value__value'>
          {value} {currency}
        </span>
      </div>
    </Summary>
  )
}

export default EstimatedValue
