import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'api/fragments'

const PAYLOAD_TYPE = 'UpdateCampaignReservationPayload'

export const UPDATE_CAMPAIGN_RESERVATION = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation updateCampaignReservation($id: ID!, $endDate: ISO8601DateTime!) {
    updateCampaignReservation(input: { id: $id, endDate: $endDate }) {
      campaign {
        id
      }
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
