import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FillingSpinnerProps, FillingSpinnerType, FillingSpinnerColor } from './types'
import TsxUtils from 'utils/tsx'
import './FillingSpinner.scss'

const FillingSpinner: React.FC<FillingSpinnerProps> = ({
  className,
  type = FillingSpinnerType.FULL_CONTAINER,
  color = FillingSpinnerColor.BLUE,
}) => {
  const spinnerType = (): [string, string] => {
    switch (type) {
      case FillingSpinnerType.STANDARD:
        return ['FillingSpinner--standard', 'FillingSpinner__icon--standard']
      case FillingSpinnerType.FULL_CONTAINER:
        return ['FillingSpinner--full-container', 'FillingSpinner__icon--full-container']
      case FillingSpinnerType.FULL_LAYOUT_CONTENT:
        return ['FillingSpinner--full-layout-content', 'FillingSpinner__icon--full-layout-content']
    }
  }

  return (
    <div
      className={`FillingSpinner ${spinnerType()[0]}` + TsxUtils.extraStyle(className, className)}
    >
      <FontAwesomeIcon
        spin
        icon={faSpinner}
        className={`FillingSpinner__icon ${spinnerType()[1]} FillingSpinner__icon--${color}`}
      />
    </div>
  )
}

export default FillingSpinner
