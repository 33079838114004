import api from 'api'
import { PublicViewProps } from '../types'
import { ReactRouterParams } from 'types/various'

export default class CampaignLocationsFileService {
  static fetchLocationsFile = (
    campaignId: ReactRouterParams['id'],
    publicViewProps: PublicViewProps
  ): Promise<void> => {
    const { campaignUuid, publicView } = publicViewProps

    if (publicView && campaignUuid) {
      return api.campaign
        .fetchCampaignLocationsFilePublic(campaignUuid)
        .then(res => res.data?.campaignLocationsFilePublic.url)
        .then(url => {
          if (!url) {
            return
          }

          window.open(url, '_blank')
        })
        .catch(() => void {})
    }

    if (!campaignId) {
      return Promise.resolve()
    }

    return api.campaign
      .fetchCampaignLocationsFile(campaignId)
      .then(res => res.data?.campaignLocationsFile.url)
      .then(url => {
        if (!url) {
          return
        }

        window.open(url, '_blank')
      })
      .catch(() => void {})
  }
}
