import FillingSpinner from 'components/FillingSpinner'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import '../../Report.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import CommonReportsService from '../../services/common-reports.service'
import Table from '../../../../components/Table/Table'
import {
  TableBody,
  TableData,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from '../../../../components/Table'
import './PricingReport.scss'

const PricingReport: React.FC = () => {
  const { t } = useTranslation()
  const { loading, audience, impressions } = useSelector((state: RootState) => state.report.pricing)

  // We need it to fetch reports on page load
  useEffect(() => {
    void CommonReportsService.fetchPricingReport()
  }, [])

  return (
    <>
      {loading ? (
        <FillingSpinner className='Report__loading' />
      ) : (
        <div className={'Pricing_Report__container'}>
          <p className={'Pricing_Report__info'}>{t('report.priceReportInfo')}</p>

          <p className={'Pricing_Report__report-type'}>
            {audience.headers.length ? t('report.audience') : t('report.audienceNoData')}
          </p>

          <Table>
            <TableHeader>
              {audience.headers.map((columnName, i) => (
                <TableHeaderItem
                  key={i}
                  className={'Pricing_Report__header'}
                >
                  {columnName}
                </TableHeaderItem>
              ))}
            </TableHeader>
            <TableBody>
              {audience.content.map((row, i) => (
                <TableRow
                  key={i}
                  className={'Pricing_Report__row'}
                >
                  {row.map((cell, j) => (
                    <TableData key={j}>{cell}</TableData>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <p className={'Pricing_Report__report-type'}>
            {impressions.headers.length ? t('report.impressions') : t('report.impressionsNoData')}
          </p>
          <Table>
            <TableHeader>
              {impressions.headers.map((columnName, i) => (
                <TableHeaderItem
                  key={i}
                  className={'Pricing_Report__header'}
                >
                  {columnName}
                </TableHeaderItem>
              ))}
            </TableHeader>
            <TableBody>
              {impressions.content.map((row, i) => (
                <TableRow
                  key={i}
                  className={'Pricing_Report__row'}
                >
                  {row.map((cell, j) => (
                    <TableData key={j}>{cell}</TableData>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </>
  )
}

export default PricingReport
