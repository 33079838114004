import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './IconAlert.scss'
import { AlertType } from '../../../types/campaign'
import { faCircleInfo, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

const IconAlert: React.FC<{ alertType?: AlertType }> = ({ alertType }) => {
  return (
    <FontAwesomeIcon
      icon={alertType === AlertType.warning ? faCircleInfo : faExclamationCircle}
      className={
        alertType === AlertType.warning ? 'IconAlert IconAlert--info' : 'IconAlert IconAlert--warn'
      }
    />
  )
}

export default IconAlert
