export default class DownloadUtils {
  static downloadBlob = (blob: Blob, filename: string): void => {
    const el = window.document.createElement('a')
    el.href = window.URL.createObjectURL(blob)
    el.download = filename
    document.body.appendChild(el)
    el.click()
    document.body.removeChild(el)
  }

  static downloadFileFromUrl = async (fileUrl: string, filename: string): Promise<void> => {
    try {
      const response = await fetch(fileUrl)
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const blob = await response.blob()
      const a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = filename
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }
}
