import { gql } from '@apollo/client'
import { MUTATION_STATE, USER_FIELDS } from '../fragments'

const PAYLOAD_TYPE = 'AcceptInvitationPayload'

export const ACCEPT_INVITATION = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  ${USER_FIELDS}
  mutation acceptInvitation(
    $token: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    acceptInvitation(
      input: {
        token: $token
        firstName: $firstName
        lastName: $lastName
        password: $password
        passwordConfirmation: $passwordConfirmation
      }
    ) {
      user {
        ...UserFields
      }
      authenticationToken
      refreshToken
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
