import { gql } from '@apollo/client'

export default gql`
  query mediaTemplatesList($mediumFormat: MediumFormatEnum!) {
    mediaTemplatesList(mediumFormat: $mediumFormat) {
      nodes {
        id
        name
        media {
          id
        }
      }
    }
  }
`
