import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'
import { components, IndicatorProps, OptionProps } from 'react-select'
import { SelectAsyncProps, SelectAsyncValue } from './types'
import InputTitle from '../InputTitle'
import InputError, { Errors } from '../InputError'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import TsxUtils from 'utils/tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { OPTION_ALL } from '../Select'
import './SelectAsync.scss'

const DEBOUNCE_GET_DELAY = 250 // [ms]

const SelectAsync: React.FC<SelectAsyncProps> = ({
  id,
  className,
  title,
  help,
  placeholder,
  value,
  selectAllOption,
  onChange,
  options,
  isClearable,
  label,
  multi,
  closeOnSelect,
  disabled,
  errors,
  asyncFiltering,
  dataCy,
}) => {
  const { t } = useTranslation()
  const [internalErrors, setInternalErrors] = useState<Errors>()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [tempAllOptions, setTempAllOptions] = useState<SelectAsyncValue[]>([])

  const finalLabel = label || t('common.selectDots')
  useEffect(() => {
    if (
      selectAllOption &&
      tempAllOptions.length > 0 &&
      Array.isArray(value) &&
      value.length === 0
    ) {
      onChange(tempAllOptions, id)
    }
  }, [selectAllOption, tempAllOptions, value])

  // TODO: Get rid of AwesomeDebouncePromise
  const debouncedSearchFunction = useCallback(
    AwesomeDebouncePromise(options, DEBOUNCE_GET_DELAY),
    []
  )

  const mapSearchOptions = () => {
    return (option: SelectAsyncValue) => ({
      ...option,
      label: option.label ?? option.value,
    })
  }

  const sortOptionsCompareFn = (
    a: { label: string; rawData?: any; value: string },
    b: {
      label: string
      value: string
    }
  ) => {
    return a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase())
  }

  const filteredOptions = (inputValue: string): Promise<SelectAsyncValue[]> =>
    debouncedSearchFunction(inputValue)
      .then((options: SelectAsyncValue[]): SelectAsyncValue[] =>
        asyncFiltering
          ? options.map(mapSearchOptions()).sort(sortOptionsCompareFn)
          : options
              .map(mapSearchOptions())
              .filter((option: SelectAsyncValue) =>
                option.label!.toLowerCase().includes(inputValue.toLowerCase())
              )
              .sort(sortOptionsCompareFn)
      )
      .then((rawOptions: SelectAsyncValue[]): SelectAsyncValue[] => {
        if (selectAllOption && inputValue.length === 0) {
          setTempAllOptions([...rawOptions])
          rawOptions.unshift({
            value: OPTION_ALL.value,
            label: OPTION_ALL.label,
          })
        }

        return rawOptions
      })
      .catch((err: { message: string }) => {
        // It catches errors only in above function, not the API call errors
        setInternalErrors([err.message])
        return []
      })

  // TODO: any
  const handleChange = (option: any): void => {
    if (Array.isArray(option)) {
      const optionAllClicked = option.find((o: SelectAsyncValue) => o.value == OPTION_ALL.value)
      onChange(optionAllClicked ? tempAllOptions : option.map((o: SelectAsyncValue) => o), id)
    } else {
      onChange(option, id)
    }
  }

  useEffect(() => {
    setHasErrors(
      (errors && errors.length > 0) || (internalErrors && internalErrors.length > 0) || false
    )
  }, [errors, internalErrors])

  const DropdownIndicator = (props: IndicatorProps<any, any>) =>
    multi ? null : <components.DropdownIndicator {...props} />

  const UndoIndicator = (props: IndicatorProps<any, any>) => (
    <components.ClearIndicator {...props}>
      <FontAwesomeIcon icon={faUndo} />
    </components.ClearIndicator>
  )

  const ClearIndicator = (props: IndicatorProps<any, any>) => (
    <components.ClearIndicator {...props} />
  )

  const Option = (props: OptionProps<any, any>) => (
    <components.Option
      className={TsxUtils.extraStyle(props.label === OPTION_ALL.label, 'SelectAsync--bold')}
      {...props}
    >
      {props.label}
    </components.Option>
  )

  return (
    <div
      className='SelectAsync__container'
      data-cy={dataCy}
    >
      {title && (
        <InputTitle
          title={title}
          help={help}
        />
      )}

      <AsyncSelect
        isClearable={isClearable}
        cacheOptions
        className={
          'SelectAsync' +
          TsxUtils.extraStyle(className, className) +
          TsxUtils.extraStyle(hasErrors, 'SelectAsync__error')
        }
        classNamePrefix='SelectAsync'
        closeMenuOnSelect={closeOnSelect}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
          Option,
          ClearIndicator: selectAllOption ? UndoIndicator : ClearIndicator,
        }}
        defaultOptions
        isDisabled={disabled}
        inputId={id}
        isMulti={multi}
        isSearchable={true}
        loadingMessage={(): string => t('common.loading')}
        loadOptions={(inputValue: string): Promise<SelectAsyncValue[]> =>
          filteredOptions(inputValue)
        }
        noOptionsMessage={(): string => t('form.noOptions')}
        onChange={handleChange}
        value={value ?? { label: finalLabel, value: '' }}
        placeholder={placeholder ?? t('common.selectDots')}
      />

      {hasErrors && (
        <InputError
          className='SelectAsync__error'
          errors={[...(errors ?? []), ...(internalErrors ?? [])]}
        />
      )}
    </div>
  )
}

export default SelectAsync
