import Card from 'components/Layout/Card'
import CardHeader from 'components/Layout/CardHeader'
import PageHeader from 'components/Layout/PageHeader'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CampaignReport from './containers/CampaignReport'
import CreationReport from './containers/CreationReport'
import MediumReport from './containers/MediumReport'
import MediumCampaignsReport from './containers/MediumCampaignsReport'
import MediaReport from './containers/MediaReport'
import './Report.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { reportActions } from './store/report-slice'
import CampaignsCombinedReport from './containers/CampaignsCombinedReport'
import PricingReport from './containers/PricingReport'

const Report: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { campaign, creation, medium, mediumCampaigns, media, campaignsCombined, pricing } =
    useSelector((state: RootState) => state.report)

  return (
    <div className={'Report__container'}>
      <PageHeader>{t('report.generatingReport')}</PageHeader>

      <div className='Report__info'>
        <div>{t('report.dataReadInfo')}</div>
        <div>{t('report.dataReadInfoA')}</div>
        <div>{t('report.dataReadInfoB')}</div>
        <div>{t('report.dataReadInfoC')}</div>
      </div>

      <div className='Report__info'>
        <div>{t('report.longTimeInfo')}</div>
      </div>

      <Card>
        <CardHeader
          collapsed={!campaign.show}
          onCollapse={() => {
            void dispatch(reportActions.toggleVisibility('campaign'))
          }}
        >
          {t('report.campaignReport')}
        </CardHeader>

        {campaign.show && <CampaignReport />}
      </Card>

      <Card>
        <CardHeader
          collapsed={!creation.show}
          onCollapse={() => {
            void dispatch(reportActions.toggleVisibility('creation'))
          }}
        >
          {t('report.creationReport')}
        </CardHeader>

        {creation.show && <CreationReport />}
      </Card>

      <Card>
        <CardHeader
          collapsed={!medium.show}
          onCollapse={() => {
            void dispatch(reportActions.toggleVisibility('medium'))
          }}
        >
          {t('report.mediumReport')}
        </CardHeader>

        {medium.show && <MediumReport />}
      </Card>

      <Card>
        <CardHeader
          collapsed={!mediumCampaigns.show}
          onCollapse={() => {
            void dispatch(reportActions.toggleVisibility('mediumCampaigns'))
          }}
        >
          {t('report.mediumCampaignsReport')}
        </CardHeader>

        {mediumCampaigns.show && <MediumCampaignsReport />}
      </Card>

      <Card>
        <CardHeader
          collapsed={!media.show}
          onCollapse={() => {
            void dispatch(reportActions.toggleVisibility('media'))
          }}
        >
          {t('report.mediaReport')}
        </CardHeader>

        {media.show && <MediaReport />}
      </Card>

      <Card>
        <CardHeader
          dataCy={'mass-campaign-report-header'}
          collapsed={!campaignsCombined.show}
          onCollapse={() => {
            void dispatch(reportActions.toggleVisibility('campaignsCombined'))
          }}
        >
          {t('report.massCampaignReport')}
        </CardHeader>

        {campaignsCombined.show && <CampaignsCombinedReport />}
      </Card>

      <Card>
        <CardHeader
          dataCy={'pricing-report-header'}
          collapsed={!pricing.show}
          onCollapse={() => {
            void dispatch(reportActions.toggleVisibility('pricing'))
          }}
        >
          {t('report.priceReportHeader')}
        </CardHeader>

        {pricing.show && <PricingReport />}
      </Card>
    </div>
  )
}

export default Report
