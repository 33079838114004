import React from 'react'
import { CampaignDetailsPdfProps } from '../models/campaign-details-pdf-props.model'
import { Document, Page } from '@react-pdf/renderer'
import { pdfStyles } from '../../../../../utils/pdf-styles'
import { Creations } from '../parts/Creations'
import { Media } from '../parts/Media'
import { FooterPdf } from '../../../../../components/FooterPdf'
import { PdfMap } from '../parts/Map'
import { AgglomerationList } from '../parts/AgglomerationList'
import { AudienceList } from '../parts/AudienceList'
import { AudienceGender } from '../parts/AudienceGender'
import { AudienceAge } from '../parts/AudienceAge'
import { AudienceGenderAndAge } from '../parts/AudienceGenderAndAge'
import { AudienceHeaderPdf } from '../parts/AudienceHeaderPdf'
import { AudienceSummary } from '../parts/AudienceSummary'
import { AudienceNotCompleteData } from '../parts/AudienceNotCompleteData'
import { MediaFormat } from '../../../../../types/campaign'

const AudiencePdf: React.FC<CampaignDetailsPdfProps> = ({
  data,
  creationsMatrix,
  audienceData,
}) => {
  const isInDoor = data.mediaCriteriaSearch.mediumFormat === MediaFormat.DG_IN

  if (!audienceData) {
    return null
  }

  return (
    <Document>
      <Page style={pdfStyles.page}>
        <AudienceHeaderPdf
          name={data.name}
          mediumFormat={data.mediaCriteriaSearch.mediumFormat}
        />
        <AudienceSummary
          campaignData={data}
          completeData={audienceData.completeData}
        />
        <AgglomerationList agglomerations={audienceData.agglomeration} />
        <AudienceList contacts={audienceData.contacts} />
        {isInDoor && (
          <>
            <AudienceGender gender={audienceData.gender} />
            <AudienceAge audienceAge={audienceData.audienceAge} />
            <AudienceGenderAndAge audienceAgeGender={audienceData.audienceAgeGender} />
          </>
        )}
        <Media
          media={data.media}
          mediumFormat={data.mediaCriteriaSearch.mediumFormat}
        />
        {!audienceData.completeData && <AudienceNotCompleteData />}
        <FooterPdf />
      </Page>
      {creationsMatrix.map((creations, index) => (
        <Page
          style={pdfStyles.page}
          key={index}
        >
          <Creations creations={creations} />
          <FooterPdf />
        </Page>
      ))}
      <Page style={pdfStyles.page}>
        <PdfMap />
      </Page>
    </Document>
  )
}

export default AudiencePdf
