import { gql } from '@apollo/client'
import { CAMPAIGN_DETAILS_FIELDS } from '../fragments'

export const GET_CAMPAIGN_DETAILS = gql`
  ${CAMPAIGN_DETAILS_FIELDS}
  query campaignDetails($id: ID!) {
    campaignDetails(campaignId: $id) {
      ...CampaignDetailsFields
    }
  }
`

export const GET_CAMPAIGN_DETAILS_PUBLIC = gql`
  ${CAMPAIGN_DETAILS_FIELDS}
  query campaignReportPublic($campaignUuid: String!) {
    campaignReportPublic(campaignUuid: $campaignUuid) {
      campaignDetails(campaignUuid: $campaignUuid) {
        ...CampaignDetailsFields
      }
    }
  }
`
