import React, { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FillingSpinner from 'components/FillingSpinner'
import useFilteredMedia from 'hooks/useFilteredMedia'
import { MediaListProps } from './types'
import TsxUtils from 'utils/tsx'
import { ITEMS_PER_PAGE } from 'constant'
import { PaginationProps } from 'types/various'
import MediaListTable from './MediaListTable'
import './MediaList.scss'

const MediaList: React.FC<MediaListProps> = ({
  className,
  campaignId,
  mediumFormat,
  publicViewProps,
}) => {
  const { publicView, campaignUuid } = publicViewProps || {}
  const { t } = useTranslation()
  const { fetchCampaignMedia, mediaLoading, page, setPage, totalCount, media } = useFilteredMedia()

  useEffect(() => {
    const paginationProps: Pick<PaginationProps, 'first' | 'last'> = {
      first: page * ITEMS_PER_PAGE,
      last: ITEMS_PER_PAGE,
    }

    fetchCampaignMedia(
      publicView ? { campaignUuid, ...paginationProps } : { campaignId, ...paginationProps }
    )
  }, [campaignId, campaignUuid, page])

  const content = (): ReactNode => {
    if (mediaLoading) {
      return <FillingSpinner className={'MediaList--loading-container'} />
    } else if (media.length === 0) {
      return t('common.noDataFound')
    } else {
      return (
        <MediaListTable
          media={media}
          mediumFormat={mediumFormat}
          pagination={{ totalItemsCount: totalCount, setPage, activePage: page }}
        />
      )
    }
  }

  return <div className={TsxUtils.joinClasses('MediaList', className)}>{content()}</div>
}

export default MediaList
