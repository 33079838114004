import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Form/Button'
import Card from 'components/Layout/Card'
import FormColumn from 'components/Form/FormColumn'
import FormRow from 'components/Form/FormRow'
import Input from 'components/Form/Input'
import SelectAsync, { SelectAsyncValue } from 'components/Form/SelectAsync'
import { AppContext } from 'contexts/AppContext'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Agency } from 'types/agency'
import { FormErrors } from 'types/various'
import { BaseRole } from 'types/user'
import ErrorUtils from 'utils/error'
import UserService, { BaseRoleSelectRawData } from '../service'
import UserInviteService from './user-invite.service'
import { formErrorsDefault, UserForm, userFormDefault } from './types'
import '../User.scss'
import RangeSlider from '../../../components/Form/RangeSlider'
import { PRIORITIES } from '../../../constant'
import Checkbox from '../../../components/Form/Checkbox'
import UserInviteFormService from './user-invite-form.service'

const UserInvite: React.FC = () => {
  const { addNotification } = useContext(AppContext)
  const { t } = useTranslation()
  const [userForm, setUserForm] = useState<UserForm>(userFormDefault)
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<FormErrors>()
  const navigate = useNavigate()

  useEffect(() => {
    if (!ErrorUtils.haveErrors(errors, formErrorsDefault)) {
      UserInviteService.inviteUser({
        user: userForm,
        setLoading,
        addNotification,
        navigate,
        t,
      })
    }
  }, [errors])

  return (
    <div className='User__form'>
      <Card title={t('user.invite.header')}>
        <FormRow>
          <FormColumn>
            <Checkbox
              id='subscriptionAccount'
              checked={userForm.subscriptionAccount}
              onChange={(event): void => {
                setUserForm({
                  ...userForm,
                  subscriptionAccount: event.target.checked,
                  roles: [],
                  firstName: '',
                  lastName: '',
                })
              }}
            >
              {t('user.common.subscriptionAccount')}
            </Checkbox>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn>
            <Input
              id='email'
              title={t('common.email')}
              placeholder={t('common.enterEmail')}
              value={userForm.email}
              onChange={email => void setUserForm({ ...userForm, email })}
              disabled={loading}
              errors={errors?.email}
            />
          </FormColumn>
        </FormRow>

        <FormRow>
          <RangeSlider
            value={[userForm.priorities[0], userForm.priorities[userForm.priorities.length - 1]]}
            min={PRIORITIES[0]}
            max={PRIORITIES[PRIORITIES.length - 1]}
            title={t('common.priority')}
            help={t('form.campaign.details.priorityHelp')}
            onChange={priorities =>
              void setUserForm({
                ...userForm,
                priorities: UserService.convertScopeToRange(priorities as [number, number]),
              })
            }
            id={'RangeSlider'}
            minLabel={t('priority.low')}
            maxLabel={t('priority.high')}
          ></RangeSlider>
        </FormRow>

        <FormRow>
          {userForm.subscriptionAccount && (
            <SelectAsync
              id='roles'
              title={t('common.roles')}
              placeholder={t('common.rolesPlaceholder')}
              value={userForm.roles}
              options={(): Promise<SelectAsyncValue<BaseRoleSelectRawData>[]> =>
                UserService.getRoles(true)
              }
              onChange={roles => {
                userForm.agencies = []
                setUserForm({
                  ...userForm,
                  roles,
                  agencies: userForm.agencies,
                })
              }}
              multi
              closeOnSelect={false}
              disabled={loading}
              errors={errors?.roles}
            />
          )}

          {!userForm.subscriptionAccount && (
            <SelectAsync
              id='roles'
              title={t('common.roles')}
              placeholder={t('common.rolesPlaceholder')}
              value={userForm.roles}
              options={(): Promise<SelectAsyncValue<BaseRoleSelectRawData>[]> =>
                UserService.getRoles(false)
              }
              onChange={roles => {
                userForm.agencies = []
                setUserForm({
                  ...userForm,
                  roles,
                  agencies: userForm.agencies,
                })
              }}
              multi
              closeOnSelect={false}
              disabled={loading}
              errors={errors?.roles}
            />
          )}
        </FormRow>

        <FormRow>
          <SelectAsync
            id='agencies'
            placeholder={t('common.agenciesPlaceholder')}
            title={t('common.agency')}
            value={userForm.agencies}
            multi={userForm.roles.some(
              role =>
                role.rawData?.baseRole === BaseRole.ADMIN ||
                role.rawData?.baseRole === BaseRole.EMPLOYEE
            )}
            options={(): Promise<SelectAsyncValue<Agency>[]> => UserService.getAgencies('')}
            onChange={(agencies): void => {
              void setUserForm({
                ...userForm,
                agencies: Array.isArray(agencies) ? agencies : [agencies],
              })
            }}
            disabled={loading}
            errors={errors?.agencies}
          />
        </FormRow>

        {userForm.subscriptionAccount && (
          <>
            <FormRow>
              <FormColumn>
                <Input
                  id='firstName'
                  title={t('common.firstName')}
                  placeholder={t('common.enterFirstName')}
                  value={userForm.firstName}
                  onChange={firstName => void setUserForm({ ...userForm, firstName })}
                  disabled={loading}
                  errors={errors?.firstName}
                />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn>
                <Input
                  id='lastName'
                  title={t('common.lastName')}
                  placeholder={t('common.enterLastName')}
                  value={userForm.lastName}
                  onChange={lastName => void setUserForm({ ...userForm, lastName })}
                  disabled={loading}
                  errors={errors?.lastName}
                />
              </FormColumn>
            </FormRow>
          </>
        )}

        <div className='User__actions'>
          <Button
            dataCy='user-invite-button'
            icon={loading ? faSpinner : undefined}
            disabled={loading}
            onClick={() => {
              void UserInviteFormService.validate(userForm, errors, setErrors)
            }}
          >
            {userForm.subscriptionAccount ? t('common.create') : t('user.invite.sendInvitation')}
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default UserInvite
