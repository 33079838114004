import { gql } from '@apollo/client'
import { MUTATION_STATE, USER_DETAILS_FIELDS } from '../fragments'

const PAYLOAD_TYPE = 'RefreshTokenPayload'

export const REFRESH_TOKEN = gql`
    ${MUTATION_STATE('RefreshTokenPayload')}
    ${USER_DETAILS_FIELDS}
    mutation refreshToken($authenticationToken: String!, $refreshToken: String!) {
        refreshToken(input: { authenticationToken: $authenticationToken, refreshToken: $refreshToken }) {
            user {
                ...UserDetailsFields
            }
            ...MutationState${PAYLOAD_TYPE}Fields
            authenticationToken
        }
    }
`
