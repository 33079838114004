import React, { useMemo } from 'react'
import { registerFonts } from '../../../../utils/pdf-styles'
import { CampaignTarget, PdfCreation } from '../../../../types/campaign'
import { CampaignDetailsPdfProps } from './models/campaign-details-pdf-props.model'
import ImpressionsPdf from './documents/ImpressionsPdf'
import AudiencePdf from './documents/AudiencePdf'

const CampaignDetailsPdf: React.FC<Pick<CampaignDetailsPdfProps, 'data' | 'audienceData'>> = ({
  data,
  audienceData,
}) => {
  useMemo(() => {
    registerFonts()
  }, [])
  const sortedCreations: PdfCreation[] = [...data.creations]
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    .map((creation, index) => {
      return {
        ...creation,
        rowNumber: index + 1,
      }
    })

  // split creations into multiple tables if there are more than 5 creations
  const maximumCreationsInOneTable = 5
  const numberOfSubTables = Math.ceil(sortedCreations.length / maximumCreationsInOneTable)
  const creationsMatrix = sortedCreations.reduce(
    (acc, creation, index) => {
      const tableIndex = Math.floor(index / maximumCreationsInOneTable)
      acc[tableIndex].push(creation)
      return acc
    },
    [...Array(numberOfSubTables)].map(() => [] as PdfCreation[])
  )

  if (data.target === CampaignTarget.AUDIENCE) {
    return (
      <AudiencePdf
        data={data}
        creationsMatrix={creationsMatrix}
        audienceData={audienceData}
      />
    )
  }

  return (
    <ImpressionsPdf
      data={data}
      creationsMatrix={creationsMatrix}
    />
  )
}

export default CampaignDetailsPdf
