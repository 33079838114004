import React, { ReactElement } from 'react'
import { View } from '@react-pdf/renderer'
import { Table, TableData, TableRow, TableTitle } from 'components/TablePdf'
import { t } from 'i18next'
import { PdfAudienceAgglomeration } from '../../../../../api/campaign/types'

type Props = {
  agglomerations: PdfAudienceAgglomeration[]
}

export const AgglomerationList = ({ agglomerations }: Props): ReactElement => {
  if (!agglomerations || agglomerations.length === 0) {
    return (
      <View>
        <Table>
          <TableTitle>{t('common.agglomerationList')}</TableTitle>
          <TableRow>
            <TableData width={40}>{t('common.noData')}</TableData>
            <TableData width={60}> </TableData>
          </TableRow>
        </Table>
      </View>
    )
  }

  return (
    <View>
      <Table>
        <TableTitle>{t('common.agglomerationList')}</TableTitle>

        {agglomerations.map(agglomeration => (
          <TableRow key={agglomeration.name}>
            <TableData width={40}>{agglomeration.name}</TableData>
            <TableData width={60}>{agglomeration.percent + '%'}</TableData>
          </TableRow>
        ))}
      </Table>
    </View>
  )
}
