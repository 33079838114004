import { gql } from '@apollo/client'
import { MUTATION_STATE } from '../fragments'

const PAYLOAD_TYPE = 'InviteUserPayload'

export const INVITE_USER = gql`
    ${MUTATION_STATE(PAYLOAD_TYPE)}
    mutation inviteUser(
        $email: String!,
        $agencies: [ID!],
        $roles: [ID!]!,
        $priorities: [Int!]!,
        $firstName: String,
        $lastName: String
    ) {
        inviteUser(
            input: {
                agencies: $agencies,
                email: $email,
                roles: $roles,
                priorities: $priorities,
                firstName: $firstName,
                lastName: $lastName
            }
        ) {
            ...MutationState${PAYLOAD_TYPE}Fields
        }
    }
`
