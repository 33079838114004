import React from 'react'
import CampaignFormContextProvider from 'contexts/CampaignFormContext'
import CampaignForm from './CampaignForm'
import { CampaignFormContainerProps } from './types'

const CampaignFormContainer: React.FC<CampaignFormContainerProps> = ({ formType }) => (
  <CampaignFormContextProvider>
    <CampaignForm formType={formType} />
  </CampaignFormContextProvider>
)
export default CampaignFormContainer
