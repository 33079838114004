import React, { useEffect, useState } from 'react'
import { ReservationProps } from './types'
import { useTranslation } from 'react-i18next'
import DayPicker from 'components/Form/DayPicker'
import { ModalActionFooter, ModalFooterContainer } from 'components/Modal/ModalFooter'
import useCampaignAction from 'hooks/useCampaignAction'
import { FormType } from 'types/various'
import VariableUtils from 'utils/variable'
import service from './service'
import './Reservation.scss'

const Reservation: React.FC<ReservationProps> = ({ formType, campaignData, setIsOpenModal }) => {
  const [date, setDate] = useState<Date>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { reserveCampaign, updateCampaignReservation } = useCampaignAction()
  const { t } = useTranslation()

  const { id, startDate, reservationTill } = campaignData

  useEffect(() => {
    if (reservationTill !== null) setDate(reservationTill)
  }, [])

  return (
    <div className='Reservation'>
      {t('reservation.info')}

      <DayPicker
        value={date}
        onChange={newDate => void setDate(newDate)}
        disabledDays={service.calendarDisableDays({ startDate })}
      />

      <ModalFooterContainer
        footer={
          <ModalActionFooter
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setIsOpen={setIsOpenModal}
            onAction={() =>
              formType === FormType.NEW
                ? reserveCampaign({ id, endDate: date! })
                : updateCampaignReservation({ id, endDate: date! })
            }
            actionText={t('reservation.reserve')}
            actionButtonDisabled={VariableUtils.isNullOrUndefined(date)}
          />
        }
      />
    </div>
  )
}

export default Reservation
