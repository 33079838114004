import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import './FullScreenSpinner.scss'

const FullScreenSpinner: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={'FullScreenSpinner'}>
      <div className={'FullScreenSpinner__icon-container'}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          title={t('common.pleaseWait')}
        />
      </div>
    </div>
  )
}

export default FullScreenSpinner
