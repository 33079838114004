import { ADDITIONAL_OFFER, MediaFormat, OFFER } from 'types/campaign'
import { SelectOption } from 'components/Form/Select'
import i18n from 'i18n'
import { AgencyType } from '../../../../../types/agency'
import { GroupedMedia, MediumGroup } from '../../../../../hooks/useMediaSelection/types'
import { BaseRole, Role } from '../../../../../types/user'

export default class OfferFormService {
  public static minimumRequiredAgglomerations = 14

  /**
   * Gets offer options based on selected medium format and agency type
   *
   * DG_CL and DG_CS:
   * - RUN_ON_NETWORK, AGGLOMERATIONS, MEDIA_SELECTION
   * DG_IN:
   * - RUN_ON_NETWORK, AGGLOMERATIONS, BUILDINGS, MEDIA_SELECTION, G14_PLUS, A14_PLUS
   *
   *
   * @param selectedMediumFormat
   * @param agencyType
   */
  public static getOptions = (
    selectedMediumFormat: MediaFormat,
    agencyType?: AgencyType
  ): SelectOption[] => {
    const isInternalAgency = agencyType === AgencyType.internal

    const additionalOffers =
      isInternalAgency && selectedMediumFormat.toLowerCase().includes('dgin')
        ? Object.values(ADDITIONAL_OFFER)
        : []

    return [...Object.values(OFFER), ...additionalOffers]
      .filter(offerOption => {
        /**
         * we remove the buildings option for DG_CL and DG_CS
         */
        if (
          selectedMediumFormat === MediaFormat.DG_CL ||
          selectedMediumFormat === MediaFormat.DG_CS
        ) {
          return offerOption !== OFFER.BUILDINGS
        }
        return true
      })
      .map(
        (offerOption: OFFER | ADDITIONAL_OFFER): SelectOption => ({
          value: offerOption,
          label: i18n.t(`offer.${offerOption}`),
        })
      )
  }

  public static isRon14Offer = (offer: string | null): boolean => {
    return Object.values(ADDITIONAL_OFFER).includes(offer as ADDITIONAL_OFFER)
  }

  /**
   * We have introduced new offer types: NETWORK_G14_PLUS and NETWORK_A14_PLUS.
   * When one of these offers is selected, the user should be able to select only
   * agglomerations.
   *
   * Admin is always able to edit the offer.
   *
   * @param userRoles
   * @param offer
   * @param mediumGroup
   */
  public static disableEdit = (
    userRoles: Role[] | undefined,
    offer: string | null,
    mediumGroup?: MediumGroup
  ): boolean => {
    // admin can always edit
    if (userRoles && userRoles.some(role => role.name === BaseRole.ADMIN)) {
      return false
    }

    // if there is no medium group, that means we are dealing with a direct medium like: 8216
    if (!mediumGroup) {
      return OfferFormService.isRon14Offer(offer)
    }

    return OfferFormService.isRon14Offer(offer) && mediumGroup !== MediumGroup.AGGLOMERATION
  }

  public static disableSave = (
    userRoles: Role[] | undefined,
    offer: string | null,
    numberOfUsedAgglomerations: number
  ): boolean => {
    if (!OfferFormService.isRon14Offer(offer)) {
      return false
    }

    // admin can always save
    if (userRoles && userRoles.some(role => role.name === BaseRole.ADMIN)) {
      return false
    }

    return numberOfUsedAgglomerations < OfferFormService.minimumRequiredAgglomerations
  }

  public static numberOfUsedAgglomerations = (groupedMedia: GroupedMedia | undefined): number => {
    if (!groupedMedia) {
      return 0
    }

    return Object.values(groupedMedia).reduce((acc, mediaGroup) => {
      return acc + (mediaGroup.group.used ? 1 : 0)
    }, 0)
  }
}
