import { gql } from '@apollo/client'

export const GET_INVENTORY_BUILDINGS = gql`
  query InventoryBuildings(
    $fromDate: ISO8601DateTime
    $toDate: ISO8601DateTime
    $topTen: Boolean
    $cities: [String!]
    $agglomerations: [String!]
    $buildings: [String!]
    $first: Int
    $last: Int
  ) {
    inventoryBuildings(
      fromDate: $fromDate
      toDate: $toDate
      cities: $cities
      agglomerations: $agglomerations
      buildings: $buildings
      topTen: $topTen
      first: $first
      last: $last
    ) {
      nodes {
        buildingName
        asId
        freeSlots
        totalSlots
        allocatedSlots
        occupiedPercentage
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`
