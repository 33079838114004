import React from 'react'
import { CampaignDetailsPdfProps } from '../models/campaign-details-pdf-props.model'
import { Document, Page } from '@react-pdf/renderer'
import { pdfStyles } from '../../../../../utils/pdf-styles'
import { HeaderPdf } from '../../../../../components/HeaderPdf'
import { Summary } from '../parts/Summary'
import { Details } from '../parts/Details'
import { Creations } from '../parts/Creations'
import { Media } from '../parts/Media'
import { FooterPdf } from '../../../../../components/FooterPdf'
import { PdfMap } from '../parts/Map'

const ImpressionsPdf: React.FC<CampaignDetailsPdfProps> = ({ data, creationsMatrix }) => {
  return (
    <Document>
      <Page style={pdfStyles.page}>
        <HeaderPdf
          name={data.name}
          mediumFormat={data.mediaCriteriaSearch.mediumFormat}
        />
        <Summary campaignData={data} />
        <Details
          campaignData={data}
          mediumFormat={data.mediaCriteriaSearch.mediumFormat}
        />
        {creationsMatrix.map((creations, index) => (
          <Creations
            key={index}
            creations={creations}
          />
        ))}
        <Media
          media={data.media}
          mediumFormat={data.mediaCriteriaSearch.mediumFormat}
        />
        <FooterPdf />
      </Page>
      <Page style={pdfStyles.page}>
        <PdfMap />
      </Page>
    </Document>
  )
}

export default ImpressionsPdf
