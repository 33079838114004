import React, { ReactElement } from 'react'
import { View } from '@react-pdf/renderer'
import { Table, TableData, TableRow, TableTitle } from 'components/TablePdf'
import { t } from 'i18next'
import CampaignDetailsService from '../../services/campaign-details.service'
import VariableUtils from 'utils/variable'
import { CampaignDetailsPdfData } from '../models/types'
import i18n from '../../../../../i18n'

type Props = {
  campaignData: Pick<
    CampaignDetailsPdfData,
    'endDate' | 'startDate' | 'totalAudience' | 'currentAudience' | 'briefName'
  >
  completeData: boolean
}

export const AudienceSummary = ({ campaignData, completeData }: Props): ReactElement => {
  const { endDate, startDate, totalAudience, currentAudience } = campaignData

  const audience = `${VariableUtils.formatNumber(totalAudience)} ${i18n.t('common.audience.a')}`
  const targetRealization = `${VariableUtils.formatNumber(currentAudience)} - ${t(
    'common.audience.a'
  )}`

  const completeInfo = completeData ? '' : '*'

  return (
    <View>
      <Table>
        <TableTitle>{t('pdf.campaignSummary') + completeInfo}</TableTitle>

        <TableRow>
          <TableData width={40}>{t('pdf.campaignName')}</TableData>
          <TableData width={60}>{campaignData.briefName}</TableData>
        </TableRow>

        <TableRow>
          <TableData width={40}>{t('common.campaignTerm')}</TableData>
          <TableData width={60}>
            {CampaignDetailsService.campaignTerm(startDate, endDate)}
          </TableData>
        </TableRow>

        <TableRow>
          <TableData width={40}>{t('pdf.plannedContacts')}</TableData>
          <TableData width={60}>{audience}</TableData>
        </TableRow>

        <TableRow>
          <TableData width={40}>{t('pdf.completedContacts')}</TableData>
          <TableData width={60}>{targetRealization}</TableData>
        </TableRow>
      </Table>
    </View>
  )
}
