import React from 'react'
import './InventoryTopTenChart.scss'
import { InventoryBuildingModel } from '../models/inventory-buiding.model'

const InventoryTopTenChart: React.FC<{
  data: InventoryBuildingModel[]
}> = ({ data }) => {
  const getDisplayedPercentage = (occupiedPercentage: number): string => {
    return `${Math.round(occupiedPercentage * 100)}%`
  }

  return (
    <div className='InventoryTopTenChart'>
      {data.map((agglomeration, index) => (
        <div
          className='InventoryTopTenChart__agglomeration'
          key={index}
        >
          <p className='InventoryTopTenChart__agglomeration-name'>{agglomeration.buildingName}</p>
          <div className='InventoryTopTenChart__agglomeration-bar'>
            <div
              className='InventoryTopTenChart__agglomeration-filling'
              style={{ width: `${getDisplayedPercentage(agglomeration.occupiedPercentage)}` }}
            ></div>
          </div>
          <p className='InventoryTopTenChart__agglomeration-value'>
            {getDisplayedPercentage(agglomeration.occupiedPercentage)}
          </p>
        </div>
      ))}
    </div>
  )
}

export default InventoryTopTenChart
