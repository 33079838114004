import { gql } from '@apollo/client'

export const GET_INVENTORY_FILTERS = gql`
  query GetInventoryFilters(
    $agglomerations: [String!]
    $buildings: [String!]
    $cities: [String!]
    $mediaFormats: [MediumFormatEnum!]
    $mediumAsIds: [String!]
  ) {
    mediaFilter(
      agglomerations: $agglomerations
      buildings: $buildings
      cities: $cities
      mediaFormats: $mediaFormats
      mediumAsIds: $mediumAsIds
    ) {
      asId
      agglomeration {
        name
      }
      building {
        name
      }
      city {
        name
      }
    }
  }
`
