import React from 'react'
import { InputSearchProps } from './types'
import Input from '../Input'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import './InputSearch.scss'

const InputSearch: React.FC<InputSearchProps> = ({
  id,
  value,
  placeholder,
  onChange,
  onSubmit,
  withButton = true,
  dataCy,
}) => {
  const { t } = useTranslation()

  return (
    <div
      data-cy={dataCy}
      className='InputSearch'
    >
      <Input
        id={id}
        placeholder={placeholder ?? t('common.search')}
        value={value}
        onChange={onChange}
        onKeyDown={{ key: 'Enter', callback: () => void onSubmit(value) }}
      />

      {withButton && (
        <div
          className='InputSearch__icon--container'
          onClick={() => void onSubmit(value)}
        >
          <FontAwesomeIcon
            className='InputSearch__icon'
            icon={faSearch}
          />
        </div>
      )}
    </div>
  )
}

export default InputSearch
