import { gql } from '@apollo/client'

export const GET_CAMPAIGN_ESTIMATED_IMPRESSIONS = gql`
  query campaignEstimatedImpressions($campaignId: ID!, $experimental: Boolean) {
    campaignEstimatedImpressions(campaignId: $campaignId, experimental: $experimental) {
      impressions
      dailyImpressions {
        day
        impressionsByDuration {
          impressions
        }
      }
    }
  }
`
