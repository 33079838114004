import { CampaignStatus, CreationStatus, MediaFormat } from 'types/campaign'
import { BaseRole } from 'types/user'
import { PermissionType, FeatureName, CampaignPermissionType } from 'types/features'

export interface UseAuthorizeProps {
  allowedFor: (args: AllowedForArgs) => boolean
}

export const useAuthorizePropsDefault: UseAuthorizeProps = {
  allowedFor: () => false,
}

export interface AllowedForArgs {
  template?: AuthorizationTemplateValue
  feature?: Feature
  status?: CampaignStatus | CreationStatus
  mediumFormat?: MediaFormat
}

interface AuthorizationTemplateValue {
  roles?: BaseRole[]
  statuses?: CampaignStatus[] | CreationStatus[]
  mediaFormat?: MediaFormat[]
}

interface Feature {
  name: FeatureName
  action: PermissionType | CampaignPermissionType
}

export interface AuthorizationTemplate {
  [key: string]: Record<string, any> | AuthorizationTemplate
}
