import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FiltersForm, filtersFormDefault } from './types'

export interface CampaignTableFiltersState {
  filters: FiltersForm
  collapsed: boolean
  loading: boolean
}

const initialState: CampaignTableFiltersState = {
  filters: filtersFormDefault,
  collapsed: true,
  loading: true,
}

export const campaignTableFiltersSlice = createSlice({
  name: 'campaignTableFilters',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<FiltersForm>) => {
      state.filters = action.payload
    },
    setCollapsed: (state, action: PayloadAction<boolean>) => {
      state.collapsed = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export const { setFilters, setCollapsed, setLoading } = campaignTableFiltersSlice.actions
export default campaignTableFiltersSlice.reducer
