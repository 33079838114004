import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'api/fragments'

const PAYLOAD_TYPE = 'UpdateCampaignPayload'

export const UPDATE_CAMPAIGN = gql`
    ${MUTATION_STATE(PAYLOAD_TYPE)}
    mutation updateCampaign(
        $id: ID!
        $agencyId: ID
        $advertiserId: ID,
        $name: String
        $briefName: String
        $startDate: ISO8601DateTime
        $endDate: ISO8601DateTime
        $supervisors: [ID!]
    ) {
        updateCampaign(
            input: {
                id: $id
                agencyId: $agencyId
                advertiserId: $advertiserId
                name: $name
                briefName: $briefName
                startDate: $startDate
                endDate: $endDate
                supervisors: $supervisors
            }
        ) {
            campaign {
                id
                brainState
                supervisors {
                    id
                    firstName
                    lastName
                }
                agency {
                    allowedTargets {
                        id
                        name
                    }
                }
            }
            ...MutationState${PAYLOAD_TYPE}Fields
        }
    }
`
