import { DATE_FORMAT, CURRENCY_PLN } from 'constant'
import { default as InputMaskOrigin } from 'inputmask'

export enum InputMask {
  INTEGER,
  NEGATIVE_INTEGER,
  FLOAT,
  CURRENCY,
  DATE,
  PERCENT,
}

const commonOptionsGlobal = {
  showMaskOnHover: false,
  autoUnmask: true,
}

const commonOptionsNumbers = {
  alias: 'decimal',
  rightAlign: false,
  groupSeparator: ' ',
  allowMinus: false,
}

export default class InputMaskUtils {
  static options = (inputMask: InputMask): InputMaskOrigin.Options => {
    switch (inputMask) {
      case InputMask.INTEGER:
        return {
          ...commonOptionsGlobal,
          ...commonOptionsNumbers,
          digits: '0',
        }
      case InputMask.NEGATIVE_INTEGER:
        return {
          ...commonOptionsGlobal,
          mask: '-9{*}',
        }
      case InputMask.FLOAT:
        return {
          ...commonOptionsGlobal,
          ...commonOptionsNumbers,
          digits: '2',
        }
      case InputMask.CURRENCY:
        return {
          ...commonOptionsGlobal,
          ...commonOptionsNumbers,
          digits: '2',
          suffix: ` ${CURRENCY_PLN}`,
        }
      case InputMask.DATE:
        return {
          ...commonOptionsGlobal,
          alias: 'datetime',
          inputFormat: DATE_FORMAT.toLowerCase(),
          clearIncomplete: true,
        }
      case InputMask.PERCENT:
        return {
          ...commonOptionsGlobal,
          ...commonOptionsNumbers,
          digits: '0',
          suffix: '%',
        }
    }
  }
}
