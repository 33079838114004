import React from 'react'
import Table, {
  TableBody,
  TableData,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from 'components/Table'
import { MediaFormat, MediaFormatIndoors, Medium, MediumStatus } from 'types/campaign'
import TsxUtils from 'utils/tsx'
import { useTranslation } from 'react-i18next'
import Pagination from 'components/Pagination'
import { ITEMS_PER_PAGE } from 'constant'

type Props = {
  media: Medium[]
  mediaLoading?: boolean
  mediumFormat: MediaFormat
  pagination?: {
    activePage: number
    setPage: (pageNumber: number) => void
    totalItemsCount: number
  }
}

const MediaListTable: React.FC<Props> = ({ pagination, mediaLoading, mediumFormat, media }) => {
  const { t } = useTranslation()

  return (
    <>
      <Table
        className='MediaList__table'
        loading={mediaLoading}
      >
        <TableHeader>
          <TableHeaderItem>{t('common.asId')}</TableHeaderItem>
          <TableHeaderItem>{t('common.agglomeration')}</TableHeaderItem>
          <>
            {MediaFormatIndoors.includes(mediumFormat) && (
              <TableHeaderItem>{t('common.building')}</TableHeaderItem>
            )}
          </>
          <TableHeaderItem>{t('media.city')}</TableHeaderItem>
          <TableHeaderItem>{t('media.address')}</TableHeaderItem>
          <TableHeaderItem>{t('media.screenCountShort')}</TableHeaderItem>
        </TableHeader>

        <TableBody>
          {media.map((m: Medium) => (
            <TableRow
              key={m.id}
              className={
                'MediaList__table--row' +
                TsxUtils.extraStyle(
                  m.status !== MediumStatus.ACTIVE,
                  'MediaList__table--grayed-out'
                )
              }
            >
              <TableData>{m.asId}</TableData>
              <TableData>{m.agglomeration?.name ?? '-'}</TableData>
              <>
                {MediaFormatIndoors.includes(mediumFormat) && (
                  <TableData>{m.building?.name ?? '-'}</TableData>
                )}
              </>
              <TableData
                className={TsxUtils.extraStyle(
                  m.status !== MediumStatus.ACTIVE,
                  'MediaList__table--bold'
                )}
              >
                {m.city.name}
              </TableData>
              <TableData>{m.address}</TableData>
              <TableData>{m.screenCount}</TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {pagination && (
        <Pagination
          activePage={pagination.activePage}
          itemsCountPerPage={ITEMS_PER_PAGE}
          totalItemsCount={pagination.totalItemsCount}
          onChange={pagination.setPage}
        />
      )}
    </>
  )
}

export default MediaListTable
