import { gql } from '@apollo/client'
import { MEDIUM_FIELDS } from '../fragments'

export const GET_FILTERED_MEDIA = gql`
  ${MEDIUM_FIELDS}
  query filteredMedia($campaignId: ID, $first: Int, $last: Int, $editCampaign: Boolean) {
    filteredMedia(
      campaignId: $campaignId
      first: $first
      last: $last
      editCampaign: $editCampaign
    ) {
      nodes {
        ...MediumFields
      }
      totalCount
    }
  }
`

export const GET_FILTERED_MEDIA_PUBLIC = gql`
  ${MEDIUM_FIELDS}
  query campaignReportPublic($campaignUuid: String!, $first: Int, $last: Int) {
    campaignReportPublic(campaignUuid: $campaignUuid) {
      filteredMedia(campaignUuid: $campaignUuid, first: $first, last: $last) {
        nodes {
          ...MediumFields
        }
        totalCount
      }
    }
  }
`
