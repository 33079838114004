import { AuthorizationTemplate } from 'hooks/useAuthorize'
import { CampaignStatus, CreationStatus, MediaFormat } from 'types/campaign'
import { BaseRole } from 'types/user'
import { ALL_CAMPAIGN_STATUSES, ALL_ROLES } from './various'

export const CAMPAIGN: AuthorizationTemplate = {
  ALERTS: { roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN] },
  NOTE: { roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN] },
}

export const CAMPAIGN_ACTION: AuthorizationTemplate = {
  EDIT: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  COPY: {
    roles: ALL_ROLES,
    mediaFormat: [MediaFormat.DG_CL, MediaFormat.DG_CS, MediaFormat.DG_IN],
  },
  EDIT_IN_PENDING_ACCEPTANCE_NOT_BY_CLIENT: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN],
    statuses: [CampaignStatus.SKETCH],
  },
  EDIT_ACTIVE_PLANNED_RESERVED_CAMPAIGN_BY_CLIENT: {
    roles: [BaseRole.CLIENT],
    statuses: [CampaignStatus.ACTIVE, CampaignStatus.PLANNED, CampaignStatus.RESERVED],
  },
  DOWNLOAD: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.FINISHED,
      CampaignStatus.RESERVED,
      CampaignStatus.RESERVATION_CANCELLED,
    ],
  },
  SHARE: {
    roles: ALL_ROLES,
    statuses: ALL_CAMPAIGN_STATUSES,
  },
  REMOVE: { roles: ALL_ROLES, statuses: [CampaignStatus.SKETCH] },
  APPROVE: { roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN], statuses: [CampaignStatus.SKETCH] },
  PUBLISH: {
    roles: ALL_ROLES,
    statuses: [CampaignStatus.PLANNED, CampaignStatus.ACTIVE, CampaignStatus.RESERVED],
  },
  SEND_TO_ACCEPTANCE: {
    roles: [BaseRole.CLIENT],
    statuses: [CampaignStatus.SKETCH],
  },
  PAUSE: { roles: [BaseRole.ADMIN], statuses: [CampaignStatus.ACTIVE] },
  FINISH: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN],
    statuses: [CampaignStatus.PLANNED, CampaignStatus.ACTIVE],
  },
  RESERVE: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN],
    statuses: [CampaignStatus.SKETCH],
  },
  CONFIRM_RESERVATION: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN],
    statuses: [CampaignStatus.RESERVED],
  },
  EDIT_NOTE: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN],
    statuses: ALL_CAMPAIGN_STATUSES,
  },
  EDIT_RESERVATION: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN],
    statuses: [CampaignStatus.RESERVED],
  },
  CANCEL_RESERVATION: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN],
    statuses: [CampaignStatus.RESERVED],
  },
  CAMPAIGN_ATTRIBUTES: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN],
  },
  CAMPAIGN_ID: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN],
  },
  UPLOAD_ZIP: {
    roles: [
      BaseRole.SUPPORT,
      BaseRole.EMPLOYEE,
      BaseRole.ADMIN,
      BaseRole.ACCOUNT,
      BaseRole.PLANNER,
    ],
  },
}

export const CAMPAIGN_FORM: AuthorizationTemplate = {
  NAME: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  BRIEF_NAME: {
    roles: ALL_ROLES,
    statuses: [CampaignStatus.SKETCH, CampaignStatus.RESERVED],
  },
  AGENCY: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN],
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.RESERVED,
      CampaignStatus.ACTIVE,
    ],
  },
  SUPERVISORS: {
    roles: [BaseRole.ADMIN, BaseRole.SUPPORT],
    statuses: ALL_CAMPAIGN_STATUSES,
  },
  START_DATE: {
    roles: ALL_ROLES,
    statuses: [CampaignStatus.SKETCH, CampaignStatus.PLANNED, CampaignStatus.RESERVED],
  },
  END_DATE: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  MEDIA_TYPES: { roles: ALL_ROLES, statuses: [CampaignStatus.SKETCH] },
  POIS: { roles: [BaseRole.ADMIN], statuses: [CampaignStatus.SKETCH] },
  CITIES: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  MEDIA: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  MEDIA_SELECTION_FULL_SCOPE: {
    roles: [
      BaseRole.ADMIN,
      BaseRole.ACCOUNT,
      BaseRole.EMPLOYEE,
      BaseRole.PLANNER,
      BaseRole.SUPPORT,
    ],
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  OFFER: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN, BaseRole.CLIENT, BaseRole.SUPPORT],
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  TARGET: { roles: ALL_ROLES, statuses: [CampaignStatus.SKETCH] },
  BUDGET: { roles: [BaseRole.ADMIN], statuses: [CampaignStatus.SKETCH] },
  EMISSIONS: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  PRIORITY: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN],
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  SEND_CREATIONS_TO_ACCEPTANCE: {
    roles: [BaseRole.EMPLOYEE, BaseRole.ADMIN],
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },

  ADD_CREATION: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },

  REMOVE_CREATION_BY_CAMPAIGN_STATUS: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  REMOVE_CREATION_BY_CREATION_STATUS: { roles: ALL_ROLES, statuses: [CreationStatus.CREATED] },

  ARCHIVE_CREATION_BY_CAMPAIGN_STATUS: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  ARCHIVE_CREATION_BY_CREATION_STATUS: {
    roles: ALL_ROLES,
    statuses: [CreationStatus.SENT, CreationStatus.EDITED],
  },

  EDIT_CREATION_AS_ID_BY_CAMPAIGN_STATUS: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  EDIT_CREATION_AS_ID_BY_CREATION_STATUS: {
    roles: ALL_ROLES,
    statuses: [CreationStatus.CREATED, CreationStatus.EDITED],
  },
  EDIT_MEDIA_DURATION: {
    roles: [BaseRole.ADMIN],
    statuses: [CampaignStatus.SKETCH, CampaignStatus.RESERVED],
  },
  CHECK_HIDDEN_TARGET_ESTIMATION: {
    roles: [BaseRole.ADMIN],
  },
}
