import { gql } from '@apollo/client'
import { CREATION_FIELDS, MUTATION_STATE } from '../fragments'

const PAYLOAD_TYPE = 'CreateCampaignCreationsPayload'

export const CREATE_CREATIONS = gql`
  ${CREATION_FIELDS}
  ${MUTATION_STATE('CreateCampaignCreationsPayload')}
  mutation createCreations($campaignId: ID!, $mediaFormat: MediumFormatEnum!, $files: [Upload!]!, $duration: Int!, $containsAlcohol: Boolean!) {
    createCreations(input: { campaignId: $campaignId, mediaFormat: $mediaFormat, files: $files, duration: $duration, containsAlcohol: $containsAlcohol }) {
      creations {
        ...CreationFields
      }
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
