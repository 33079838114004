import React, { ReactChild } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertType, Creation } from '../../../../../types/campaign'
import TooltipHelp from '../../../../../components/TooltipHelp'
import IconAlert from '../../../../../components/Icon/IconAlert'
import { CreationsEmissionsTooltipAlertProps } from './types'

const CreationsEmissionsTooltipAlert: React.FC<CreationsEmissionsTooltipAlertProps> = ({
  currentCreationId,
  creations,
  creationEmissionNumber,
  campaignEmissions,
}) => {
  const { t } = useTranslation()

  const tooltip = (alertType: AlertType, message: string): ReactChild => {
    return (
      <TooltipHelp
        containerClassName={`CreationsFormEmissionNumber__emissions--tooltip-${alertType.toString()}`}
      >
        <div className={'CreationsFormEmissionNumber__emissions--alert'}>
          <div className='Alerts__alert'>
            <div className={'CreationsFormEmissionNumber__emissions--alert'}>
              {(alertType === AlertType.warning || alertType === AlertType.alert) && (
                <div className={'Alerts__header'}>
                  <IconAlert alertType={alertType} />
                </div>
              )}
              <div className='Alerts__alert'>{message}</div>
            </div>
          </div>
        </div>
      </TooltipHelp>
    )
  }
  const getTooltipAlert = (): ReactChild => {
    const totalCreationsEmissions =
      (creations as Creation[])
        .filter(creation => creation.id !== currentCreationId)
        .reduce((sum, { impressions }) => sum + impressions, 0) +
      (creationEmissionNumber ? parseInt(creationEmissionNumber) : 0)

    if ((creations as Creation[]).some(creation => creation.impressions === null)) {
      return tooltip(
        AlertType.info,
        t('form.campaign.creations.creationsEmissionsNumberShouldBeEqualToCampaigns')
      )
    }

    if (totalCreationsEmissions < campaignEmissions) {
      return tooltip(
        AlertType.warning,
        t('form.campaign.creations.creationsEmissionsNumberCantBeLowerThanCampaigns')
      )
    }

    if (totalCreationsEmissions > campaignEmissions) {
      return tooltip(
        AlertType.alert,
        t('form.campaign.creations.creationsEmissionsNumberCantBeHigherThanCampaigns')
      )
    }

    return tooltip(
      AlertType.info,
      t('form.campaign.creations.creationsEmissionsNumberShouldBeEqualToCampaigns')
    )
  }

  return <>{getTooltipAlert()}</>
}

export default CreationsEmissionsTooltipAlert
