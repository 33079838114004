import React, { CSSProperties } from 'react'
import './InventoryOccupancyChart.scss'
import { InventoryBuildingModel } from '../models/inventory-buiding.model'

const InventoryOccupancyChart: React.FC<{
  inventoryBuilding: InventoryBuildingModel
  selectInventoryBuilding: (inventoryBuilding: string) => void
}> = ({ inventoryBuilding, selectInventoryBuilding }) => {
  const getGradient = (occupiedPercentage: number): CSSProperties => {
    const realPercentage = occupiedPercentage * 100

    return {
      backgroundImage: `conic-gradient(
                    #90E0EF 0%,
                    #2A77B6 ${realPercentage}%,
                    #E5E6EB ${realPercentage}%,
                    #E5E6EB 100%
    )`,
    }
  }

  const getDisplayedPercentage = (occupiedPercentage: number): string => {
    return `${Math.round(occupiedPercentage * 100)}%`
  }

  return (
    <div
      className='InventoryOccupancyChart'
      onClick={() => selectInventoryBuilding(inventoryBuilding.buildingName)}
    >
      <div
        className='InventoryOccupancyChart__wheel'
        style={getGradient(inventoryBuilding.occupiedPercentage)}
      >
        <p className='InventoryOccupancyChart__value'>
          {getDisplayedPercentage(inventoryBuilding.occupiedPercentage)}
        </p>
      </div>

      <p className='InventoryOccupancyChart__description'>{inventoryBuilding.buildingName}</p>
      <p className='InventoryOccupancyChart__description'>
        {inventoryBuilding.allocatedSlots} / {inventoryBuilding.totalSlots}
      </p>
    </div>
  )
}

export default InventoryOccupancyChart
