import { User } from 'types/user'

export type ProfileData = Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>

export const profileDataDefault: ProfileData = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
}

export interface PasswordData {
  currentPassword: User['password']
  password: User['password']
  passwordConfirmation: User['password']
}

export const passwordDataDefault: PasswordData = {
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
}
