import { SelectOption } from 'components/Form/Select'
import { iterateOverTail } from './iterator'
import { Filters, FilterValue, GroupedMedia, MediumGroup } from './types'
import ArrayUtils from 'utils/array'

export const agglomerationsFilter = (groupedMedia: GroupedMedia, filters: Filters): Filters => {
  const buildingsAll = new Set<string>()
  const citiesAll = new Set<string>()
  const selectedAgglomerations: FilterValue[] = filters.userSelectedAgglomerations
  const filteredAgglomerations = new Set<string>()
  const mediaAll: FilterValue[] = []

  iterateOverTail({
    groupTail: groupedMedia,

    nodeCallback: node => {
      if (
        node.agglomeration &&
        (selectedAgglomerations.includes(node.agglomeration.name) || !selectedAgglomerations.length)
      ) {
        mediaAll.push(node.asId.toString())
        filteredAgglomerations.add(node.agglomeration.name)
        if (node.building) buildingsAll.add(node.building.name)
        citiesAll.add(node.city.name)
      }
    },
  })

  const selectedBuildings = selectedAgglomerations.length ? [...buildingsAll] : []
  const selectedCities = selectedAgglomerations.length ? [...citiesAll] : []
  const selectedMedia = selectedAgglomerations.length ? [...mediaAll] : []

  return {
    ...filters,
    allBuildings: ArrayUtils.sortDescending([...buildingsAll]),
    allCities: ArrayUtils.sortDescending([...citiesAll]),
    allMedia: ArrayUtils.sortDescending(mediaAll),
    filteredAgglomerations: [...filteredAgglomerations],
    filteredMedia: mediaAll,
    userSelectedBuildings: [...selectedBuildings],
    userSelectedCities: [...selectedCities],
    userSelectedMedia: [...selectedMedia],
    text: '',
  }
}

export const citiesFilter = (groupedMedia: GroupedMedia, filters: Filters): Filters => {
  const buildingsAll = new Set<string>()
  const filteredAgglomerations = new Set<string>()
  const filteredCities = new Set<string>()
  const mediaAll: FilterValue[] = []
  const selectedCities: FilterValue[] = filters.userSelectedCities

  iterateOverTail({
    groupTail: groupedMedia,

    nodeCallback: node => {
      if (
        selectedCities.includes(node.city.name) ||
        (!selectedCities.length && filters.allCities.includes(node.city.name))
      ) {
        mediaAll.push(node.asId.toString())
        if (node.agglomeration) filteredAgglomerations.add(node.agglomeration.name)
        if (node.building) buildingsAll.add(node.building.name)
        filteredCities.add(node.city.name)
      }
    },
  })

  const selectedBuildings = selectedCities.length ? [...buildingsAll] : []
  const selectedMedia = selectedCities.length ? [...mediaAll] : []

  return {
    ...filters,
    allBuildings: ArrayUtils.sortDescending([...buildingsAll]),
    allMedia: ArrayUtils.sortDescending(mediaAll),
    filteredAgglomerations: [...filteredAgglomerations],
    filteredBuildings: [...buildingsAll],
    filteredCities: [...filteredCities],
    filteredMedia: mediaAll,
    userSelectedBuildings: [...selectedBuildings],
    userSelectedMedia: [...selectedMedia],
    text: '',
  }
}

export const buildingsFilter = (groupedMedia: GroupedMedia, filters: Filters): Filters => {
  const filteredAgglomerations = new Set<string>()
  const filteredCities = new Set<string>()
  const filteredBuildings = new Set<string>()
  const mediaAll: FilterValue[] = []
  const selectedBuildings: FilterValue[] = filters.userSelectedBuildings

  iterateOverTail({
    groupTail: groupedMedia,

    nodeCallback: node => {
      if (node.building && selectedBuildings.includes(node.building.name)) {
        mediaAll.push(node.asId.toString())
        if (node.agglomeration) filteredAgglomerations.add(node.agglomeration.name)
        filteredBuildings.add(node.building.name)
        filteredCities.add(node.city.name)
      }
    },
  })

  return {
    ...filters,
    filteredAgglomerations: [...filteredAgglomerations],
    filteredBuildings: [...filteredBuildings],
    filteredCities: [...filteredCities],
    filteredMedia: mediaAll,
    allMedia: ArrayUtils.sortDescending([...mediaAll]),
    userSelectedMedia: mediaAll,
    text: '',
  }
}

export const mediaFilter = (groupedMedia: GroupedMedia, filters: Filters): Filters => {
  const filteredAgglomerations = new Set<string>()
  const filteredBuildings = new Set<string>()
  const filteredCities = new Set<string>()
  const filteredMedia = new Set<string>()
  const selectedMedia: FilterValue[] = filters.userSelectedMedia

  iterateOverTail({
    groupTail: groupedMedia,

    nodeCallback: node => {
      if (
        selectedMedia.includes(node.asId.toString()) ||
        (!selectedMedia.length && filters.allMedia.includes(node.asId.toString()))
      ) {
        if (node.agglomeration) filteredAgglomerations.add(node.agglomeration.name)
        if (node.building) filteredBuildings.add(node.building.name)
        filteredCities.add(node.city.name)
        filteredMedia.add(node.asId.toString())
      }
    },
  })

  return {
    ...filters,
    filteredAgglomerations: [...filteredAgglomerations],
    filteredBuildings: [...filteredBuildings],
    filteredCities: [...filteredCities],
    filteredMedia: [...filteredMedia],
    text: '',
  }
}

export const poiFilter = (groupedMedia: GroupedMedia, filters: Filters): Filters => {
  const filteredAgglomerations = new Set<string>()
  const filteredBuildings = new Set<string>()
  const filteredCities = new Set<string>()
  const filteredMedia = new Set<string>()
  const selectedPois: FilterValue[] = filters.userSelectedPois

  iterateOverTail({
    groupTail: groupedMedia,

    nodeCallback: node => {
      // check if node has any of selected pois or no pois selected
      if (node.poiCategories.some(poi => selectedPois.includes(poi)) || selectedPois.length === 0) {
        if (node.agglomeration) filteredAgglomerations.add(node.agglomeration.name)
        if (node.building) filteredBuildings.add(node.building.name)
        filteredCities.add(node.city.name)
        filteredMedia.add(node.asId.toString())
      }
    },
  })

  return {
    ...filters,
    filteredAgglomerations: [...filteredAgglomerations],
    filteredBuildings: [...filteredBuildings],
    filteredCities: [...filteredCities],
    filteredMedia: [...filteredMedia],
    text: '',
  }
}

export const textFilter = (groupedMedia: GroupedMedia, filters: Filters, text: string): Filters => {
  const filteredAgglomerations = new Set<string>()
  const filteredBuildings = new Set<string>()
  const filteredCities = new Set<string>()
  const filteredMedia = new Set<string>()

  const mediaFilter = filters.userSelectedMedia.length ? 'userSelectedMedia' : 'allMedia'
  const agglomerationsFilter = filters.userSelectedAgglomerations.length
    ? 'userSelectedAgglomerations'
    : 'allAgglomerations'
  const buildingsFilter = filters.userSelectedBuildings.length
    ? 'userSelectedBuildings'
    : 'allBuildings'
  const citiesFilter = filters.userSelectedCities.length ? 'userSelectedCities' : 'allCities'

  const textQueryRegex = new RegExp(`${text}`, 'i')
  const shouldShowNode = (
    nodeName: string | undefined,
    filterName:
      | 'userSelectedMedia'
      | 'userSelectedAgglomerations'
      | 'userSelectedBuildings'
      | 'userSelectedCities'
      | 'allMedia'
      | 'allAgglomerations'
      | 'allBuildings'
      | 'allCities',
    filterQuery?: string
  ): boolean => {
    if (nodeName === undefined) return false

    return textQueryRegex.test(nodeName) && filters[filterName].includes(filterQuery || nodeName)
  }

  iterateOverTail({
    groupTail: groupedMedia,

    nodeCallback: node => {
      if (
        shouldShowNode(node.address, mediaFilter, node.asId.toString()) ||
        shouldShowNode(node.agglomeration?.name, agglomerationsFilter) ||
        shouldShowNode(node.building?.name, buildingsFilter) ||
        shouldShowNode(node.city.name, citiesFilter)
      ) {
        filteredCities.add(node.city.name)
        filteredMedia.add(node.asId.toString())
        if (node.agglomeration) filteredAgglomerations.add(node.agglomeration.name)
        if (node.building) filteredBuildings.add(node.building.name)
      }
    },
  })

  return {
    ...filters,
    filteredAgglomerations: [...filteredAgglomerations],
    filteredBuildings: [...filteredBuildings],
    filteredCities: [...filteredCities],
    filteredMedia: [...filteredMedia],
    text,
  }
}

export const collectFilters = (groupedMedia: GroupedMedia): Filters => {
  const agglomerationsAll: FilterValue[] = []
  const buildingsAll: FilterValue[] = []
  const citiesAll: FilterValue[] = []
  const mediaAll: FilterValue[] = []
  const allPois: FilterValue[] = []

  iterateOverTail({
    groupTail: groupedMedia,

    nodeCallback: node => {
      mediaAll.push(node.asId.toString())
      node.poiCategories.forEach(poi => {
        if (!allPois.includes(poi)) allPois.push(poi)
      })
    },

    nodeGroupCallback: ({ nodeGroup }) => {
      switch (nodeGroup.group) {
        case MediumGroup.AGGLOMERATION: {
          agglomerationsAll.push(nodeGroup.name)
          break
        }

        case MediumGroup.CITY: {
          citiesAll.push(nodeGroup.name)
          break
        }

        case MediumGroup.BUILDING: {
          buildingsAll.push(nodeGroup.name)
          break
        }
      }
    },
  })

  return {
    allAgglomerations: ArrayUtils.sortDescending(agglomerationsAll),
    allCities: ArrayUtils.sortDescending(citiesAll),
    allBuildings: ArrayUtils.sortDescending(buildingsAll),
    allMedia: ArrayUtils.sortDescending(mediaAll),
    allPois: ArrayUtils.sortDescending(allPois),
    userSelectedAgglomerations: [],
    userSelectedCities: [],
    userSelectedBuildings: [],
    userSelectedMedia: [],
    userSelectedPois: [],
    text: '',
  }
}

export const mapFilterToSelectOption = (filterValues: FilterValue[]): SelectOption[] =>
  filterValues.map((fv: FilterValue): SelectOption => ({ value: fv, label: fv }))

const conditionToHideNode = (
  nodesFilteredByUserInput: FilterValue[],
  nodesFilteredByAnotherInputs: FilterValue[] | undefined,
  nodeName: string
): boolean => {
  if (nodesFilteredByAnotherInputs === undefined) return false

  return (
    !nodesFilteredByAnotherInputs.includes(nodeName) || !nodesFilteredByUserInput.includes(nodeName)
  )
}

export const filterGroupedMedia = (groupedMedia: GroupedMedia, filters: Filters): GroupedMedia => {
  const {
    userSelectedAgglomerations,
    userSelectedCities,
    userSelectedBuildings,
    userSelectedMedia,
    filteredAgglomerations,
    filteredCities,
    filteredBuildings,
    filteredMedia,
    allMedia,
    allBuildings,
    allCities,
    allAgglomerations,
  } = filters

  const mediaNode = userSelectedMedia.length ? userSelectedMedia : allMedia
  const citiesNode = userSelectedCities.length ? userSelectedCities : allCities
  const buildingsNode = userSelectedBuildings.length ? userSelectedBuildings : allBuildings
  const agglomerationsNode = userSelectedAgglomerations.length
    ? userSelectedAgglomerations
    : allAgglomerations

  iterateOverTail({
    groupTail: groupedMedia,

    nodeCallback: node => {
      node.hidden = conditionToHideNode(mediaNode, filteredMedia, node.asId.toString())
    },

    nodeGroupCallback: ({ nodeGroup }) => {
      const name = nodeGroup.name
      switch (nodeGroup.group) {
        case MediumGroup.AGGLOMERATION:
          nodeGroup.hidden = conditionToHideNode(agglomerationsNode, filteredAgglomerations, name)
          break

        case MediumGroup.CITY:
          nodeGroup.hidden = conditionToHideNode(citiesNode, filteredCities, name)
          break

        case MediumGroup.BUILDING:
          nodeGroup.hidden = conditionToHideNode(buildingsNode, filteredBuildings, name)
          break
      }
    },
  })

  return groupedMedia
}
