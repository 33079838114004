import { gql } from '@apollo/client'

export const UPDATE_CAMPAIGN_ORGANIZATION_ATTRIBUTES = gql`
  mutation UpdateCampaignOrganizationAttributes(
    $campaignId: ID!
    $organizationAttribute: OrganizationAttributeUsage!
  ) {
    updateCampaignOrganizationAttributes(
      input: { campaignId: $campaignId, organizationAttribute: $organizationAttribute }
    ) {
      campaign {
        organizationAttribute {
          commercial
          complaint
          freeComplaint
          outsidePh
          productOffer
          social
        }
      }
      success
      errors
    }
  }
`
