import en from './en'
import pl from './pl'

export default {
  en: {
    translation: en,
  },
  pl: {
    translation: pl,
  },
}
