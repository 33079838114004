import React, { useEffect } from 'react'
import './UserTableFilters.scss'
import Card from '../../../../components/Layout/Card'
import TsxUtils from '../../../../utils/tsx'
import FillingSpinner, { FillingSpinnerType } from '../../../../components/FillingSpinner'
import SelectMultiCheckbox from '../../../../components/Form/SelectMultiCheckbox'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import UserTableFiltersService from '../services/user-table-filters.service'
import { SelectOption } from '../../../../components/Form/Select'
import { userTableActions } from '../store/user-table-slice'
import Button, { ButtonSize, ButtonTheme } from '../../../../components/Form/Button'

const UserTableFilters: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    loading,
    allAgencies,
    allStatuses,
    allRoles,
    selectedAgencies,
    selectedStatuses,
    selectedRoles,
  } = useSelector((state: RootState) => state.userTable.filters)

  useEffect(() => {
    UserTableFiltersService.fetchFilters()
  }, [])

  return (
    <Card className={'Filters' + TsxUtils.extraStyle(loading, 'Filters--loading')}>
      {loading ? (
        <FillingSpinner type={FillingSpinnerType.STANDARD} />
      ) : (
        <>
          <div className='filter-container'>
            <SelectMultiCheckbox
              dataCy={'user-table-filters-roles'}
              id='roles'
              title={t('common.roles')}
              value={selectedRoles}
              options={allRoles.map((role: string): SelectOption => ({ value: role, label: role }))}
              onChange={(roles): void =>
                void dispatch(userTableActions.setSelectedFilters({ roles }))
              }
              selectAllOption
            />
            <SelectMultiCheckbox
              dataCy={'user-table-filters-agencies'}
              id='agencies'
              title={t('common.agency')}
              value={selectedAgencies}
              options={allAgencies.map(
                (agency: string): SelectOption => ({ value: agency, label: agency })
              )}
              onChange={(agencies): void =>
                void dispatch(userTableActions.setSelectedFilters({ agencies }))
              }
              selectAllOption
            />
            <SelectMultiCheckbox
              dataCy={'user-table-filters-statuses'}
              id='statuses'
              title={t('common.status')}
              value={selectedStatuses}
              options={allStatuses.map(
                (status: string): SelectOption => ({
                  value: status,
                  label: t(`user.status.${status}`),
                })
              )}
              onChange={(statuses): void =>
                void dispatch(userTableActions.setSelectedFilters({ statuses }))
              }
              selectAllOption
            />
          </div>

          <div className='buttons-container'>
            <Button
              dataCy={'user-table-filters-clear-button'}
              size={ButtonSize.SMALL}
              theme={ButtonTheme.BLUE_OUTLINE}
              onClick={() => {
                void dispatch(userTableActions.resetFilters())
                void dispatch(userTableActions.setLoaded(false))
              }}
            >
              {t('filters.clearFilters')}
            </Button>

            <Button
              dataCy={'user-table-filters-filter-button'}
              size={ButtonSize.SMALL}
              onClick={() => void dispatch(userTableActions.setLoaded(false))}
            >
              {t('filters.filter')}
            </Button>
          </div>
        </>
      )}
    </Card>
  )
}

export default UserTableFilters
