import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from 'contexts/AppContext'
import { faCamera, faChevronDown, faChevronUp, faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'components/Link'
import { Routes } from 'routes'
import { useTranslation } from 'react-i18next'
import Button, { ButtonTheme } from 'components/Form/Button'
import useOnClickOutside from 'hooks/useOnClickOutside'
import Modal from 'components/Modal'
import ModalUpload from 'components/Modal/ModalUpload/ModalUpload'
import service from './service'
import { default as UsersService } from 'pages/User/UserTable/services/user-table.service'
import { User } from 'types/user'
import avatarPlaceholder from 'images/user.png'
import './TopBarUser.scss'

const TopBarUser: React.FC = () => {
  const { t } = useTranslation()
  const { userData, updateUser, resetUser, addNotification } = useContext(AppContext)
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const [signOutLoading, setSignOutLoading] = useState(false)
  const [signOutSuccess, setSignOutSuccess] = useState(false)
  const topBarUserRef = useRef<HTMLDivElement>(null)
  const [isUploadAvatarModalOpen, setIsUploadAvatarModalOpen] = useState<boolean>(false)

  const user: User | null = userData.user
  const userName = `${user?.firstName} ${user?.lastName}`
  const userAvatar = () => {
    if (!user || !user.avatar) return avatarPlaceholder
    return user.avatar.url
  }

  useOnClickOutside(topBarUserRef, () => {
    hideDropdown()
  })

  const hideDropdown = () => void setIsDropdownVisible(false)
  const hideModal = () => void setIsUploadAvatarModalOpen(false)

  useEffect(() => {
    if (signOutSuccess) resetUser()
  }, [signOutSuccess])

  const Dropdown = (u: User): ReactElement => (
    <div className='TopBarUser__dropdown--container'>
      <div className='TopBarUser__dropdown'>
        <div className='TopBarUser__dropdown--avatar'>
          <img
            className='TopBarUser__dropdown--avatar-img'
            src={userAvatar()}
            alt='avatar'
          />
          <div
            className='TopBarUser__dropdown--avatar-upload'
            onClick={() => void setIsUploadAvatarModalOpen(true)}
          >
            <FontAwesomeIcon
              className='TopBarUser__dropdown--avatar-upload-icon'
              icon={faCamera}
            />
          </div>
        </div>

        <div className='TopBarUser__dropdown--data'>
          <div className='TopBarUser__dropdown--data-name'>{userName}</div>
          <div className='TopBarUser__dropdown--data-email'>{u.email}</div>
          <div className='TopBarUser__dropdown--data-role'>{UsersService.parseRoles(u.roles)}</div>
        </div>

        <Link
          className='TopBarUser__dropdown--settings'
          toUrl={Routes.USERS.SETTINGS}
          onClick={() => void hideDropdown()}
        >
          <FontAwesomeIcon
            className='TopBarUser__dropdown--settings-icon'
            icon={faCog}
          />
          <div className='TopBarUser__dropdown--settings-text'>{t('user.settings.header')}</div>
        </Link>

        <div className='TopBarUser__dropdown--sign-out'>
          <Button
            onClick={(): void =>
              void service.signOut(setSignOutLoading, setSignOutSuccess, addNotification, t)
            }
            disabled={signOutLoading}
          >
            {t('topBar.signOut')}
          </Button>
        </div>
      </div>
    </div>
  )

  return (
    <div
      className='TopBarUser--container'
      ref={topBarUserRef}
    >
      <div
        className='TopBarUser'
        onClick={() => void setIsDropdownVisible(!isDropdownVisible)}
      >
        <img
          className='TopBarUser__avatar'
          src={userAvatar()}
          alt='avatar'
        />
        <div className='TopBarUser__name'>{userName}</div>
        <FontAwesomeIcon
          icon={isDropdownVisible ? faChevronUp : faChevronDown}
          className='TopBarUser__arrow'
        />
      </div>

      {isDropdownVisible && user && Dropdown(user)}

      {user && isUploadAvatarModalOpen && (
        <Modal
          title={t('topBar.selectProfilePhoto')}
          isOpen={isUploadAvatarModalOpen}
          onClose={(isOpen: boolean) => void setIsUploadAvatarModalOpen(isOpen)}
          footer={
            <Button
              theme={ButtonTheme.BLUE_OUTLINE}
              onClick={hideModal}
            >
              {t('common.cancel')}
            </Button>
          }
        >
          <ModalUpload
            className='TopBarUser__upload-modal'
            accept='image/png, image/jpeg'
            onDrop={(file, setLoading) =>
              void service.uploadAvatar(user.id, file[0], setLoading, hideModal, updateUser)
            }
          />
        </Modal>
      )}
    </div>
  )
}

export default TopBarUser
