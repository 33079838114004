import { gql } from '@apollo/client'

export const FETCH_USER_FILTERS = gql`
  query {
    userFilters {
      agencies
      roles
      statuses
    }
  }
`
