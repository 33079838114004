import React, { ReactChild, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { SignType, SignProps } from './types'
import logo from 'images/logo-ams.jpg'
import background from 'images/sign-in-background.png'
import SignIn from './SignIn'
import RemindPassword from './RemindPassword'
import ResetPassword from './ResetPassword'
import { Routes } from 'routes'
import { AppContext } from 'contexts/AppContext'
import AcceptInvitation from './AcceptInvitation'
import './Sign.scss'

const Sign: React.FC<SignProps> = ({ type = SignType.SIGN_IN }) => {
  const {
    userData: { user },
  } = useContext(AppContext)
  const navigate = useNavigate()

  const Container = (): ReactChild | undefined => {
    switch (type) {
      case SignType.SIGN_IN:
        return <SignIn />
      case SignType.REMIND_PASSWORD:
        return <RemindPassword />
      case SignType.RESET_PASSWORD:
        return <ResetPassword />
      case SignType.ACCEPT_INVITATION:
        return <AcceptInvitation />
    }
  }

  return (
    <div className='Sign'>
      <div className='Sign__left--container'>
        <div className='Sign__left'>
          <img
            src={logo}
            alt='AMS logo'
            className='Sign__logo'
            onClick={(): void => void navigate(user ? Routes.HOME : Routes.SIGN.SIGN_IN)}
          />

          {Container()}
        </div>
      </div>

      <div className='Sign__right--container'>
        <img
          src={background}
          alt='Sign in background'
        />
      </div>
    </div>
  )
}

export default Sign
