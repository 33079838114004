import { client } from 'components/ApolloProvider'
import { loader } from 'graphql.macro'
import { MutationStateResponse, SortDirection } from 'types/various'
import { TableSortColumn } from 'components/Table'
import { Agency, AllowedTarget } from 'types/agency'
import { GET_AGENCY } from './getAgency'
import { GetAgenciesAQR, GetAgencyAQR } from './types'
import { GET_AGENCIES } from './getAgencies'
import { Company } from '../companies/types'
import { User } from 'types/user'
import { FetchResult } from '@apollo/client'
import { GET_ALLOWED_TARGETS } from './getAllowedTargets'

const agencyApi = {
  getAgency: (id: Agency['id']): Promise<GetAgencyAQR> =>
    client.query({
      query: GET_AGENCY,
      variables: { id },
    }),

  getAgencies: (payload: {
    first?: number
    last?: number
    sortColumn?: TableSortColumn
    sortDirection?: SortDirection
    term?: string
  }): Promise<GetAgenciesAQR> =>
    client.query({
      query: GET_AGENCIES,
      variables: {
        first: payload.first ?? null,
        last: payload.last ?? null,
        sortColumn: payload.sortColumn ?? null,
        sortDirection: payload.sortDirection ?? null,
        term: payload.term ?? null,
      },
    }),

  createAgency: (mutationVariable: {
    company: Company | null
    clientId: Agency['clientId']
    agencyType: Agency['agencyType']
    currencyType: Agency['currencyType']
    origin: Agency['origin']
    supervisors: User['id'][]
    name: Agency['name']
    allocatedTime: Agency['allocatedTime']
    mediumAllocatedTime: Agency['mediumAllocatedTime']
    agencyMediums: Agency['agencyMediums']
    withAllocatedTime: Agency['withAllocatedTime']
    allowedTargets: AllowedTarget['id'][]
  }): Promise<FetchResult<MutationStateResponse>> =>
    client.mutate({
      mutation: loader('./createAgency.gql'),
      variables: {
        name: mutationVariable?.name || '',
        companyId: mutationVariable?.company?.id,
        clientId: mutationVariable.clientId,
        agencyType: mutationVariable.agencyType,
        currencyType: mutationVariable.currencyType,
        origin: mutationVariable.origin,
        supervisors: mutationVariable.supervisors,
        allocatedTime: mutationVariable.allocatedTime,
        mediumAllocatedTime: mutationVariable.mediumAllocatedTime,
        agencyMediums: mutationVariable.agencyMediums,
        withAllocatedTime: mutationVariable.withAllocatedTime,
        allowedTargets: mutationVariable.allowedTargets,
      },
    }),

  updateAgency: (mutationVariable: {
    id: Agency['id']
    company: Company
    clientId: Agency['clientId']
    agencyType: Agency['agencyType']
    currencyType: Agency['currencyType']
    origin: Agency['origin']
    supervisors: User['id'][]
    name: Agency['name']
    discount: Agency['discount']
    billingAllowance: Agency['billingAllowance']
    allocatedTime: Agency['allocatedTime']
    mediumAllocatedTime: Agency['mediumAllocatedTime']
    agencyMediums: Agency['agencyMediums']
    withAllocatedTime: Agency['withAllocatedTime']
    allowedTargets: AllowedTarget['id'][]
  }): Promise<FetchResult<{ updateAgency: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./updateAgency.gql'),
      variables: {
        id: mutationVariable.id,
        name: mutationVariable.name,
        companyId: mutationVariable.company?.id,
        clientId: mutationVariable.clientId,
        agencyType: mutationVariable.agencyType,
        currencyType: mutationVariable.currencyType,
        origin: mutationVariable.origin,
        supervisors: mutationVariable.supervisors,
        discount: mutationVariable.discount,
        billingAllowance: false,
        allocatedTime: mutationVariable.allocatedTime,
        mediumAllocatedTime: mutationVariable.mediumAllocatedTime,
        agencyMediums: mutationVariable.agencyMediums,
        withAllocatedTime: mutationVariable.withAllocatedTime,
        allowedTargets: mutationVariable.allowedTargets,
        // TODO: billing - uncomment later
        // billingAllowance: mutationVariable.billingAllowance,
      },
    }),

  deleteAgency: (
    agencyId: Agency['id']
  ): Promise<FetchResult<{ destroyAgency: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./destroyAgency.gql'),
      variables: { agencyId },
    }),

  getAllowedTargets: (): Promise<FetchResult<{ allowedTargets: AllowedTarget[] }>> =>
    client.query({
      query: GET_ALLOWED_TARGETS,
    }),
}

export default agencyApi
