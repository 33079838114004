import { gql } from '@apollo/client'
import { ALERT_FIELDS, AGENCY_FIELDS, USER_FIELDS, COMPANY_FIELDS } from '../fragments'

export const GET_CAMPAIGN_FOR_BASIC_FORM = gql`
  ${USER_FIELDS}
  ${ALERT_FIELDS}
  ${AGENCY_FIELDS}
  ${COMPANY_FIELDS}
  query campaignForBasicForm($id: ID!) {
    node(id: $id) {
      ... on Campaign {
        alerts {
          ...AlertFields
        }
        aggregatedAcceptanceAlerts {
          type
          active
          event
          genre
          message
        }
        brainState
        briefName
        agency {
          ...AgencyFields
        }
        advertiser {
          ...CompanyFields
        }
        endDate
        id
        name
        note
        contractNote
        reservationTill
        startDate
        status
        acceptanceStatus
        supervisors {
          ...UserFields
        }
        estimatedValue {
          value
          valueCents
          currency
        }
        createdAt
        bundle {
          identifier
          suite
          commercialName
        }
      }
    }
  }
`
