import React, { ReactElement } from 'react'
import { Image, Text, View } from '@react-pdf/renderer'
import { MediaFormat } from 'types/campaign'
import logo from 'images/ooh_red.png'
import { pdfStyles } from '../../../../../utils/pdf-styles'
import { dgCl, dgCs, dgIn } from '../../../../../images/pdfAudienceHeaders'

type Props = {
  name: string
  mediumFormat: MediaFormat
}

export const AudienceHeaderPdf = ({ name, mediumFormat }: Props): ReactElement => {
  const mediumImage = MEDIUM_IMAGES[mediumFormat] || dgIn

  return (
    <View style={pdfStyles.header}>
      <View style={pdfStyles.headerLeft}>
        <Image
          style={pdfStyles.audienceHeaderLogo}
          src={logo}
        />
        <Text style={pdfStyles.headerText}>{name}</Text>
      </View>

      <Image
        style={pdfStyles.headerImage}
        src={mediumImage}
      />
    </View>
  )
}

const MEDIUM_IMAGES: Record<MediaFormat, string> = {
  [MediaFormat.DG_CL]: dgCl,
  [MediaFormat.DG_CS]: dgCs,
  [MediaFormat.DG_IN]: dgIn,
  [MediaFormat.DG_IN_H]: dgIn,
  [MediaFormat.DG_IN_S]: dgIn,
}
