import { gql } from '@apollo/client'
import { CREATION_FIELDS } from '../fragments'

export const GET_CAMPAIGN_FOR_CREATIONS_FORM = gql`
  ${CREATION_FIELDS}
  query campaignForCreationsForm($id: ID!) {
    node(id: $id) {
      ... on Campaign {
        id
        creations {
          ...CreationFields
        }
      }
    }
  }
`
