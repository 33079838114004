import React, { ReactElement, useEffect, useState } from 'react'
import { default as RcSlider, Handle } from 'rc-slider'
import InputTitle from '../InputTitle'
import { OnInputChangeFn } from '../Input'
import { ProgressBarIndicator } from 'components/ProgressBar'
import Colors from 'styles/colors.module.scss'
import TsxUtils from 'utils/tsx'
import ArrayUtils from 'utils/array'
import 'rc-slider/assets/index.css'
import './Slider.scss'

interface SliderProps {
  id: string
  title?: string
  className?: string
  help?: string
  value: number
  onChange: OnInputChangeFn
  minLabel?: string
  maxLabel?: string
  showHandleValue?: boolean
  step?: number | null
  marks: number[]
  showTrack?: boolean
  useFixedValues?: boolean
  disabled?: boolean
}

const Slider: React.FC<SliderProps> = ({
  id,
  title,
  className,
  help,
  value,
  onChange,
  minLabel,
  maxLabel,
  showHandleValue = true,
  step,
  marks,
  showTrack = true,
  useFixedValues,
  disabled = false,
}) => {
  const [number, setNumber] = useState<number>()

  const { min, max } = ArrayUtils.getMinMax(marks)

  useEffect(() => {
    if (number !== undefined) onChange(number, id)
  }, [number])

  const handleEl = (props: any): ReactElement => {
    const { dragging, ...restProps } = props // eslint-disable-line react/prop-types

    return (
      <Handle
        dragging={dragging.toString()}
        onClick={() => void setNumber(number === min ? max : min)}
        {...restProps}
      >
        {showHandleValue && <div className='Slider__handle'>{props.value}</div>}
        <ProgressBarIndicator className='Slider__handle--indicator' />
      </Handle>
    )
  }

  return (
    <div
      className={'Slider' + TsxUtils.extraStyle(className, className)}
      id={id}
    >
      {title && (
        <InputTitle
          className='Slider__title'
          title={title}
          help={help}
        />
      )}

      <RcSlider
        disabled={disabled}
        min={min}
        max={max}
        value={value}
        marks={
          !useFixedValues
            ? marks
            : marks.reduce((previous, current) => ({ ...previous, [current]: current }), {})
        }
        step={!useFixedValues ? step : null}
        onChange={number => void setNumber(number)}
        railStyle={{
          height: 8,
          borderRadius: 10,
          backgroundColor: Colors.gray2,
        }}
        trackStyle={{
          height: 8,
          borderRadius: 10,
          backgroundColor: Colors.blue,
          ...(!showTrack && { display: 'none' }),
        }}
        handle={handleEl}
        handleStyle={{
          border: 'none',
          height: 22,
          width: 22,
          marginTop: -7,
        }}
      />

      {minLabel && maxLabel && (
        <div className='Slider__labels'>
          <div>{minLabel}</div>
          <div>{maxLabel}</div>
        </div>
      )}
    </div>
  )
}

export default Slider
