import React, { ReactElement } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { initRollbar } from 'utils/rollbar'
import AppContextProvider from 'contexts/AppContext'
import ApolloProvider from 'components/ApolloProvider'
import { store } from './store'
import { Provider } from 'react-redux'
import 'styles/index.scss'

// Workaround for Leaflet errors
window.addEventListener('error', e => {
  if (
    e.message === 'this._map is null' ||
    e.message === "Cannot read properties of null (reading 'getBounds')" ||
    e.message === "Cannot read properties of undefined (reading '_leaflet_pos')"
  ) {
    e.preventDefault()
  }
})

initRollbar()

const AppContainer = (): ReactElement => (
  <Provider store={store}>
    <AppContextProvider>
      <ApolloProvider>
        <App />
      </ApolloProvider>
    </AppContextProvider>
  </Provider>
)

const container = document.getElementById('root')
if (container) createRoot(container).render(<AppContainer />)
