import React, { useContext, useEffect } from 'react'
import FillingSpinner, { FillingSpinnerType } from 'components/FillingSpinner'
import Pagination from 'components/Pagination'
import { useTranslation } from 'react-i18next'
import Table, {
  TableBody,
  TableData,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from 'components/Table'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import Button, { ButtonTheme } from 'components/Form/Button'
import PageHeader from 'components/Layout/PageHeader'
import { ROLES_TABLE_ITEMS_PER_PAGE } from 'constant'
import { AppContext } from 'contexts/AppContext'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'routes'
import ModalAction from 'components/Modal/ModalAction'
import { USERS_ACTION } from 'constant/authorization'
import { Filter } from 'hooks/useTableFilter'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { userTableActions } from '../../User/UserTable/store/user-table-slice'
import service from '../service'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan'
import { rolesTableActions } from './store/roles-table-slice'
import { RoleTableItem } from '../models/roles-table-slice.model'
import { FeatureName, PermissionType } from '../../../types/features'

const RolesTable: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { getTableFilter, setTableFilter, allowedFor } = useContext(AppContext)
  const { roles, totalCount, loading, loaded, pageNumber } = useSelector(
    (state: RootState) => state.rolesTable
  )
  const { loading: modalLoading, role: removeRoleItem } = useSelector(
    (state: RootState) => state.rolesTable.removeRoleModal
  )
  const tableSort = getTableFilter(Filter.USER)

  const getRoles = (): void => {
    service.getRoles(pageNumber, ROLES_TABLE_ITEMS_PER_PAGE)
  }

  useEffect(() => {
    getRoles()
  }, [pageNumber])

  useEffect(() => {
    if (!loaded) {
      getRoles()
    }
  }, [loaded, pageNumber])

  const Buttons = (role: RoleTableItem) => (
    <>
      {allowedFor({
        feature: { name: FeatureName.Users, action: PermissionType.Write },
      }) && (
        <Button
          icon={faEdit}
          tooltip={t('common.edit')}
          onClick={(): void => void navigate(Routes.ROLES.EDIT(role.id))}
          disabled={!allowedFor({ template: USERS_ACTION.EDIT })}
        />
      )}
      {allowedFor({
        feature: { name: FeatureName.Users, action: PermissionType.Delete },
      }) && (
        <Button
          theme={ButtonTheme.RED}
          icon={faTrashCan}
          tooltip={t('common.remove')}
          onClick={() => void dispatch(rolesTableActions.setModalRole(role))}
        />
      )}
    </>
  )

  const RolesTable = (
    <Table
      className='CommonTable'
      sort={tableSort}
      onSort={({ column, direction }) => void setTableFilter(Filter.USER, { column, direction })}
    >
      <TableHeader>
        <TableHeaderItem
          dataCy={'user-table-header-name'}
          className='CommonTable__header-item'
        >
          {t('common.name')}
        </TableHeaderItem>
        <TableHeaderItem
          dataCy={'user-table-header-actions'}
          className='CommonTable__header-item'
        >
          {t('common.actions')}
        </TableHeaderItem>
      </TableHeader>

      <TableBody>
        {roles.map((role: { id: string; name: string }) => (
          <TableRow
            key={role.id}
            className='CommonTable__data-row'
          >
            <TableData className='CommonTable__data-item CommonTable__data-item--name'>
              {role.name}
            </TableData>
            <TableData className='CommonTable__data-item CommonTable__data-item--buttons'>
              {!service.isReservedRole(role.name) && Buttons(role)}
            </TableData>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

  return (
    <div className='CommonTable__container'>
      <PageHeader
        link={Routes.ROLES.NEW}
        allowedFor={allowedFor({
          feature: { name: FeatureName.Users, action: PermissionType.Write },
        })}
        linkTooltip={t('common.createRole')}
      >
        {t('user.roles')}
      </PageHeader>

      {loading ? (
        <FillingSpinner type={FillingSpinnerType.STANDARD} />
      ) : (
        <>
          {!!roles.length && (
            <>
              {RolesTable}
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={ROLES_TABLE_ITEMS_PER_PAGE}
                totalItemsCount={totalCount}
                onChange={(page: number) => void dispatch(rolesTableActions.setPageNumber(page))}
              />
              {removeRoleItem && (
                <ModalAction
                  title={t('modal.removeRole')}
                  itemText={t('modal.doYouWantToRemoveRole')}
                  itemName={removeRoleItem.name}
                  actionText={t('common.remove')}
                  isOpen={!!removeRoleItem}
                  setIsOpen={() => void dispatch(rolesTableActions.setModalRole(null))}
                  isLoading={modalLoading}
                  setIsLoading={isLoading =>
                    void dispatch(userTableActions.setModalLoading(isLoading))
                  }
                  onAction={() => service.removeRole(removeRoleItem)}
                />
              )}
            </>
          )}
          {!roles.length && <p className='no-data'>{t('common.noDataFound')}</p>}
        </>
      )}
    </div>
  )
}

export default RolesTable
