import Card from 'components/Layout/Card'
import CardHeader from 'components/Layout/CardHeader'
import PageHeader from 'components/Layout/PageHeader'
import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './EmissionPlan.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import CampaignStatusComponent from './components/CampaignStatus'
import { useParams } from 'react-router-dom'
import { ReactRouterParams } from '../../types/various'
import EmissionPlanService from './services/emission-plan.service'
import { EmissionPlanFilters } from './containers/EmissionPlanFilters'
import { emissionPlanActions } from './store/emission-plan-slice'
import EmissionPlanTable from './components/EmissionPlanTable'
import { AppContext } from '../../contexts/AppContext'
import debounce from 'lodash/debounce'
import FillingSpinner from '../../components/FillingSpinner'
import { DailySummaryNode } from '../../api/emissionPlan/models/daily-summary-listing.model'
import EmissionSummaryDetails from './components/EmissionSummaryDetails'

const EmissionPlan: React.FC = () => {
  const debounceTime = 2000
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const debouncedFetchDailySummary = useCallback(
    debounce(EmissionPlanService.fetchDailySummaryListing, debounceTime),
    []
  )
  const { userData } = useContext(AppContext)
  const { uuid: urlUuid } = useParams<ReactRouterParams>()
  const { pageNumber, totalItemsCount, campaign, data, filters, loading, dailySummary } =
    useSelector((state: RootState) => state.emissionPlan)

  useEffect(() => {
    if (!urlUuid) {
      return
    }

    const firstPage = 1
    const { agglomerations, cities, buildings, creations, mediaAsIds } = filters.selected

    void debouncedFetchDailySummary(
      urlUuid,
      agglomerations,
      cities,
      buildings,
      creations,
      mediaAsIds,
      firstPage
    )
  }, [filters])

  useEffect(() => {
    if (campaign.loaded) {
      return
    }

    if (!urlUuid) {
      return
    }

    void EmissionPlanService.fetchCampaign(urlUuid)
  }, [campaign.loaded])

  useEffect(() => {
    // Reset state on unmount/destroy
    return () => {
      dispatch(emissionPlanActions.resetState())
    }
  }, [])

  const onRowClick = (summaryNode?: DailySummaryNode) => {
    if (summaryNode && urlUuid) {
      void EmissionPlanService.fetchDailySummary(urlUuid, summaryNode.id)
    }
  }

  const setPageNumber = (page: number) => {
    if (!urlUuid) {
      return
    }

    const { agglomerations, cities, buildings, creations, mediaAsIds } = filters.selected

    void EmissionPlanService.fetchDailySummaryListing(
      urlUuid,
      agglomerations,
      cities,
      buildings,
      creations,
      mediaAsIds,
      page
    )
  }

  const closeDetailsModal = () => {
    void dispatch(emissionPlanActions.setDailySummary(undefined))
  }

  return (
    <div className={`EmissionPlan ${userData ? 'EmissionPlan--full-width' : ''}`}>
      <PageHeader>{campaign.data && campaign.data.name}</PageHeader>

      <div className='EmissionPlan__status'>
        {campaign.data &&
          CampaignStatusComponent({
            status: campaign.data.status,
            acceptanceStatus: campaign.data.acceptanceStatus,
            reservationTill: campaign.data.reservationTill,
          })}
      </div>

      <Card>
        <CardHeader>{t('emissionPlan.header')}</CardHeader>
        <EmissionPlanFilters uuid={urlUuid} />
        {loading ? (
          <FillingSpinner className={'EmissionPlan__loading-spinner'} />
        ) : (
          <EmissionPlanTable
            pageNumber={pageNumber}
            totalItemsCount={totalItemsCount}
            data={data}
            rowClick={onRowClick}
            setPageNumber={setPageNumber}
            hasBuildings={filters.all.buildings.length > 0}
          />
        )}
        <EmissionSummaryDetails
          summary={dailySummary}
          closeClick={closeDetailsModal}
        />
      </Card>
    </div>
  )
}

export default EmissionPlan
