import api from 'api'
import { Dispatch, SetStateAction } from 'react'
import { Campaign } from 'types/campaign'
import { DailyImpression } from './types'

export default class CampaignFormService {
  static getCampaignEstimatedEmissions = ({
    campaignId,
    setEstimatedEmissions,
    setEstimatedEmissionsLoading,
    experimental,
    setDailyImpressions,
  }: {
    campaignId: Campaign['id']
    setEstimatedEmissions: Dispatch<SetStateAction<number | null>>
    setEstimatedEmissionsLoading: Dispatch<SetStateAction<boolean>>
    experimental: boolean
    setDailyImpressions: Dispatch<SetStateAction<DailyImpression[]>>
  }): void => {
    setEstimatedEmissionsLoading(true)

    api.campaign
      .getCampaignEstimatedImpressions(campaignId, experimental)
      .then(res => {
        const dailyImpressions: DailyImpression[] =
          res.data.campaignEstimatedImpressions.dailyImpressions.map(impression => {
            const totalImpressions = impression.impressionsByDuration.reduce(
              (acc, duration) => acc + duration.impressions,
              0
            )
            return {
              day: impression.day,
              totalImpressions,
            }
          })

        void setDailyImpressions(dailyImpressions)
        void setEstimatedEmissions(res.data.campaignEstimatedImpressions.impressions)
      })
      .catch(() => void {})
      .finally(() => void setEstimatedEmissionsLoading(false))
  }
}
