import { Moment } from 'moment'
import {
  differenceInCalendarDays,
  differenceInMinutes,
  format,
  isValid,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
  setYear,
} from 'date-fns'
import { MAX_DATE_TIMESTAMP } from 'constant'

export default class DateUtils {
  static convertMomentToISO_UTCString = (m: Moment): string => m.toISOString()

  static convertMomentToJSDate = (m: Moment): Date => m.toDate()

  /** Reset moment.js date to time 00:00:00:000 */
  static resetMomentTime = (m: Moment): Moment => m.hour(0).minute(0).second(0).millisecond(0)

  static isBeforeDay = (a: Date, b: Date): boolean => differenceInCalendarDays(a, b) < 0

  static isAfterDay = (a: Date, b: Date): boolean => differenceInCalendarDays(a, b) > 0

  static setTime = (d: Date, h: number, m: number) => setSeconds(setMinutes(setHours(d, h), m), 0)

  static isNowInPolandAfterHour = (h: number) => {
    const nowInPl: Date = new Date(
      new Date().toLocaleString('en-US', { timeZone: 'Europe/Warsaw' })
    )
    const deadline: Date = DateUtils.setTime(nowInPl, h, 0)

    return differenceInMinutes(nowInPl, deadline) > 0
  }

  static getDatesFromYearZeroToMaxPossible = (): { zeroYear: Date; max: Date } => ({
    zeroYear: setYear(new Date(), 0),
    max: new Date(MAX_DATE_TIMESTAMP),
  })

  static validateAndConvertToISO_UTCString = (date: string): string => {
    const isoDateInlocalTimeZone: Date = parseISO(date)
    const isValidISO = isValid(isoDateInlocalTimeZone)

    if (!isValidISO) {
      throw new Error(`Invalid date format provided. Should be the ISO 8601.`)
    } else {
      return isoDateInlocalTimeZone.toISOString() // UTC
    }
  }

  static parseAndFormat = (d: Date | string, pattern: string): string => {
    if (d instanceof Date) {
      return format(d, pattern)
    } else {
      return format(new Date(DateUtils.validateAndConvertToISO_UTCString(d)), pattern)
    }
  }
}
