import React, { ReactElement } from 'react'
import { CampaignDetailsPdfData } from '../models/types'
import { Table, TableData, TableHeader, TableRow, TableTitle } from 'components/TablePdf'
import { t } from 'i18next'
import { MediaFormat, MediaFormatIndoors, Medium } from 'types/campaign'

type Props = {
  media: CampaignDetailsPdfData['media']
  mediumFormat: MediaFormat
}

export const Media = ({ media, mediumFormat }: Props): ReactElement => {
  const extraColumn = MediaFormatIndoors.includes(mediumFormat)

  const col = {
    1: 8,
    2: 15,
    3: 20,
    4: 0,
    5: 5,
  }
  col[4] = 100 - (col[1] + col[2] + col[3] + (extraColumn ? col[5] : 0))

  return (
    <>
      <Table>
        <TableTitle>{t('common.mediaList')}</TableTitle>
        <TableHeader>
          <TableData width={col[1]}>{t('pdf.number')}</TableData>
          <TableData width={col[2]}>{t('common.asId')}</TableData>
          <TableData width={col[3]}>{t('media.city')}</TableData>
          <TableData width={col[4]}>{t('media.address')}</TableData>
          {extraColumn && <TableData width={col[5]}>{t('media.screenCountShort')}</TableData>}
        </TableHeader>

        {media.map((medium: Medium, i: number) => {
          const num = i + 1

          return (
            <TableRow key={num}>
              <TableData width={col[1]}>{num}</TableData>
              <TableData width={col[2]}>{medium.asId}</TableData>
              <TableData width={col[3]}>{medium.city.name}</TableData>
              <TableData width={col[4]}>{medium.address}</TableData>
              {extraColumn && <TableData width={col[5]}>{medium.screenCount}</TableData>}
            </TableRow>
          )
        })}
      </Table>
    </>
  )
}
