import React, { ReactElement } from 'react'
import { Image, Text, View } from '@react-pdf/renderer'
import { MediaFormat } from 'types/campaign'
import logo from 'images/logo-ams-ooh.jpg'
import { cityLight, cityScreen, indoor } from 'images/pdfHeaders'
import { pdfStyles } from '../../utils/pdf-styles'

type Props = {
  name: string
  mediumFormat: MediaFormat
}

export const HeaderPdf = ({ name, mediumFormat }: Props): ReactElement => {
  const mediumImage = MEDIUM_IMAGES[mediumFormat] || indoor

  return (
    <View style={pdfStyles.header}>
      <View style={pdfStyles.headerLeft}>
        <Image
          style={pdfStyles.headerLogo}
          src={logo}
        />
        <Text style={pdfStyles.headerText}>{name}</Text>
      </View>

      <Image
        style={pdfStyles.headerImage}
        src={mediumImage}
      />
    </View>
  )
}

const MEDIUM_IMAGES: Record<MediaFormat, string> = {
  [MediaFormat.DG_CL]: cityLight,
  [MediaFormat.DG_CS]: cityScreen,
  [MediaFormat.DG_IN]: indoor,
  [MediaFormat.DG_IN_H]: indoor,
  [MediaFormat.DG_IN_S]: indoor,
}
