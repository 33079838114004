import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker'
import InputError, { Errors } from 'components/Form/InputError'
import Inputmask from 'inputmask'
import InputMaskUtils, { InputMask } from 'utils/inputMask'
import InputTitle from 'components/Form/InputTitle'
import TsxUtils from 'utils/tsx'
import DateUtils from 'utils/date'
import { DateSinglePickerProps } from './types'
import { AppContext } from 'contexts/AppContext'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/pl'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './react_dates_overrides.scss'

// TODO: Remove moment.js dependency.
// Currently everywhere dates are manupilated with `date-fns` but `SingleDatePicker` accepts only `moment.js` type.
// - https://github.com/airbnb/react-dates/issues/208
// - https://github.com/airbnb/react-dates/issues/1149
const DatePickerSingle: React.FC<DateSinglePickerProps> = ({
  id,
  className,
  title,
  placeholder,
  value,
  isOutsideRange,
  onChange,
  errors,
  disabled,
}) => {
  const { t } = useTranslation()
  const { userData } = useContext(AppContext)
  const [focused, setFocused] = useState<boolean>(false)
  const [inputEl, setInputEl] = useState<HTMLElement | null>(null)
  const [internalErrors, setInternalErrors] = useState<Errors>([])
  const [hasErrors, setHasErrors] = useState<boolean>(false)

  useEffect(() => {
    if (userData.user) moment.locale(userData.user.preferredLocale)
  }, [userData.user])

  useEffect(() => {
    const el = document.getElementById(id)

    if (el) {
      Inputmask(InputMaskUtils.options(InputMask.DATE)).mask(el)
      setInputEl(el)
    }
  }, [id])

  useEffect(() => {
    setHasErrors(
      (errors && errors.length > 0) || (internalErrors && internalErrors.length > 0) || false
    )
  }, [errors, internalErrors])

  useEffect(() => {
    inputEl?.classList[hasErrors ? 'add' : 'remove']('error')
  }, [hasErrors])

  const onDateChange = (newDate: moment.Moment | null) => {
    if (newDate) {
      setInternalErrors([])
      onChange(DateUtils.convertMomentToISO_UTCString(DateUtils.resetMomentTime(newDate)), id)
    } else {
      errors && setInternalErrors([t('form.invalidDate')])
      onChange('', id)
    }
  }

  const parsedDate = (date: string): moment.Moment | null =>
    moment(date).isValid() ? moment(date) : null

  const isOutsideRangeWrapper = (day: moment.Moment): boolean => {
    if (isOutsideRange) {
      return isOutsideRange(DateUtils.convertMomentToJSDate(DateUtils.resetMomentTime(day)))
    } else return false
  }

  return (
    <div>
      {title && <InputTitle title={title} />}

      <div className={'SingleDatePicker__wrapper' + TsxUtils.extraStyle(className, className)}>
        <SingleDatePicker
          id={id}
          displayFormat={'DD.MM.YYYY'}
          numberOfMonths={1}
          date={parsedDate(value)}
          onDateChange={newDate => onDateChange(newDate)}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused ?? false)}
          navNext={<FontAwesomeIcon icon={faCaretRight} />}
          navPrev={<FontAwesomeIcon icon={faCaretLeft} />}
          hideKeyboardShortcutsPanel
          isOutsideRange={isOutsideRangeWrapper}
          placeholder={placeholder}
          disabled={disabled}
          transitionDuration={0}
        />

        {hasErrors && (
          <InputError
            className='SelectAsync__error'
            errors={[...(errors ?? []), ...(internalErrors ?? [])]}
          />
        )}
      </div>
    </div>
  )
}

export default DatePickerSingle
