import React, { ReactChild, useContext, useEffect } from 'react'
import UserTableService from './services/user-table.service'
import FillingSpinner, { FillingSpinnerType } from 'components/FillingSpinner'
import Pagination from 'components/Pagination'
import { useTranslation } from 'react-i18next'
import Table, {
  TableBody,
  TableData,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from 'components/Table'
import { faCaretDown, faCaretUp, faEdit, faUserTimes } from '@fortawesome/free-solid-svg-icons'
import Button, { ButtonSize, ButtonTheme } from 'components/Form/Button'
import PageHeader from 'components/Layout/PageHeader'
import { BaseRole, User, UserSortColumn, UserStatus } from 'types/user'
import { USER_TABLE_ITEMS_PER_PAGE } from 'constant'
import { AppContext } from 'contexts/AppContext'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'routes'
import ModalAction from 'components/Modal/ModalAction'
import { USERS_ACTION } from 'constant/authorization'
import { Filter } from 'hooks/useTableFilter'
import { userFullName } from 'utils/user'
import './UserTable.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { userTableActions } from './store/user-table-slice'
import InputSearch from '../../../components/Form/InputSearch'
import UserTableFilters from './containers/UserTableFilters'
import { PermissionType, FeatureName } from '../../../types/features'

const UserTable: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { getTableFilter, setTableFilter, allowedFor } = useContext(AppContext)
  const { users, totalCount, loading, loaded, pageNumber } = useSelector(
    (state: RootState) => state.userTable
  )
  const { loading: modalLoading, user: modalUser } = useSelector(
    (state: RootState) => state.userTable.deactivateModal
  )
  const {
    show: showFilters,
    searchNameInput: searchInput,
    selectedRoles,
    selectedStatuses,
    selectedAgencies,
  } = useSelector((state: RootState) => state.userTable.filters)
  const tableSort = getTableFilter(Filter.USER)

  const getUsers = (): void => {
    UserTableService.getUsers(pageNumber, USER_TABLE_ITEMS_PER_PAGE, tableSort, searchInput, {
      agencies: selectedAgencies,
      statuses: selectedStatuses as UserStatus[],
      roles: selectedRoles as BaseRole[],
    })
  }

  useEffect(() => {
    getUsers()
  }, [pageNumber, tableSort])

  useEffect(() => {
    if (!loaded) {
      getUsers()
    }
  }, [loaded])

  const StatusLabel = (status: UserStatus): ReactChild => (
    <div className={`UserTable__status-label UserTable__status-label--${status}`}>
      {t(`user.status.${status}`)}
    </div>
  )

  const userName = (firstName: User['firstName'], lastName: User['lastName']): string =>
    firstName && lastName ? `${firstName} ${lastName}` : ''

  const Buttons = (user: User): ReactChild => (
    <>
      {allowedFor({
        feature: { name: FeatureName.Users, action: PermissionType.Write },
      }) && (
        <Button
          icon={faEdit}
          tooltip={t('common.edit')}
          onClick={(): void => void navigate(Routes.USERS.EDIT(user.id))}
          disabled={!allowedFor({ template: USERS_ACTION.EDIT })}
        />
      )}
      {user.status !== UserStatus.DEACTIVATED &&
        allowedFor({
          feature: { name: FeatureName.Users, action: PermissionType.Delete },
        }) && (
          <Button
            icon={faUserTimes}
            tooltip={t('common.deactivate')}
            onClick={() => void dispatch(userTableActions.setModalUser(user))}
            disabled={
              user.status !== UserStatus.ACTIVE ||
              !allowedFor({ template: USERS_ACTION.DEACTIVATE })
            }
          />
        )}
    </>
  )

  const UsersTable: ReactChild = (
    <Table
      className='CommonTable'
      sort={tableSort}
      onSort={({ column, direction }) => void setTableFilter(Filter.USER, { column, direction })}
    >
      <TableHeader>
        <TableHeaderItem
          dataCy={'user-table-header-name'}
          className='CommonTable__header-item'
          sortBy={UserSortColumn.FIRST_NAME}
        >
          {t('common.name')}
        </TableHeaderItem>
        <TableHeaderItem
          dataCy={'user-table-header-email'}
          className='CommonTable__header-item'
          sortBy={UserSortColumn.EMAIL}
        >
          {t('common.email')}
        </TableHeaderItem>
        <TableHeaderItem
          dataCy={'user-table-header-roles'}
          className='CommonTable__header-item'
          sortBy={UserSortColumn.ROLES}
        >
          {t('user.all.role')}
        </TableHeaderItem>
        <TableHeaderItem
          dataCy={'user-table-header-agency'}
          className='CommonTable__header-item'
          sortBy={UserSortColumn.AGENCY_NAME}
        >
          {t('common.agency')}
        </TableHeaderItem>
        <TableHeaderItem
          dataCy={'user-table-header-status'}
          className='CommonTable__header-item'
          sortBy={UserSortColumn.STATUS}
        >
          {t('common.status')}
        </TableHeaderItem>
        <TableHeaderItem
          dataCy={'user-table-header-actions'}
          className='CommonTable__header-item'
        >
          {t('common.actions')}
        </TableHeaderItem>
      </TableHeader>

      <TableBody>
        {users.map((user: User) => (
          <TableRow
            key={user.id}
            className='CommonTable__data-row'
            onClick={(): void => {
              allowedFor({
                feature: { name: FeatureName.Users, action: PermissionType.Write },
              }) && navigate(Routes.USERS.EDIT(user.id))
            }}
          >
            <TableData className='CommonTable__data-item CommonTable__data-item--name'>
              {userName(user.firstName, user.lastName)}
            </TableData>
            <TableData className='CommonTable__data-item'>{user.email}</TableData>
            <TableData className='CommonTable__data-item'>
              {UserTableService.parseRoles(user.roles)}
            </TableData>
            <TableData className='CommonTable__data-item'>
              {UserTableService.parseAgencies(user.agencies)}
            </TableData>
            <TableData className='CommonTable__data-item'>{StatusLabel(user.status)}</TableData>
            <TableData className='CommonTable__data-item CommonTable__data-item--buttons'>
              {Buttons(user)}
            </TableData>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

  return (
    <div className='CommonTable__container'>
      <PageHeader
        allowedFor={allowedFor({
          feature: { name: FeatureName.Users, action: PermissionType.Write },
        })}
        link={Routes.USERS.INVITE}
        linkTooltip={t('common.createUser')}
      >
        {t('user.all.users')}
      </PageHeader>

      <div className={'CommonTable__search-row'}>
        <Button
          dataCy={'user-table-filters-button'}
          theme={ButtonTheme.NAVY_BLUE_OUTLINE}
          size={ButtonSize.SMALL}
          icon={showFilters ? faCaretDown : faCaretUp}
          onClick={() => void dispatch(userTableActions.setFiltersShow(!showFilters))}
        >
          {t('common.filters')}
        </Button>

        <InputSearch
          id='text'
          dataCy={'user-table-search-input'}
          value={searchInput}
          onChange={text => void dispatch(userTableActions.setSearchNameInput(text))}
          onSubmit={() => {
            void dispatch(userTableActions.setPageNumber(1))
            void dispatch(userTableActions.setLoaded(false))
          }}
        />
      </div>

      {showFilters && <UserTableFilters></UserTableFilters>}

      {loading ? (
        <FillingSpinner type={FillingSpinnerType.STANDARD} />
      ) : (
        <>
          {!!users.length && (
            <>
              {UsersTable}
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={USER_TABLE_ITEMS_PER_PAGE}
                totalItemsCount={totalCount}
                onChange={(page: number) => void dispatch(userTableActions.setPageNumber(page))}
              />
              {modalUser && (
                <ModalAction
                  title={t('modal.deactivationUser')}
                  itemText={t('modal.doYouWantToDeactivateUser')}
                  itemName={userFullName(modalUser)}
                  actionText={t('modal.deactivate')}
                  isOpen={!!modalUser}
                  setIsOpen={() => void dispatch(userTableActions.setModalUser(null))}
                  isLoading={modalLoading}
                  setIsLoading={isLoading =>
                    void dispatch(userTableActions.setModalLoading(isLoading))
                  }
                  onAction={() => UserTableService.deactivateUser(modalUser)}
                />
              )}
            </>
          )}
          {!users.length && <p className='no-data'>{t('common.noDataFound')}</p>}
        </>
      )}
    </div>
  )
}

export default UserTable
