import { gql } from '@apollo/client'

export const GET_CAMPAIGN_IMPRESSIONS_BASED_ON_AUDIENCE = gql`
  query campaignEstimatedImpressions($campaignId: ID!) {
    campaignImpressionsBasedOnAudience(campaignId: $campaignId) {
      impressions
      hiddenTarget
    }
  }
`
