export default class ArrayUtils {
  /**
   * ```
   * ArrayUtils.toChunks([1,2,3,4,5,6], 2) // [[1, 2], [3, 4], [5, 6]]
   * ```
   */
  static toChunks = <T>(array: T[], chunkSize: number): T[][] => {
    if (chunkSize === 0) throw new Error('ChunkSize must be greater than 0.')

    let i, j: number
    const resultArray = []

    for (i = 0, j = array.length; i < j; i += chunkSize) {
      resultArray.push(array.slice(i, i + chunkSize))
    }

    return resultArray
  }

  static containsOneOrMoreItems = (arrayToSearch: any[], itemsForCheck: any[]): boolean =>
    itemsForCheck.some(v => arrayToSearch.includes(v))

  static containsAllItems = (arrayToSearch: any[], itemsForCheck: any[]): boolean =>
    itemsForCheck.every(v => arrayToSearch.includes(v))

  static getMinMax = (numbers: number[]): { min: number; max: number } => {
    if (numbers.length === 0) throw new Error('Numbers required.')

    return {
      min: Math.min(...numbers),
      max: Math.max(...numbers),
    }
  }

  static unique = <T>(array: T[], compare: (a: T, b: T) => boolean): T[] => {
    const result: T[] = []
    const duplicateExists = (item: T) => result.some(existingItem => compare(item, existingItem))

    for (const newItem of array) {
      if (!duplicateExists(newItem)) result.push(newItem)
    }

    return result
  }

  static sortDescending(array: string[]): string[] {
    return array.sort((a, b) => {
      if (!isNaN(parseInt(a)) || !isNaN(parseInt(b))) {
        return parseInt(a) < parseInt(b) ? -1 : 1
      }

      return a.localeCompare(b)
    })
  }
}
