import React from 'react'
import { filtersDefault } from './types'
import { Creation, Medium } from '../../../types/campaign'

export type FilterContextProps<T> = {
  availableFilters: T
  filters: T
  setFilters: React.Dispatch<React.SetStateAction<T>>
  disabled: boolean
  creations?: Creation[]
  displayedMedia?: Medium[]
  setAvailableFilters?: React.Dispatch<React.SetStateAction<T>>
}

export default React.createContext<FilterContextProps<any>>({
  availableFilters: filtersDefault(),
  filters: filtersDefault(),
  setFilters: () => void {},
  disabled: true,
  creations: [],
  displayedMedia: [],
  setAvailableFilters: () => void {},
})
