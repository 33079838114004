import api from 'api'
import { Errors } from 'components/Form/InputError'
import { SelectAsyncValue } from 'components/Form/SelectAsync'
import { AGENCIES_LIMIT, SUBSCRIPTION_ACCOUNT_ROLES } from 'constant'
import i18n from 'i18n'
import { Agency } from 'types/agency'
import { BaseRole } from 'types/user'
import ArrayUtils from 'utils/array'

export default class UserService {
  static getRoles = (
    subscriptionAccount?: boolean
  ): Promise<SelectAsyncValue<BaseRoleSelectRawData>[]> => {
    /**
     * 1) Works as before, we return all options
     */
    if (subscriptionAccount === undefined) {
      return api.user.getAllRoles().then(roles => {
        console.log('roles: ', roles)
        return roles.data.rolesForFilters.map(roleForFilters => {
          return {
            value: roleForFilters.id,
            label: roleForFilters.name,
            rawData: { baseRole: roleForFilters.baseRole },
          }
        })
      })
    }

    /**
     * 2) We return only subscription accounts
     */
    if (subscriptionAccount) {
      return api.user.getAllRoles().then(roles => {
        return roles.data.rolesForFilters
          .map(roleForFilters => {
            return {
              value: roleForFilters.id,
              label: roleForFilters.name,
              rawData: { baseRole: roleForFilters.baseRole },
            }
          })
          .filter(role => SUBSCRIPTION_ACCOUNT_ROLES.includes(role.rawData!.baseRole))
      })
    }

    /**
     * 3) We return all accounts that are not subscriptions
     */
    return api.user.getAllRoles().then(roles => {
      return roles.data.rolesForFilters
        .map(roleForFilters => {
          return {
            value: roleForFilters.id,
            label: roleForFilters.name,
            rawData: { baseRole: roleForFilters.baseRole },
          }
        })
        .filter(role => !SUBSCRIPTION_ACCOUNT_ROLES.includes(role.rawData!.baseRole))
    })
  }

  static getAgencies = (input: string): Promise<SelectAsyncValue<Agency>[]> => {
    return api.agency
      .getAgencies({
        term: input,
        first: AGENCIES_LIMIT,
      })
      .then(res =>
        res.data.agencies.nodes.map(
          (agency: Agency): SelectAsyncValue<Agency> => ({
            value: agency.name,
            rawData: agency,
          })
        )
      )
      .catch(() => [])
  }

  static shouldDisableClientInput = (userRoles: { rawData?: { baseRole: string } }[]): boolean => {
    return !ArrayUtils.containsOneOrMoreItems(
      userRoles.map(role => role.rawData!.baseRole as BaseRole),
      [BaseRole.ADMIN, BaseRole.CLIENT, BaseRole.PLANNER, BaseRole.ACCOUNT, BaseRole.SUPPORT]
    )
  }

  static agencyNameError = (user: {
    agencies: unknown[]
    roles: { rawData?: { baseRole: string } }[]
  }): Errors =>
    UserService.shouldDisableClientInput(user.roles)
      ? []
      : user.agencies.length === 0 &&
        user.roles.some(role => role.rawData?.baseRole !== BaseRole.ADMIN)
      ? [i18n.t('errors.required')]
      : []

  static convertScopeToRange = (scope: [number, number]) =>
    Array.from({ length: scope[1] - scope[0] + 1 }, (v, k) => k + scope[0])
}

export interface BaseRoleSelectRawData {
  baseRole: BaseRole
}
