import React, { ReactElement, ReactNode } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import { defaultStyles } from './styles'
import { TFunctionResult } from 'i18next'
import StringUtils from 'utils/string'

export const Table = (props: { children: ReactNode }): ReactElement => (
  <View
    minPresenceAhead={40}
    style={defaultStyles.table}
  >
    {props.children}
  </View>
)

export const TableTitle = (props: { children: ReactNode | TFunctionResult }) => (
  <View
    wrap={false}
    style={defaultStyles.tableTitle}
  >
    <Text>{props.children?.toString()}</Text>
  </View>
)

export const TableHeader = (props: { children: ReactNode[] }): ReactElement => (
  <View
    fixed
    wrap={false}
    style={[defaultStyles.tableRow, defaultStyles.tableHeader]}
  >
    {props.children}
  </View>
)

export const TableRow = (props: { children: ReactNode[] }): ReactElement => (
  <View
    wrap={false}
    style={[defaultStyles.tableRow]}
  >
    {props.children}
  </View>
)

export const TableData = (props: {
  children: ReactNode | TFunctionResult
  forcedSplit?: number
  width: number
}): ReactElement => {
  const { forcedSplit, children, width } = props

  const Body = (): ReactElement => {
    if (typeof children === 'string' || typeof children === 'number') {
      const content = children.toString()

      return (
        <TableTextCell>
          {forcedSplit ? StringUtils.breakWordsWithNewLines(content, forcedSplit) : content}
        </TableTextCell>
      )
    }

    return <>{children}</>
  }

  return <View style={[defaultStyles.tableData, appendWidthToDataCell(width)]}>{Body()}</View>
}

const TableTextCell = (props: { children: string }): ReactElement => (
  <Text style={defaultStyles.tableCell}>{props.children}</Text>
)

const appendWidthToDataCell = (width: number): Style =>
  StyleSheet.create({
    tableData: {
      width: `${width}%`,
    },
  }).tableData
