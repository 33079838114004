import { GroupData, GroupedMedia, FilterableMedium, MediaGroup } from './types'

export const iterateOverTail = ({
  groupTail,
  nodeGroupCallback,
  nodeCallback,
}: {
  groupTail: MediaGroup
  nodeGroupCallback?: ({
    nodeGroup,
    node,
  }: {
    nodeGroup: GroupData['group']
    node: GroupedMedia
  }) => void
  nodeCallback?: (node: FilterableMedium) => void
}) => {
  const tail = (group: any): any =>
    Object.entries(group).map(([key, node]: any) => {
      if (key === 'group') return

      const nodeGroup: GroupData['group'] | null = node.group ?? null
      if (nodeGroup) {
        if (nodeGroupCallback) nodeGroupCallback({ nodeGroup, node })

        return tail(node)
      } else {
        if (nodeCallback) nodeCallback(node)

        return
      }
    })

  tail(groupTail)
}
