import React, { ReactNode } from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { TFunctionResult } from 'i18next'
import TsxUtils from 'utils/tsx'
import Button from 'components/Form/Button'
import './PageHeader.scss'

interface PageHeaderProps {
  children: ReactNode | TFunctionResult
  allowedFor?: boolean
  link?: string
  linkTooltip?: string
  className?: string
  withBorder?: boolean
}

const PageHeader: React.FC<PageHeaderProps> = ({
  children,
  link,
  allowedFor = true,
  linkTooltip,
  className,
  withBorder,
}) => (
  <div
    className={
      'PageHeader' +
      TsxUtils.extraStyle(className, className) +
      TsxUtils.extraStyle(withBorder, 'PageHeader__border')
    }
  >
    <h2 className='PageHeader__heading'>
      <>
        {children}

        {link && allowedFor && (
          <Button
            dataCy={'add-campaign-btn'}
            className='PageHeader__link'
            icon={faPlus}
            tooltip={linkTooltip}
            toUrl={link}
          />
        )}
      </>
    </h2>
  </div>
)

export default PageHeader
