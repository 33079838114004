import { gql } from '@apollo/client'

export default gql`
  mutation CreateMediaTemplate($input: CreateMediaTemplateInput!) {
    createMediaTemplate(input: $input) {
      success
      errors
    }
  }
`
