import React from 'react'
import { useTranslation } from 'react-i18next'
import { CampaignDetailsShareUrlProps } from './types'
import Input from 'components/Form/Input'
import Link from 'components/Link'
import './CampaignDetailsShareUrl.scss'

const CampaignDetailsShareUrl: React.FC<CampaignDetailsShareUrlProps> = ({ url }) => {
  const { t } = useTranslation()

  return (
    <div className='CampaignDetailsShareUrl'>
      <div className='CampaignDetailsShareUrl__info'>{t('campaignDetails.shareUrl.info')}</div>

      <div className='CampaignDetailsShareUrl__share-container'>
        <Input
          id='name'
          title={t('campaignDetails.shareUrl.downloadLink')}
          value={url}
          onChange={() => void {}}
          readOnly
        />

        <div className='CampaignDetailsShareUrl__link--container'>
          <Link
            className='CampaignDetailsShareUrl__link'
            onClick={() => navigator.clipboard.writeText(url)}
          >
            {t('campaignDetails.shareUrl.copyLink')}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CampaignDetailsShareUrl
