import { Errors } from 'components/Form/InputError'

export interface SignInValues {
  email: string
  password: string
}

export interface SignInValuesErr {
  email: Errors
  password: Errors
}

export const SignInValuesDefault = {
  email: '',
  password: '',
}

export type Event = React.MouseEvent<HTMLButtonElement, MouseEvent>
