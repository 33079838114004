import { ReactNode } from 'react'
import { UseNotifierProps, useNotifierPropsDefault } from 'hooks/useNotifier'
import { UseUserProps, useUserPropsDefault } from 'hooks/useUser'
import { UseAuthorizeProps, useAuthorizePropsDefault } from 'hooks/useAuthorize'
import { UseFeatureProps, useFeaturePropsDefault } from 'hooks/useFeature'
import { Use404Props, use404PropsDefault } from 'hooks/use404'
import { UseTableFilterProps, useTableFilterPropsDefault } from 'hooks/useTableFilter'
import { UseHomepageProps, useHomepagePropsDefault } from 'hooks/useHomepage'

export interface AppContextProviderProps {
  children: ReactNode | ReactNode[]
}

export interface AppContextProps
  extends UseNotifierProps,
    UseUserProps,
    UseAuthorizeProps,
    UseFeatureProps,
    Use404Props,
    UseTableFilterProps,
    UseHomepageProps {}

export const appContextPropsDefault: AppContextProps = {
  ...useUserPropsDefault,
  ...useNotifierPropsDefault,
  ...useAuthorizePropsDefault,
  ...useFeaturePropsDefault,
  ...use404PropsDefault,
  ...useTableFilterPropsDefault,
  ...useHomepagePropsDefault,
}
