import { CampaignStatus } from '../types/campaign'
import { CAMPAIGN_ACTION } from '../constant/authorization'
import { AllowedForArgs } from '../hooks/useAuthorize/types'
import { CampaignPermissionType, FeatureName } from '../types/features'

export class ActionButtonsHelper {
  /**
   * Campaign can be approved (activated) if:
   * - isAdmin or isEmployee,
   * - Campaign is in sketch status,
   * - withAllocatedTime:
   *    - is true when user owns a media, so when he owns a media, then he can approve it on his own
   *    - is false when user doesn't own a media, so he needs to ask for approval
   */
  public static getCanApprove(
    allowedFor: (args: AllowedForArgs) => boolean,
    status: CampaignStatus,
    withAllocatedTime: boolean
  ): boolean {
    return (
      allowedFor({
        template: CAMPAIGN_ACTION.APPROVE,
        status,
      }) ||
      (status === CampaignStatus.SKETCH && withAllocatedTime)
    )
  }

  /**
   * Campaign can be sent to acceptance if:
   * - Every single role has access to send to acceptance,
   * - Campaign is not already in acceptance,
   * - withAllocatedTime - is true when user owns a media, so when he owns a media, then he doesn't need to ask for acceptance
   */
  public static getCanSendToAcceptance(
    allowedFor: (args: AllowedForArgs) => boolean,
    status: CampaignStatus,
    withAllocatedTime: boolean,
    publicView?: boolean
  ): boolean {
    return (
      allowedFor({
        template: CAMPAIGN_ACTION.SEND_TO_ACCEPTANCE,
        status,
      }) &&
      !withAllocatedTime &&
      !publicView
    )
  }

  /**
   * @param allowedFor
   * @param status
   * @param withAllocatedTime
   */
  public static getCanPublish(
    allowedFor: (args: AllowedForArgs) => boolean,
    status: CampaignStatus,
    withAllocatedTime: boolean
  ): boolean {
    return (
      allowedFor({
        template: CAMPAIGN_ACTION.PUBLISH,
        feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Publish },
        status,
      }) ||
      ([CampaignStatus.PLANNED, CampaignStatus.ACTIVE, CampaignStatus.RESERVED].includes(status) &&
        withAllocatedTime)
    )
  }

  /**
   * - withAllocatedTime - is true when user owns a media, so when he owns a media, then he doesn't need to ask for acceptance
   *
   * @param allowedFor
   * @param status
   * @param withAllocatedTime
   */
  public static getCanFinish(
    allowedFor: (args: AllowedForArgs) => boolean,
    status: CampaignStatus,
    withAllocatedTime: boolean
  ): boolean {
    return (
      allowedFor({ template: CAMPAIGN_ACTION.FINISH, status }) ||
      (withAllocatedTime && (status === CampaignStatus.PLANNED || status === CampaignStatus.ACTIVE))
    )
  }

  /**
   * IMPORTANT!
   * This method should be only used with other methods that check if user can edit campaign
   *
   * Campaign can be edited if:
   * - withAllocatedTime - is true when user owns a media, so when he owns a media
   *
   * @param campaignStatus
   * @param withAllocatedTime
   */
  public static getCanEditByOwner(
    campaignStatus: CampaignStatus,
    withAllocatedTime: boolean
  ): boolean {
    return (
      [
        CampaignStatus.SKETCH,
        CampaignStatus.PLANNED,
        CampaignStatus.ACTIVE,
        CampaignStatus.RESERVED,
      ].includes(campaignStatus) && withAllocatedTime
    )
  }
}
