import { gql } from '@apollo/client'

export const FETCH_PRICING_REPORT = gql`
  query currentPricingReport {
    currentPricingReport {
      audience {
        headers
        content
      }
      impressions {
        headers
        content
      }
    }
  }
`
