import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SelectOption } from '../../../../../components/Form/Select'

export interface DetailsFormSliceModel {
  disableMediaSelection: boolean
  disableUsedMedia: boolean
  targetOptions: SelectOption[]
}

const initialState: DetailsFormSliceModel = {
  disableMediaSelection: false,
  disableUsedMedia: false,
  targetOptions: [],
}

const detailsFormSlice = createSlice({
  name: 'detailsForm',
  initialState,
  reducers: {
    setDisableMediaSelection(state, action: PayloadAction<boolean>) {
      state.disableMediaSelection = action.payload
    },
    setDisableUsedMedia(state, action: PayloadAction<boolean>) {
      state.disableUsedMedia = action.payload
    },
    setTargetOptions(state, action: PayloadAction<SelectOption[]>) {
      state.targetOptions = action.payload
    },
  },
})

export const detailsFormActions = detailsFormSlice.actions

export default detailsFormSlice
