import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Table, {
  TableBody,
  TableData,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from 'components/Table'
import { Agency, AgencySortColumn } from 'types/agency'
import service from './service'
import FillingSpinner from 'components/FillingSpinner'
import Pagination from 'components/Pagination'
import Button, { ButtonSize, ButtonTheme } from 'components/Form/Button'
import { Routes } from 'routes'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { ITEMS_PER_PAGE } from 'constant'
import ModalAction from 'components/Modal/ModalAction'
import { AppContext } from 'contexts/AppContext'
import { AGENCIES_ACTION } from 'constant/authorization'
import { Filter } from 'hooks/useTableFilter'
import { useNavigate } from 'react-router-dom'
import '../Agencies.scss'
import { PermissionType, FeatureName } from '../../../types/features'

const AgenciesList: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { allowedFor, setTableFilter, getTableFilter } = useContext(AppContext)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [agencies, setAgencies] = useState<Agency[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false)
  const [removeAgencyLoading, setRemoveAgencyLoading] = useState<boolean>(false)
  const [currentAgency, setCurrentAgency] = useState<Agency>()
  const [trigger, setTrigger] = useState<boolean>(true)
  const tableFilter = getTableFilter(Filter.AGENCY)
  const { addNotification } = useContext(AppContext)

  const getAgencies = (): void =>
    service.getAgencies(pageNumber, setAgencies, setTotalCount, setLoading, setTrigger, tableFilter)

  useEffect(() => {
    getAgencies()
  }, [pageNumber, tableFilter])

  useEffect(() => {
    if (trigger) {
      getAgencies()
    }
  }, [trigger])

  const AgenciesList: ReactNode = (
    <Table
      className='Agencies CommonTable'
      sort={tableFilter}
      onSort={({ column, direction }) => void setTableFilter(Filter.AGENCY, { column, direction })}
    >
      <TableHeader>
        <TableHeaderItem
          className='CommonTable__header-item'
          sortBy={AgencySortColumn.NAME}
        >
          {t('common.name')}
        </TableHeaderItem>
        <TableHeaderItem className='CommonTable__header-item' />
      </TableHeader>

      <TableBody>
        {agencies.map((agency: Agency) => (
          <TableRow
            key={agency.id}
            className='CommonTable__data-row'
            onClick={() => navigate(Routes.AGENCIES.EDIT(agency.id))}
          >
            <TableData className='CommonTable__data-item Agencies__data--name'>
              {agency.name}
            </TableData>
            <TableData className='CommonTable__data-item Agencies__data--buttons'>
              {allowedFor({
                feature: { name: FeatureName.Agencies, action: PermissionType.Write },
              }) && (
                <Button
                  dataCy='agencies-list-edit'
                  size={ButtonSize.SMALL}
                  theme={ButtonTheme.NAVY_BLUE_OUTLINE}
                  icon={faEdit}
                  toUrl={Routes.AGENCIES.EDIT(agency.id)}
                  disabled={!allowedFor({ template: AGENCIES_ACTION.EDIT })}
                >
                  {t('common.edit')}
                </Button>
              )}
              {allowedFor({
                feature: { name: FeatureName.Agencies, action: PermissionType.Delete },
              }) && (
                <Button
                  dataCy='agencies-list-remove'
                  size={ButtonSize.SMALL}
                  theme={ButtonTheme.NAVY_BLUE_OUTLINE}
                  icon={faTrashAlt}
                  onClick={() => {
                    setCurrentAgency(agency)
                    setIsRemoveModalOpen(true)
                  }}
                  disabled={!allowedFor({ template: AGENCIES_ACTION.REMOVE })}
                >
                  {t('common.remove')}
                </Button>
              )}
            </TableData>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

  return (
    <>
      {loading ? (
        <FillingSpinner />
      ) : (
        <>
          {AgenciesList}

          <Pagination
            activePage={pageNumber}
            itemsCountPerPage={ITEMS_PER_PAGE}
            totalItemsCount={totalCount}
            onChange={setPageNumber}
          />

          {currentAgency && (
            <ModalAction
              title={t('modal.agencyRemoval')}
              itemText={t('modal.doYouWantToRemove')}
              itemName={currentAgency.name}
              actionText={t('common.remove')}
              isOpen={isRemoveModalOpen}
              setIsOpen={setIsRemoveModalOpen}
              isLoading={removeAgencyLoading}
              setIsLoading={setRemoveAgencyLoading}
              onAction={() =>
                service.deleteAgency(currentAgency.id, setTrigger, addNotification, t)
              }
            />
          )}
        </>
      )}
    </>
  )
}

export default AgenciesList
