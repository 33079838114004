import { gql } from '@apollo/client'

export const CREATE_MEDIUM_REPORT = gql`
  mutation createMediumReport(
    $asId: String!
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
  ) {
    createMediumReport(input: { asId: $asId, startDate: $startDate, endDate: $endDate }) {
      report {
        name
        status
        startDate
        endDate
        generatedAt
        csvUrl
      }
      success
      errors
    }
  }
`
