import { client } from 'components/ApolloProvider'
import { TableSortColumn } from 'components/Table'
import { loader } from 'graphql.macro'
import { Agency } from 'types/agency'
import { Priority, User, UserFilters } from 'types/user'
import { MutationStateResponse, SortDirection } from 'types/various'
import VariableUtils from 'utils/variable'
import { ACCEPT_INVITATION } from './acceptInvitation'
import { DEACTIVATE_USER } from './deactivateUser'
import { GET_USER } from './getUser'
import { GET_USER_BY_ID } from './getUserById'
import { GET_USERS } from './getUsers'
import { INVITE_USER } from './inviteUser'
import { REFRESH_TOKEN } from './refreshToken'
import { SIGN_IN } from './signIn'
import {
  AcceptInvitationFR,
  CreateOrInviteUserFR,
  InviteUserInput,
  DeactivateUserFR,
  GetAllRoles,
  GetUserAQR,
  GetUserByIdAQR,
  GetUsersAQR,
  InitiatePasswordResetResponse,
  RefreshTokenFR,
  ReInviteUserResponse,
  SignInFR,
  UpdateUserEmailResponse,
  UpdateUserFR,
} from './types'
import { UPDATE_USER } from './updateUser'
import { ModalUploadFile } from 'components/Modal/ModalUpload'
import { FETCH_USER_FILTERS } from './fetchUserFilters'
import { FetchUsersResponseModel } from './models/fetch-users-response.model'
import { GET_ALL_ROLES } from './getAllRoles'
import { INITIATE_PASSWORD_RESET } from './initiatePasswordReset'
import { RE_INVITE_USER } from './reInviteUser'
import { UPDATE_EMAIL } from './updateEmail'
import { FetchResult } from '@apollo/client'

const userApi = {
  getUser: (): Promise<GetUserAQR> =>
    client.query({
      query: GET_USER,
    }),

  getAllRoles: (): Promise<GetAllRoles> =>
    client.query({
      query: GET_ALL_ROLES,
    }),

  updateUser: (variables: {
    id: User['id']
    firstName: User['firstName']
    lastName: User['lastName']
    priorities: Priority[]
    roles: string[]
    agencies: Agency['id'][]
  }): Promise<UpdateUserFR> => {
    const priorities = variables.priorities.map(p => VariableUtils.numberToGqlInt(p))

    return client.mutate({
      mutation: UPDATE_USER,
      variables: { ...variables, priorities },
    })
  },

  inviteUser: (variables: InviteUserInput): Promise<CreateOrInviteUserFR> =>
    client.mutate({
      mutation: INVITE_USER,
      variables: {
        ...variables,
        priorities: variables.priorities.map(p => VariableUtils.numberToGqlInt(p)),
      },
    }),

  deactivateUser: (variables: { id: User['id'] }): Promise<DeactivateUserFR> =>
    client.mutate({
      mutation: DEACTIVATE_USER,
      variables,
    }),

  acceptInvitation: (variables: {
    token: User['token']
    firstName: User['firstName']
    lastName: User['lastName']
    password: User['password']
    passwordConfirmation: User['password']
  }): Promise<AcceptInvitationFR> =>
    client.mutate({
      mutation: ACCEPT_INVITATION,
      variables,
    }),

  getUserById: (id: User['id']): Promise<GetUserByIdAQR> =>
    client.query({
      query: GET_USER_BY_ID,
      variables: { id },
    }),

  getUsers: (v: {
    first?: number
    last?: number
    sortColumn?: TableSortColumn
    sortDirection?: SortDirection
    term?: string
    filters?: UserFilters
  }): Promise<GetUsersAQR> =>
    client.query({
      query: GET_USERS,
      variables: {
        first: v.first ?? null,
        last: v.last ?? null,
        sortColumn: v.sortColumn ?? null,
        sortDirection: v.sortDirection ?? null,
        term: v.term ?? null,
        filters: v.filters ?? null,
      },
    }),

  signIn: (variables: { email: User['email']; password: string }): Promise<SignInFR> =>
    client.mutate({
      mutation: SIGN_IN,
      variables,
    }),

  refreshToken: (variables: {
    authenticationToken: string
    refreshToken: string
  }): Promise<RefreshTokenFR> =>
    client.mutate({
      mutation: REFRESH_TOKEN,
      variables,
    }),

  initiatePasswordReset: (userId: string): Promise<InitiatePasswordResetResponse> =>
    client.mutate({
      mutation: INITIATE_PASSWORD_RESET,
      variables: {
        id: userId,
      },
    }),

  reInviteUser: (userId: string): Promise<ReInviteUserResponse> =>
    client.mutate({
      mutation: RE_INVITE_USER,
      variables: {
        id: userId,
      },
    }),

  updateUserEmail: (userId: string, newEmail: string): Promise<UpdateUserEmailResponse> =>
    client.mutate({
      mutation: UPDATE_EMAIL,
      variables: {
        id: userId,
        email: newEmail,
      },
    }),

  signOut: (): Promise<FetchResult<{ signOut: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./gql/signOut.gql'),
    }),

  updatePreferredLocale: (
    preferredLocale: User['preferredLocale']
  ): Promise<FetchResult<{ updatePreferredLocale: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./gql/updatePreferredLocale.gql'),
      variables: { preferredLocale },
    }),

  sendResetPasswordInstructions: (
    email: User['email']
  ): Promise<FetchResult<{ sendResetPasswordInstructions: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./gql/sendResetPasswordInstructions.gql'),
      variables: { email },
    }),

  resetPasswordByToken: (variables: {
    token: User['token']
    password: User['password']
    passwordConfirmation: User['password']
  }): Promise<FetchResult<{ resetPasswordByToken: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./gql/resetPasswordByToken.gql'),
      variables,
    }),

  registerUser: (variables: {
    firstName: User['firstName']
    lastName: User['lastName']
    email: User['email']
    password: User['password']
    passwordConfirmation: User['password']
  }): Promise<FetchResult<{ registerUser: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./gql/registerUser.gql'),
      variables,
    }),

  updateProfile: (variables: {
    id: User['id']
    firstName: User['firstName']
    lastName: User['lastName']
  }): Promise<FetchResult<{ updateProfile: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./gql/updateProfile.gql'),
      variables,
    }),

  updatePassword: (variables: {
    id: User['id']
    currentPassword: User['password']
    password: User['password']
    passwordConfirmation: User['password']
  }): Promise<FetchResult<{ updatePassword: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./gql/updatePassword.gql'),
      variables,
    }),

  createAvatar: (variables: { userId: User['id']; avatar: ModalUploadFile }): Promise<unknown> =>
    client.mutate({
      mutation: loader('./gql/createAvatar.gql'),
      variables,
    }),

  fetchUserFilters: (): Promise<FetchUsersResponseModel> => {
    return client.query({
      query: FETCH_USER_FILTERS,
    })
  },
}

export default userApi
