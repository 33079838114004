import { gql } from '@apollo/client'

export const FETCH_DAILY_SUMMARY_LISTING = gql`
  query dailySummaryListing(
    $campaignUuid: String!
    $agglomerations: [String!]
    $cities: [String!]
    $buildings: [String!]
    $creations: [String!]
    $mediaAsIds: [String!]
    $first: Int
    $last: Int
  ) {
    dailySummaryListing(
      campaignUuid: $campaignUuid
      agglomerations: $agglomerations
      cities: $cities
      buildings: $buildings
      creations: $creations
      mediaAsIds: $mediaAsIds
      first: $first
      last: $last
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          day
          medium {
            asId
            address
            building {
              name
            }
            agglomeration {
              name
            }
          }
        }
      }
      totalCount
    }
  }
`
