import React, { useCallback, useEffect, useState } from 'react'
import FlexRow from '../../../../../components/Layout/FlexRow'
import FlexCol from '../../../../../components/Layout/FlexCol'
import Preview from '../../../../../components/Preview'
import VariableUtils from '../../../../../utils/variable'
import './CreationsFormAudienceNumber.scss'
import Input, { OnInputChangeFn } from '../../../../../components/Form/Input'
import { InputMask } from '../../../../../utils/inputMask'
import { useTranslation } from 'react-i18next'
import service from './service'
import debounce from 'lodash/debounce'
import { DEBOUNCE_POST_DELAY_DEFAULT } from '../../../../../constant'
import { CreationsFormAudienceNumberProps } from './types'
import CreationsAudienceTooltipAlert from '../CreationAudienceTooltipAlert'

const CreationsFormAudienceNumber: React.FC<CreationsFormAudienceNumberProps> = ({
  creationId,
  creations,
  campaignAudience,
  setLoadingAudienceNumber,
  readonly,
}) => {
  const [audienceNumber, setAudienceNumber] = useState<string | null>(null)
  const [errors, setErrors] = useState<string[]>([])
  const { t } = useTranslation()
  const creation = creations.find(c => c.id === creationId)!
  const onInputChange: OnInputChangeFn = (value: string) => {
    setLoadingAudienceNumber(true)
    setAudienceNumber(value)
  }
  const debouncedCall = useCallback(
    debounce(
      audienceNumber =>
        service.updateCreationAudienceNumber(
          creation.id,
          parseInt(audienceNumber || ''),
          setAudienceNumber,
          setLoadingAudienceNumber,
          setErrors,
          t
        ),
      DEBOUNCE_POST_DELAY_DEFAULT
    ),
    []
  )

  useEffect(() => {
    setErrors([])
    debouncedCall(audienceNumber)
  }, [audienceNumber])

  useEffect(() => {
    if (creation.audience) {
      setAudienceNumber(creation.audience.toString())
    }
  }, [creation])

  return (
    <FlexRow className='CreationsFormAudienceNumber--row'>
      <FlexCol>
        <Preview
          className={'CreationsFormAudienceNumber--preview'}
          src={creation.file.url}
          thumbnailSrc={creation.thumbnailUrl}
          isVideo={creation.file.isVideo}
          alt={creation.file.filename}
        />
      </FlexCol>

      <FlexCol>
        <div className={'CreationsFormAudienceNumber__emissions'}>
          <p>
            {t('form.campaign.details.target')}:
            <span className={'CreationsFormAudienceNumber__emissions--number'}>
              &nbsp;{VariableUtils.formatNumber(campaignAudience)}
            </span>
          </p>
          <div className={'CreationsFormAudienceNumber__emissions--label'}>
            <span>
              {t('form.campaign.creations.creationAudience')}:&nbsp;
              <b>{creation.file.filename}</b>
            </span>
            <CreationsAudienceTooltipAlert
              creations={creations}
              currentCreationId={creation.id}
              campaignAudience={campaignAudience}
              creationAudienceNumber={audienceNumber}
            />
          </div>
          <Input
            id='audience'
            title={''}
            help={t('form.campaign.details.emissionsHelp')}
            dataCy={'number-of-audience-emissions'}
            value={audienceNumber || ''}
            onChange={onInputChange}
            maskType={InputMask.INTEGER}
            errors={errors}
            readOnly={!!readonly}
          />
        </div>
      </FlexCol>
    </FlexRow>
  )
}

export default CreationsFormAudienceNumber
