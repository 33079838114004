import React from 'react'
import IconMemo from 'components/Icon/IconMemo'
import StripeCard from 'components/StripeCard/StripeCard'
import { useTranslation } from 'react-i18next'
import StripeCardDouble from '../../../../components/StripeCardDouble'

type Props = {
  note: string
  contractNote: string
}

const CampaignDetailsNote: React.FC<Props> = ({ note, contractNote }) => {
  const { t } = useTranslation()
  return contractNote && note ? (
    <StripeCardDouble
      icon={<IconMemo />}
      bottomTitle={t('common.contractNote')}
      topTitle={t('common.note')}
      topContent={<p>{note}</p>}
      bottomContent={<p>{contractNote}</p>}
      bodyMaxHeight='200px'
    ></StripeCardDouble>
  ) : (
    <StripeCard
      icon={<IconMemo />}
      title={note ? t('common.note') : t('common.contractNote')}
      className='CampaignDetailsNote pre-line'
      bodyMaxHeight='200px'
    >
      {note || contractNote}
    </StripeCard>
  )
}

export default CampaignDetailsNote
