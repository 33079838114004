import { SystemCapacityModel } from 'types/system-capacity.model'

export interface MediaFormatCheckboxes {
  indoor: {
    dgIn: boolean
  }
  outdoor: {
    dgCl: boolean
    dgCs: boolean
  }
}

export const mediaFormatCheckboxes: MediaFormatCheckboxes = {
  indoor: {
    dgIn: true,
  },
  outdoor: {
    dgCl: true,
    dgCs: true,
  },
}

export type InOut = 'indoor' | 'outdoor'

export interface SystemCapacityPerDay {
  date: SystemCapacityModel['date']
  freeSlots: SystemCapacityModel['freeSlots']
  plannedSlots: SystemCapacityModel['plannedSlots']
  reservedSlots: SystemCapacityModel['reservedSlots']
  p3PlannedSlots: SystemCapacityModel['p3PlannedSlots']
  p4PlannedSlots: SystemCapacityModel['p4PlannedSlots']
  p5PlannedSlots: SystemCapacityModel['p5PlannedSlots']
  p6PlannedSlots: SystemCapacityModel['p6PlannedSlots']
  p7PlannedSlots: SystemCapacityModel['p7PlannedSlots']
  p8PlannedSlots: SystemCapacityModel['p8PlannedSlots']
  total: SystemCapacityModel['totalSlots']
}

export enum PeriodGranulation {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}
export const SlotDurations = [0, 10, 15, 20, 25, 30]
