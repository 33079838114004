import React, { useEffect, useRef } from 'react'
import TsxUtils from 'utils/tsx'
import { useTranslation } from 'react-i18next'
import { useResizeDetector } from 'react-resize-detector'
import L, { FeatureGroup, Map as MapLeaflet } from 'leaflet'
import { MapProps } from './types'
import 'leaflet.markercluster'
import 'leaflet-active-area'
import './Map.scss'
import MapService from './map.service'
import { useSelector } from 'react-redux'
import { MapSliceModel } from './store/map-slice'

let markersGroup: FeatureGroup | null = null

const Map: React.FC<MapProps> = ({
  className,
  activeAreaClassName,
  zoomControlPosition,
  markers,
  withClustering,
}) => {
  const { t } = useTranslation()
  const { width, height, ref: mapContainerRef } = useResizeDetector()
  const mapRef = useRef<MapLeaflet | null>(MapService.map)
  const mapLoading = useSelector(state => (state as { map: MapSliceModel }).map.loading)

  useEffect(() => {
    markersGroup = L.featureGroup()
    const map = MapService.prepareMap({ markersGroup, activeAreaClassName, zoomControlPosition })
    MapService.map = map

    MapService.addZoomControlKeyEvents(map)
    MapService.zoom = map.getZoom()
    MapService.center = map.getCenter()

    return () => {
      markersGroup = null
      map.remove()
    }
  }, [])

  useEffect(() => {
    const map = MapService.map

    if (map && markersGroup) {
      MapService.prepareMarkerks({ markersGroup, markers, withClustering })
      MapService.fitMapToMarkers(map, markersGroup)
    }
  }, [mapRef, markers])

  useEffect(() => {
    const map = MapService.map

    if (map && markersGroup) {
      MapService.fitMapToMarkers(map, markersGroup)
    }
  }, [mapRef, width, height])

  return (
    <div
      ref={mapContainerRef}
      className={TsxUtils.extraStyle(className, className)}
    >
      <div
        id='map'
        ref={mapRef as any}
        className={'Map' + TsxUtils.extraStyle(className, className)}
      >
        <div className={`${!mapLoading ? 'Map__info' : 'Map__info--hidden'}`}>
          {t('map.holdButton')}
          <span className='Map__info--key'> Control </span>
          {t('common.or')}
          <span className='Map__info--key'> &#8984; </span>
          {t('map.toZoomIn')}
        </div>
      </div>
    </div>
  )
}

export default Map
