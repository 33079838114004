export enum FeatureName {
  Campaigns = 'Campaigns',
  Agencies = 'Agencies',
  Users = 'Users',
  Reports = 'Reports',
  Inventory = 'Inventory',
}

export enum PermissionType {
  Read = 'read',
  Write = 'write',
  Delete = 'delete',
  Active = 'active',
}

export const CampaignPermissionType = {
  ...PermissionType,
  Publish: 'publish',
  Pause: 'pause',
  Copy: 'copy',
  SendToAcceptance: 'send_to_acceptance',
}
export type CampaignPermissionType =
  (typeof CampaignPermissionType)[keyof typeof CampaignPermissionType]
