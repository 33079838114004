import { gql } from '@apollo/client'
import { AGENCY_FIELDS } from '../fragments'

export const GET_AGENCY = gql`
  ${AGENCY_FIELDS}
  query agency($id: ID!) {
    node(id: $id) {
      ... on Agency {
        ...AgencyFields
      }
    }
  }
`
