import { gql } from '@apollo/client'
import { CREATION_MEDIUM_USAGE_FIELDS } from 'api/fragments'

export const GET_CREATION_MEDIA_USAGE = gql`
  ${CREATION_MEDIUM_USAGE_FIELDS}
  query campaignCreationMedia($creationId: ID!) {
    campaignCreationMedia(creationId: $creationId) {
      nodes {
        ...CreationMediumFields
      }
    }
  }
`

export const GET_CREATION_MEDIA_USAGE_PUBLIC = gql`
  ${CREATION_MEDIUM_USAGE_FIELDS}
  query campaignCreationMediaPublic($creationUuid: ID!) {
    campaignCreationMediaPublic(creationUuid: $creationUuid) {
      nodes {
        ...CreationMediumFields
      }
    }
  }
`
