import { Errors } from 'components/Form/InputError'
import { Campaign } from 'types/campaign'

export type FormData = {
  asId: string
  campaignStartDate: Campaign['startDate'] | null
  campaignEndDate: Campaign['endDate'] | null
}

export const formDataDefault: FormData = {
  asId: '',
  campaignStartDate: null,
  campaignEndDate: null,
}

export interface FormDataErrors {
  asId: Errors
}

export const formDataErrorsDefault: FormDataErrors = {
  asId: [],
}
