import React from 'react'
import TsxUtils from 'utils/tsx'
import './InputError.scss'

interface InputErrorProps {
  className?: string
  errors: Errors
}

export type Errors = string[]

const InputError: React.FC<InputErrorProps> = ({ className, errors }) => (
  <div className={'InputError' + TsxUtils.extraStyle(className, className)}>
    {errors.map(err => (
      <div key={err}>{err}</div>
    ))}
  </div>
)

export default InputError
