import ArrayUtils from 'utils/array'
import { AllowedForArgs, UseAuthorizeProps } from './types'
import { Role } from '../../types/user'

const useAuthorize = (currentUserRoles?: Role[]): UseAuthorizeProps => {
  const allowedFor = (args: AllowedForArgs): boolean => {
    const { template, status, feature, mediumFormat } = args

    if (!currentUserRoles) {
      return false
    }

    let allowForBaseRoles = true
    let allowForStatuses = true
    let allowFeature = true
    let allowForMediaFormat = true

    if (feature) {
      allowFeature = currentUserRoles?.some(roleElement => {
        const permission = roleElement.permissionElementRoles?.filter(
          permission => permission.title === feature.name
        )[0]
        const action = permission?.permissions.filter(action => action.title === feature.action)[0]
        return action?.value
      })
    }

    if (!template) {
      return allowFeature
    }

    const { roles, statuses, mediaFormat } = template

    if (roles) {
      allowForBaseRoles = ArrayUtils.containsOneOrMoreItems(
        roles,
        currentUserRoles.map(roleElement => roleElement.baseRole)
      )
    }

    if ((statuses && !status) || (!statuses && status)) {
      throw new Error(`You forgot one of two needed props - 'status' or 'statuses' in template.`)
    }

    if ((mediaFormat && !mediumFormat) || (!mediaFormat && mediumFormat)) {
      throw new Error(
        `You forgot one of two needed props - 'mediumFormat' or 'mediaFormat' in template.`
      )
    }

    if (statuses && status) {
      allowForStatuses = ArrayUtils.containsOneOrMoreItems(statuses, [status])
    }

    if (mediaFormat && mediumFormat) {
      allowForMediaFormat = ArrayUtils.containsOneOrMoreItems(mediaFormat, [mediumFormat])
    }

    return allowForMediaFormat && allowForBaseRoles && allowForStatuses && allowFeature
  }

  return { allowedFor }
}

export default useAuthorize
