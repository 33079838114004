import { createSlice } from '@reduxjs/toolkit'

export interface MapSliceModel {
  loading: boolean
}

const mapSlice = createSlice({
  name: 'map',
  initialState: {
    loading: false,
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
  },
})

export const mapActions = mapSlice.actions

export default mapSlice
