import api from '../../../api'
import { CampaignsCombinedSummaryModel } from '../../../api/report/types'

export default class CampaignsCombinedReportService {
  static fetchCampaignsCombinedReport = (
    campaignIds: string[],
    startDate: string | null,
    endDate: string | null
  ): Promise<CampaignsCombinedSummaryModel[]> => {
    return api.report
      .getCampaignsCombinedReport({
        campaignIds,
        startDate,
        endDate,
      })
      .then(response => {
        return response.data.campaignsCombinedSummary
      })
  }
}
