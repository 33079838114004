import { gql } from '@apollo/client'
import { MUTATION_STATE } from '../fragments'

const PAYLOAD = 'ArchiveCampaignCreationPayload'

export const ARCHIVE_CREATION = gql`
  ${MUTATION_STATE(PAYLOAD)}
  mutation archiveCreation($creationId: ID!, $archiveReason: String!) {
    archiveCreation(input: { creationId: $creationId, archiveReason: $archiveReason }) {
      ...MutationState${PAYLOAD}Fields
    }
  }
`
