import React, { ReactNode, useState } from 'react'
import Modal from 'components/Modal'
import { ModalActionProps } from './types'
import { ModalActionFooter } from '../ModalFooter'
import NoteModalFooter from 'pages/Campaign/CampaignForm/NoteSummary/NoteModalFooter'
import './ModalAction.scss'

const ModalAction: React.FC<ModalActionProps> = ({
  actionText,
  contentText,
  descriptionText,
  textInputHeader,
  isLoading,
  isOpen,
  itemName,
  itemText,
  textInputInitValue,
  onAction,
  setIsLoading,
  setIsOpen,
  title,
}) => {
  const [inputText, setInputText] = useState(textInputInitValue)
  const [isEditing, setIsEditing] = useState(textInputInitValue === '')

  const reasonFooterAction = () => {
    setIsLoading && setIsLoading(true)
    onAction
      .bind(null, inputText)()
      .catch(() => void {})
      .finally(() => {
        setIsLoading && setIsLoading(false)
        setIsOpen(false)
      })
  }

  const Footer = (): ReactNode => (
    <ModalActionFooter
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      setIsOpen={setIsOpen}
      onAction={onAction}
      actionText={actionText}
    />
  )

  const NoteFooter = (): ReactNode => (
    <NoteModalFooter
      isEditing={isEditing}
      loading={!!isLoading}
      onCancel={() => void setIsOpen(false)}
      onEdit={() => setIsEditing(true)}
      onSave={reasonFooterAction}
    />
  )

  const textEdit = (
    <div className='ModalAction__text-input'>
      <span className='ModalAction__text-input-title'>{textInputHeader}</span>
      <textarea
        onChange={e => setInputText(e.target.value)}
        value={inputText}
        autoFocus
        className='Input__field NoteModal__input'
      />
    </div>
  )

  const textDisplay = textInputInitValue !== undefined && (
    <div className='ModalAction__text-input-display'>
      <span className='ModalAction__text-input-title'>{textInputHeader}</span>
      <p>{textInputInitValue}</p>
    </div>
  )

  return isOpen ? (
    <Modal
      title={title}
      separateTitleBar={false}
      isOpen={isOpen}
      onClose={(isOpen: boolean) => void setIsOpen(isOpen)}
      classNameChildren='ModalAction'
      footer={textInputInitValue !== undefined ? NoteFooter() : Footer()}
      isLoading={isLoading}
    >
      {itemText && itemName ? (
        <div className='ModalAction__text'>
          <div className='ModalAction__text--content'>{contentText}</div>
          {itemText} <span className='ModalAction__text--name'>{itemName}</span>?
          {descriptionText && <p>{descriptionText}</p>}
        </div>
      ) : null}
      {textInputInitValue === '' || isEditing ? textEdit : textDisplay}
    </Modal>
  ) : null
}

export default ModalAction
