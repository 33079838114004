import React, { useState, useEffect } from 'react'
import { default as ReactModal } from 'react-modal'
import { ModalProps } from './types'
import TsxUtils from 'utils/tsx'
import { ModalFooterContainer } from './ModalFooter'
import './Modal.scss'

const Modal: React.FC<ModalProps> = ({
  classNameOverlay,
  classNameContent,
  classNameChildren,
  title,
  separateTitleBar = true,
  children,
  footer,
  isOpen,
  onClose,
  scrollable,
  isLoading,
}) => {
  const [isOpenLocal, setIsOpenLocal] = useState(false)

  useEffect(() => {
    setIsOpenLocal(isOpen)
  }, [isOpen])

  const handleRequestClose = () => {
    onClose(false)
  }

  return (
    <ReactModal
      appElement={document.getElementById('root')!}
      isOpen={isOpenLocal}
      onRequestClose={handleRequestClose}
      shouldCloseOnOverlayClick={!isLoading}
      shouldCloseOnEsc={!isLoading}
      portalClassName='ModalPortal'
      overlayClassName={'Modal__overlay' + TsxUtils.extraStyle(classNameOverlay, classNameOverlay)}
      className={'Modal__content' + TsxUtils.extraStyle(classNameContent, classNameContent)}
    >
      {title && (
        <div
          className={'Modal__bar' + TsxUtils.extraStyle(separateTitleBar, 'Modal__bar--separate')}
        >
          <div
            className={
              'Modal__bar--title' +
              TsxUtils.extraStyle(separateTitleBar, 'Modal__bar--title-separate')
            }
          >
            {title}
          </div>
        </div>
      )}

      <div
        className={
          'Modal__children' +
          TsxUtils.extraStyle(classNameChildren, classNameChildren) +
          TsxUtils.extraStyle(scrollable, 'Modal__children--scrollable')
        }
        data-cy={'common-modal'}
      >
        {children}
      </div>

      {footer && <ModalFooterContainer footer={footer} />}
    </ReactModal>
  )
}

export default Modal
