import { Errors } from 'components/Form/InputError'

export interface Agencies {
  id: string
  name: string
}

export interface BasicFormErrors {
  agency: Errors
  name: Errors
  briefName: Errors
  startDate: Errors
  endDate: Errors
}

export const BasicFormErrorsEmpty = {
  agency: [],
  name: [],
  briefName: [],
  startDate: [],
  endDate: [],
}
