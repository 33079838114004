import api from 'api'
import { UpdateCampaignMediaUsageFR } from 'api/campaign/types'
import { UpdateCreationMediaUsageFR } from 'api/creation/types'
import { Campaign, Creation, MediaFormat, Medium, MediumUsage } from 'types/campaign'
import EnumUtils from 'utils/enum'

export const fetchAllMedia = (
  mediumFormat: MediaFormat,
  withAllocatedTime: boolean,
  campaignId: Campaign['id']
): Promise<Medium[]> => {
  let payload: { mediumFormat: keyof MediaFormat; campaignId?: Campaign['id'] } = {
    mediumFormat: EnumUtils.getKeyByValueT(MediaFormat, mediumFormat) as keyof MediaFormat,
  }

  if (withAllocatedTime) {
    payload = { ...payload, campaignId }
  }

  return api.media
    .getAll({
      ...payload,
    })
    .then(res => res.data.media.nodes)
    .then(medias => {
      return medias.map(medium => {
        return {
          ...medium,
          poiCategories: medium.pois.edges.map(poi => poi.node.category),
        }
      })
    })
}

export const fetchMediaUsedInCampaign = (campaignId: Campaign['id']): Promise<Medium[]> =>
  api.media
    .getUsedInCampaign({ campaignId })
    .then(res => res.data.mediaUsedInCampaign.nodes)
    .then(medias => {
      return medias.map(medium => {
        return {
          ...medium,
          poiCategories: medium.pois.edges.map(poi => poi.node.category),
        }
      })
    })

export const fetchMediaUsedInCampaignPublic = (campaignUuid: Campaign['uuid']): Promise<Medium[]> =>
  api.media
    .getUsedInCampaignPublic({ campaignUuid })
    .then(res => res.data.mediaUsedInCampaignPublic.nodes)
    .then(medias => {
      return medias.map(medium => {
        return {
          ...medium,
          poiCategories: medium.pois.edges.map(poi => poi.node.category),
        }
      })
    })

export const fetchCampaignMediaUsage = (campaignId: Campaign['id']): Promise<MediumUsage[]> =>
  api.campaign.getMediaUsage(campaignId).then(res => res.data.node.media)

export const fetchCreationMediaUsage = (creationId: Creation['id']): Promise<MediumUsage[]> =>
  api.creation.getMediaUsage({ creationId }).then(res => res.data.campaignCreationMedia.nodes)

export const fetchCreationMediaUsagePublic = (
  creationUuid: Creation['uuid']
): Promise<MediumUsage[]> =>
  api.creation
    .getMediaUsagePublic({ creationUuid })
    .then(res => res.data.campaignCreationMediaPublic.nodes)

export const updateCampaignMediaUsage = (
  campaignId: Campaign['id'],
  media: MediumUsage[]
): Promise<UpdateCampaignMediaUsageFR> => api.campaign.updateMediaUsage({ campaignId, media })

export const updateCreationMediaUsage = (
  creationId: Creation['id'],
  media: MediumUsage[]
): Promise<UpdateCreationMediaUsageFR> => api.creation.updateMediaUsage({ creationId, media })
