import { gql } from '@apollo/client'

export default gql`
  query CampaignHistory($campaignId: ID!) {
    campaignHistory(campaignId: $campaignId) {
      nodes {
        alters
        category
        createdAt
        resourcableId
        resourcableType
        user {
          firstName
          lastName
        }
        galleryName
      }
    }
  }
`
