import { Errors } from '../components/Form/InputError'
import i18n from '../i18n'
import { default as isEmailValidator } from 'validator/es/lib/isEmail'
import isNumeric from 'validator/es/lib/isNumeric'
import isBase64 from 'validator/es/lib/isBase64'
import matches from 'validator/es/lib/matches'
import { REGEX_NUMBERS_COMMA_OR_SPACE_SEPARATED, MAX_SAFE_GQL_INTEGER } from 'constant'
import VariableUtils from './variable'
import { FormErrors } from 'types/various'

export default class ErrorUtils {
  static haveErrors = (errors: FormErrors | undefined, errorsDefault: FormErrors): boolean =>
    !VariableUtils.isDeepEqual(errors, errorsDefault)

  static isRequired = (v: string | any[]): Errors => (!v.length ? [i18n.t('errors.required')] : [])

  static maxLength = (value: string, max: number): Errors => {
    if (!value || value.length === 0) {
      return []
    }

    if (value.length <= max) {
      return []
    }

    return [`${i18n.t('errors.maxNumberOfCharactersExceeded')}: ${max}`]
  }

  static isEmail = (v: string): Errors =>
    isEmailValidator(v) ? [] : [i18n.t('errors.invalidEmail')]

  static isNumeric = (v: string): Errors =>
    !isNumeric(v) ? [i18n.t('errors.invalidNumericFormat')] : []

  static isSafeInteger = (v: number): Errors =>
    v > MAX_SAFE_GQL_INTEGER ? [i18n.t('errors.maxSafeInteger')] : []

  static isBase64 = (v: string): Errors =>
    !isBase64(v) ? [i18n.t('errors.invalidEncodingFormat')] : []

  static areNumbersSeparatedCorrectly = (v: string): Errors =>
    !matches(v, REGEX_NUMBERS_COMMA_OR_SPACE_SEPARATED)
      ? [i18n.t('errors.invalidNumberSeparation')]
      : []

  static areDifferent = (a: string, b: string): Errors =>
    a === b ? [] : [i18n.t('errors.differFromEachOther')]
}
