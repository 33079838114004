import { useState } from 'react'
import { Filter, FilterMeta, filtersDefault, UseTableFilterProps } from './types'

const useTableFilter = (): UseTableFilterProps => {
  const [filters, setFilters] = useState<FilterMeta[]>(filtersDefault)

  const filterIndexBy = (id: Filter): number => filters.findIndex((fm: FilterMeta) => fm.id === id)

  const setTableFilter: UseTableFilterProps['setTableFilter'] = (id, filter) => {
    const filterIndex: number = filterIndexBy(id)

    if (filterIndex > -1) {
      const tempFilters = filters
      filters[filterIndex].filter = filter
      setFilters([...tempFilters])
    } else {
      setFilters([...filters, { id, filter }])
    }
  }

  const getTableFilter: UseTableFilterProps['getTableFilter'] = id => {
    const filterMeta = filters.find((fm: FilterMeta) => fm.id === id)

    if (!filterMeta)
      throw new Error(`Searched filter should exists. Check 'filtersDefault' values.`)

    return filterMeta.filter
  }

  return { setTableFilter, getTableFilter }
}

export default useTableFilter
