import React, { ReactNode, useContext } from 'react'
import PageHeader from 'components/Layout/PageHeader'
import Card from 'components/Layout/Card'
import { AppContext } from 'contexts/AppContext'
import { Feature } from 'hooks/useFeature'
import { Features as FeaturesEnum } from 'constant/features'
import Slider from 'components/Form/Slider'
import Button, { ButtonSize, ButtonTheme } from 'components/Form/Button'
import './Features.scss'

const Features: React.FC = () => {
  const { features, setFeatureState, resetFeatures } = useContext(AppContext)

  const Features = (features: Feature[]): ReactNode =>
    features.map((f: Feature) => {
      const name = FeaturesEnum[f.name]

      return (
        <div
          key={name}
          className='Features__feature'
        >
          <div className='Features__feature--name'>{name}</div>

          <Slider
            id={name}
            className='Features__feature--slider'
            value={f.state ? 1 : 0}
            onChange={newState => void setFeatureState(f.name, newState === 1)}
            showHandleValue={false}
            marks={[0, 1]}
          />
        </div>
      )
    })

  return (
    <>
      <PageHeader>Features</PageHeader>

      <Card className='Features__card'>
        <div className='Features__card--description'>
          Note that below settings are stored in your browser cookies.
        </div>

        {Features(features)}

        <Button
          className='Features__card--button'
          size={ButtonSize.SMALL}
          theme={ButtonTheme.RED_OUTLINE}
          onClick={() => void resetFeatures()}
        >
          Reset
        </Button>
      </Card>
    </>
  )
}

export default Features
