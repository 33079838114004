import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import DatePickerSingle from 'components/Form/DatePickerSingle'
import { DATE_PLACEHOLDER } from 'constant'
import Input from 'components/Form/Input'
import FormColumn from 'components/Form/FormColumn'
import FormRow from 'components/Form/FormRow'
import Button, { ButtonTheme } from 'components/Form/Button'
import '../../Report.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { reportActions } from '../../store/report-slice'
import { faPlus, faRemove } from '@fortawesome/free-solid-svg-icons'
import FillingSpinner from '../../../../components/FillingSpinner'
import { AppContext } from '../../../../contexts/AppContext'
import CampaignsCombinedReportService from '../../services/campaigns-combined-report.service'
import { NotifierType } from '../../../../components/Notifier'
import { pdf } from '@react-pdf/renderer'
import CombinedCampaignReportPdf from '../../components/CombinedCampaignReportPdf'
import DownloadUtils from '../../../../utils/download'

const CampaignsCombinedReport: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { addNotification } = useContext(AppContext)
  const { loading, campaigns, startDate, endDate } = useSelector(
    (state: RootState) => state.report.campaignsCombined
  )

  const isGenerateButtonDisabled = () => {
    const isAnyInputEmpty = Object.values(campaigns).some(campaign => !campaign)

    return !campaigns.length || isAnyInputEmpty
  }

  return (
    <>
      {loading ? (
        <FillingSpinner className='Report__loading' />
      ) : (
        <div className='Report__combined-form'>
          <FormRow>
            <FormColumn>
              <DatePickerSingle
                id='campaignStartDate'
                value={startDate ?? ''}
                title={t('common.from')}
                placeholder={DATE_PLACEHOLDER}
                onChange={(date: string) => {
                  dispatch(
                    reportActions.setCombinedCampaignsData({ startDate: !date ? null : date })
                  )
                }}
                isOutsideRange={() => false}
              />
            </FormColumn>

            <FormColumn>
              <DatePickerSingle
                id='campaignEndDate'
                value={endDate ?? ''}
                title={t('common.to')}
                placeholder={DATE_PLACEHOLDER}
                onChange={(date: string) => {
                  dispatch(reportActions.setCombinedCampaignsData({ endDate: !date ? null : date }))
                }}
                isOutsideRange={() => false}
              />
            </FormColumn>
          </FormRow>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '15px' }}>
            {campaigns.map((campaign, index) => (
              <div
                key={index}
                style={{ display: 'flex', alignItems: 'center', gap: '15px' }}
              >
                <Input
                  id='campaignId'
                  title={t('report.campaignId') + '*'}
                  placeholder='Q2FtcGFpZ24tNTE='
                  value={campaign}
                  dataCy={`mass-campaign-input-${index}`}
                  onChange={(value: string) => {
                    dispatch(reportActions.updateCombinedCampaign({ index, value }))
                  }}
                />

                {index > 0 && (
                  <div style={{ transform: 'translateY(15px)' }}>
                    <Button
                      icon={faRemove}
                      theme={ButtonTheme.RED_OUTLINE}
                      tooltip={t('common.remove')}
                      onClick={() => {
                        dispatch(reportActions.removeCombinedCampaign(index))
                      }}
                    />
                  </div>
                )}
              </div>
            ))}

            <Button
              icon={faPlus}
              tooltip={t('common.add')}
              onClick={() => {
                dispatch(reportActions.addCombinedCampaign())
              }}
            />
          </div>

          <div style={{ marginTop: '15px' }}>
            <Button
              dataCy={'generate-mass-campaign-pdf-report'}
              onClick={() => {
                dispatch(reportActions.setCombinedCampaignsData({ loading: true }))

                const ids = campaigns.filter(campaign => !!campaign.trim())
                let fileName = `combined_report`

                CampaignsCombinedReportService.fetchCampaignsCombinedReport(ids, null, null)
                  .then(summaries => {
                    fileName = `${summaries[0].details.name ?? fileName}`
                    return pdf(<CombinedCampaignReportPdf summaries={summaries} />).toBlob()
                  })
                  .then(blob => DownloadUtils.downloadBlob(blob, `${fileName}.pdf`))
                  .catch(() => {
                    addNotification(NotifierType.ERROR, t('notification.generalError'))
                    return void {}
                  })
                  .finally(() =>
                    dispatch(reportActions.setCombinedCampaignsData({ loading: false }))
                  )
              }}
              disabled={isGenerateButtonDisabled()}
            >
              {t('report.generate')}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default CampaignsCombinedReport
