import api from 'api'
import { Dispatch, SetStateAction } from 'react'
import { CreationsValues, FormCreation } from 'contexts/CampaignFormContext'
import { CardStatus } from 'components/Layout/Card'
import { Campaign, CreationAcceptanceDetails } from '../../../../../types/campaign'
import { store } from 'store'
import { creationAcceptancesActions } from '../store/creation-acceptances-slice'
import { TFunction } from 'react-i18next'
import { NotifierType } from '../../../../../components/Notifier'
import { AppContextProps } from '../../../../../contexts/AppContext/types'
import { campaignActions } from '../../../store/campaign-slice'

export default class CreationsFormPreviewService {
  static deleteCreation = (
    creationId: FormCreation['id'],
    creations: CreationsValues,
    setCreations: Dispatch<SetStateAction<CreationsValues>>,
    setTriggerCreations: Dispatch<SetStateAction<boolean>>
  ): Promise<void> => {
    store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVING }))

    return api.creation
      .deleteCreation(creationId)
      .then(() => {
        setCreations(creations.filter(c => c.id !== creationId))
        const creationsAcceptances = store
          .getState()
          .creationAcceptances.acceptancesList.map(creationAcceptance => {
            return {
              ...creationAcceptance,
              acceptances: creationAcceptance.acceptances.filter(
                acceptanceDetails => acceptanceDetails.creationId !== creationId
              ),
            }
          })
        store.dispatch(creationAcceptancesActions.setAcceptancesList(creationsAcceptances))
        store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVED }))
      })
      .catch(
        () => void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.ERROR }))
      )
      .finally(() => {
        setTriggerCreations(true)
      })
  }

  static archiveCreation = (
    creationId: FormCreation['id'],
    setTriggerCreations: Dispatch<SetStateAction<boolean>>,
    addNotification: AppContextProps['addNotification'],
    t: TFunction,
    archiveReason: string
  ): Promise<void> => {
    store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVING }))

    return api.creation
      .archiveCreation(creationId, archiveReason)
      .then(res => {
        if (res.data?.archiveCreation.success) {
          addNotification(NotifierType.SUCCESS, t('form.campaign.creations.archive.success'))
          store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVED }))
        }
      })
      .catch(
        () => void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.ERROR }))
      )
      .finally(() => {
        setTriggerCreations(true)
      })
  }

  static sendToAcceptance = (
    campaignId: Campaign['id'],
    addNotification: (type: NotifierType, text: string | string[], time?: number) => void
  ): Promise<void> => {
    store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVING }))
    return api.creation
      .sendToAcceptance(campaignId)
      .then(sendToAcceptanceResponse => {
        const infoMessage = sendToAcceptanceResponse.data?.sendCampaignCreationsToAcceptance.info
        store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVED }))

        if (infoMessage && infoMessage.length) {
          addNotification(NotifierType.SUCCESS, infoMessage)
        }

        if (sendToAcceptanceResponse.data) {
          store.dispatch(
            creationAcceptancesActions.setAcceptancesList(
              sendToAcceptanceResponse.data.sendCampaignCreationsToAcceptance.acceptances
            )
          )
        }
      })
      .catch(() => {
        store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.ERROR }))
      })
      .finally(() => {
        store.dispatch(creationAcceptancesActions.setDisableSendToAcceptance(false))
      })
  }

  static getAcceptancesList = (campaignId: Campaign['id']): Promise<void> => {
    store.dispatch(creationAcceptancesActions.setLoadingAcceptancesList(true))

    return api.creation
      .getCampaignAcceptances(campaignId)
      .then(acceptancesListData => {
        store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVED }))

        store.dispatch(
          creationAcceptancesActions.setAcceptancesList(
            acceptancesListData.data.campaignAcceptances
          )
        )
      })
      .catch(() => {
        store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.ERROR }))
      })
      .finally(() => {
        store.dispatch(creationAcceptancesActions.setDisableSendToAcceptance(false))
        store.dispatch(creationAcceptancesActions.setLoadingAcceptancesList(false))
      })
  }

  static updateCreationDetails = ({
    value,
    creationId,
    creations,
    setCreations,
  }: {
    value: string
    creationId: FormCreation['id']
    creations: FormCreation[]
    setCreations: Dispatch<SetStateAction<CreationsValues>>
  }) => {
    const temp = [...creations]
    const creation = temp.find((c: FormCreation) => c.id === creationId)!
    creation.details = value

    setCreations(temp)
  }

  static updateCreationEmissionsNumber = ({
    value,
    creationId,
    creations,
    setCreations,
  }: {
    value: string
    creationId: FormCreation['id']
    creations: FormCreation[]
    setCreations: Dispatch<SetStateAction<CreationsValues>>
  }) => {
    const temp = [...creations]
    const creation = temp.find((c: FormCreation) => c.id === creationId)!
    creation.impressions = parseInt(value)

    setCreations(temp)
  }

  static updateCreationAudienceNumber = ({
    newAudience,
    creationId,
    creations,
    setCreations,
  }: {
    newAudience: string
    creationId: FormCreation['id']
    creations: FormCreation[]
    setCreations: Dispatch<SetStateAction<CreationsValues>>
  }) => {
    const updatedCreations = creations.map((creation: FormCreation) =>
      creation.id === creationId ? { ...creation, audience: parseInt(newAudience) } : creation
    )

    setCreations(updatedCreations)
  }

  static getBuildingAcceptanceStatus = (
    buildingAcceptances: CreationAcceptanceDetails[],
    t: TFunction
  ) => {
    switch (true) {
      case buildingAcceptances.some(
        acceptance =>
          acceptance.status === 'pending_acceptation' || acceptance.status === 'not_sent'
      ): {
        return t('form.campaign.creations.accepting')
      }
      case buildingAcceptances.some(acceptance => acceptance.status === 'rejected'): {
        return t('form.campaign.creations.rejected')
      }
      default: {
        return t('form.campaign.creations.accepted')
      }
    }
  }

  static updateCreationDuration = ({
    value,
    creationId,
    creations,
    setCreations,
  }: {
    value: number
    creationId: FormCreation['id']
    creations: FormCreation[]
    setCreations: Dispatch<SetStateAction<CreationsValues>>
  }) => {
    setCreations(
      creations.map(creation => {
        if (creation.id === creationId) {
          creation.duration = value
        }
        return creation
      })
    )
  }

  static updateCreationContainsAlcohol = ({
    value,
    creationId,
    creations,
    setCreations,
  }: {
    value: boolean
    creationId: FormCreation['id']
    creations: FormCreation[]
    setCreations: Dispatch<SetStateAction<CreationsValues>>
  }) => {
    setCreations(
      creations.map(creation => {
        if (creation.id === creationId) {
          creation.containsAlcohol = value
        }
        return creation
      })
    )
  }

  static fetchCampaignAcceptancesReportFile = (campaignId: Campaign['id']): Promise<void> => {
    return api.creation
      .fetchCampaignAcceptanceReportFile(campaignId)
      .then(res => res.data?.campaignAcceptancesFile.url)
      .then(url => {
        if (!url) {
          return
        }
        window.open(url, '_blank')
      })
      .catch(() => void {})
  }
}
