import Button, { ButtonSize } from 'components/Form/Button'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Summary from '../Summary'
import { EstimatedAudienceEmissionsProps } from './types'
import service from './service'
import './EstimatedAudienceEmissions.scss'
import { AppContext } from '../../../../contexts/AppContext'
import { CAMPAIGN_FORM } from '../../../../constant/authorization'
import VariableUtils from '../../../../utils/variable'

const EstimatedAudienceEmissions: React.FC<EstimatedAudienceEmissionsProps> = ({
  campaignId,
  audience,
  hiddenAudience,
}) => {
  const { t } = useTranslation()
  const { allowedFor } = useContext(AppContext)
  const [estimatedImpressions, setEstimatedImpressions] = useState<number | null>(null)
  const [hiddenTarget, setHiddenTarget] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleClick = () => {
    void service.getCampaignImpressionsBasedOnAudience({
      campaignId,
      setEstimatedImpressions,
      setHiddenTarget,
      setLoading,
    })
  }

  const showHiddenTarget = () => {
    /**
     * When value comes from the backend, it can be a number, when user types it, it can be a string
     */
    const audienceString = audience?.toString()
    const hiddenAudienceString = hiddenAudience?.toString()

    /**
     * If hiddenAudience is null, 0, '0' or equal to audience, we should not show hidden target
     */
    if (
      hiddenAudienceString === '' ||
      hiddenAudienceString === '0' ||
      hiddenAudienceString === null ||
      hiddenAudienceString === undefined ||
      hiddenAudienceString === audienceString
    ) {
      return false
    }

    /**
     * From business perspective we can show hiddenTarget
     * Last check if the user is admin (only admin can see it)
     */
    return allowedFor({ template: CAMPAIGN_FORM.CHECK_HIDDEN_TARGET_ESTIMATION })
  }

  return (
    <Summary
      title={t(`form.campaign.summary.audience`)}
      quantity={estimatedImpressions}
      isLoading={loading}
    >
      {showHiddenTarget() && (
        <div className='EstimatedAudienceEmissions__hidden-target'>
          <p>{t('common.hiddenTarget')}</p>
          <p>{VariableUtils.formatNumberWithGuard(hiddenTarget)}</p>
        </div>
      )}

      {campaignId && (
        <Button
          className='EstimatedAudienceEmissions__button'
          size={ButtonSize.SMALL}
          onClick={handleClick}
        >
          {t('form.campaign.summary.recalculate')}
        </Button>
      )}
    </Summary>
  )
}

export default EstimatedAudienceEmissions
