import { RESERVATION_DEADLINE_HOUR_IN_POLAND } from 'constant'
import { addDays, isSameDay, subDays } from 'date-fns'
import { DayPickerProps } from 'react-day-picker'
import { Campaign } from 'types/campaign'
import DateUtils from 'utils/date'

interface TimeRules {
  today: Date
  dayBeforeStart: Date
  startIsToday: boolean
  startIsTomorrow: boolean
  isNowInPolandAfterHour18: boolean
  allDisabled: DayPickerProps['disabledDays']
}

export default class ReservationService {
  static shouldDisableReservation = ({
    startDate,
  }: {
    startDate: Campaign['startDate']
  }): boolean => {
    const { startIsToday, startIsTomorrow, isNowInPolandAfterHour18 } =
      ReservationService.prepareDataForTimeRules({ startDate })

    return startIsToday || (startIsTomorrow && isNowInPolandAfterHour18)
  }

  static calendarDisableDays = ({
    startDate,
  }: {
    startDate: Campaign['startDate']
  }): DayPickerProps['disabledDays'] => {
    const {
      today,
      dayBeforeStart,
      startIsToday,
      startIsTomorrow,
      isNowInPolandAfterHour18,
      allDisabled,
    } = ReservationService.prepareDataForTimeRules({ startDate })

    if (startIsToday) {
      return allDisabled
    } else if (startIsTomorrow) {
      if (isNowInPolandAfterHour18) {
        return allDisabled
      } else {
        return { before: today, after: today }
      }
    } else {
      return { before: today, after: dayBeforeStart }
    }
  }

  static prepareDataForTimeRules = ({
    startDate,
  }: {
    startDate: Campaign['startDate']
  }): TimeRules => {
    const today = new Date()
    const start = new Date(startDate)
    const dayBeforeStart = subDays(start, 1)
    const startIsToday = isSameDay(start, today)
    const startIsTomorrow = isSameDay(start, addDays(today, 1))
    const isNowInPolandAfterHour18 = DateUtils.isNowInPolandAfterHour(
      RESERVATION_DEADLINE_HOUR_IN_POLAND
    )
    const { zeroYear, max } = DateUtils.getDatesFromYearZeroToMaxPossible()
    const allDisabled: DayPickerProps['disabledDays'] = { from: zeroYear, to: max }

    return {
      today,
      dayBeforeStart,
      startIsToday,
      startIsTomorrow,
      isNowInPolandAfterHour18,
      allDisabled,
    }
  }
}
