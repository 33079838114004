import React, { useEffect, useState } from 'react'
import { CreationsFormLocationsProps } from './types'
import MediaSelection from 'components/MediaSelection/MediaSelection'
import './CreationsFormLocations.scss'

const CreationsFormLocations: React.FC<CreationsFormLocationsProps> = ({
  ids,
  mediumFormat,
  readOnly,
  onChange,
  onLoading,
}) => {
  const [shouldSaveMedia, setShouldSaveMedia] = useState<boolean>(false)

  useEffect(() => {
    onLoading(shouldSaveMedia)

    if (shouldSaveMedia) {
      onChange()
    }
  }, [shouldSaveMedia])

  return (
    <MediaSelection
      ids={ids}
      mediumFormat={mediumFormat}
      readOnly={readOnly}
      shouldSave={shouldSaveMedia}
      setShouldSave={setShouldSaveMedia}
      withFilters
      autoSave={true}
      onLoading={onLoading}
    />
  )
}

export default CreationsFormLocations
