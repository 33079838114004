export class SlotsSecondsHelper {
  static getName(
    slotDuration: number,
    lowerCase?: boolean
  ): 'Slots' | 'Seconds' | 'slots' | 'seconds' {
    const result = slotDuration == 0 ? 'Seconds' : 'Slots'

    if (lowerCase) {
      return result.toLowerCase() as 'slots' | 'seconds'
    }

    return result
  }
}
