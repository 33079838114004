import { gql } from '@apollo/client'

export const CREATE_CREATION_REPORT = gql`
  mutation createCreationReport(
    $campaignId: ID!
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
  ) {
    createCreationReport(
      input: { campaignId: $campaignId, startDate: $startDate, endDate: $endDate }
    ) {
      report {
        name
        status
        startDate
        endDate
        generatedAt
        csvUrl
      }
      success
      errors
    }
  }
`
