import { RangeModifier } from 'react-day-picker'

export interface DatePickerRangeProps {
  disabledDays?: DisabledDays
  values: DateRange[]
  onChange: (dateRanges: DateRange[]) => void
  readOnly?: boolean
  loading?: boolean
}

export interface RangeModifierNullable {
  from: RangeModifier['from'] | null
  to: RangeModifier['to'] | null
}

export const rangeModifierNullableDefault: RangeModifierNullable = {
  from: null,
  to: null,
}

export type DateRange = RangeModifier

export interface DisabledDays {
  from: Date
  to: Date
}
