import React, { useContext } from 'react'
import api from 'api'
import ModalAction from 'components/Modal/ModalAction'
import useCampaignAction from 'hooks/useCampaignAction'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'routes'
import service from '../services/campaign-details.service'
import { CampaignDetailsActionModalsProps, ModalActionKind } from './types'
import { AppContext } from '../../../../contexts/AppContext'
import { NotifierType } from '../../../../components/Notifier'

const CampaignDetailsActionModals: React.FC<CampaignDetailsActionModalsProps> = ({
  campaign,
  onShouldReload,
  reducerState,
  dispatch,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    acceptCampaign,
    publishCampaign,
    confirmCampaignReservation,
    cancelCampaignReservation,
    createCampaignCopy,
    rejectCampaign,
  } = useCampaignAction()
  const { addNotification } = useContext(AppContext)

  return (
    <>
      {reducerState.createCopyOpen && (
        <ModalAction
          title={t('modal.createCampaignCopy')}
          itemText={t('modal.doYouWantToCreateCampaignCopy')}
          itemName={campaign.name}
          actionText={t('modal.createCopy')}
          isOpen={reducerState.createCopyOpen}
          setIsOpen={isOpen => void dispatch(ModalActionKind.CREATE_COPY_OPEN, isOpen)}
          isLoading={reducerState.createCopyLoading}
          setIsLoading={isLoading => void dispatch(ModalActionKind.CREATE_COPY_LOADING, isLoading)}
          onAction={() => createCampaignCopy(campaign.id)}
        />
      )}

      {reducerState.removeOpen && (
        <ModalAction
          title={t('modal.campaignRemoval')}
          itemText={t('modal.doYouWantToRemove')}
          itemName={campaign.name}
          actionText={t('common.remove')}
          isOpen={reducerState.removeOpen}
          setIsOpen={isOpen => void dispatch(ModalActionKind.REMOVE_OPEN, isOpen)}
          isLoading={reducerState.removeLoading}
          setIsLoading={isLoading => void dispatch(ModalActionKind.REMOVE_LOADING, isLoading)}
          onAction={() => service.deleteCampaign(campaign.id, navigate, addNotification, t)}
        />
      )}

      {reducerState.editOpen && (
        <ModalAction
          title={t('modal.campaignEditing')}
          contentText={t('modal.editCampaignInfo')}
          itemText={t('modal.doYouWant')}
          itemName={t('modal.editCampaignItem')}
          actionText={t('common.edit')}
          isOpen={reducerState.editOpen}
          setIsOpen={isOpen => void dispatch(ModalActionKind.EDIT_OPEN, isOpen)}
          onAction={() => new Promise(() => void navigate(Routes.CAMPAIGNS.EDIT(campaign.id)))}
        />
      )}

      {reducerState.publishOpen && (
        <ModalAction
          title={t('modal.publishingChanges')}
          itemText={t('modal.doYouWantToPublishCampaign')}
          itemName={campaign.name}
          actionText={t('modal.publish')}
          isOpen={reducerState.publishOpen}
          setIsOpen={isOpen => void dispatch(ModalActionKind.PUBLISH_OPEN, isOpen)}
          isLoading={reducerState.publishLoading}
          setIsLoading={isLoading => void dispatch(ModalActionKind.PUBLISH_LOADING, isLoading)}
          onAction={() => publishCampaign(campaign.id)}
        />
      )}

      {reducerState.approveOpen && (
        <ModalAction
          title={t('modal.approveCampaign')}
          itemText={t('modal.doYouWantToApproveCampaign')}
          itemName={campaign.name}
          actionText={t('modal.activate')}
          isOpen={reducerState.approveOpen}
          setIsOpen={isOpen => void dispatch(ModalActionKind.APPROVE_OPEN, isOpen)}
          isLoading={reducerState.approveLoading}
          setIsLoading={isLoading => void dispatch(ModalActionKind.APPROVE_LOADING, isLoading)}
          onAction={() => acceptCampaign(campaign.id)}
        />
      )}

      {reducerState.rejectOpen && (
        <ModalAction
          title={t('modal.rejectCampaign')}
          itemText={t('modal.doYouWantToRejectCampaign')}
          itemName={campaign.name}
          actionText={t('modal.reject')}
          isOpen={reducerState.rejectOpen}
          setIsOpen={isOpen => void dispatch(ModalActionKind.REJECT_OPEN, isOpen)}
          isLoading={reducerState.rejectLoading}
          setIsLoading={isLoading => void dispatch(ModalActionKind.REJECT_LOADING, isLoading)}
          onAction={() => rejectCampaign(campaign.id)}
        />
      )}

      {reducerState.pauseOpen && (
        <ModalAction
          title={t('modal.pauseCampaign')}
          itemText={t('modal.doYouWantToPauseCampaign')}
          itemName={campaign.name}
          actionText={t('modal.pause')}
          isOpen={reducerState.pauseOpen}
          setIsOpen={isOpen => void dispatch(ModalActionKind.PAUSE_OPEN, isOpen)}
          isLoading={reducerState.pauseLoading}
          setIsLoading={isLoading => void dispatch(ModalActionKind.PAUSE_LOADING, isLoading)}
          onAction={() =>
            api.campaign.pauseCampaign(campaign.id).then(res => {
              if (res.data?.pauseCampaign.success) onShouldReload(true)
            })
          }
        />
      )}

      {reducerState.stopOpen && (
        <ModalAction
          title={t('modal.stoppingCampaign')}
          itemText={t('modal.doYouWantToStopCampaign')}
          itemName={campaign.name}
          descriptionText={t('modal.stopCampaignDescription')}
          actionText={t('common.stop')}
          isOpen={reducerState.stopOpen}
          setIsOpen={isOpen => void dispatch(ModalActionKind.STOP_OPEN, isOpen)}
          isLoading={reducerState.stopLoading}
          setIsLoading={isLoading => void dispatch(ModalActionKind.STOP_LOADING, isLoading)}
          onAction={() =>
            api.campaign.stopCampaign(campaign.id).then(res => {
              if (res.data?.stopCampaign.success) {
                onShouldReload(true)
                addNotification(NotifierType.SUCCESS, t('form.campaign.finish.success'))
              }
            })
          }
        />
      )}

      {reducerState.confirmReservationOpen && (
        <ModalAction
          title={t('modal.reservationConfirmation')}
          itemText={t('modal.doYouWantToConfirmReservation')}
          itemName={campaign.name}
          actionText={t('reservation.confirmReservation')}
          isOpen={reducerState.confirmReservationOpen}
          setIsOpen={isOpen => void dispatch(ModalActionKind.CONFIRM_RESERVATION_OPEN, isOpen)}
          isLoading={reducerState.confirmReservationLoading}
          setIsLoading={isLoading =>
            void dispatch(ModalActionKind.CONFIRM_RESERVATION_LOADING, isLoading)
          }
          onAction={() =>
            confirmCampaignReservation(campaign.id).then(() => void onShouldReload(true))
          }
        />
      )}

      {reducerState.cancelReservationOpen && (
        <ModalAction
          title={t('modal.reservationCancellation')}
          itemText={t('modal.doYouWantToCancelReservation')}
          itemName={campaign.name}
          actionText={t('reservation.cancelReservation')}
          isOpen={reducerState.cancelReservationOpen}
          setIsOpen={isOpen => void dispatch(ModalActionKind.CANCEL_RESERVATION_OPEN, isOpen)}
          isLoading={reducerState.cancelReservationLoading}
          setIsLoading={isLoading =>
            void dispatch(ModalActionKind.CANCEL_RESERVATION_LOADING, isLoading)
          }
          onAction={() =>
            cancelCampaignReservation(campaign.id).then(() => void onShouldReload(true))
          }
        />
      )}
    </>
  )
}

export default CampaignDetailsActionModals
