import Button, { ButtonSize } from 'components/Form/Button'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Summary from '../Summary'
import { DailyImpression, EstimatedEmissionsSummaryProps } from './types'
import service from './service'
import './EstimatedEmissionsSummary.scss'
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts'

const EstimatedEmissionsSummary: React.FC<EstimatedEmissionsSummaryProps> = ({
  campaignId,
  title,
  experimental,
}) => {
  const { t } = useTranslation()
  const [estimatedEmissions, setEstimatedEmissions] = useState<number | null>(null)
  const [dailyImpressions, setDailyImpressions] = useState<DailyImpression[]>([])
  const [estimatedEmissionsLoading, setEstimatedEmissionsLoading] = useState<boolean>(false)

  const handleClick = () => {
    void service.getCampaignEstimatedEmissions({
      campaignId,
      setEstimatedEmissions,
      setEstimatedEmissionsLoading,
      experimental,
      setDailyImpressions,
    })
  }

  const getChartTicks = (data: DailyImpression[]) => {
    // if the length is 0, return an empty array
    if (data.length === 0) {
      return []
    }

    // if the length is 1, return the single day
    if (data.length === 1) {
      return [data[0].day]
    }

    // if the length is 2, return the first and last day
    if (data.length === 2) {
      return [data[0].day, data[data.length - 1].day]
    }

    // if the length is 3 or more, return the first, middle, and last day
    const middleIndex = Math.floor(data.length / 2)
    return [data[0].day, data[middleIndex].day, data[data.length - 1].day]
  }

  return (
    <Summary
      title={t(`form.campaign.summary.${title}`)}
      quantity={estimatedEmissions}
      isLoading={estimatedEmissionsLoading}
    >
      {campaignId && (
        <Button
          className='EstimatedEmissionsSummary__button'
          size={ButtonSize.SMALL}
          onClick={handleClick}
        >
          {t('form.campaign.summary.recalculate')}
        </Button>
      )}

      {dailyImpressions.length > 0 && (
        <div className='EstimatedEmissionsSummary__chart'>
          <LineChart
            height={100}
            width={230}
            data={dailyImpressions}
            margin={{
              top: 5,
              right: 30,
              left: 30,
              bottom: 5,
            }}
          >
            <CartesianGrid
              strokeDasharray='2 2'
              horizontal={false}
            />
            <XAxis
              dataKey='day'
              fontSize={10}
              ticks={getChartTicks(dailyImpressions)}
            />
            <YAxis hide={true} />
            <Line
              dot={false}
              dataKey='totalImpressions'
              stroke='#8884d8'
            />
          </LineChart>
        </div>
      )}
    </Summary>
  )
}

export default EstimatedEmissionsSummary
