import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LinkProps } from './types'
import TsxUtils from 'utils/tsx'
import './Link.scss'

const Link: React.FC<LinkProps> = ({
  children,
  className,
  onClick,
  toUrl,
  toUrlExternal,
  disabled,
  onMouseEnter,
  onMouseLeave,
  dataCy,
}) => {
  const navigate = useNavigate()

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={
        'Link' +
        TsxUtils.extraStyle(className, className) +
        TsxUtils.extraStyle(disabled, 'Link--disabled')
      }
      data-cy={dataCy}
      onClick={(event): void => {
        if (!disabled) {
          onClick && onClick(event)

          if (toUrl) void navigate(toUrl)

          if (toUrlExternal) {
            const newWindow: Window | null = window.open(toUrlExternal, '_blank')
            if (newWindow) {
              newWindow.focus()
              newWindow.opener = null
            }
          }
        }
      }}
    >
      {children}
    </div>
  )
}

export default Link
