import dgClIcon from 'images/dg-cl.svg'
import dgCsIcon from 'images/dg-cs.svg'
import dgInIcon from 'images/dg-in.svg'
import dgInHIcon from 'images/dg-in-h.svg'
import dgInSIcon from 'images/dg-in-s.svg'
import { MediaFormat } from 'types/campaign'

export default class MediaFormatUtils {
  static getIcon = (format: MediaFormat): string => {
    switch (format) {
      case MediaFormat.DG_CL:
        return dgClIcon
      case MediaFormat.DG_CS:
        return dgCsIcon
      case MediaFormat.DG_IN:
        return dgInIcon
      case MediaFormat.DG_IN_H:
        return dgInHIcon
      case MediaFormat.DG_IN_S:
        return dgInSIcon
    }
  }
}
