import React, { useContext, useEffect, useState } from 'react'
import DayPicker, { DateUtils, DayPickerProps, RangeModifier } from 'react-day-picker'
import { DatePickerRangeProps, RangeModifierNullable, rangeModifierNullableDefault } from './types'
import { AppContext } from 'contexts/AppContext'
import service from './service'
import VariableUtils from 'utils/variable'
import DayPickerNavbar from '../DayPickerNavbar'

// TODO: Remove moment.js dependency
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/en-gb'
import 'moment/locale/pl'

import 'react-day-picker/lib/style.css'
import './DatePickerRange.scss'

const DatePickerRange: React.FC<DatePickerRangeProps> = ({
  disabledDays,
  values,
  onChange,
  readOnly = false,
  loading,
}) => {
  const { userData } = useContext(AppContext)
  const [tempRange, setTempRange] = useState<RangeModifierNullable>(rangeModifierNullableDefault)
  const [ranges, setRanges] = useState<RangeModifier[]>([])
  const [lastDayMouseEnter, setLastDayMouseEnter] = useState<RangeModifier['to'] | null>(null)

  useEffect(() => {
    if (!VariableUtils.isDeepEqual(values, ranges)) setRanges(values)
  }, [values])

  useEffect(() => {
    onChange(ranges)
  }, [ranges])

  useEffect(() => {
    if (!!tempRange.from && !!tempRange.to) {
      const { shouldIncrease, increasedRanges } = service.increaseSmallerRanges(tempRange, ranges)
      setRanges(shouldIncrease ? increasedRanges : [...ranges, tempRange as RangeModifier])
    }
  }, [tempRange])

  useEffect(() => {
    setTempRange(rangeModifierNullableDefault)
    setLastDayMouseEnter(null)
  }, [ranges])

  const handleDayClick: DayPickerProps['onDayClick'] = (day, modifiers) => {
    if (readOnly) return
    if (service.isDisabledDay(day, disabledDays)) return

    const { selected } = modifiers
    const isDayInHoverRange = DateUtils.isDayInRange(day, {
      from: tempRange.from,
      to: lastDayMouseEnter,
    } as RangeModifier)

    // Clicking logic:
    // 1. startDate    -> selected: undefined, isDayInHoverRange: null
    // 2. endDate      -> selected: true,      isDayInHoverRange: true
    // 3. remove range -> selected: true,      isDayInHoverRange: null
    if (!selected || isDayInHoverRange) {
      setTempRange(DateUtils.addDayToRange(day, tempRange as RangeModifier))
    } else {
      const filteredRanges = ranges.filter((r: RangeModifier) => !DateUtils.isDayInRange(day, r))
      setRanges(filteredRanges)
    }
  }

  const handleDayMouseEnter: DayPickerProps['onDayMouseEnter'] = day => {
    const { from, to } = tempRange
    if (!service.isSelectingFirstDay(from, to, day)) {
      setLastDayMouseEnter(day)
    }
  }

  const modifiers = {
    'already-in-range': (day: Date) =>
      ranges.some((r: RangeModifier) => DateUtils.isDayInRange(day, r)),
    'first-day-from-range': (day: Date) =>
      ranges.some((r: RangeModifier) => DateUtils.isSameDay(day, r.from!)),
    'last-day-from-range': (day: Date) =>
      ranges.some((r: RangeModifier) => DateUtils.isSameDay(day, r.to!)),
    disabled: (day: Date) => service.isDisabledDay(day, disabledDays),
    'read-only': () => readOnly,
  }

  return (
    <DayPicker
      className={loading ? 'DatePickerRange DatePickerRange-loading' : 'DatePickerRange'}
      localeUtils={MomentLocaleUtils}
      locale={userData.user?.preferredLocale as string}
      navbarElement={DayPickerNavbar}
      firstDayOfWeek={1}
      numberOfMonths={2}
      selectedDays={[{ from: tempRange.from, to: lastDayMouseEnter } as RangeModifier, ...ranges]}
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayMouseEnter}
      modifiers={modifiers}
    />
  )
}

export default DatePickerRange
