import React, { ReactElement } from 'react'
import TsxUtils from 'utils/tsx'
import TooltipHelp from 'components/TooltipHelp'
import './InputTitle.scss'
import { CypressSelectable } from '../../../types'

interface InputTitleProps extends CypressSelectable {
  className?: string
  title: string
  help?: string | ReactElement
}

const InputTitle: React.FC<InputTitleProps> = ({ className, title, help, dataCy }) => (
  <div
    className={'InputTitle' + TsxUtils.extraStyle(className, className)}
    data-cy={dataCy}
  >
    {title}
    {help && <TooltipHelp containerClassName='InputTitle__tooltip'>{help}</TooltipHelp>}
  </div>
)

export default InputTitle
