import { useState } from 'react'
import { Routes } from 'routes'
import { Use404Props } from './types'
import { NavigateFunction } from 'react-router-dom'

const use404 = (): Use404Props => {
  const [is404Present, setIs404Present] = useState<boolean>(false)

  const force404 = (): void => {
    setIs404Present(true)
  }

  const redirectTo404 = (navigate: NavigateFunction): void => {
    if (is404Present) {
      navigate(Routes.NOT_FOUND)
    }
  }

  const clear404 = (): void => {
    setIs404Present(false)
  }

  return { force404, is404Present, redirectTo404, clear404 }
}

export default use404
