import React from 'react'
import './ImageCarousel.scss'
import ImageGallery from 'react-image-gallery'
import ImageCarouselItem from 'components/ImageCarouselItem'
import ImageCarouselThumbItem from 'components/ImageCarouselThumbItem'
import { ImageCarouselItem as ImageCarouselItemType } from 'components/ImageCarouselItem/types'

interface ImageCarouselProps {
  imageUrls: ImageCarouselItemType[]
  handleImageLoaded?: ((event: unknown, original: unknown) => void) | undefined
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ imageUrls }) => {
  return (
    <ImageGallery
      items={imageUrls}
      showPlayButton={false}
      showNav={false}
      showFullscreenButton={false}
      renderItem={item => {
        return <ImageCarouselItem {...item} />
      }}
      renderThumbInner={item => <ImageCarouselThumbItem {...item} />}
    />
  )
}

export default ImageCarousel
