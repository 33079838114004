import React from 'react'
import { NavLink } from 'react-router-dom'
import './NavbarSubItem.scss'
import { CypressSelectable } from '../../../../types'

interface NavbarSubItemProps extends CypressSelectable {
  to: string
  children?: string | React.ReactNode
  title?: string
}

const NavSubItem: React.FC<NavbarSubItemProps> = ({ to, children, title, dataCy }) => (
  <NavLink
    to={to}
    data-cy={dataCy}
    className={({ isActive }) =>
      'NavbarSubItem__container' + (isActive ? ' NavbarSubItem__container--active' : '')
    }
    title={title}
  >
    <div className='NavbarSubItem__marker' />
    <div className='NavbarSubItem'>
      <div className='NavbarSubItem__text'>{children}</div>
    </div>
  </NavLink>
)

export default NavSubItem
