import { Errors } from 'components/Form/InputError'

export interface DetailsFormErrors {
  budget: Errors
  audience: Errors
  emissions: Errors
  hiddenImpressions: Errors
  hiddenAudience: Errors
}

export const detailsFormErrorsEmpty = {
  budget: [],
  audience: [],
  emissions: [],
  hiddenImpressions: [],
  hiddenAudience: [],
}
