import React, { useEffect, useState } from 'react'
import { MediaListTable } from 'pages/MediaList'
import { ITEMS_PER_PAGE } from 'constant'
import { CampaignDetailsMediaListProps, MediaPage } from './types'
import Button, { ButtonSize } from '../../../../components/Form/Button'
import './CampaignDetailsMediaList.scss'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ReactRouterParams } from '../../../../types/various'
import { PublicViewProps } from '../types'
import CampaignLocationsFileService from '../services/campaign-locations-file.service'
import FillingSpinner from '../../../../components/FillingSpinner'

const CampaignDetailsMediaList: React.FC<CampaignDetailsMediaListProps> = ({
  media,
  mediumFormat,
  publicView,
}) => {
  const { t } = useTranslation()
  const { id: urlId, uuid: urlUuid } = useParams<ReactRouterParams>()
  const [mediaPagination, setMediaPagination] = useState<MediaPage>(defaultPagination)
  const [loading, setLoading] = useState<boolean>(false)

  const publicViewProps: PublicViewProps = {
    campaignUuid: urlUuid,
    publicView,
  }

  const setActivePage = (activePage: number) => {
    setMediaPagination({
      mediumSlice: media.slice(ITEMS_PER_PAGE * (activePage - 1), ITEMS_PER_PAGE * activePage),
      pageNumber: activePage,
      totalMediumCount: media.length,
    })
  }

  const handleClick = async () => {
    setLoading(true)
    await CampaignLocationsFileService.fetchLocationsFile(urlId, publicViewProps)
    setLoading(false)
  }

  useEffect((): void => setActivePage(1), [media])

  return (
    <div className='MediaList CampaignDetails__media-list'>
      <MediaListTable
        mediumFormat={mediumFormat}
        media={mediaPagination.mediumSlice}
        pagination={{
          activePage: mediaPagination.pageNumber,
          totalItemsCount: mediaPagination.totalMediumCount,
          setPage: setActivePage,
        }}
      />

      <div className={'CampaignDetailsMediaList__report'}>
        <Button
          size={ButtonSize.SMALL}
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? (
            <FillingSpinner className={'CampaignDetailsMediaList__spinner'} />
          ) : (
            t('campaignDetails.locationsReport')
          )}
        </Button>
      </div>
    </div>
  )
}

const defaultPagination = {
  pageNumber: 1,
  totalMediumCount: 0,
  mediumSlice: [],
}

export default CampaignDetailsMediaList
