import { client } from 'components/ApolloProvider'
import { FETCH_DAILY_SUMMARY_FILTERS } from './fetchDailySummaryFilters'
import { FetchResult } from '@apollo/client'
import { DailySummaryFiltersModel } from './models/daily-summary-filters.model'
import { FETCH_DAILY_SUMMARY_LISTING } from './fetchDailySummaryListing'
import { DailySummaryListingModel } from './models/daily-summary-listing.model'
import { FETCH_DAILY_SUMMARY } from './fetchDailySummary'
import { DailySummaryResponseModel } from './models/daily-summary-response.model'
import { ITEMS_PER_PAGE } from '../../constant'

const emissionPlanApi = {
  fetchDailySummaryFilters: (
    campaignUuid: string
  ): Promise<
    FetchResult<{
      dailySummaryFilters: DailySummaryFiltersModel
    }>
  > => {
    return client.query({
      query: FETCH_DAILY_SUMMARY_FILTERS,
      variables: {
        campaignUuid,
      },
    })
  },

  fetchDailySummaryListing: (
    campaignUuid: string,
    agglomerations: string[],
    cities: string[],
    buildings: string[],
    creations: string[],
    mediaAsIds: string[],
    first: number
  ): Promise<
    FetchResult<{
      dailySummaryListing: DailySummaryListingModel
    }>
  > => {
    return client.query({
      query: FETCH_DAILY_SUMMARY_LISTING,
      variables: {
        campaignUuid,
        agglomerations,
        cities,
        buildings,
        creations,
        mediaAsIds,
        first: first,
        last: ITEMS_PER_PAGE,
      },
    })
  },

  /**
   * Schedule is a string that represents the schedule id
   *
   * @param campaignUuid
   * @param schedule
   */
  fetchDailySummary: (
    campaignUuid: string,
    schedule: string
  ): Promise<FetchResult<{ dailySummary: DailySummaryResponseModel }>> => {
    return client.query({
      query: FETCH_DAILY_SUMMARY,
      variables: {
        campaignUuid,
        schedule,
      },
    })
  },
}

export default emissionPlanApi
