import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export interface IconBoxProps {
  className?: string
  icon: IconDefinition
  theme?: IconBoxTheme
}

export enum IconBoxTheme {
  GREEN = 'green',
  GREEN_DARK = 'green-dark',
  PURPLE = 'purple',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  RED = 'red',
  BLUE = 'blue',
}
