import { client } from 'components/ApolloProvider'
import {
  CreateRoleFR,
  GetNewRolePermissionsAQR,
  GetRoleAQR,
  RemoveRoleFR,
  UpdateRoleFR,
} from './types'
import { GET_NEW_ROLE_PERMISSIONS } from './getPermissionsForNewRole'
import { GetRolesAQR } from '../user/types'
import { GET_ROLES } from './getRoles'
import { GET_ROLE } from './getRole'
import { loader } from 'graphql.macro'
import { RoleModel } from '../../pages/Roles/models/role-form-slice.model'
import { REMOVE_ROLE } from './removeRole'

const rolesApi = {
  getNewRolePermissions: (): Promise<GetNewRolePermissionsAQR> =>
    client.query({
      query: GET_NEW_ROLE_PERMISSIONS,
    }),
  getRole: (id: string): Promise<GetRoleAQR> =>
    client.query({
      query: GET_ROLE,
      variables: { id },
    }),
  getRoles: (queryVariables: { first?: number; last?: number }): Promise<GetRolesAQR> =>
    client.query({
      query: GET_ROLES,
      variables: {
        first: queryVariables.first ?? null,
        last: queryVariables.last ?? null,
      },
    }),
  createRole: (roleModel: RoleModel): Promise<CreateRoleFR> => {
    return client.mutate({
      mutation: loader('./createRole.gql'),
      variables: {
        name: roleModel.name,
        baseRole: roleModel.baseRole,
        permissions: roleModel.permissionElementRoles.map(permissionElement => {
          return {
            permissionElementId: permissionElement.id,
            permissionElement: permissionElement.name,
            permissions: permissionElement.permissions,
          }
        }),
      },
    })
  },
  updateRole: (roleModel: RoleModel): Promise<UpdateRoleFR> => {
    return client.mutate({
      mutation: loader('./updateRole.gql'),
      variables: {
        roleId: roleModel.id,
        name: roleModel.name,
        baseRole: roleModel.baseRole,
        permissions: roleModel.permissionElementRoles.map(permissionElement => {
          return {
            permissionElementId: permissionElement.id,
            permissions: permissionElement.permissions,
          }
        }),
      },
    })
  },
  removeRole: (variables: { id: string }): Promise<RemoveRoleFR> =>
    client.mutate({
      mutation: REMOVE_ROLE,
      variables,
    }),
}

export default rolesApi
