import api from 'api'
import { Campaign, CampaignDetails, CampaignTarget } from 'types/campaign'
import EnumUtils from 'utils/enum'
import VariableUtils from 'utils/variable'
import i18n from 'i18n'
import { CampaignDetailsQueryData } from 'api/campaign/types'
import { store } from '../../../../store'
import { campaignActions } from '../../store/campaign-slice'

export default class CampaignDetailsRealizationService {
  static getData = (
    id: Campaign['id'],
    uuid: Campaign['uuid'],
    publicView: boolean | undefined
  ): void => {
    if (publicView) {
      api.campaign
        .getCampaignDetailsPublic(uuid)
        .then(
          res =>
            void CampaignDetailsRealizationService.prepareData(
              res.data.campaignReportPublic.campaignDetails
            )
        )
        .catch(() => void {})
    } else {
      api.campaign
        .getCampaignDetails(id)
        .then(res => void CampaignDetailsRealizationService.prepareData(res.data.campaignDetails))
        .catch(() => void {})
    }
  }

  static prepareData = (data: CampaignDetailsQueryData): void => {
    store.dispatch(
      campaignActions.setCampaignDetails({
        ...data,
        everUsedMedia: data.everUsedMedia.nodes,
        target:
          CampaignTarget[
            EnumUtils.getKeyByValue(CampaignTarget, data.target) as keyof typeof CampaignTarget
          ],
        // TODO: Remove both after API clean-up (https://alterdataio.atlassian.net/browse/AMS-1115)
        currentEmissions: (data as any).currentImpressions,
        totalEmissions: (data as any).totalImpressions,
      })
    )
  }

  static parseTargetValue = (
    totalBudget: CampaignDetails['totalBudget'],
    totalEmissions: CampaignDetails['totalEmissions'],
    budgetCurrency: Campaign['budgetCurrency'],
    totalAudience: CampaignDetails['totalAudience']
  ): { budget: string; emissions: string; audience: string } => {
    const budget = `${i18n.t('common.budget').toLocaleLowerCase()} ${VariableUtils.formatNumber(
      totalBudget
    )} ${budgetCurrency.toUpperCase()}`

    const emissions = `${VariableUtils.formatNumber(totalEmissions)} ${i18n.t(
      'common.emissions.a'
    )}`

    const audience = `${VariableUtils.formatNumber(totalAudience)} ${i18n.t('common.audience.a')}`

    return { budget, emissions, audience }
  }
}
