import { gql } from '@apollo/client'
import { MUTATION_STATE } from '../fragments'

const PAYLOAD_TYPE = 'DeactivateUserPayload'

export const DEACTIVATE_USER = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation deactivateUser(
    $id: ID!
  ) {
    deactivateUser(
      input: {
        id: $id
      }
    ) {
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
