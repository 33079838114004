import { Medium, MediumPlaceDetails } from 'types/campaign'
import { GroupedMedia, GroupData, MediumGroup, MediaGroupArgs, MediaGroup } from './types'

/**
    Medium can have fields filled (not null) in the following combinations:
    1. agglomeration, city, building
    2. agglomeration, city
    3. city, building
    4. city

    We arrange them as follows:
    1. A > C > B > M
    2. A > C > M
    3. C > B > M
    4. C > M

    Where:
    - A - Agglomeration
    - C - City
    - B - Building (Mall)
    - M - Medium
  */
export const groupMediaForCheckboxes = (media: Medium[]): GroupedMedia =>
  media.reduce((acc: GroupedMedia, curr: Medium): GroupedMedia => {
    const { agglomeration, city, building, id } = curr

    const aID: string | null = agglomeration?.id ?? null
    const cID: string = city.id
    const bID: string | null = building?.id ?? null
    const mID: string = id

    const aGroup: GroupData = prepareGroupData(MediumGroup.AGGLOMERATION, curr.agglomeration!)
    const cGroup: GroupData = prepareGroupData(MediumGroup.CITY, curr.city)
    const bGroup: GroupData = prepareGroupData(MediumGroup.BUILDING, curr.building!)

    const args: MediaGroupArgs = {
      aID,
      cID,
      bID,
      mID,
      aGroup,
      cGroup,
      bGroup,
      acc,
      curr: { ...curr, hidden: false },
    }
    const groupedACB: MediaGroup = groupCaseACB(args)
    const groupedAC: MediaGroup = groupCaseAC(args)
    const groupedCB: MediaGroup = groupCaseCB(args)
    const groupedC: MediaGroup = groupCaseC(args)

    return {
      ...acc,
      ...groupedACB,
      ...groupedAC,
      ...groupedCB,
      ...groupedC,
    }
  }, {})

const prepareGroupData = (group: MediumGroup, mpd: MediumPlaceDetails): GroupData => ({
  group: {
    group,
    ...mpd,
    used: false,
    hidden: false,
    folded: true,
  },
})

const groupCaseACB = (args: MediaGroupArgs): MediaGroup => {
  const { aID, cID, bID, mID, acc, curr, aGroup, cGroup, bGroup } = args
  const caseACB = !!aID && !!cID && !!bID

  if (caseACB) {
    return {
      [aID]: {
        ...acc[aID],
        ...aGroup,

        [cID]: {
          ...(acc[aID] ? acc[aID][cID] : {}), // try to use existing object or create new
          ...cGroup,

          [bID]: {
            ...(acc[aID] ? (acc[aID][cID] ? acc[aID][cID][bID] : {}) : {}),
            ...bGroup,

            [mID]: {
              ...curr,
            },
          },
        },
      },
    }
  } else return {}
}

const groupCaseAC = (args: MediaGroupArgs): MediaGroup => {
  const { aID, cID, bID, mID, acc, curr, aGroup, cGroup } = args
  const caseAC = !!aID && !!cID && !bID

  if (caseAC) {
    return {
      [aID]: {
        ...acc[aID],
        ...aGroup,

        [cID]: {
          ...(acc[aID] ? acc[aID][cID] : {}),
          ...cGroup,

          [mID]: {
            ...curr,
          },
        },
      },
    }
  } else return {}
}

const groupCaseCB = (args: MediaGroupArgs): MediaGroup => {
  const { aID, cID, bID, mID, acc, curr, cGroup, bGroup } = args
  const caseCB = !aID && !!cID && !!bID

  if (caseCB) {
    return {
      [cID]: {
        ...acc[cID],
        ...cGroup,

        [bID]: {
          ...(acc[cID] ? acc[cID][bID] : {}),
          ...bGroup,

          [mID]: {
            ...curr,
          },
        },
      },
    }
  } else return {}
}

const groupCaseC = (args: MediaGroupArgs): MediaGroup => {
  const { aID, cID, bID, mID, acc, curr, cGroup } = args
  const caseC = !aID && !!cID && !bID

  if (caseC) {
    return {
      [cID]: {
        ...acc[cID],
        ...cGroup,

        [mID]: {
          ...curr,
        },
      },
    }
  } else return {}
}
