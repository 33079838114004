import React, { createContext } from 'react'
import { TableContextProps, tableContextPropsDefault, TableContextProviderProps } from './types'

export const TableContext = createContext<TableContextProps>(tableContextPropsDefault)

const TableContextProvider: React.FC<TableContextProviderProps> = ({ children, sort, onSort }) => (
  <TableContext.Provider
    value={{
      sort,
      onSort,
    }}
  >
    {children}
  </TableContext.Provider>
)

export default TableContextProvider
