import { gql } from '@apollo/client'

export default gql`
  mutation RemoveMediaTemplate($input: RemoveMediaTemplateInput!) {
    removeMediaTemplate(input: $input) {
      success
      errors
    }
  }
`
