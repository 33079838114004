import api from 'api'
import { DateRange } from 'components/Form/DatePickerRange'
import DateUtils from 'utils/date'
import { Creation, CreationSchedule } from 'types/campaign'
import VariableUtils from 'utils/variable'
import { DATE_FORMAT } from 'constant'

export default class CreationsFormScheduleService {
  static parseSchedule = (creationSchedule: CreationSchedule): string => {
    const from = DateUtils.parseAndFormat(creationSchedule.startDate, DATE_FORMAT)
    const to = DateUtils.parseAndFormat(creationSchedule.endDate, DATE_FORMAT)
    const isSingleDay = from === to

    return `${from}${isSingleDay ? '' : ' - ' + to}`
  }

  static dateRangesToCreationSchedules = (dateRanges: DateRange[]): CreationSchedule[] =>
    dateRanges.map((dr: DateRange) => ({ startDate: dr.from!, endDate: dr.to! }))

  static creationSchedulesToDateRanges = (creationSchedules: CreationSchedule[]): DateRange[] =>
    creationSchedules.map((cs: CreationSchedule) => ({ from: cs.startDate, to: cs.endDate }))

  static fetchCreationSchedules = ({
    creationId,
    creationUuid,
    setCreationSchedules,
  }: {
    creationId?: Creation['id']
    creationUuid?: Creation['uuid']
    setCreationSchedules: React.Dispatch<React.SetStateAction<CreationSchedule[] | undefined>>
  }): void => {
    const parseAndSetCreationSchedules = (cs: CreationSchedule[]): void =>
      void setCreationSchedules(
        cs.map(cs => ({
          startDate: new Date(cs.startDate),
          endDate: new Date(cs.endDate),
        }))
      )

    if (creationUuid) {
      api.creation
        .getCreationSchedulesPublic(creationUuid)
        .then(res => void parseAndSetCreationSchedules(res.data.creationSchedulesPublic.nodes))
        .catch(() => void {})
    } else if (creationId) {
      api.creation
        .getCreationSchedules(creationId)
        .then(res => void parseAndSetCreationSchedules(res.data.creationSchedules.nodes))
        .catch(() => void {})
    }
  }

  static handleChangeCreationSchedules = (
    dateRanges: DateRange[],
    creationId: Creation['id'],
    creationSchedules: CreationSchedule[],
    setCreationSchedules: React.Dispatch<React.SetStateAction<CreationSchedule[] | undefined>>,
    creationSchedulesUpdateCounter: number,
    setLoadingSchedules: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    const creationSchedulesFromDatePicker =
      CreationsFormScheduleService.dateRangesToCreationSchedules(dateRanges)

    // `creationSchedulesUpdateCounter` is workaround for not sending first
    // `creationSchedules` update which equals `[]`. Any additional setState
    // function like `isLoading` etc. inside `service.updateCreationSchedules`
    // causes unwanted component updates.
    const creationSchedulesHaveChanged =
      !VariableUtils.isDeepEqual(creationSchedules, creationSchedulesFromDatePicker) &&
      creationSchedulesUpdateCounter > 0

    if (creationSchedulesHaveChanged) {
      CreationsFormScheduleService.updateCreationSchedules(
        creationId,
        creationSchedulesFromDatePicker,
        setCreationSchedules,
        setLoadingSchedules
      )
    }
  }

  static updateCreationSchedules = (
    creationId: Creation['id'],
    creationSchedules: CreationSchedule[],
    setCreationSchedules: React.Dispatch<React.SetStateAction<CreationSchedule[] | undefined>>,
    setLoadingSchedules: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<void> => {
    setLoadingSchedules(true)
    return api.creation
      .updateCreationSchedule(creationId, creationSchedules)
      .then(res => {
        const success = res.data?.updateCreationSchedule.success
        if (success) {
          setCreationSchedules(creationSchedules)
          setLoadingSchedules(false)
        }
      })
      .catch(() => void {})
  }
}
