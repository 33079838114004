import { ApolloLink } from '@apollo/client'
import Cookies from 'js-cookie'

const authLink = new ApolloLink((operation, forward) => {
  const at = Cookies.get('at')

  if (at)
    operation.setContext(({ headers }: { headers: Headers }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${at}`,
      },
    }))

  return forward(operation)
})

export default authLink
