import React, { useEffect, useState } from 'react'
import { MediumTemplate } from 'api/media/types'
import FillingSpinner, { FillingSpinnerType } from 'components/FillingSpinner'
import { NodeSelectOption } from 'components/Form/Select/Select'
import TemplateLabel from './TemplateLabel'
import FormRow from 'components/Form/FormRow'
import Select from 'components/Form/Select'
import { t } from 'i18next'
import TemplateDeleteModal from './TemplateDeleteModal'
import { UseMediaSelection } from 'hooks/useMediaSelection/types'
import './TemplateSelection.scss'

type Props = Pick<
  UseMediaSelection,
  'selectMedia' | 'mediumTemplates' | 'refetchMediumTemplates'
> & { templateSelectionRef: React.MutableRefObject<() => void>; templateLoader: boolean }

const TemplateSelection: React.FC<Props> = ({
  mediumTemplates,
  selectMedia,
  refetchMediumTemplates,
  templateSelectionRef,
  templateLoader,
}) => {
  useEffect(() => {
    templateSelectionRef.current = resetSelected
  }, [])

  const [removingTemplate, setRemovingTemplate] = useState<MediumTemplate>()

  const [selected, setSelected] = useState<string | null>(null)
  if (!mediumTemplates) return <FillingSpinner />

  const onChange = (templateId: string): void => {
    setSelected(templateId)
    selectMedia(mediumTemplates.find(t => t.id === templateId)!.media.map(m => m.id))
  }

  const resetSelected = () => {
    setSelected(null)
  }

  const options: NodeSelectOption[] | undefined = mediumTemplates?.map(template => ({
    value: template.id,
    label: (
      <TemplateLabel
        onClick={() => setRemovingTemplate(template)}
        template={template}
      />
    ),
  }))

  const close = async () => {
    await refetchMediumTemplates()
    setRemovingTemplate(undefined)
    setSelected(null)
  }

  return (
    <FormRow className={'FormRow--template-selection'}>
      <Select
        id='template-media'
        className='MediaSelection__templates'
        title={t('mediaSelection.template.title')}
        onChange={onChange}
        options={options || []}
        value={selected}
      />
      {removingTemplate && (
        <TemplateDeleteModal
          template={removingTemplate}
          close={close}
        />
      )}
      {templateLoader && <FillingSpinner type={FillingSpinnerType.STANDARD} />}
    </FormRow>
  )
}

export default TemplateSelection
