import React, { ChangeEvent, CSSProperties, FC, ReactNode } from 'react'
import TsxUtils from 'utils/tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import './Checkbox.scss'
import { CypressSelectable } from '../../../types'

interface CheckboxProps extends CypressSelectable {
  id?: string
  className?: string
  style?: CSSProperties
  checked: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  children?: ReactNode
  readOnly?: boolean
  disabled?: boolean
}

const Checkbox: FC<CheckboxProps> = ({
  id,
  className,
  style,
  onChange,
  checked,
  children,
  readOnly,
  disabled,
  dataCy,
}) => (
  <div
    className={'Checkbox' + TsxUtils.extraStyle(className, className)}
    style={style}
  >
    <label
      htmlFor={id}
      className={
        'Checkbox__label' + TsxUtils.extraStyle(!readOnly && !disabled, 'Checkbox__label--editable')
      }
    >
      <input
        className='Checkbox__input'
        id={id}
        type='checkbox'
        checked={checked}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (!readOnly && !disabled && onChange) {
            onChange(e)
          }
        }}
        readOnly={!onChange || readOnly}
        disabled={disabled}
      />

      <span
        data-cy={dataCy}
        className={
          `Checkbox__icon-container` +
          TsxUtils.extraStyle(checked, 'Checkbox__icon-container--checked') +
          TsxUtils.extraStyle(readOnly, 'Checkbox__icon-container--read-only') +
          TsxUtils.extraStyle(disabled, 'Checkbox__icon-container--disabled')
        }
      >
        {checked && (
          <FontAwesomeIcon
            icon={faCheck}
            className={
              'Checkbox__icon' +
              TsxUtils.extraStyle(readOnly, 'Checkbox__icon--read-only') +
              TsxUtils.extraStyle(disabled, 'Checkbox__icon--disabled')
            }
          />
        )}
      </span>

      <span
        className={
          'Checkbox__title' +
          TsxUtils.extraStyle(readOnly, 'Checkbox__title--read-only') +
          TsxUtils.extraStyle(disabled, 'Checkbox__title--disabled')
        }
      >
        {children}
      </span>
    </label>
  </div>
)

export default Checkbox
