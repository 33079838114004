import { MediaFormat } from 'types/campaign'

export interface CreationsFormUploadProps {
  mediaFormat: MediaFormat
  onFinish: () => void
}

export interface DropZoneOptionsByMediaFormat {
  dgCl: DropZoneOptions
  dgIn: DropZoneOptions
  dgInH: DropZoneOptions
  dgInS: DropZoneOptions
  dgCs: DropZoneOptions
}

export interface DropZoneSize {
  name: string
  width: number
  height: number
}

export interface DropZoneOptions {
  sizes: DropZoneSize[]
  maxMovieLength?: string
  icon: string
  acceptedFormats: string[]
}

export const basicDropZoneOptions: DropZoneOptionsByMediaFormat = {
  dgCl: {
    sizes: [{ name: 'Main', width: 1080, height: 1595 }],
    icon: 'dg-cl',
    acceptedFormats: ['jpeg', 'jpg', 'png'],
  },
  dgIn: {
    sizes: [{ name: 'Full screen', width: 1080, height: 1920 }],
    maxMovieLength: '10',
    icon: 'dg-in',
    acceptedFormats: ['jpeg', 'jpg', 'png', 'mp4'],
  },
  dgInH: {
    sizes: [{ name: 'Main', width: 1920, height: 902 }],
    maxMovieLength: '10',
    icon: 'dg-in-h',
    acceptedFormats: ['jpeg', 'jpg', 'png', 'mp4'],
  },
  dgInS: {
    sizes: [{ name: 'Main', width: 1920, height: 902 }],
    maxMovieLength: '10',
    icon: 'dg-in-s',
    acceptedFormats: ['jpeg', 'jpg', 'png', 'mp4'],
  },
  dgCs: {
    sizes: [
      { name: 'Main', width: 700, height: 500 },
      { name: '16:9', width: 586, height: 330 },
      { name: '2:1', width: 600, height: 300 },
      { name: '51:17', width: 1040, height: 340 },
    ],
    icon: 'dg-cs',
    acceptedFormats: ['jpeg', 'jpg', 'png', 'mp4'],
  },
}

export const extendedDropZoneOptions: DropZoneOptionsByMediaFormat = {
  dgCl: {
    ...basicDropZoneOptions.dgCl,
    acceptedFormats: [...basicDropZoneOptions.dgCl.acceptedFormats, 'zip'],
  },
  dgIn: {
    ...basicDropZoneOptions.dgIn,
    acceptedFormats: [...basicDropZoneOptions.dgIn.acceptedFormats, 'zip'],
  },
  dgInH: {
    ...basicDropZoneOptions.dgInH,
    acceptedFormats: [...basicDropZoneOptions.dgInH.acceptedFormats, 'zip'],
  },
  dgInS: {
    ...basicDropZoneOptions.dgInS,
    acceptedFormats: [...basicDropZoneOptions.dgInS.acceptedFormats, 'zip'],
  },
  dgCs: {
    ...basicDropZoneOptions.dgCs,
    acceptedFormats: [...basicDropZoneOptions.dgCs.acceptedFormats, 'zip'],
  },
}
