import { gql } from '@apollo/client'

export const GET_ROLES = gql`
  query rolesList($first: Int, $last: Int) {
    rolesList(first: $first, last: $last) {
      totalCount
      nodes {
        name
        id
      }
    }
  }
`
