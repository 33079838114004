import { client } from 'components/ApolloProvider'
import { Campaign, Creation, MediaFormat } from 'types/campaign'
import { GET_CREATION_MEDIA, GET_CREATION_MEDIA_PUBLIC } from './getCreationMedia'
import { GET_FILTERED_MEDIA, GET_FILTERED_MEDIA_PUBLIC } from './getFilteredMedia'
import { GET_ALL_MEDIA } from './getAll'
import {
  GetAllMediaAQR,
  GetCreationMediaAQR,
  GetCreationMediaPublicAQR,
  GetFilteredMediaAQR,
  GetFilteredMediaAQRProps,
  GetFilteredMediaPublicAQR,
  GetFilteredMediaPublicAQRProps,
  GetMediaUsedInCampaignAQR,
  GetMediaUsedInCampaignPublicAQR,
} from './types'
import { GET_MEDIA_USED_IN_CAMPAIGN, GET_MEDIA_USED_IN_CAMPAIGN_PUBLIC } from './getUsedInCampaign'

const mediaApi = {
  getFilteredMedia: ({
    campaignId,
    first,
    last,
    editCampaign,
  }: GetFilteredMediaAQRProps): Promise<GetFilteredMediaAQR> =>
    client.query({
      query: GET_FILTERED_MEDIA,
      variables: {
        campaignId: campaignId ?? null,
        first: first ?? null,
        last: last ?? null,
        editCampaign: editCampaign ?? false,
      },
    }),

  getFilteredMediaPublic: ({
    campaignUuid,
    first,
    last,
  }: GetFilteredMediaPublicAQRProps): Promise<GetFilteredMediaPublicAQR> =>
    client.query({
      query: GET_FILTERED_MEDIA_PUBLIC,
      variables: {
        campaignUuid,
        first: first ?? null,
        last: last ?? null,
      },
    }),

  getCreationMedia: (variables: { creationId: Creation['id'] }): Promise<GetCreationMediaAQR> =>
    client.query({
      query: GET_CREATION_MEDIA,
      variables,
    }),

  getCreationMediaPublic: (variables: {
    creationUuid: Creation['uuid']
  }): Promise<GetCreationMediaPublicAQR> =>
    client.query({
      query: GET_CREATION_MEDIA_PUBLIC,
      variables,
    }),

  getAll: (variables?: {
    mediumFormat: keyof MediaFormat
    campaignId?: Campaign['id']
  }): Promise<GetAllMediaAQR> => {
    return client.query({
      query: GET_ALL_MEDIA,
      variables,
    })
  },

  getUsedInCampaign: (variables: {
    campaignId: Campaign['id']
  }): Promise<GetMediaUsedInCampaignAQR> =>
    client.query({
      query: GET_MEDIA_USED_IN_CAMPAIGN,
      variables,
    }),

  getUsedInCampaignPublic: (variables: {
    campaignUuid: Campaign['uuid']
  }): Promise<GetMediaUsedInCampaignPublicAQR> =>
    client.query({
      query: GET_MEDIA_USED_IN_CAMPAIGN_PUBLIC,
      variables,
    }),
}

export default mediaApi
