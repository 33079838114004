import Rollbar from 'rollbar'
import { AppMode } from 'utils/env'

let rollbar: Rollbar

const initRollbar = (): void => {
  const customEnv = process.env.REACT_APP_CUSTOM_NODE_ENV
  const enabled = customEnv === (AppMode.DEV || AppMode.STAGING || AppMode.PRODUCTION)

  rollbar = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: customEnv,
      client: {
        source_map_enabled: true,
        code_version: process.env.REACT_APP_GIT_SHA,
        guess_uncaught_frames: true,
      },
    },
    enabled,
  })
}

export { rollbar as default, initRollbar }
