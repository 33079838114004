import React, { CSSProperties } from 'react'
import TsxUtils from 'utils/tsx'
import './ProgressBarIndicator.scss'

interface ProgressBarIndicatorProps {
  className?: string
  computedStyle?: CSSProperties
}

const ProgressBarIndicator: React.FC<ProgressBarIndicatorProps> = ({
  className,
  computedStyle,
}) => (
  <div
    className={'ProgressBarIndicator' + TsxUtils.extraStyle(className, className)}
    style={computedStyle}
  >
    <div className='ProgressBarIndicator--background' />
    <div className='ProgressBarIndicator--circle' />
  </div>
)

export default ProgressBarIndicator
