import { gql } from '@apollo/client'
import { MEDIUM_FIELDS } from '../fragments'

export const GET_CREATION_MEDIA = gql`
  ${MEDIUM_FIELDS}
  query creationMedia($creationId: ID!) {
    creationMedia(creationId: $creationId) {
      nodes {
        ...MediumFields
      }
      totalCount
    }
  }
`

export const GET_CREATION_MEDIA_PUBLIC = gql`
  ${MEDIUM_FIELDS}
  query creationMediaPublic($creationUuid: String!) {
    creationMediaPublic(creationUuid: $creationUuid) {
      nodes {
        ...MediumFields
      }
      totalCount
    }
  }
`
