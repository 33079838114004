import api from 'api'
import { store } from 'store'
import { userTableActions } from '../store/user-table-slice'

export default class UserTableFiltersService {
  static fetchFilters = (): void => {
    store.dispatch(userTableActions.setFiltersLoading(true))

    api.user
      .fetchUserFilters()
      .then(response => {
        return response.data.userFilters
      })
      .then(filters => {
        store.dispatch(userTableActions.setAllData(filters))
      })
      .catch(
        () =>
          void store.dispatch(
            userTableActions.setAllData({ agencies: [], roles: [], statuses: [] })
          )
      )
      .finally(() => {
        void store.dispatch(userTableActions.setFiltersLoading(false))
      })
  }
}
