import { store } from '../../../store'
import { emissionPlanActions } from '../store/emission-plan-slice'
import api from '../../../api'
import { DailySummaryModel } from '../models/daily-summary.model'
import { DailySummaryNode } from '../../../api/emissionPlan/models/daily-summary-listing.model'

export default class EmissionPlanService {
  static fetchDailySummaryFilters = (campaignUuid: string) => {
    return Promise.resolve(store.dispatch(emissionPlanActions.setFiltersLoading(true)))
      .then(() => {
        return api.emissionPlan.fetchDailySummaryFilters(campaignUuid)
      })
      .then(({ data }) => {
        if (!data) {
          throw new Error('No data')
        }

        void store.dispatch(emissionPlanActions.setFiltersLoaded(true))

        return store.dispatch(emissionPlanActions.setAvailableFilters(data.dailySummaryFilters))
      })
      .catch(() => {
        void store.dispatch(emissionPlanActions.setFiltersLoaded(false))
      })
      .finally(() => void store.dispatch(emissionPlanActions.setFiltersLoading(false)))
  }

  static fetchDailySummaryListing = (
    campaignUuid: string,
    agglomerations: string[],
    cities: string[],
    buildings: string[],
    creations: string[],
    mediaAsIds: string[],
    pageNumber: number
  ) => {
    return Promise.resolve(store.dispatch(emissionPlanActions.setPageNumber(pageNumber)))
      .then(() => Promise.resolve(store.dispatch(emissionPlanActions.setLoading(true))))
      .then(() => {
        const pageToFirst = pageNumber * 10

        return api.emissionPlan.fetchDailySummaryListing(
          campaignUuid,
          agglomerations,
          cities,
          buildings,
          creations,
          mediaAsIds,
          pageToFirst
        )
      })
      .then(({ data }) => {
        if (!data) {
          throw new Error('No data')
        }

        void store.dispatch(emissionPlanActions.setLoaded(true))

        const summaryNodes: DailySummaryNode[] = data.dailySummaryListing.edges.map(
          edge => edge.node
        )

        return store.dispatch(
          emissionPlanActions.setData({
            data: summaryNodes,
            totalItemsCount: data.dailySummaryListing.totalCount,
          })
        )
      })
      .catch(() => {
        void store.dispatch(emissionPlanActions.setLoaded(false))
      })
      .finally(() => void store.dispatch(emissionPlanActions.setLoading(false)))
  }

  static fetchDailySummary = (campaignUuid: string, schedule: string) => {
    return Promise.resolve(store.dispatch(emissionPlanActions.setLoading(true)))
      .then(() => {
        return api.emissionPlan.fetchDailySummary(campaignUuid, schedule)
      })
      .then(({ data }) => {
        if (!data) {
          throw new Error('No data')
        }

        const rawDailySummary = data.dailySummary

        const dailySummary: DailySummaryModel = {
          mediumAsId: rawDailySummary.medium.asId,
          day: rawDailySummary.day,
          totalImpressions: rawDailySummary.totalImpressions,
          creationThumbnailUrls: rawDailySummary.impressionsPerCreation.map(
            impressionPerCreation => {
              return impressionPerCreation.creation.thumbnailUrl
            }
          ),
          intervals: rawDailySummary.intervals.map(interval => {
            return {
              from: interval.from,
              to: interval.to,
              totalImpressions: interval.totalImpressions,
              impressions: rawDailySummary.impressionsPerCreation.map(impressionPerCreation => {
                const impression = impressionPerCreation.impressions.find(
                  impression => impression.hour === interval.from
                )

                return impression ? impression.totalImpressions : 0
              }),
            }
          }),
        }

        void store.dispatch(emissionPlanActions.setDailySummary(dailySummary))
      })
      .finally(() => void store.dispatch(emissionPlanActions.setLoading(false)))
  }

  static fetchCampaign = (campaignUuid: string): Promise<unknown> => {
    return Promise.resolve(store.dispatch(emissionPlanActions.setCampaignLoading(true)))
      .then(() => {
        return api.campaign.getCampaignForDetailsPublic(campaignUuid)
      })
      .then(({ data }) => {
        if (!data) {
          throw new Error('No data')
        }

        const payload = {
          name: data.campaignReportPublic.campaign.name,
          status: data.campaignReportPublic.campaign.status,
          acceptanceStatus: data.campaignReportPublic.campaign.acceptanceStatus,
          reservationTill: data.campaignReportPublic.campaign.reservationTill,
        }

        void store.dispatch(emissionPlanActions.setCampaignLoaded(true))

        return store.dispatch(emissionPlanActions.setCampaignData(payload))
      })
      .catch(() => {
        return store.dispatch(emissionPlanActions.setCampaignLoaded(false))
      })
      .finally(() => void store.dispatch(emissionPlanActions.setCampaignLoading(false)))
  }
}
