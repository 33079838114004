export interface CardProps {
  className?: string
  title?: string
  id?: string
  headerNumber?: number
  status?: CardStatus
  isCollapsed?: boolean
  onCollapse?: (isCollapsed: boolean) => void
  children?: React.ReactNode
  disabled?: boolean
  noMarginBody?: boolean
  dataCy?: string
}

export enum CardStatus {
  NONE,
  LOADING,
  SAVING,
  SAVED,
  ERROR,
}
