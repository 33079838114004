import React, { ReactElement } from 'react'
import { View } from '@react-pdf/renderer'
import { Table, TableData, TableRow, TableTitle } from 'components/TablePdf'
import { t } from 'i18next'
import { PdfAudienceContact } from '../../../../../api/campaign/types'

type Props = {
  contacts: PdfAudienceContact[]
}

export const AudienceList = ({ contacts }: Props): ReactElement => {
  if (!contacts || contacts.length === 0) {
    return (
      <View>
        <Table>
          <TableTitle>{t('common.distributionOfContacts')}</TableTitle>
          <TableRow>
            <TableData width={40}>{t('common.noData')}</TableData>
            <TableData width={60}> </TableData>
          </TableRow>
        </Table>
      </View>
    )
  }

  return (
    <View>
      <Table>
        <TableTitle>{t('common.distributionOfContacts')}</TableTitle>

        {contacts.map(contact => (
          <TableRow key={contact.day}>
            <TableData width={40}>{contact.day}</TableData>
            <TableData width={60}>{contact.count}</TableData>
          </TableRow>
        ))}
      </Table>
    </View>
  )
}
