import { gql } from '@apollo/client'
import { CAMPAIGN_MEDIUM_USAGE_FIELDS } from 'api/fragments'

export const GET_CAMPAIGN_MEDIA_USAGE = gql`
  ${CAMPAIGN_MEDIUM_USAGE_FIELDS}
  query campaignMediaUsage($id: ID!) {
    node(id: $id) {
      ... on Campaign {
        media {
          ...CampaignMediumFields
        }
      }
    }
  }
`
