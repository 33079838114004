import { CampaignSortColumn } from 'types/campaign'
import { AgencySortColumn } from 'types/agency'
import { UserSortColumn } from 'types/user'
import { SortDirection } from 'types/various'

export interface UseTableFilterProps {
  setTableFilter: (id: Filter, filter: TableSort) => void
  getTableFilter: (id: Filter) => TableSort
}

export const useTableFilterPropsDefault: UseTableFilterProps = {
  setTableFilter: () => void {},
  getTableFilter: () => tableFilterDefault,
}

export type TableSort = CampaignTableFilter | UserTableFilter | AgencyTableFilter

const tableFilterDefault: TableSort = {
  column: CampaignSortColumn.NAME,
  direction: SortDirection.ASC,
}

interface CampaignTableFilter {
  column: CampaignSortColumn
  direction: SortDirection
}

interface UserTableFilter {
  column: UserSortColumn
  direction: SortDirection
}

interface AgencyTableFilter {
  column: AgencySortColumn
  direction: SortDirection
}

const campaignTableFilterDefault: CampaignTableFilter = {
  column: CampaignSortColumn.START_DATE,
  direction: SortDirection.DESC,
}

const userTableFilterDefault: UserTableFilter = {
  column: UserSortColumn.AGENCY_NAME,
  direction: SortDirection.ASC,
}

const agencyTableFilterDefault: AgencyTableFilter = {
  column: AgencySortColumn.NAME,
  direction: SortDirection.ASC,
}

export interface FilterMeta {
  id: Filter
  filter: TableSort
}

export enum Filter {
  CAMPAIGN = 'campaign',
  USER = 'user',
  AGENCY = 'agency',
}

export const filtersDefault: FilterMeta[] = [
  {
    id: Filter.CAMPAIGN,
    filter: campaignTableFilterDefault,
  },
  {
    id: Filter.USER,
    filter: userTableFilterDefault,
  },
  {
    id: Filter.AGENCY,
    filter: agencyTableFilterDefault,
  },
]
