import { useCallback } from 'react'
import debounce from 'lodash/debounce'

export default class TsxUtils {
  static extraStyle = (condition: unknown, className: unknown): string =>
    condition ? ` ${className}` : ''

  static joinClasses = (...classNames: unknown[]): string => {
    return classNames.filter(Boolean).join(' ')
  }
}

export const useDebounce = <T extends unknown[]>(callback: (...args: T) => unknown, ms: number) => {
  const callbackWrapper = (...args: T) => {
    debouncedCallback.cancel()
    callback(...args)
  }

  const debouncedCallback = useCallback(debounce(callbackWrapper, ms), [])
  return [debouncedCallback, callbackWrapper]
}
