import React, { ReactElement } from 'react'
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavbarElementProps } from 'react-day-picker'

const DayPickerNavbar = ({
  onPreviousClick,
  onNextClick,
  className,
}: Partial<NavbarElementProps>): ReactElement => (
  <div className={className}>
    <FontAwesomeIcon
      icon={faCaretLeft}
      onClick={() => onPreviousClick?.()}
    />
    <FontAwesomeIcon
      icon={faCaretRight}
      onClick={() => onNextClick?.()}
    />
  </div>
)

export default DayPickerNavbar
