import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CampaignSliceModel } from '../models/campaign-details.model'
import { CampaignData } from '../models/campaign-data.model'
import { CampaignDetails } from '../../../types/campaign'
import { basicsValuesDefault, detailsValuesDefault } from '../../../contexts/CampaignFormContext'
import { produce } from 'immer'
import { CardStatus } from '../../../components/Layout/Card'

const initialState: CampaignSliceModel = {
  cardStatus: {
    basics: CardStatus.NONE,
    details: CardStatus.NONE,
    creations: CardStatus.NONE,
  },
  details: {
    campaignData: undefined,
    campaignDetails: undefined,
  },
  form: {
    basicsValues: basicsValuesDefault,
    detailsValues: detailsValuesDefault,
  },
  numberOfUsedAgglomerations: 0,
}

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignData(state, action: PayloadAction<CampaignData>) {
      state.details.campaignData = action.payload
    },
    setCampaignDetails(state, action: PayloadAction<CampaignDetails>) {
      state.details.campaignDetails = action.payload
    },
    updateBasicsValues(
      state,
      action: PayloadAction<Partial<CampaignSliceModel['form']['basicsValues']>>
    ) {
      state.form.basicsValues = produce(state.form.basicsValues, draft => {
        return { ...draft, ...action.payload }
      })
    },
    updateDetailsValues(
      state,
      action: PayloadAction<Partial<CampaignSliceModel['form']['detailsValues']>>
    ) {
      state.form.detailsValues = produce(state.form.detailsValues, draft => {
        return { ...draft, ...action.payload }
      })
    },
    resetCampaignDetails(state) {
      state.details.campaignDetails = undefined
      state.details.campaignData = undefined
    },
    resetCampaignForm(state) {
      state.form.basicsValues = basicsValuesDefault
      state.form.detailsValues = detailsValuesDefault
    },
    setCardStatus(state, action: PayloadAction<Partial<CampaignSliceModel['cardStatus']>>) {
      state.cardStatus = produce(state.cardStatus, draft => {
        return { ...draft, ...action.payload }
      })
    },
    setNumberOfUsedAgglomerations(state, action: PayloadAction<number>) {
      state.numberOfUsedAgglomerations = action.payload
    },
  },
})

export const campaignActions = campaignSlice.actions

export default campaignSlice
