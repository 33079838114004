import { gql } from '@apollo/client'

export const GET_CAMPAIGN_AUDIENCE_DATA = gql`
  query ($campaignId: ID!) {
    campaignPdfAudienceData(campaignId: $campaignId) {
      contacts {
        day
        count
      }
      gender {
        male
        female
        undefined
      }
      audienceAge {
        ageGroup
        percent
      }
      audienceAgeGender {
        ageGroup
        male
        female
        undefined
      }
      agglomeration {
        name
        percent
      }
      completeData
    }
  }
`
