import React, { useEffect, ReactNode, useState, useContext } from 'react'
import CreationsFormPreview from './CreationsFormPreview'
import service from './service'
import { CampaignFormContext, FormCreation } from 'contexts/CampaignFormContext'
import { MediaFormat } from 'types/campaign'
import './CreationsForm.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'

const CreationsForm: React.FC = () => {
  const {
    creationsValues: creations,
    setCreationsValues: setCreations,
    triggerCreations,
    setTriggerCreations,
  } = useContext(CampaignFormContext)
  const [mediaFormats, setMediaFormats] = useState<MediaFormat[]>([])
  const { basicsValues, detailsValues } = useSelector((state: RootState) => state.campaign.form)

  const { campaignId, startDate, endDate, status } = basicsValues

  useEffect(() => {
    if (campaignId) {
      service.getCreations(campaignId, creations, setCreations, setTriggerCreations)
    }
  }, [campaignId, triggerCreations])

  useEffect(() => {
    setMediaFormats([detailsValues.mediumFormat])
  }, [detailsValues.mediumFormat])

  return (
    <div className='CreationsForm'>
      {mediaFormats
        .sort((a, b) => a.localeCompare(b))
        .map(
          (mediaFormat: MediaFormat): ReactNode => (
            <CreationsFormPreview
              key={mediaFormat}
              campaignData={{
                id: campaignId,
                startDate,
                endDate,
                status,
                acceptanceStatus: detailsValues.acceptanceStatus,
                impressions: detailsValues.emissions,
                target: detailsValues.target,
                audience: detailsValues.audience,
              }}
              creations={creations.filter((c: FormCreation) => c.mediaFormat === mediaFormat)}
              mediaFormat={mediaFormat}
            />
          )
        )}
    </div>
  )
}

export default CreationsForm
