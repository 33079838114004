import React, { useContext } from 'react'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { useTranslation } from 'react-i18next'
import imgPl from 'images/flag-pl.svg'
import imgEn from 'images/flag-en.svg'
import { AppContext } from 'contexts/AppContext'
import api from 'api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { UserLocale } from 'types/user'
import './TopBarLanguage.scss'

const TopBarLanguage: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { userData, updateUser } = useContext(AppContext)

  const onChange = (data: { value: string }): void => {
    const newLanguage = data?.value as UserLocale
    if (!userData.user) {
      i18n.changeLanguage(newLanguage)
      return
    }

    api.user
      .updatePreferredLocale(newLanguage)
      .then(() => {
        i18n.changeLanguage(newLanguage)
        updateUser()
      })
      .catch(() => void {})
  }

  return (
    <div className={'TopBarLanguage'}>
      <div className='TopBarLanguage__img--container'>
        <img
          className='TopBarLanguage__img'
          src={i18n.language === 'pl' ? imgPl : imgEn}
          alt={t('topBar.preferredLanguage')}
        />
      </div>

      <Dropdown
        options={[
          { value: 'pl', label: 'PL', className: 'TopBarLanguage__Dropdown--option' },
          { value: 'en', label: 'EN', className: 'TopBarLanguage__Dropdown--option' },
        ]}
        placeholderClassName='TopBarLanguage__Dropdown--placeholder'
        controlClassName='TopBarLanguage__Dropdown--control'
        menuClassName='TopBarLanguage__Dropdown--menu'
        arrowOpen={
          <FontAwesomeIcon
            icon={faChevronUp}
            className='TopBarLanguage__Dropdown--arrow'
          />
        }
        arrowClosed={
          <FontAwesomeIcon
            icon={faChevronDown}
            className='TopBarLanguage__Dropdown--arrow'
          />
        }
        onChange={onChange}
      />
    </div>
  )
}

export default TopBarLanguage
