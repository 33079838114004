export interface NotifierProps extends Notification {
  onRemoveNotification: (id: string) => void
}

export enum NotifierType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface Notification {
  id: string
  type: NotifierType
  text: string | string[]
  time?: number
}
