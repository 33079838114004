import { gql } from '@apollo/client'
import { CAMPAIGN_FOR_DETAILS_FIELDS } from '../fragments'

export const GET_CAMPAIGN_FOR_DETAILS = gql`
  ${CAMPAIGN_FOR_DETAILS_FIELDS}
  query campaignForDetails($id: ID!) {
    node(id: $id) {
      ... on Campaign {
        ...CampaignForDetailsFields
      }
    }
  }
`

export const GET_CAMPAIGN_FOR_DETAILS_PUBLIC = gql`
  ${CAMPAIGN_FOR_DETAILS_FIELDS}
  query campaignReportPublic($campaignUuid: String!) {
    campaignReportPublic(campaignUuid: $campaignUuid) {
      campaign {
        ...CampaignForDetailsFields
      }
    }
  }
`
