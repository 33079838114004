import React, { ReactChild } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertType, Creation } from '../../../../../types/campaign'
import TooltipHelp from '../../../../../components/TooltipHelp'
import IconAlert from '../../../../../components/Icon/IconAlert'
import { CreationsAudienceTooltipAlertProps } from './types'

const CreationsAudienceTooltipAlert: React.FC<CreationsAudienceTooltipAlertProps> = ({
  currentCreationId,
  creations,
  creationAudienceNumber,
  campaignAudience,
}) => {
  const { t } = useTranslation()

  const tooltip = (alertType: AlertType, message: string): ReactChild => {
    return (
      <TooltipHelp
        containerClassName={`CreationsFormEmissionNumber__emissions--tooltip-${alertType.toString()}`}
      >
        <div className={'CreationsFormEmissionNumber__emissions--alert'}>
          <div className='Alerts__alert'>
            <div className={'CreationsFormEmissionNumber__emissions--alert'}>
              {(alertType === AlertType.warning || alertType === AlertType.alert) && (
                <div className={'Alerts__header'}>
                  <IconAlert alertType={alertType} />
                </div>
              )}
              <div className='Alerts__alert'>{message}</div>
            </div>
          </div>
        </div>
      </TooltipHelp>
    )
  }
  const getTooltipAlert = (): ReactChild => {
    const totalCreationsAudience =
      (creations as Creation[])
        .filter(creation => creation.id !== currentCreationId)
        .reduce((sum, { audience }) => sum + audience, 0) +
      (creationAudienceNumber ? parseInt(creationAudienceNumber) : 0)

    if ((creations as Creation[]).some(creation => creation.impressions === null)) {
      return tooltip(
        AlertType.info,
        t('form.campaign.creations.creationsAudienceNumberShouldBeEqualToCampaigns')
      )
    }

    if (totalCreationsAudience < campaignAudience) {
      return tooltip(
        AlertType.warning,
        t('form.campaign.creations.creationsAudienceNumberCantBeLowerThanCampaigns')
      )
    }

    if (totalCreationsAudience > campaignAudience) {
      return tooltip(
        AlertType.alert,
        t('form.campaign.creations.creationsAudienceNumberCantBeHigherThanCampaigns')
      )
    }

    return tooltip(
      AlertType.info,
      t('form.campaign.creations.creationsAudienceNumberShouldBeEqualToCampaigns')
    )
  }

  return <>{getTooltipAlert()}</>
}

export default CreationsAudienceTooltipAlert
