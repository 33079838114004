import React, { createContext } from 'react'
import { AppContextProps, AppContextProviderProps, appContextPropsDefault } from './types'
import useNotifier, { UseNotifierProps } from 'hooks/useNotifier'
import useUser, { UseUserProps } from 'hooks/useUser'
import useAuthorize, { UseAuthorizeProps } from 'hooks/useAuthorize'
import useFeature, { UseFeatureProps } from 'hooks/useFeature'
import use404, { Use404Props } from 'hooks/use404'
import useTableFilter, { UseTableFilterProps } from 'hooks/useTableFilter'
import useHomepage from 'hooks/useHomepage'

export const AppContext = createContext<AppContextProps>(appContextPropsDefault)

const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const useNotifierProps: UseNotifierProps = useNotifier()
  const useUserProps: UseUserProps = useUser()
  const useAuthorizeProps: UseAuthorizeProps = useAuthorize(useUserProps.userData.user?.roles)
  const useFeatureProps: UseFeatureProps = useFeature()
  const use404Props: Use404Props = use404()
  const useTableFilterProps: UseTableFilterProps = useTableFilter()
  const useHomepageProps = useHomepage(useAuthorizeProps.allowedFor)

  return (
    <AppContext.Provider
      value={{
        ...useNotifierProps,
        ...useUserProps,
        ...useAuthorizeProps,
        ...useFeatureProps,
        ...use404Props,
        ...useTableFilterProps,
        ...useHomepageProps,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
