import React, { ReactElement } from 'react'
import { View } from '@react-pdf/renderer'
import { CampaignTarget } from 'types/campaign'
import { Table, TableData, TableRow, TableTitle } from 'components/TablePdf'
import { t } from 'i18next'
import CampaignUtils from 'utils/campaign'
import CampaignDetailsService from '../../services/campaign-details.service'
import CampaignDetailsRealizationService from '../../CampaignDetailsRealization/service'
import VariableUtils from 'utils/variable'
import { CampaignDetailsPdfData } from '../models/types'

type Props = {
  campaignData: Pick<
    CampaignDetailsPdfData,
    | 'budgetCurrency'
    | 'currentEmissions'
    | 'endDate'
    | 'startDate'
    | 'status'
    | 'target'
    | 'totalBudget'
    | 'totalEmissions'
    | 'acceptanceStatus'
    | 'totalAudience'
    | 'currentAudience'
  >
}

export const Summary = ({ campaignData }: Props): ReactElement => {
  const {
    budgetCurrency,
    currentEmissions,
    endDate,
    startDate,
    status,
    target,
    totalBudget,
    totalEmissions,
    acceptanceStatus,
    totalAudience,
    currentAudience,
  } = campaignData

  const { budget, emissions, audience } = CampaignDetailsRealizationService.parseTargetValue(
    totalBudget,
    totalEmissions,
    budgetCurrency,
    totalAudience
  )

  const availableTargets = {
    [CampaignTarget.BUDGET]: budget,
    [CampaignTarget.IMPRESSIONS]: emissions,
    [CampaignTarget.AUDIENCE]: audience,
  }

  const targetRealizations = {
    [CampaignTarget.BUDGET]: `${VariableUtils.formatNumber(currentEmissions)} ${t(
      'common.emissions.a'
    )}`,
    [CampaignTarget.IMPRESSIONS]: `${VariableUtils.formatNumber(currentEmissions)} ${t(
      'common.emissions.a'
    )}`,
    [CampaignTarget.AUDIENCE]: `${VariableUtils.formatNumber(currentAudience)} - ${t(
      'common.audience.a'
    )}`,
  }

  return (
    <View>
      <Table>
        <TableTitle>{t('pdf.campaignSummary')}</TableTitle>

        <TableRow>
          <TableData width={40}>{t('pdf.campaignStatus')}</TableData>
          <TableData width={60}>
            {CampaignUtils.parseStatus(status, acceptanceStatus).text}
          </TableData>
        </TableRow>

        <TableRow>
          <TableData width={40}>{t('common.campaignTerm')}</TableData>
          <TableData width={60}>
            {CampaignDetailsService.campaignTerm(startDate, endDate)}
          </TableData>
        </TableRow>

        <TableRow>
          <TableData width={40}>{t('common.target')}</TableData>
          <TableData width={60}>{availableTargets[target]}</TableData>
        </TableRow>

        <TableRow>
          <TableData width={40}>{t('common.targetRealization')}</TableData>
          <TableData width={60}>{targetRealizations[target]}</TableData>
        </TableRow>
      </Table>
    </View>
  )
}
