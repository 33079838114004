import { gql } from '@apollo/client'
import { MUTATION_STATE, USER_FIELDS } from '../fragments'

const PAYLOAD_TYPE = 'UpdateUserPayload'

export const UPDATE_USER = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  ${USER_FIELDS}
  mutation updateUser(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $priorities: [Int!]!
    $roles: [ID!]!
    $agencies: [ID!]!
  ) {
    updateUser(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        priorities: $priorities
        roles: $roles
        agencies: $agencies
      }
    ) {
      user {
        ...UserFields
      }
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
