import React from 'react'
import { AlertsProps } from './types'
import { Alert } from 'types/campaign'
import TsxUtils from 'utils/tsx'
import './Alerts.scss'

const Alerts: React.FC<AlertsProps> = ({ className, alerts }) => (
  <div className={TsxUtils.extraStyle(className, className)}>
    {alerts.map((alert: Alert, index: number) => (
      <div
        key={alert.message}
        className='Alerts__alert'
      >
        <span className='Alerts__alert--index'>{index + 1 + '. '}</span>
        {alert.message}
      </div>
    ))}
  </div>
)

export default Alerts
