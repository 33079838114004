import { gql } from '@apollo/client'

export const CREATE_MEDIA_REPORT = gql`
  mutation createMediaReport(
    $asIds: [String!]!
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
  ) {
    createMediaReport(input: { asIds: $asIds, startDate: $startDate, endDate: $endDate }) {
      report {
        name
        status
        startDate
        endDate
        generatedAt
        csvUrl
      }
      success
      errors
    }
  }
`
