import { ModalAction, ModalActionKind, ModalReducerState } from './types'
import { camelCase } from 'case-anything'

export const modalsReducer = (
  state: ModalReducerState,
  modalAction: ModalAction
): ModalReducerState => {
  const { type: action } = modalAction

  const set = (action: ModalActionKind): ModalReducerState => ({
    ...state,
    [`${camelCase(ModalActionKind[action])}`]: modalAction.payload,
  })

  switch (action) {
    case ModalActionKind.CREATE_COPY_OPEN:
      return set(action)
    case ModalActionKind.CREATE_COPY_LOADING:
      return set(action)

    case ModalActionKind.EDIT_OPEN:
      return set(action)

    case ModalActionKind.REMOVE_OPEN:
      return set(action)
    case ModalActionKind.REMOVE_LOADING:
      return set(action)

    case ModalActionKind.PUBLISH_OPEN:
      return set(action)
    case ModalActionKind.PUBLISH_LOADING:
      return set(action)

    case ModalActionKind.APPROVE_OPEN:
      return set(action)
    case ModalActionKind.APPROVE_LOADING:
      return set(action)

    case ModalActionKind.REJECT_OPEN:
      return set(action)
    case ModalActionKind.REJECT_LOADING:
      return set(action)

    case ModalActionKind.PAUSE_OPEN:
      return set(action)
    case ModalActionKind.PAUSE_LOADING:
      return set(action)

    case ModalActionKind.STOP_OPEN:
      return set(action)
    case ModalActionKind.STOP_LOADING:
      return set(action)

    case ModalActionKind.CONFIRM_RESERVATION_OPEN:
      return set(action)
    case ModalActionKind.CONFIRM_RESERVATION_LOADING:
      return set(action)

    case ModalActionKind.CANCEL_RESERVATION_OPEN:
      return set(action)
    case ModalActionKind.CANCEL_RESERVATION_LOADING:
      return set(action)
  }
}
