import { Company } from '../api/companies/types'
import { AgencyOrigin, Currency } from '../api/agency/types'
import { User } from './user'

export enum AgencyType {
  external = 'external',
  internal = 'internal',
}

export interface AllowedTarget {
  id: string
  name: string
}

export interface Agency {
  id: string
  clientId: string
  name: string
  company: Company | null
  agencyType: AgencyType
  origin: AgencyOrigin
  currencyType: Currency
  supervisors: User[]
  billingAllowance: boolean
  discount: number
  allocatedTime: number
  withAllocatedTime: boolean
  agencyMediums: AgencyMedium[] | AgencyMediumQR[]
  mediumAllocatedTime: number
  allowedTargets: AllowedTarget[]
}

export const agencyDefault: Agency = {
  id: '',
  clientId: '',
  name: '',
  company: null,
  origin: AgencyOrigin.DOMESTIC,
  agencyType: AgencyType.external,
  currencyType: Currency.PLN,
  supervisors: [],
  billingAllowance: false,
  discount: 0,
  allocatedTime: 0,
  withAllocatedTime: false,
  agencyMediums: [],
  mediumAllocatedTime: 0,
  allowedTargets: [],
}

export enum AgencySortColumn {
  NAME = 'name',
}

interface AgencyMediumBase {
  allocatedTime: number | null
}
export interface AgencyMedium extends AgencyMediumBase {
  mediumId: string
}
export interface AgencyMediumQR extends AgencyMediumBase {
  media: {
    id: string
  }
}
