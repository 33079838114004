import { gql } from '@apollo/client'

export const RE_INVITE_USER = gql`
  mutation reInviteUser($id: ID!) {
    reinviteUser(input: { id: $id }) {
      invitationUrl
      success
      errors
    }
  }
`
