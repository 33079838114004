import React, { ReactElement, useContext, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Routes } from 'routes'
import { AppContext } from 'contexts/AppContext'
import Layout from 'components/Layout'

interface PrivateRouteProps {
  allowed: boolean
  children: ReactElement
  withLayout?: boolean
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ allowed, withLayout = true, children }) => {
  const { userData, is404Present, redirectTo404, resetUser } = useContext(AppContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (is404Present) {
      redirectTo404(navigate)
    }
  }, [is404Present])

  useEffect(() => {
    if (userData.withoutAccess) {
      resetUser()
    }
  }, [userData.withoutAccess])

  const secureRoute = (): ReactElement => {
    if (!userData.signedIn) return <Navigate to={Routes.SIGN.SIGN_IN} />
    if (!allowed) return <Navigate to={Routes.NOT_FOUND} />

    if (withLayout) {
      return <Layout>{children}</Layout>
    } else {
      return children
    }
  }

  return secureRoute()
}

export default PrivateRoute
