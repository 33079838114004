import React from 'react'
import { ImageCarouselItem } from './types'

const defaultProps: ImageCarouselItem & {
  isFullscreen?: boolean
} = {
  description: '',
  fullscreen: '',
  preview: '',
  original: '',
  originalAlt: '',
  originalHeight: undefined,
  originalWidth: undefined,
  originalTitle: undefined,
  sizes: '',
  srcSet: '',
  loading: 'eager',
}

const Item = React.memo((props: ImageCarouselItem) => {
  const {
    description,
    fullscreen, // fullscreen version of img
    handleImageLoaded,
    isFullscreen,
    onImageError,
    original,
    originalAlt,
    originalHeight,
    originalWidth,
    originalTitle,
    sizes,
    srcSet,
    loading,
    preview,
  } = { ...defaultProps, ...props }
  const itemSrc = (isFullscreen && fullscreen) || original

  return (
    <React.Fragment>
      <img
        className='image-gallery-image'
        style={{ cursor: preview ? 'pointer' : undefined }}
        data-preview={preview}
        src={itemSrc}
        alt={originalAlt}
        srcSet={srcSet}
        height={originalHeight}
        width={originalWidth}
        sizes={sizes}
        title={originalTitle}
        onLoad={event => handleImageLoaded && handleImageLoaded(event, original)}
        onError={onImageError}
        loading={loading}
      />
      {description && <span className='image-gallery-description'>{description}</span>}
    </React.Fragment>
  )
})
Item.displayName = 'ImageCarouselItem'

export default Item
