import { AuthorizationTemplate } from 'hooks/useAuthorize'
import { BaseRole } from 'types/user'
import { ALL_ROLES } from './various'

export const USERS_ACTION: AuthorizationTemplate = {
  CHANGE_AVATAR: { roles: ALL_ROLES },
  CHANGE_LANGUAGE: { roles: ALL_ROLES },
  EDIT: { roles: [BaseRole.ADMIN] },
  DEACTIVATE: { roles: [BaseRole.ADMIN] },
}
