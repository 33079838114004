import Button, { ButtonTheme } from 'components/Form/Button'
import Modal from 'components/Modal'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './ChangeEmailModal.scss'
import FormRow from '../../../components/Form/FormRow'
import FormColumn from '../../../components/Form/FormColumn'
import Input from '../../../components/Form/Input'
import { Errors } from '../../../components/Form/InputError'
import isEmail from 'validator/es/lib/isEmail'
import UserEditService from '../UserEdit/user-edit.service'
import { UserEditForm } from '../UserEdit/types'

type Props = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  userId: string
  currentEmail: string
  setUser: Dispatch<SetStateAction<UserEditForm | undefined>>
  setLoading: Dispatch<SetStateAction<boolean>>
}

const ChangeEmailModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  currentEmail,
  userId,
  setUser,
  setLoading,
}) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [error, setError] = useState<Errors | undefined>(undefined)

  const Footer = () => {
    return (
      <div className='Modal__footer ChangeEmailModal__footer'>
        <Button
          onClick={async () => {
            if (!isEmail(email)) {
              setError([t('user.changeEmailModal.error')])
              return
            }

            setError(undefined)

            await UserEditService.updateUserEmail(userId, email)
            void UserEditService.getUser(userId, setUser, setLoading)

            void setIsOpen(false)
          }}
        >
          {t('common.save')}
        </Button>
        <Button
          theme={ButtonTheme.BLUE_OUTLINE}
          onClick={() => void setIsOpen(false)}
        >
          {t('common.back')}
        </Button>
      </div>
    )
  }

  return (
    <Modal
      classNameChildren={'ChangeEmailModal'}
      title={t('user.changeEmailModal.header')}
      isOpen={isOpen}
      onClose={setIsOpen}
      footer={Footer()}
      scrollable
    >
      <div className={'ChangeEmailContainer'}>
        <FormRow>
          <FormColumn>
            <Input
              id='email'
              title={t('user.changeEmailModal.topLabel')}
              placeholder={t('user.changeEmailModal.topLabel')}
              value={currentEmail}
              onChange={() => void {}}
              disabled
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <Input
              id='email'
              title={t('user.changeEmailModal.bottomLabel')}
              placeholder={t('user.changeEmailModal.placeholder')}
              value={email}
              onChange={(value): void => void setEmail(value)}
              errors={error}
            />
          </FormColumn>
        </FormRow>
      </div>
    </Modal>
  )
}

export default ChangeEmailModal
