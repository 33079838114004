import React, { ReactElement, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PageHeader from 'components/Layout/PageHeader'
import CardHeader from 'components/Layout/CardHeader'
import Card, { CardStatus } from 'components/Layout/Card'
import { CampaignData, Collapsed, collapsedDefault, PrivateViewIds, PublicViewIds } from './types'
import { EmissionScheduleRouteParams } from 'routes'
import { useNavigate, useParams } from 'react-router-dom'
import service from './service'
import FillingSpinner from 'components/FillingSpinner'
import CreationsFormSchedule from 'pages/Campaign/CampaignForm/CreationsForm/CreationsFormSchedule'
import CreationsFormLocations from 'pages/Campaign/CampaignForm/CreationsForm/CreationsFormLocations'
import TooltipHelp from 'components/TooltipHelp'
import Button from 'components/Form/Button'
import { AppContext } from 'contexts/AppContext'
import { EMISSION_SCHEDULE } from 'constant/authorization'
import MediaMap from 'components/MediaMap'
import useFilteredMedia from 'hooks/useFilteredMedia'
import debounce from 'lodash/debounce'
import { DEBOUNCE_POST_DELAY_DEFAULT } from 'constant'
import './EmissionSchedule.scss'
import CreationsFormEmissionNumber from '../CampaignForm/CreationsForm/CreationsFormEmissionNumber'
import { CampaignTarget } from '../../../types/campaign'
import CreationsFormAudienceNumber from '../CampaignForm/CreationsForm/CreationsFormAudienceNumber'

const EmissionSchedule: React.FC = () => {
  const { t } = useTranslation()
  const { allowedFor } = useContext(AppContext)
  const navigate = useNavigate()
  const {
    campaignId: urlCampaignId,
    campaignUuid: urlCampaignUuid,
    creationId: urlCreationId,
    creationUuid: urlCreationUuid,
    readOnly: urlReadOnly,
  } = useParams<EmissionScheduleRouteParams>()
  const [collapsed, setCollapsed] = useState<Collapsed>(collapsedDefault)
  const [campaignData, setCampaignData] = useState<CampaignData>()
  const [triggerFetchCreationMedia, setTriggerFetchCreationMedia] = useState<boolean>(false)
  const [areLocationsLoading, setAreLocationsLoading] = useState<boolean>(false)
  const [loadingEmissionNumber, setLoadingEmissionNumber] = useState<boolean>(false)

  const privateViewIds: PrivateViewIds | undefined =
    urlCampaignId && urlCreationId ? { urlCampaignId, urlCreationId } : undefined
  const publicViewIds: PublicViewIds | undefined =
    urlCampaignUuid && urlCreationUuid ? { urlCampaignUuid, urlCreationUuid } : undefined

  const { fetchCreationMedia, mediaLoading, media } = useFilteredMedia()

  const isReadOnly = !!urlCampaignUuid || !!urlReadOnly
  const campaignStatus = campaignData?.status

  const debouncedFetchCreationMedia = useCallback(
    debounce(fetchCreationMedia, DEBOUNCE_POST_DELAY_DEFAULT),
    []
  )

  useEffect(() => {
    if (privateViewIds || publicViewIds) {
      service.getCampaignData(privateViewIds, publicViewIds, navigate, setCampaignData)
    }
  }, [urlCampaignId, urlCreationId, urlCampaignUuid, urlCreationUuid])

  useEffect(() => {
    if ((campaignData || triggerFetchCreationMedia) && !collapsed.map) {
      debouncedFetchCreationMedia({
        creationId: privateViewIds?.urlCreationId,
        creationUuid: publicViewIds?.urlCreationUuid,
      })

      return () => void setTriggerFetchCreationMedia(false)
    }
  }, [campaignData, triggerFetchCreationMedia, debouncedFetchCreationMedia, collapsed.map])

  const ScheduleTooltip = (): ReactNode => (
    <TooltipHelp
      className='EmissionSchedule__tooltip'
      containerClassName='EmissionSchedule__tooltip--b'
    >
      <b>{t('emissonSchedule.infoA')}</b>
      <p>
        <Trans i18nKey='emissonSchedule.infoB'>
          <b>
            <>{{ single: t('emissonSchedule.single') }}</>
          </b>
        </Trans>
      </p>
      <p>
        <Trans i18nKey='emissonSchedule.infoC'>
          <b>
            <>{{ timePeriod: t('emissonSchedule.timePeriod') }}</>
          </b>
        </Trans>
      </p>
      <p>{t('emissonSchedule.infoD')}</p>
    </TooltipHelp>
  )

  const Container = (campaignData: CampaignData): ReactElement => {
    const creation = campaignData.creations.find(
      c => c.id === urlCreationId || c.uuid === urlCreationUuid
    )!

    const isScheduleReadOnly: boolean =
      isReadOnly ||
      !(
        allowedFor({
          template: EMISSION_SCHEDULE.SCHEDULE_BY_CAMPAIGN_STATUS,
          status: campaignStatus,
        }) &&
        allowedFor({
          template: EMISSION_SCHEDULE.SCHEDULE_BY_CREATION_STATUS,
          status: creation.state,
        })
      )

    const isMediaReadOnly: boolean =
      isReadOnly ||
      Boolean(creation.archivedAt) ||
      !(
        allowedFor({
          template: EMISSION_SCHEDULE.LOCATIONS_BY_CAMPAIGN_STATUS,
          status: campaignStatus,
        }) &&
        allowedFor({
          template: EMISSION_SCHEDULE.LOCATIONS_BY_CREATION_STATUS,
          status: creation.state,
        })
      )

    return (
      <div className='EmissionSchedule'>
        <div className='EmissionSchedule__header'>
          <PageHeader>
            {t(`${isReadOnly ? 'common.emissonSchedule' : 'emissonSchedule.titleEdit'}`) +
              ' - ' +
              creation.file.filename}
          </PageHeader>

          {!isReadOnly && (
            <TooltipHelp
              className='EmissionSchedule__tooltip'
              containerClassName='EmissionSchedule__tooltip--a'
            >
              {t('emissonSchedule.info')}
            </TooltipHelp>
          )}
        </div>

        <Card>
          <CardHeader
            number={1}
            collapsed={collapsed.schedule}
            onCollapse={(isCollapsed: boolean) =>
              void setCollapsed({ ...collapsed, schedule: isCollapsed })
            }
          >
            <div className='EmissionSchedule__header'>
              {t('emissonSchedule.duration')}
              {!isReadOnly && ScheduleTooltip()}
            </div>
          </CardHeader>

          {!collapsed.schedule && (
            <div className='EmissionSchedule__schedule'>
              <CreationsFormSchedule
                campaignStartDate={campaignData.startDate}
                campaignEndDate={campaignData.endDate}
                creationId={urlCreationId}
                creationUuid={urlCreationUuid}
                readOnly={isScheduleReadOnly}
              />
            </div>
          )}
        </Card>

        <Card>
          <CardHeader
            number={2}
            collapsed={collapsed.locations}
            onCollapse={(isCollapsed: boolean) =>
              void setCollapsed({ ...collapsed, locations: isCollapsed })
            }
            status={areLocationsLoading ? CardStatus.LOADING : CardStatus.NONE}
          >
            {t('common.locations')}
          </CardHeader>

          <div
            className={
              !collapsed.locations
                ? 'EmissionSchedule__locations'
                : 'EmissionSchedule__locations--hidden'
            }
          >
            <CreationsFormLocations
              ids={{
                campaignId: urlCampaignId,
                campaignUuid: urlCampaignUuid,
                creationId: urlCreationId,
                creationUuid: urlCreationUuid,
              }}
              mediumFormat={campaignData.mediumFormat}
              readOnly={isMediaReadOnly}
              onChange={() => void setTriggerFetchCreationMedia(true)}
              onLoading={setAreLocationsLoading}
            />
          </div>
        </Card>

        <Card>
          <CardHeader
            number={3}
            collapsed={!collapsed.emissionNumber}
            onCollapse={(isCollapsed: boolean) => {
              void setCollapsed({ ...collapsed, emissionNumber: !isCollapsed })
            }}
            status={loadingEmissionNumber ? CardStatus.LOADING : CardStatus.NONE}
          >
            {t('form.campaign.creations.creationEmissions')}
          </CardHeader>
          {collapsed.emissionNumber && campaignData.target === CampaignTarget.IMPRESSIONS && (
            <CreationsFormEmissionNumber
              readonly={isReadOnly}
              creationId={creation.id}
              creations={campaignData.creations}
              campaignEmissions={campaignData.impressions}
              setLoadingEmissionsNumber={setLoadingEmissionNumber}
            />
          )}

          {collapsed.emissionNumber && campaignData.target === CampaignTarget.AUDIENCE && (
            <CreationsFormAudienceNumber
              readonly={isReadOnly}
              creationId={creation.id}
              creations={campaignData.creations}
              campaignAudience={campaignData.audience}
              setLoadingAudienceNumber={setLoadingEmissionNumber}
            />
          )}
        </Card>

        <Card
          headerNumber={4}
          title={t('common.placementOfMedia')}
          isCollapsed={collapsed.map}
          onCollapse={(isCollapsed: boolean) =>
            void setCollapsed({ ...collapsed, map: isCollapsed })
          }
          status={mediaLoading ? CardStatus.LOADING : CardStatus.NONE}
          noMarginBody
        >
          {!collapsed.map && (
            <MediaMap
              className='EmissionSchedule__map'
              media={media}
              withClustering
            />
          )}
        </Card>

        <Button
          className='EmissionSchedule__button'
          onClick={() => void navigate(-1)}
        >
          {isReadOnly ? t('common.back') : t('common.save')}
        </Button>
      </div>
    )
  }

  return campaignData ? Container(campaignData) : <FillingSpinner />
}

export default EmissionSchedule
