import { SelectOption } from '../Select'
import { CypressSelectable } from '../../../types'

export interface SelectMultiCheckboxProps extends CypressSelectable {
  id: string
  className?: string
  title?: string
  value: SelectOption['value'][]
  options: SelectOption[]
  onChange: (selected: SelectOption['value'][]) => void
  closeOnSelect?: boolean
  selectAllOption?: boolean
  menuPlacement?: MenuPlacement
  disabled?: boolean
  help?: string
  onMenuClose?: () => void
  onMenuOpen?: () => void
}

export enum MenuPlacement {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
