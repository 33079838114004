export default class StringUtils {
  static camelToKebabCase = (str: string): string =>
    str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)

  static breakWordsWithNewLines = (str: string, wordMaxLength: number): string => {
    const regex = new RegExp(`\\S{1,${wordMaxLength}}`, 'g')
    const matches = str.match(regex)
    if (!matches) return str

    const result: string[] = []
    for (const item of matches) {
      result.push(item)
      result.push(item.length >= wordMaxLength ? '\n' : ' ')
    }

    return result.join('').trim()
  }
}
