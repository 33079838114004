import React, { ReactNode, useEffect, useRef } from 'react'
import './Tooltip.scss'

interface TooltipProps {
  children: ReactNode
}

const Tooltip: React.FC<TooltipProps> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      const tooltip = ref.current

      // 12px - scrollbar width
      // 8px - tooltip padding
      const isTooltipBeyondWindow =
        tooltip.getBoundingClientRect().right > window.innerWidth - 12 - 8

      if (isTooltipBeyondWindow) {
        tooltip.style.right = '35px'
      } else {
        tooltip.style.left = '15px'
      }
    }
  }, [ref])

  return (
    <div className='Tooltip'>
      <div
        className='Tooltip__text'
        ref={ref}
      >
        {children}
      </div>
    </div>
  )
}

export default Tooltip
