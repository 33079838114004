import { gql } from '@apollo/client'
import { MUTATION_STATE, UPDATE_CREATION_AUDIENCE_NUMBER_FIELDS } from '../fragments'

const PAYLOAD = 'UpdateCreationAudiencePayload'

export const UPDATE_CREATION_AUDIENCE_NUMBER = gql`
    ${UPDATE_CREATION_AUDIENCE_NUMBER_FIELDS}
    ${MUTATION_STATE(PAYLOAD)}
    mutation updateCreationAudience($creationId: ID!, $audience: Int) {
        updateCreationAudience(input: { creationId: $creationId, audience: $audience }) {
            ...UpdateCreationAudienceFields
            ...MutationState${PAYLOAD}Fields
        }
    }
`
