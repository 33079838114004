import api from 'api'
import { Role, User, UserFilters } from 'types/user'
import { TableSort } from 'hooks/useTableFilter'
import { userTableActions } from '../store/user-table-slice'
import { store } from '../../../../store'
import { Agency } from '../../../../types/agency'

export default class UserTableService {
  static getUsers = (
    pageNumber: number,
    quantityPerPage: number,
    tableSort: TableSort,
    term?: string,
    filters?: UserFilters
  ): void => {
    store.dispatch(userTableActions.setLoading(true))

    api.user
      .getUsers({
        first: pageNumber * quantityPerPage,
        last: quantityPerPage,
        sortColumn: tableSort.column,
        sortDirection: tableSort.direction,
        term,
        filters,
      })
      .then(res => {
        const { users } = res.data

        store.dispatch(userTableActions.setUsers(users.nodes))
        store.dispatch(userTableActions.setTotalCount(users.totalCount))
      })
      .catch(() => void {})
      .finally(() => {
        store.dispatch(userTableActions.setLoading(false))
        store.dispatch(userTableActions.setLoaded(true))
      })
  }

  static parseRoles = (roles: User['roles']): string =>
    roles
      .map((role: Role) => {
        return role.name
      })
      .sort()
      .join(', ')

  static parseAgencies = (roles: User['agencies']): string =>
    roles
      .map((agency: Agency) => {
        return agency.name
      })
      .sort()
      .join(', ')

  static deactivateUser = (user: User): Promise<unknown> =>
    api.user
      .deactivateUser({ id: user.id })
      .catch(() => void {})
      .finally(() => store.dispatch(userTableActions.setLoaded(false)))
}
