import React from 'react'
import TsxUtils from 'utils/tsx'
import { TableRowProps } from '../types'
import './TableRow.scss'

const TableRow: React.FC<TableRowProps> = ({ className, children, onClick }) => (
  <tr
    className={'TableRow' + TsxUtils.extraStyle(className, className)}
    onClick={onClick}
  >
    {children}
  </tr>
)

export default TableRow
