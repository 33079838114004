import React from 'react'
import { Medium, MediumDisplayPhoto } from 'types/campaign'
import { useTranslation } from 'react-i18next'
import './MediaPopup.scss'
import ImageCarousel from '../../ImageCarousel/ImageCarousel'
import { ImageCarouselItem } from 'components/ImageCarouselItem/types'

interface MediaMapPopupProps {
  medium: Medium
}

const MediaMapPopup: React.FC<MediaMapPopupProps> = ({ medium }) => {
  const { t } = useTranslation()

  const mediaImageUrls =
    medium.images.length > 0
      ? medium.images.map(
          (image: MediumDisplayPhoto): ImageCarouselItem => ({
            original: image.thumbnailUrl,
            thumbnail: image.thumbnailUrl,
            preview: image.imageUrl,
          })
        )
      : []

  const getPoiToString = (pois: string[]): string => {
    return pois.reduce(
      (previous: string, poi: string, index) => (index === 0 ? poi : `${previous}, ${poi}`),
      ''
    )
  }

  return (
    <div className='MediaPopup'>
      <div className='MediaPopup__label'>{t('media.address')}</div>
      <div className='MediaPopup__data'>{medium.address}</div>
      <div className='MediaPopup__label'>{t('common.format')}</div>
      <div className='MediaPopup__data'>{t(`mediaType.${medium.mediaFormat}`)}</div>
      <div className='MediaPopup__label'>{t('common.mediumLocation')}</div>
      <div className='MediaPopup__data'>{medium.placement}</div>
      <div className='MediaPopup__label'>{t('common.asId')}</div>
      <div className='MediaPopup__data'>{medium.asId}</div>
      <div className='MediaPopup__label'>{t('common.status')}</div>
      <div className='MediaPopup__data'>{t(`mediumStatus.${medium.status}`)}</div>
      {medium.poiCategories && medium.poiCategories.length > 0 && (
        <>
          <div className='MediaPopup__label'>{t('common.pois')}</div>
          <div className='MediaPopup__data'>{getPoiToString(medium.poiCategories)}</div>
        </>
      )}
      {medium.comment && (
        <>
          <div className='MediaPopup__label'>{t('common.comment')}</div>
          <div className='MediaPopup__data'>{medium.comment}</div>
        </>
      )}
      {!!mediaImageUrls.length && <ImageCarousel imageUrls={mediaImageUrls} />}
    </div>
  )
}

export default MediaMapPopup
