import React, { ReactNode, useContext } from 'react'
import Navbar from 'components/Layout/Navbar'
import TopBar from './TopBar'
import { AppContext } from 'contexts/AppContext'
import './Layout.scss'

interface LayoutProps {
  children: ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const {
    userData: { user },
  } = useContext(AppContext)

  return (
    <div className='Layout'>
      <TopBar />

      <div className='Layout__content'>
        {user && <Navbar />}
        <div className='Layout__content--children'>{children}</div>
      </div>
    </div>
  )
}

export default Layout
