import React from 'react'
import { useTranslation } from 'react-i18next'
import './EmissionSummaryDetails.scss'
import Modal from '../../../components/Modal'
import Table, {
  TableBody,
  TableData,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from '../../../components/Table'
import { DailySummaryModel } from '../models/daily-summary.model'
import DateUtils from '../../../utils/date'
import { DATE_FORMAT, HOUR_FORMAT } from '../../../constant'

const EmissionSummaryDetails: React.FC<{
  summary?: DailySummaryModel
  closeClick: (open: boolean) => void
}> = ({ summary, closeClick }) => {
  const { t } = useTranslation()
  const dayTitle =
    summary && summary.day
      ? `, ${t('emissionSummaryDetails.day')}: ${DateUtils.parseAndFormat(
          summary.day,
          DATE_FORMAT
        )}`
      : ''

  return (
    <Modal
      title={`${t('emissionSummaryDetails.header')}: ${summary?.mediumAsId}${dayTitle}`}
      classNameOverlay='EmissionSummaryDetails'
      isOpen={!!summary}
      onClose={(isOpen: boolean) => void closeClick(isOpen)}
    >
      {summary && (
        <div>
          <Table className='CommonTable'>
            <TableHeader sticky>
              <TableHeaderItem className='CommonTable__header-item'>
                {t<string>('emissionPlan.hour')}
              </TableHeaderItem>

              <TableHeaderItem className='CommonTable__header-item EmissionSummaryDetails--center'>
                {t<string>('emissionPlan.creations')}
              </TableHeaderItem>
            </TableHeader>

            <TableBody>
              <>
                <TableRow className='CommonTable__data-row'>
                  <>
                    <TableData className='CommonTable__data-item EmissionSummaryDetails__hour-row'></TableData>
                    {summary.creationThumbnailUrls.map((url, i: number) => (
                      <TableData
                        key={i}
                        className='CommonTable__data-item EmissionSummaryDetails--center'
                      >
                        <img
                          className={'EmissionSummaryDetails__creation'}
                          src={url}
                          alt=''
                        />
                      </TableData>
                    ))}
                  </>
                </TableRow>

                {summary.intervals.map((interval, i: number, array) => (
                  <>
                    <TableRow
                      key={i}
                      className='CommonTable__data-row'
                    >
                      <>
                        <TableData
                          key={`${i}-hour`}
                          className='CommonTable__data-item EmissionSummaryDetails__hour-row'
                        >
                          <p
                            className={`${
                              i === array.length - 1 ? 'EmissionSummaryDetails__last-row' : ''
                            }`}
                          >
                            {DateUtils.parseAndFormat(interval.from, HOUR_FORMAT)} -{' '}
                            {DateUtils.parseAndFormat(interval.to, HOUR_FORMAT)}
                          </p>
                        </TableData>

                        {interval.impressions.map((impression, j: number) => (
                          <TableData
                            key={`${j}-impression`}
                            className='CommonTable__data-item EmissionSummaryDetails--center'
                          >
                            <p
                              className={`${
                                i === array.length - 1 ? 'EmissionSummaryDetails__last-row' : ''
                              }`}
                            >
                              {impression}
                            </p>
                          </TableData>
                        ))}
                      </>
                    </TableRow>
                  </>
                ))}
              </>
            </TableBody>
          </Table>

          <p>{`${t('emissionSummaryDetails.totalImpressions')}: ${summary.totalImpressions}`}</p>
        </div>
      )}
    </Modal>
  )
}

export default EmissionSummaryDetails
