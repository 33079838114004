import { ReactChild } from 'react'
import { TableContainerProps } from 'components/Table'

export interface TableContextProviderProps {
  sort?: TableContainerProps['sort']
  onSort?: TableContainerProps['onSort']
  children: ReactChild
}

export type TableContextProps = Pick<TableContextProviderProps, 'sort' | 'onSort'>

export const tableContextPropsDefault: TableContextProps = {
  sort: undefined,
  onSort: undefined,
}
