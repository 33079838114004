import { gql } from '@apollo/client'

export const GET_MEDIA_REPORT = gql`
  query mediaReport($asIds: [Int!]!, $startDate: ISO8601DateTime, $endDate: ISO8601DateTime) {
    mediaReport(asIds: $asIds, startDate: $startDate, endDate: $endDate) {
      headers
      content
    }
  }
`
