import React from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonTheme } from 'components/Form/Button'

type Props = {
  isEditing: boolean
  onEdit: () => void
  onSave: () => void
  onCancel: () => void
  loading: boolean
}

const NoteModalFooter: React.FC<Props> = ({ isEditing, onEdit, onSave, onCancel, loading }) => {
  const { t } = useTranslation()

  const EditButton = () => <Button onClick={onEdit}>{t('common.edit')}</Button>

  const SaveButton = () => (
    <Button
      dataCy={'save-note-btn'}
      disabled={loading}
      onClick={onSave}
    >
      {t('common.save')}
    </Button>
  )

  return (
    <div style={{ minWidth: '500px', display: 'flex', gap: '20px' }}>
      {isEditing ? <SaveButton /> : <EditButton />}
      <Button
        dataCy={'cancel-note-btn'}
        theme={ButtonTheme.BLUE_OUTLINE}
        onClick={onCancel}
      >
        {t('common.cancel')}
      </Button>
    </div>
  )
}

export default NoteModalFooter
