import React from 'react'
import MediaMap from 'components/MediaMap'
import { LiveMediaMapProps } from './types'
import './LiveMediaMap.scss'

const LiveMediaMap: React.FC<LiveMediaMapProps> = ({ media }) => (
  <MediaMap
    className='LiveMediaMap'
    activeAreaClassName='LiveMediaMap__active-area'
    zoomControlPosition='bottomright'
    media={media}
    withClustering
  />
)

export default LiveMediaMap
