import { ApolloQueryResult } from '@apollo/client'
import { Agency } from 'types/agency'

export type GetAgencyAQR = ApolloQueryResult<{ node: Agency }>

export type GetAgenciesAQR = ApolloQueryResult<{
  agencies: {
    totalCount: number
    nodes: Agency[]
  }
}>
export enum AgencyOrigin {
  DOMESTIC = 'domestic',
  FOREIGN = 'foreign',
}

export enum Currency {
  PLN = 'PLN',
  USD = 'USD',
  EUR = 'EUR',
}
