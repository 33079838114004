import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faUser } from '@fortawesome/free-solid-svg-icons'
import './DropdownMenu.scss'
import TsxUtils from '../../../../utils/tsx'
import { useLocation } from 'react-router-dom'

interface DropdownMenuProps {
  headerTitle: string
  subPages: string[]
  children: React.ReactNode
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ headerTitle, subPages, children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const location = useLocation()
  useEffect(() => {
    const subPageMatch = subPages.some(page => {
      return location.pathname === page
    })
    setOpen(subPageMatch)
  }, [location])
  return (
    <div className={'DropdownMenu'}>
      <div
        className={'DropdownMenu__header-container'}
        onClick={() => setOpen(!open)}
      >
        <div className='DropdownMenu__header-container__marker' />
        <div className='DropdownMenu__header'>
          <FontAwesomeIcon
            icon={faUser}
            className='NavbarItem__icon'
          />
          <div className='NavbarItem__text'>{headerTitle}</div>
          <FontAwesomeIcon
            icon={open ? faChevronUp : faChevronDown}
            className='TopBarUser__arrow'
          />
        </div>
      </div>
      <div
        className={
          'DropdownMenu__container' + TsxUtils.extraStyle(open, 'DropdownMenu__container--active')
        }
      >
        {children}
      </div>
    </div>
  )
}

export default DropdownMenu
