import { CampaignData } from '../../models/campaign-data.model'

export interface CampaignDetailsRealizationProps {
  campaign: CampaignData
  publicView?: boolean
}

export enum ColumnPlacement {
  LEFT = 'left',
  RIGHT = 'right',
}
