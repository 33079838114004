export interface SignProps {
  type: SignType
}

export enum SignType {
  SIGN_IN,
  REMIND_PASSWORD,
  RESET_PASSWORD,
  ACCEPT_INVITATION,
}
