import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/Layout/PageHeader'
import { Routes } from 'routes'
import AgenciesList from './AgenciesList'
import { PermissionType, FeatureName } from '../../types/features'
import { AppContext } from '../../contexts/AppContext'

const Agencies: React.FC = () => {
  const { t } = useTranslation()
  const { allowedFor } = useContext(AppContext)

  return (
    <>
      <div className='CommonTable__container'>
        <PageHeader
          allowedFor={allowedFor({
            feature: { name: FeatureName.Agencies, action: PermissionType.Write },
          })}
          link={Routes.AGENCIES.NEW}
          linkTooltip={t('common.createAgency')}
        >
          {t('agencies.endAgencies')}
        </PageHeader>
        <AgenciesList />
      </div>
    </>
  )
}

export default Agencies
