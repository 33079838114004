import { AuthorizationTemplate } from 'hooks/useAuthorize'
import { CampaignStatus, CreationStatus } from 'types/campaign'
import { ALL_ROLES } from './various'

export const EMISSION_SCHEDULE: AuthorizationTemplate = {
  SCHEDULE_BY_CAMPAIGN_STATUS: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  SCHEDULE_BY_CREATION_STATUS: {
    roles: ALL_ROLES,
    statuses: [CreationStatus.CREATED, CreationStatus.SENT, CreationStatus.EDITED],
  },
  LOCATIONS_BY_CAMPAIGN_STATUS: {
    roles: ALL_ROLES,
    statuses: [
      CampaignStatus.SKETCH,
      CampaignStatus.PLANNED,
      CampaignStatus.ACTIVE,
      CampaignStatus.RESERVED,
    ],
  },
  LOCATIONS_BY_CREATION_STATUS: {
    roles: ALL_ROLES,
    statuses: [CreationStatus.CREATED, CreationStatus.SENT, CreationStatus.EDITED],
  },
}
