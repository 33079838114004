import { gql } from '@apollo/client'

export const GET_INVENTORY_FOR_PROGRESS_BAR = gql`
  query inventoryForProgressBar(
    $agglomerations: [String!]
    $buildings: [String!]
    $cities: [String!]
    $mediaFormats: [MediumFormatEnum!]
    $mediumAsIds: [String!]
  ) {
    inventoryForProgressBar(
      agglomerations: $agglomerations
      buildings: $buildings
      cities: $cities
      mediaFormats: $mediaFormats
      mediumAsIds: $mediumAsIds
    ) {
      lastUpdate
      allocatedSlots
      freeSlots
    }
  }
`
