import { gql } from '@apollo/client'
import { MEDIUM_FIELDS } from '../fragments'

export const GET_MEDIA_USED_IN_CAMPAIGN = gql`
  ${MEDIUM_FIELDS}
  query mediaUsedInCampaign($campaignId: ID!) {
    mediaUsedInCampaign(campaignId: $campaignId) {
      nodes {
        ...MediumFields
      }
    }
  }
`

export const GET_MEDIA_USED_IN_CAMPAIGN_PUBLIC = gql`
  ${MEDIUM_FIELDS}
  query mediaUsedInCampaignPublic($campaignUuid: String!) {
    mediaUsedInCampaignPublic(campaignUuid: $campaignUuid) {
      nodes {
        ...MediumFields
      }
    }
  }
`
