import React, { useState } from 'react'
import Card from '../../../../components/Layout/Card'
import CardHeader from '../../../../components/Layout/CardHeader'
import SwitchButton from '../../../../components/Form/SwitchButton'

interface PermissionsCardProps {
  id: string
  title: string
  permissions: { name: string; value: boolean }[]
  permissionChange: (permissionElementId: string, permission: string, checked: boolean) => void
  disabled?: boolean
}

const PermissionsCard: React.FC<PermissionsCardProps> = ({
  id,
  title,
  permissions,
  permissionChange,
  disabled,
}) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Card className={'Roles__privileges--card'}>
      <CardHeader
        className={'Roles__privileges--card__header'}
        collapsed={open}
        onCollapse={() => {
          void setOpen(!open)
        }}
      >
        {title}
      </CardHeader>
      {open && (
        <div className={'Roles__privileges--card__list'}>
          {permissions.map(permission => {
            return (
              <div
                className={'item'}
                key={permission.name}
              >
                <span>{permission.name}</span>
                <SwitchButton
                  id={'usersViewRights'}
                  small={true}
                  checked={permission.value}
                  disabled={disabled}
                  onChange={val => {
                    permissionChange(id, permission.name, val)
                  }}
                ></SwitchButton>
              </div>
            )
          })}
        </div>
      )}
    </Card>
  )
}

export default PermissionsCard
