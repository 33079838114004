import React from 'react'
import TsxUtils from 'utils/tsx'
import './FormRow.scss'

export interface FormRowProps {
  className?: string
  children: React.ReactNode
}

const FormRow: React.FC<FormRowProps> = ({ children, className }) => (
  <div className={`FormRow` + TsxUtils.extraStyle(className, className)}>{children}</div>
)

export default FormRow
