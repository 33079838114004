import L, {
  DivIcon,
  divIcon,
  FeatureGroup,
  LatLng,
  LatLngBounds,
  Map,
  Marker,
  point,
} from 'leaflet'
import { PrepareMapProps, PrepareMarkersProps } from './types'
import { ATTRIBUTION, CLUSTER_GROUP_OPTIONS, MAP_OPTIONS, URL } from 'constant/map'

export default class MapService {
  public static map: Map | null = null
  public static zoom = 0
  public static center: LatLng = new LatLng(52, 19)

  static prepareMap = ({
    markersGroup,
    activeAreaClassName,
    zoomControlPosition,
  }: PrepareMapProps): Map => {
    const map: Map = L.map('map', {
      ...MAP_OPTIONS,
      layers: [
        L.tileLayer(URL, {
          attribution: ATTRIBUTION,
        }),
      ],
    })

    L.control
      .attribution({
        position: 'bottomleft',
      })
      .addTo(map)

    L.control
      .zoom({
        position: zoomControlPosition ?? 'topleft',
      })
      .addTo(map)

    if (activeAreaClassName) (map as any).setActiveArea(activeAreaClassName, true, true)

    markersGroup.addTo(map)

    return map
  }

  static addZoomControlKeyEvents = (map: Map) => {
    map.scrollWheelZoom.disable()
    document.addEventListener('keydown', e => MapService.handleZoomKeyDown(e, map))
    document.addEventListener('keyup', e => MapService.handleZoomKeyUp(e, map))
  }

  static handleZoomKeyDown = (e: KeyboardEvent, map: Map) => {
    if (e.key === 'Control' || e.key === 'Meta') {
      map.scrollWheelZoom.enable()
    }
  }

  static handleZoomKeyUp = (e: KeyboardEvent, map: Map) => {
    if (e.key === 'Control' || e.key === 'Meta') {
      map.scrollWheelZoom.disable()
    }
  }

  static prepareMarkerks = ({
    markersGroup,
    markers,
    withClustering,
  }: PrepareMarkersProps): void => {
    markersGroup.clearLayers()

    if (withClustering) {
      L.markerClusterGroup({
        ...CLUSTER_GROUP_OPTIONS,
        iconCreateFunction: cluster => MapService.createClusterIcon(cluster.getChildCount()),
      })
        .addLayers(markers)
        .addTo(markersGroup)
    } else {
      markers.forEach((m: Marker) => {
        m.addTo(markersGroup)
      })
    }
  }

  static createClusterIcon = (markersCount: number): DivIcon =>
    divIcon({
      html: `
      <div class='Map__cluster--circle'>
      <span class='Map__cluster--text'>${markersCount}</span>
      </div>`,
      className: 'Map__cluster',
      iconSize: point(36, 36, true),
    })

  static fitMapToMarkers = (map: Map, markersGroup: FeatureGroup): void => {
    const bounds: LatLngBounds = markersGroup.getBounds()
    if (bounds.isValid()) map.fitBounds(bounds)
  }
}
