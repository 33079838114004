import { Dispatch, SetStateAction } from 'react'
import api from 'api'
import { InventoryMediumsAvailabilityInfo } from '../models/types'
import { galleryOccupancyActions } from '../store/inventory-gallery-occupancy-slice'
import { store } from '../../../store'
import { topTenGalleriesActions } from '../store/inventory-top-ten-galleries-slice'
import { InventoryBuildingModel } from '../models/inventory-buiding.model'
import { GetInventoryFiltersVariables } from '../../../api/inventory/types'
import { mediaToFilters } from '../helpers/media-filters.helper'

export default class InventoryService {
  public static getInventoryAlerts = (
    setInventoryAlert: Dispatch<SetStateAction<InventoryMediumsAvailabilityInfo[] | null>>
  ): void => {
    api.inventory
      .getInventoryAlerts()
      .then(res => {
        setInventoryAlert(res.data.inventoryAlerts.nodes)
      })
      .catch(() => void {})
  }

  /**
   * Fetches the gallery occupancy data for common chart list
   *
   * @param fromDate
   * @param toDate
   * @param agglomerations
   * @param cities
   * @param pageNumber
   */
  public static fetchGalleryOccupancy = (
    fromDate: string,
    toDate: string,
    agglomerations: string[],
    cities: string[],
    pageNumber: number
  ): Promise<unknown> => {
    return Promise.resolve(store.dispatch(galleryOccupancyActions.setPageNumber(pageNumber)))
      .then(() => {
        return Promise.resolve(store.dispatch(galleryOccupancyActions.setLoading(true)))
      })
      .then(() => {
        const pageToFirst = pageNumber * 10
        const topTen = false
        const buildings: string[] = []

        return api.inventory.fetchInventoryBuildings(
          fromDate,
          toDate,
          topTen,
          cities,
          agglomerations,
          buildings,
          pageToFirst
        )
      })
      .then(({ data }) => {
        if (!data) {
          throw new Error('No data')
        }

        void store.dispatch(
          galleryOccupancyActions.setData({
            data: [...data.inventoryBuildings.nodes],
            totalItemsCount: data.inventoryBuildings.totalCount,
          })
        )
      })
      .finally(() => {
        void store.dispatch(galleryOccupancyActions.setLoading(false))
      })
  }

  public static fetchSingleGalleryOccupancyDetails = (
    fromDate: string,
    toDate: string,
    cities: string[],
    agglomerations: string[],
    buildings: string[] // should be only one building
  ): Promise<unknown> => {
    return Promise.resolve(store.dispatch(galleryOccupancyActions.setLoading(true)))
      .then(() => {
        const topTen = false

        return api.inventory.fetchInventoryBuildings(
          fromDate,
          toDate,
          topTen,
          cities,
          agglomerations,
          buildings
        )
      })
      .then(({ data }) => {
        if (!data) {
          throw new Error('No data')
        }

        // there is no name in the response, so we need to add it
        const mappedData = data.inventoryBuildings.nodes.map((node: InventoryBuildingModel) => {
          return {
            ...node,
            buildingName: buildings[0],
          }
        })

        void store.dispatch(galleryOccupancyActions.setSelectedBuildingData(mappedData))
      })
      .finally(() => {
        void store.dispatch(galleryOccupancyActions.setLoading(false))
      })
  }

  public static fetchTopTenGalleryOccupancy = (
    fromDate: string,
    toDate: string
  ): Promise<unknown> => {
    return Promise.resolve(store.dispatch(topTenGalleriesActions.setLoading(true)))
      .then(() => {
        const topTen = true

        return api.inventory.fetchInventoryBuildings(fromDate, toDate, topTen)
      })
      .then(({ data }) => {
        if (!data) {
          throw new Error('No data')
        }

        void store.dispatch(
          topTenGalleriesActions.setData({
            data: [...data.inventoryBuildings.nodes],
          })
        )
      })
      .finally(() => {
        void store.dispatch(topTenGalleriesActions.setLoading(false))
      })
  }

  public static fetchInventoryFilters = (
    payload: GetInventoryFiltersVariables
  ): Promise<unknown> => {
    return api.inventory
      .fetchInventoryFilters(payload)
      .then(({ data }) => {
        if (!data) {
          return
        }

        const mediaFilters = mediaToFilters(data.mediaFilter)

        const payload = {
          agglomerations: mediaFilters.agglomerations,
          cities: mediaFilters.cities,
        }

        void store.dispatch(galleryOccupancyActions.setAllFilters(payload))
      })
      .finally(() => {
        void store.dispatch(topTenGalleriesActions.setLoading(false))
      })
  }
}
