import { gql } from '@apollo/client'
import { MUTATION_STATE, SEND_ACCEPTANCES_FIELDS } from '../fragments'

const PAYLOAD = 'SendCampaignCreationsToAcceptancePayload'

export const SEND_TO_ACCEPTANCE = gql`
    ${SEND_ACCEPTANCES_FIELDS}
    ${MUTATION_STATE(PAYLOAD)}
    mutation sendCampaignCreationsToAcceptance($id: ID!) {
        sendCampaignCreationsToAcceptance(input: { campaignId: $id }) {
            ...SendToAcceptanceFields
            ...MutationState${PAYLOAD}Fields
        }
    }
`
