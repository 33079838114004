import React from 'react'
import 'rc-slider/assets/index.css'
import './SwitchButton.scss'
import Switch, { SwitchChangeEventHandler } from 'rc-switch'
import TsxUtils from '../../../utils/tsx'

interface SwitchButtonProps {
  id: string

  checked: boolean
  onChange: SwitchChangeEventHandler
  disabled?: boolean
  small?: boolean
  className?: string
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
  id,
  checked,
  onChange,
  disabled,
  small,
  className,
}) => {
  return (
    <Switch
      id={id}
      className={className + TsxUtils.extraStyle(small, ' rc-switch--small')}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    >
      test
    </Switch>
  )
}

export default SwitchButton
