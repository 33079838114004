import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonProps, ButtonSize, ButtonTheme } from './types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TsxUtils from 'utils/tsx'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Tooltip from 'components/Tooltip'
import './Button.scss'

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  disabled = false,
  icon,
  iconSvg,
  onClick,
  size = ButtonSize.STANDARD,
  theme = ButtonTheme.BLUE,
  toUrl,
  toUrlExternal,
  tooltip,
  type,
  dataCy,
}) => {
  const navigate = useNavigate()
  const [hover, setHover] = useState<boolean>(false)

  return (
    <button
      data-cy={dataCy}
      type={type}
      className={
        'Button' +
        ` Button--${theme}` +
        ` Button--${size}` +
        TsxUtils.extraStyle(!children && (icon || iconSvg), ` Button--${size}--only-icon`) +
        TsxUtils.extraStyle(className, className) +
        TsxUtils.extraStyle(disabled, 'Button--disabled')
      }
      onClick={(event): void => {
        if (disabled) return

        event?.preventDefault()
        event?.stopPropagation()

        onClick && onClick(event)

        if (toUrl) void navigate(toUrl)

        if (toUrlExternal) {
          const newWindow: Window | null = window.open(toUrlExternal, '_blank')
          if (newWindow) {
            newWindow.focus()
            newWindow.opener = null
          }
        }
      }}
      onMouseEnter={() => {
        if (tooltip) setHover(true)
      }}
      onMouseLeave={() => {
        if (tooltip) setHover(false)
      }}
      disabled={disabled}
    >
      <>
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            spin={icon === faSpinner}
            className={
              TsxUtils.extraStyle(children, 'Button__icon--margin') +
              TsxUtils.extraStyle(icon, 'Button__icon')
            }
          />
        )}

        {iconSvg && (
          <img
            src={iconSvg}
            className={TsxUtils.extraStyle(children, 'Button__icon--margin')}
          />
        )}

        {children}
        {tooltip && hover && <Tooltip>{tooltip}</Tooltip>}
      </>
    </button>
  )
}

export default Button
